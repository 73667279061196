/* set up landing page,products and appointment module json */

export const SETUPJSON = [
  {
    primary_heading_1: "Recommended for you",
    secondary_heading_1: "Enable or disable all your settings here",
    appointy_heading_primary: "APPOINTMENTS",
    appointy_heading_secondary: "Food Delivery",
    appointy_content: "The module streamlines appointment scheduling for smooth customer bookings and efficient management.",
    meeting_heading_primary: "MEETINGS",
    meeting_heading_secondary: "Chat Application",
    meeting_content: "Enable to effortlessly booking meetings with specific individuals and optimise time management.",
    product_heading_primary: "PRODUCTS/ SERVICES",
    product_heading_secondary: "Blog",
    product_content: "Showcase the products or services your offer to customers, facilitating a seamless buying experience.",
    smartpopup_heading_primary: "SMART POP-UPS",
    smartpopup_heading_secondary: "Design Tool",
    smartpopup_content: "Show real-time visitors counter,Boost sales using coupons,Promote new collections, Create FOMO, Urgency & Scarcity.",
    formbuilder_heading_primary: "FORM BUILDER",
    formbuilder_heading_secondary: "Design Tool",
    formbuilder_content: "Create customised forms to collect essential data from customers simplifying data collection processes.",
    bot_heading_primary: "BOT",
    bot_heading_secondary: "Chat Application",
    bot_content: "Automate interaction with the customers in handling common queries, providing instant responses, and enhancing customer support capabilities.",
    knowledge_base_heading_primary: "KNOWLEDGE BASE",
    knowledge_base_heading_secondary: "Chat Application",
    knowledge_base_content: "A self-help information center where customers can access articles to solve their problems and find relevant assistance.",
    announcments_heading_primary: "ANNOUNCEMENTS",
    announcments_heading_secondary: "Chat Application",
    announcments_content: "Increase product adoption rates, decreasing user frustration, and re-engaging inactive users and to intimate and update about latest information.",
    open: "Open"
  }
];

export const APPOINTMENT_FORM_JSON = [
  {
    back: "Back",
    heading_create_appointment: "Add Appointment Information",
    heading_edit_appointment: 'Update Appointment Information',
    name_label: 'Appointment Name',
    name_placeholder: "Enter appointment name",
    category_label: "Category Name",
    category_placeholder: "Select category",
    duration_label: "Appointment duration",
    duration_placeholder: "Select duration",
    label_online: "Online",
    label_price: "Staff special Price",
    price_online_placeholder: "Online Price",
    label_discount_type: "Discount type",
    select_placeholder: "Select",
    label_discount_value: "Discount Value",
    label_tax: "Tax",
    label_tax_type: "Tax type",
    label_stream_type: "Stream type",
    label_offline: "Offline",
    price_offline_placeholder: "Offline Price",
    label_doorstep: "Doorstep",
    doorstep_price_placeholder: "Doorstep Price",
    label_add_staff: "Add staff",
    label_assigned_staff: "Assigned staff",
    select_staff_placeholder: "Select staff",
    btn_special_price: "Special price",
    label_description: "Appointment Description",
    description_info: "Describe about appointment details here.",
    label_appointment_media: "Appointment Media",
    dropzone_label_1: "Drag a image here or ",
    dropzone_label_2: "Browse",
    dropzone_label_3: "for a image to upload",
    label_recommended: "Recommended size (1000px*1000px)",
    heading_special_Price: "Add Special Price",
    btn_create: "Create",
    btn_update: "update",
    btn_save: "Save"
  }
];

export const APPOINTMENTS_LIST_JSON = [
  {
    back: "Back",
    heading: "Appointments",
    btn_label_crt_aptmt: "Create new appointment",
    label_all_categories: "All categories",
    label_add_category: "Add Category",
    label_available: "Available Meetings",
    offline: "Offline",
    online: "Online",
    doorstep: "DoorStep",
    label_copy_link: "Copy link",
    label_appoint_info: "Add Category",
    label_category: "Appointment Category",
    label_edit_category: "Update Category",
    label_edit_appoint_info: "Update Category"
  }
];

export const CATEGORIES_JSON = [
  {
    search_placeholder: "Search Category",
    btn_add_category: "Category",
    col_label_2: "category",
    col_label_3: "Product count",
    col_label_4: "Status",
    col_label_5: "Action",
    label_add_category: "Category Information",
    label_add: "Add",
    label_update: "Update",
    label_category: "Product Category",
    label_description: "Category Description",
    label_image: "Image",
    category_name_required: "Category name",
    category_placeholder: "Enter",
    dropzone_label_1: "Drag a image here or",
    dropzone_label_2: "Browse",
    dropzone_label_3: "for a image to upload"
  }
];

export const ALL_PRODUCTS_JSON = [
  {
    btn_label_add_product: "Add Product",
    col_label_1: "Image",
    col_label_2: "Product",
    col_label_3: "Price",
    col_label_4: "Inventory",
    col_label_5: "Status",
    col_label_6: "Action"
  }
];

export const INVENTORY_JSON = [
  {
    col_label_2: "Product",
    col_label_3: "Inventory",
    col_label_4: "Unit/Variant",
    col_label_5: "Price",
    col_label_6: "Selling price",
    placeholder_enter: "Enter"
  }
];

export const CREATE_PRODUCT_JSON = [
  {
    back: "Back",
    label_quick_navigation: "Quick navigation",
    label_inventory_section: "Inventory",
    label_shipping_tax_section: "Shipping & Tax",
    label_variants_section: "Variants",
    label_product_media_section: "Product media",
    label_form_heading: "Product Information",
    label_add: "Add",
    label_update: "Update",
    label_product_name: "Name",
    placeholder_product_name: "Enter name",
    label_product_type: "Type",
    palceholder_product_type: "Type",
    label_category_name: "Category Name",
    placeholder_cateogoty_name: "Select category or add new",
    label_price: "Price",
    placeholder_price: "Enter price",
    label_discount_price: "Discount Price",
    placeholder_discount_price: "Enter discount price",
    label_product_unit: "Unit",
    placeholder_unit: "Enter units",
    placeholder_type: "Enter type",
    unit_error: "Unit",
    unit_type_error: "Unit type",
    label_product_description: "Description",
    // span_product_description: "Write high quality product descriptions",
    span_product_description: "Describe about product/service details here.",
    label_product_media_info: "(Upto 8)",
    label_product_media: "Media",
    label_1_file: "Drag a image here or",
    label_2_file: "Browse",
    label_3_file: "for a image to upload",
    file_size_suggestion: "Recommended size (1000px*1000px)",
    label_quantity: "Quantity",
    placeholder_quantity: "Eg:10",
    sku_id: "SKU ID",
    placeholder_sku_id: "Eg:34AID56HJD0",
    label_shippment_weight: "Shipment Weight",
    placeholder_shippment_weigh: "Enter Weight",
    placeholder_shippment_weigh_type: "Enter type",
    label_hsn_code: "HSN Code",
    placeholder_hsn: "Enter HSN Code",
    label_gst: "GST",
    placeholder_gst: "Enter GST Percentage %",
    label_variants_info: "(Add variants like color, size etc to the product.)",
    btn_label_add_variant: "Add Variants",
    btn_label_edit_variant: "Add/Edit Variants",
    col_label_2: "Product",
    col_label_3: "Price",
    col_label_4: "Selling price",
    col_label_5: "SKU Id",
    col_label_6: "Quantity",
    col_label_7: "Action",
    placholder_enter_here: "Enter",
    btn_type_label: "Button Type",
    option_buy_now: "Buy now",
    option_enquire_now: "Enquiry",
    btn_add: "Add",
    label_choose_unit: "Choose product unit",
    label_add_variants: "Add Variants",
    edit_label: "(Edit label)",
    input_variant_add: "Add",
    label_color: "Color",
    placeholder_color: "Choose color",
    label_edit: "Edit label",
    label_label_name: "Label name",
    placeholder_label_name: "Eg:Size,count..",
    btn_close: 'Close',
    btn_save: "Save",
    btn_update: "Update"
  }
];

export const PRODUCTS_LANDING_PAGE_JSON = [
  {
    tab_label_1: "Categories",
    tab_label_2: "All Products",
    tab_label_3: "Inventory"
  }
];