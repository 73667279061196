<div class="container-fluid">
    <div class="row p-2" *ngIf="loggedInUser.data.role === 'ADMIN'">
        <div class="col-md-12 h-35rem">
            <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom mt-0 me-3"
                (navChange)="tabChange($event)">
                <li [ngbNavItem]="1" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">General</span>
                    </a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="generalSettingsForm" (ngSubmit)="onGeneralSettingsDetailsSubmit()">
                            <div class="row mt-3">

                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.country}} <small
                                            class="text-danger">*</small></label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select placeholder='Select {{companysettings.country}}'
                                            formControlName="country">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                                    placeholderLabel="Search"
                                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let country of filteredBanks | async"
                                                value={{country.Entity}}>{{country.Entity}}</mat-option>
                                        </mat-select>
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="submited && f['country'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span *ngIf="f['country'].errors['required']">{{companysettings.country}}
                                                {{errorjson.required}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.currency}} <small
                                            class="text-danger">*</small></label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select placeholder='Select {{companysettings.currency}}'
                                            formControlName="currency">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="bankFilterCurrencyCtrl"
                                                    placeholderLabel="Search"
                                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let currency of filteredCurrencyBanks | async"
                                                value='{{currency.abbreviation +", "+currency.symbol}}'>{{currency.currency
                                                + " (" + currency.abbreviation + "," + currency.symbol +
                                                ")"}}</mat-option>
                                            <!-- <mat-option>
                                            <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                                placeholderLabel="Search"
                                                noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let currency of filteredBanks | async"
                                            value='{{currency.Currency.Currencies}}'>{{currency.Currency.Currencies}}</mat-option> -->
                                        </mat-select>
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="submited && f['currency'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span *ngIf="f['currency'].errors['required']">{{companysettings.currency}}
                                                {{errorjson.required}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.timezone}} <small
                                            class="text-danger">*</small></label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select placeholder='Select {{companysettings.timezone}}'
                                            formControlName="timezone">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="bankFilterTimezoneCtrl"
                                                    placeholderLabel="Search"
                                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let time_zone of filteredTimezoneBanks | async"
                                                value="{{time_zone.utc[0]}}">{{time_zone.text}}</mat-option>
                                        </mat-select>
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="submited && f['timezone'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span *ngIf="f['timezone'].errors['required']">{{companysettings.timezone}}
                                                {{errorjson.required}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.ccode}} <small
                                            class="text-danger">*</small></label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select placeholder='{{companysettings.ccode}}' formControlName="ccode">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                                    placeholderLabel="Search"
                                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let country of filteredBanks | async"
                                                [ngClass]="{'d-none': country.IAC === ''}"
                                                value={{country.IAC}}>{{country.IAC + " - " + country.Entity
                                                }}</mat-option>
                                        </mat-select>
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="submited && f['ccode'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span *ngIf="f['ccode'].errors['required']">{{companysettings.ccode}}
                                                {{errorjson.required}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.dateformat}}</label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select placeholder='Select {{companysettings.dateformat}}'
                                            formControlName="dateformat">
                                            <mat-option value="MMMM dd YYYY">MMMM DD, YYYY</mat-option>
                                            <mat-option value="MMM dd,YYYY">MMM DD, YYYY</mat-option>
                                            <mat-option value="YYYY-MM-dd">YYYY-MM-DD</mat-option>
                                            <mat-option value="MM/dd/YYYY">MM/DD/YYYY</mat-option>
                                            <mat-option value="dd/MM/YYYY">DD/MM/YYYY</mat-option>
                                            <mat-option value="YYYY/MM/dd">YYYY/MM/DD</mat-option>
                                            <mat-option value="dd.MM.YYYY">DD.MM.YYYY</mat-option>
                                            <mat-option value="MM.dd.YYYY">MM.DD.YYYY</mat-option>
                                            <mat-option value="dd MMM YYYY">DD MMM YYYY</mat-option>
                                            <mat-option value="dd MMMM YYYY">DD MMMM YYYY</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6 d-none">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.timeformat}}</label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-select placeholder='Select {{companysettings.timeformat}}'
                                            formControlName="timeformat">
                                            <mat-option value="H:MM A">12h</mat-option>
                                            <mat-option value="H:MM">24h</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="row mt-3">
                                    <label class="fw-600 mb-3">{{companysettings.booking_settings}}</label>
                                    <div class="col-sm-12 col-md-6">
                                        <label
                                            class="fw-600 font-size-14 mb-2">{{companysettings.slot_duration}}</label>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-select placeholder='{{companysettings.slot}}'
                                                formControlName="slot_duration">
                                                <mat-option value="30">30 MIN </mat-option>
                                                <mat-option value="45">45 MIN </mat-option>
                                                <mat-option value="60">60 MIN </mat-option>
                                            </mat-select>
                                            <mat-error class="error text-danger d-flex"
                                                *ngIf="submited && f['slot_duration'].errors">
                                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                                <span
                                                    *ngIf="f['slot_duration'].errors['required']">{{companysettings.slot}}
                                                    {{errorjson.required}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="mt-3 text-end">
                                    <button class="btn btn-light me-3" type="button"
                                        *ngIf="activatedRouter === '/onboarding'" (click)="onActiveChange(2)">
                                        Skip
                                    </button>
                                    <button class="btn btn-primary" type="submit">
                                        {{ activatedRouter === '/settings' ? companysettings.save_btn :
                                        companysettings.save_proceed_btn}}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">{{companysettings.heading}}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="companySettingsForm" (ngSubmit)="onCompanyDetailsSubmit()">
                            <div class="row mt-3">
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.company_name}} <small
                                            class="text-danger">*</small></label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <input style="outline: none" matInput
                                            placeholder='{{companysettings.company_name}}' formControlName="name"
                                            #myFirstInput autofocus="autofocus" />
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="isCompanySubmitted && companyForm['name'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span
                                                *ngIf="companyForm['name'].errors['required']">{{companysettings.company_name}}
                                                {{errorjson.required}}</span>
                                            <span *ngIf="companyForm['name'].errors['pattern']">{{errorjson.invalid}}
                                                {{companysettings.company_name}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.email}} <small
                                            class="text-danger">*</small></label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <input style="outline: none" matInput placeholder='{{companysettings.email}}'
                                            formControlName="email" />
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="isCompanySubmitted && companyForm['email'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span
                                                *ngIf="companyForm['email'].errors['required']">{{companysettings.email}}
                                                {{errorjson.required}}</span>
                                            <span *ngIf="companyForm['email'].errors['pattern']">{{errorjson.invalid}}
                                                {{companysettings.email}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.domain}} <small
                                            class="text-danger">*</small>
                                    </label>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <input style="outline: none" matInput placeholder='{{companysettings.domain}}'
                                            formControlName="website" />
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="isCompanySubmitted && companyForm['website'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span
                                                *ngIf="companyForm['website'].errors['required']">{{companysettings.domain}}
                                                {{errorjson.required}}</span>
                                            <span *ngIf="companyForm['website'].errors['pattern']">{{errorjson.invalid}}
                                                {{companysettings.domain}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.language}}</label>
                                    <mat-form-field appearance="outline" class="w-100 text-capitalize disable">
                                        <!-- <mat-select placeholder='Select {{companysettings.language}}'
                                            formControlName="language">
                                            <mat-option value="english">English</mat-option>
                                        </mat-select> -->
                                        <input style="outline: none" matInput formControlName="language"
                                            placeholder='{{companysettings.language}}' />
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.dsp}}</label>
                                    <ckeditor [editor]="Editor" placeholder="Description" formControlName="description"
                                        [config]="ckconfigQue" class="h-35rem"></ckeditor>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.social_links}}</label>
                                    <mat-form-field appearance="outline" class="w-100 mat-hint-none mb-2">
                                        <input style="outline: none" matInput formControlName="instagram_url"
                                            placeholder='{{companysettings.social_links_insta}}' />
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="w-100 mat-hint-none mb-2">
                                        <input style="outline: none" matInput formControlName="twitter_url"
                                            placeholder='{{companysettings.social_links_twitter}}' />
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="w-100 mat-hint-none mb-2">
                                        <input style="outline: none" matInput formControlName="linkedin_url"
                                            placeholder='{{companysettings.social_links_linkedin}}' />
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <input style="outline: none" matInput
                                            placeholder='{{companysettings.social_links_utube}}' />
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="fw-600 font-size-14 mb-2">{{companysettings.compony_logo}}</label>
                                    <div *ngIf="companySettingsForm.value.image" class="w-50">
                                        <img src="{{presignedurl}}{{companySettingsForm.value.image}}" alt=""
                                            width="150px">
                                    </div>
                                    <ngx-dropzone [accept]="'image/*'" (change)="onSelect($event)" [multiple]="false"
                                        class="bg-gray-light"
                                        [ngClass]="{'mt-4': companySettingsForm.value.image}"><!-- style="height: 100px;"-->
                                        <ngx-dropzone-label>{{companysettings.dropzone_label_1}} <span
                                                class="text-primary fw-500">{{companysettings.dropzone_label_2}}</span>
                                            {{companysettings.dropzone_label_3}}
                                        </ngx-dropzone-label>
                                        <ngx-dropzone-image-preview [removable]="true" (removed)="onRemove(f)"
                                            ngProjectAs="ngx-dropzone-preview" *ngFor="let f of company_logo"
                                            [file]="f">
                                        </ngx-dropzone-image-preview>
                                    </ngx-dropzone>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label
                                        class="fw-600 font-size-14 mb-2">{{companysettings.generate}}{{companysettings.business_url}}</label>
                                    <div
                                        class="bg-light border-radius-4 text-white d-flex align-items-center justify-content-between p-2">
                                        <p class="font-size-12 mb-0 d-none">{{Iframe_url}}</p><!---Flutter URL-->
                                        <p class="font-size-12 mb-0">{{companySettingsForm.value.slug}}</p><!--Iframe URL-->
                                        <div class="">
                                            <!---Flutter URL-->
                                            <!-- <mat-icon class="cursor"
                                                (cdkCopyToClipboardCopied)="onClipboardCopy($event)"
                                                matTooltip="Copy to clipboard" matTooltipPosition="above"
                                                #tp="matTooltip"
                                                [cdkCopyToClipboard]="Iframe_url">content_copy</mat-icon> -->
                                            <!--Iframe URL-->
                                            <mat-icon class="cursor"
                                                (cdkCopyToClipboardCopied)="onClipboardCopy($event)"
                                                matTooltip="Copy to clipboard" matTooltipPosition="above"
                                                #tp="matTooltip"
                                                [cdkCopyToClipboard]="'https://'+companySettingsForm.value.slug+'.worke.io'">content_copy</mat-icon>
                                            <mat-icon class="ms-2 cursor"
                                                (click)="businessURLUpdate(true)">edit</mat-icon>
                                        </div>
                                    </div>
                                    <mat-form-field appearance="outline" class="w-100 mt-3" *ngIf="editBusinessUrl">
                                        <input style="outline: none" matInput
                                            placeholder='{{companysettings.business_url}}' formControlName="slug" />
                                        <mat-error class="error text-danger d-flex"
                                            *ngIf="isCompanySubmitted && companyForm['slug'].errors">
                                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                            <span
                                                *ngIf="companyForm['slug'].errors['required']">{{companysettings.business_url}}
                                                {{errorjson.required}}</span>
                                            <span *ngIf="companyForm['slug'].errors['pattern']">
                                                {{errorjson.invalid}} {{companysettings.business_url}}</span>
                                        </mat-error>
                                    </mat-form-field>
                                    <label class="ms-1 mb- text-secondary mb-2 font-size-12"
                                        *ngIf="editBusinessUrl">{{companysettings.bussinessValidationText}}</label>
                                </div>
                                <div class="mt-3 text-end">
                                    <button class="btn btn-light me-3" type="button"
                                        *ngIf="activatedRouter === '/onboarding'" (click)="onActiveChange(3)">
                                        Skip
                                    </button>
                                    <button class="btn btn-primary" type="submit">
                                        {{activatedRouter === '/settings' ? companysettings.save_btn :
                                        companysettings.save_proceed_btn}}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">{{locationsettings.heading}}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="d-flex align-items-center justify-content-between mt-3">
                            <label class="fw-600 font-size-14">{{locationsettings.branch_location}}</label>
                            <button class="btn btn-primary d-flex align-items-center me-3"
                                (click)="addLocation(locationModel)">
                                <mat-icon>add</mat-icon>{{locationsettings.add_btn}}
                            </button>
                        </div>
                        <div class="table-responsive table-height mt-4" style="white-space: normal;">
                            <table class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
                                <thead>
                                    <tr>
                                        <th scope="col" class="text-primary pb-3">{{locationsettings.col_name}}</th>
                                        <th scope="col-3" class="text-primary pb-3" style="width: 50%;">
                                            {{locationsettings.col_address}}</th>
                                        <th scope="col" class="text-primary pb-3">{{locationsettings.col_status}}</th>
                                        <th scope="col" class="text-primary pb-3">{{locationsettings.col_action}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="cursor" *ngFor="let item of branches; let i = index">
                                        <td>
                                            {{ item.name }}
                                        </td>
                                        <td>
                                            <p class="">{{ item.location }}</p>
                                        </td>
                                        <td class="text-capitalize">
                                            <label class="switch ms-3">
                                                <input type="checkbox" [(ngModel)]="item.status"
                                                    (change)="toggleVisibility(item)">
                                                <span class="slider round"></span>
                                            </label>
                                        </td>
                                        <td>
                                            <mat-icon class="font-size-18"
                                                (click)="addLocation(locationModel,item)">edit</mat-icon>
                                            <mat-icon class="font-size-18" (click)="confirm(item?.id)">delete</mat-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="mt-3 text-end" *ngIf="activatedRouter === '/onboarding'">
                            <button class="btn btn-light me-3" type="button" (click)="onActiveChange(4)">
                                Skip
                            </button>
                            <button class="btn btn-primary" type="button" (click)="onActiveChange(4)">
                                {{locationsettings.proceed_btn}}
                            </button>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4" class="me-2" *ngIf="activatedRouter !== '/onboarding'">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">Working Hours</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-work-hours></app-work-hours>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="customNav"></div>
        </div>
    </div>
    <ng-container *ngIf="loggedInUser.data.role !== 'ADMIN'">
        <div class="d-flex align-items-center justify-content-center">
            <h6 class="text-secondary">You are not authorized to view this.</h6>
        </div>
    </ng-container>
</div>

<ng-template #locationModel let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">
            <span>{{locationData.value.id ? locationsettings.update_btn : locationsettings.add_btn}}</span>
            {{locationsettings.add_location}}
        </h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Close click')"></button>
    </div>
    <div class="modal-body">
        <form class="mt-3" [formGroup]="locationData">
            <div class="">
                <div class="row mt-3 d-flex align-items-center">
                    <div class="col-sm-12 col-md-12 text-end" *ngIf="locationData.value.id">
                        <label class="switch ms-3">
                            <input type="checkbox" [value]="locationData.value.status" formControlName="status">
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <label class="fw-600 font-size-14 mb-2">{{locationsettings.branch_location}} <small
                                class="text-danger">*</small></label>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder='{{locationsettings.branch_location}}'
                                id="pac-input-1" autocorrect="off" autocapitalize="off" spellcheck="off"
                                formControlName="location" (input)="initAutocomplete($event)" #locationFirstElement />
                            <mat-error class="error text-danger d-flex"
                                *ngIf="locationSubmit && locationForm['location'].errors">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <span
                                    *ngIf="locationForm['location'].errors['required']">{{locationsettings.branch_location}}
                                    {{errorjson.required}}</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <label class="fw-600 font-size-14 mb-2">{{locationsettings.branch_name}} <small
                                class="text-danger">*</small></label>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder='{{locationsettings.branch_name}}'
                                formControlName="name" />
                            <mat-error class="error text-danger d-flex"
                                *ngIf="locationSubmit && locationForm['name'].errors">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <span *ngIf="locationForm['name'].errors['required']">{{locationsettings.branch_name}}
                                    {{errorjson.required}}</span>
                                <span *ngIf="locationForm['name'].errors['pattern']">{{locationsettings.branch_name}}
                                    {{errorjson.invalid}}</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-12" *ngIf="locationData.value.id">
                        <div class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                formControlName="default" />
                            <label class="ms-1 text-secondary font-size-12" for="remember-me">
                                {{locationsettings.check_box_default}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-light me-2" type="button" (click)="modal.dismiss('Cross click')">
                    {{locationsettings.close_btn}}
                </button>
                <button class="btn btn-primary" type="submit"
                    (click)="onLocationSubmit()">{{locationsettings.save_btn}}</button>
            </div>
        </form>
    </div>
</ng-template>