export const TIMELINEJSON = [
  {
    heading: 'History',
    filter_label: 'Filter'
  }
];

export const NOTESJSON = [
  {
    title_placeholder: 'Specify a title',
    content_placeholder: "What's this note about?",
    attach_file_label: 'Attach File',
    title_btn_label: 'Add a Title',
    save_btn_label: 'Save',
    cancel_btn_label: 'Cancel'
  }
];

export const ACTIVITYJSON = [
  {
    task_btn_label: 'Task',
    event_btn_label: 'Event',
    call_btn_label: 'Call',
    activites_heading: 'Linked Activities',
    filter_label: 'Filter',
    activity_btn_label: 'Activity'
  }
];

export const FILESJSON = [
  {
    files_heading: 'Files',
    dropfile_placeholder_text1: 'Drag a file here or',
    dropfile_placeholder_text2: 'Browse',
    dropfile_placeholder_text3: 'for a file to upload',
    col_one_label: 'Name',
    col_two_label: 'Date modified',
    col_three_label: 'Size',
    col_four_label: 'Delete',
    col_five_label: 'Client visibility',
    remove_label: 'Remove'
  }
];

export const DEALSJSON = [
  {
    deals_label: 'Deals',
    col_one_label: 'Deal Name',
    col_two_label: 'Pipeline Name',
    col_three_label: 'Amount',
    col_four_label: 'Stage',
    mat_menu_search_label: 'Displayed Column',
    search_placeholder: 'Search',
    deal_info_label: 'Deal Information',
    deal_name_label: 'Deal Name'
  }
];

export const CONVERSATIONSJSON = [
  {
    start_conversation: 'Start New',
    send_btn_label: 'Send'
  }
];

export const PROJECTJSON = [
  {
    projectHeading: 'Projects',
    col_one_label: 'Name',
    col_two_label: 'Status',
    col_three_label: 'Client',
    col_four_label: 'Amount',
    col_five_label: 'Due Date'
  }
];

export const APPOINTMENTJSON = [
  {
    aptHeading: 'Appointments',
    col_one_label: 'Name',
    col_two_label: 'Client Name',
    col_three_label: 'Email',
    col_four_label: 'Phone Number',
    col_five_label: 'Amount',
    col_six_label: 'Due Date'
  }
];
