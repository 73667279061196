import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';

@Pipe({
  name: 'currencyConversion'
})
export class CurrencyConversionPipe implements PipeTransform {
  constructor(private authService: AuthService) { }

  transform(value: unknown, ...args: unknown[]): unknown {
    const symbol = this.authService.getCompanyData().currency?.split(',')[1];
    return `${symbol || '₹'}` + value;
  }

}
