<div class="modal-header">
    <h5 class="modal-title text-primary fw-600" *ngIf="!this.clientData?.id">{{addclient.heading}}</h5>
    <h5 class="modal-title text-primary fw-600" *ngIf="this.clientData?.id">Update Client</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="ngbActiveModal.dismiss()"></button>
</div>

<div class="modal-body">
    <form [formGroup]="clientForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2" *ngIf="this.clientData?.id">
                <label class="fw-600 mb-2 text-primary">{{addclient.label_name}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="name" placeholder={{addclient.label_name}} class="pt-1">
                        <mat-option *ngFor="let contact of contactListArray"
                            [value]="contact?.first_name">{{contact?.first_name}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex align-items-center"
                        *ngIf="submitted && f['name'].errors">
                        <mat-icon class="font-size-12 d-flex align-items-center me-1">info</mat-icon>
                        <span *ngIf="f['name'].errors['required']">{{addclient.label_name}}
                            {{errorJson.required}}</span>
                        <span *ngIf="f['name'].errors['pattern']">Only Alphanumeric are accepted.</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="!this.clientData?.id">
                <label class="fw-600 mb-2 text-primary">{{addclient.label_name}} <small
                        class="text-danger">*</small></label>
                <ng-select class="form-control" [items]="contactListArray" bindLabel="first_name" [addTag]="addNewName"
                    [multiple]="false" (change)="onSelectContact($event)" placeholder={{addclient.label_name}}
                    formControlName="name" (search)="searchContact($event)" (clear)="clearContactFields($event)">
                </ng-select>
                <div *ngIf="submitted && f['name'].errors"
                    class="text-danger error font-size-12 d-flex align-items-center">
                    <mat-icon class="font-size-12 d-flex align-items-center me-1">info</mat-icon>
                    <span *ngIf="f['name'].errors['required']">{{addclient.label_name}}
                        {{errorJson.required}}</span>
                    <span *ngIf="f['name'].errors['pattern']">{{errorJson.invalid}} {{addclient.label_name}}</span>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 text-primary">{{addclient.label_email}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder={{addclient.label_email}}
                        formControlName="email" />
                    <mat-error class="error text-danger d-flex align-items-center"
                        *ngIf="submitted && f['email'].errors">
                        <mat-icon class="font-size-12 d-flex align-items-center me-1">info</mat-icon>
                        <span *ngIf="f['email'].errors['required']">{{addclient.label_email}}
                            {{errorJson.required}}</span>
                        <span *ngIf="f['email'].errors['pattern']">{{errorJson.invalid}}
                            {{addclient.label_email}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{addclient.label_phone}} <small
                        class="text-danger">*</small></label>
                <div class="d-flex align-items-center">
                    <mat-form-field appearance="outline" class="me-1">
                        <mat-select placeholder="Country code" formControlName="ccode">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of filteredBanks | async"
                                [ngClass]="{'d-none': country.IAC === ''}"
                                value={{country.IAC}}>{{country.IAC}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="{{addclient.label_phone}}"
                            formControlName="phone_number" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['phone_number'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['phone_number'].errors['required']">
                                {{addclient.label_phone}} {{errorJson.required}}
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{addclient.label_company}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="source_id" placeholder="{{addclient.label_company}}" class="pt-1">
                        <mat-option *ngFor="let company of companiesListArray"
                            [value]="company.id">{{company.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 text-primary">{{addclient.label_tag}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="tag" placeholder="Select Tag" class="pt-1">
                        <mat-option [value]="item.id" *ngFor="let item of tagsListArray">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">Channel </label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="source" placeholder="Select Channel" class="pt-1">
                        <mat-option value="whatsapp">Whatsapp</mat-option>
                        <mat-option value="manual">Manual entry</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6">
                <label class="fw-600 font-size-14 mb-2">Country</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select placeholder='Select Country' formControlName="country">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let country of filteredBanks | async"
                            value={{country.Entity}}>{{country.Entity}}</mat-option>
                    </mat-select>
                    <mat-error class="error d-flex" *ngIf="submitted && f['country'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['country'].errors['required']">Country
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{addclient.owner}} <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="owner" placeholder={{addclient.owner}} class="pt-1">
                        <mat-option *ngFor="let member of staffMembers" [value]="member.id"
                            (click)="onOwnerSelect(member)">{{member.fname}}
                            {{member.lname}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['owner'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['owner'].errors['required']">
                            {{addclient.owner}} {{errorJson.required}}
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="mt-0">
            <div class="mt-3 d-flex justify-content-end">
                <div class="d-flex d-none" routerLink="settings/custom-fields" (click)="ngbActiveModal.dismiss()">
                    <span class="material-icons text-primary">settings_applications</span>
                    <span class="text-primary"></span> {{addclient.customize_text}}
                </div>
                <div class="d-flex">
                    <button class="btn btn-light me-2" type="button" (click)="ngbActiveModal.dismiss()">
                        {{addclient.cancel_btn}}
                    </button>
                    <button class="btn btn-primary" [disabled]="loading" type="submit">{{addclient.save_btn}}</button>
                </div>
            </div>
        </div>
    </form>
</div>