import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { FORMBUILDER } from 'src/app/core/data/form_builder_json';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { FormBuilderService } from 'src/app/core/service/form-builder.service';
import { SetUpService } from 'src/app/core/service/set-up.service';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit {

  tableHeads = [
    'Form Name',
    'Submission values',
    'Actions'
  ];
  forms_list: any = [];
  form: FormGroup;
  is_form_submitted = false;
  formbuilderJson = FORMBUILDER[0];
  errorJson = ERRORJSON[0];
  display_no_data = false;
  submitted = false;

  constructor(
    public modelService: NgbModal,
    private router: Router,
    private _formBuilderService: FormBuilderService,
    private formBuilder: FormBuilder,
    private confirmationMsz: ConfirmationMszService,
    private setupService: SetUpService
  ) {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9_\s]{1,}$/)]]
    });
  }

  ngOnInit(): void {
    this.getForms();

  }

  get f() {
    return this.form.controls;
  }

  getForms() {
    this._formBuilderService.getForms().subscribe((resp: any) => {
      this.forms_list = resp.data;
      this.display_no_data = false;
      if (this.forms_list.length === 0) this.display_no_data = true;
    });
  }

  openModel(modal: any) {
    this.modelService.open(modal);
  }

  createformbuilder() {
    this.submitted = true;
    if (!this.form.valid || this.is_form_submitted) {
      this.form.markAllAsTouched();
      return;
    }
    this.is_form_submitted = true;
    this._formBuilderService.createForm(this.form.value).subscribe((resp: any) => {
      this.router.navigate(["../form-settings"], { queryParams: { id: resp.data.id } });
      this.form.reset();
      this.modelService.dismissAll();
      this.getForms();
      this.is_form_submitted = false;
    });
  }

  deleteForm(id: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this._formBuilderService.deleteForm(id).subscribe((resp) => {
          this.getForms();
        });
      }
    });
  }

  featureShow() {
    return this.setupService.checkFeature('FORM BUILDER');
  }
}
