<div class="conversations h-100 p-3">
    <!-- <div class="start-conversation text-center">
        <p class="bg-conversation px-3 py-2 border-radius-8 d-inline-flex">{{date | date:'mediumDate'}}</p>
        <p class="text-secondary">{{convJSON.start_conversation}}</p>
    </div> -->
    <div class="middle-chat-section">
        <section class="middle">
            <div class="chat">
                <div class="messages-chat" id="syoft-msgContainer">
                    <ng-container *ngFor="let item of converstion">
                        <!-- <div class="message" *ngIf="item.type === 'EMAIL' && item.from_Staff === true">
                            <div class="response">
                                <div class="text">
                                    <p class="mb-1"><b>To: </b>{{item.message.to}}</p>
                                    <p><b>From: </b>{{item.message.from}}</p>
                                    <p *ngIf="item.message?.subject"><b>Subject: </b>{{item.message.subject}}</p>
                                    <div>
                                        <div [innerHTML]="item.message.body"></div>
                                    </div>
                                    <p class="mt-3 d-flex align-items-center" *ngIf="item.media_url">
                                        <b class="me-1">File: </b>
                                        <a class="d-flex align-items-center" href="{{item.media_url}}" target="_blank"
                                            download>{{item.media_name}}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div> -->
                        <div class="message" *ngIf="item.from_Staff === false && item.type === 'EMAIL'">
                            <p class="text text-radius">{{item.message.body}} </p>
                        </div>
                        <div class="message" *ngIf="item.from_Staff === true && item.type === 'text'">
                            <div class="response">
                                <p class="text">{{item.message.body}}</p>
                            </div>
                        </div>
                        <div class="message" *ngIf="item.from_Staff === false && item.type === 'img'">
                            <div class="photo">
                                <img class="chat-image"
                                    src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80">
                            </div>
                        </div>
                        <div class="message" *ngIf="item.type === 'EMAIL' && item.from_Staff === true">
                            <div class="response">
                                <div class="text">
                                    <p class="mb-1"><b>To: </b>{{item.message.to}}</p>
                                    <!-- <p><b>From: </b>{{item.message.from}}</p> -->
                                    <p *ngIf="item.message?.subject"><b>Subject: </b>{{item.message.subject}}</p>
                                    <div>
                                        <!-- <b>Body: </b> -->
                                        <div [innerHTML]="item.message.body"></div>
                                    </div>
                                    <p class="mt-3 d-flex align-items-center" *ngIf="item.media_url">
                                        <b class="me-1">File: </b>
                                        <a class="d-flex align-items-center" href="{{item.media_url}}" target="_blank"
                                            download>{{item.media_name}}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="converstion.length === 0 ">
                        <div class="text-center mt-5">
                            <p class="bg-conversation px-3 py-2 border-radius-8 d-inline-flex">{{date |
                                date:'mediumDate'}}</p>
                            <p class="text-secondary">{{convJSON.start_conversation}}
                            </p>
                        </div>
                    </ng-container>
                </div>
            </div>
        </section>
        <div class="d-flex align-items-center justify-content-end mt-4">
            <button class="btn btn-primary medium-filter border-radius-8 py-2 px-4 d-flex align-items-center"
                (click)="openMailTemplate(true)">
                <mat-icon class="me-2" style="color: rgb(255, 255, 255);">mail_outline</mat-icon>Compose Mail
            </button>
        </div>
        <!-- <div
            class="conversation-select bg-conversation d-flex align-items-center justify-content-between mt-1 p-3 border-radius-15">
            <mat-select class="medium-filter border-radius-15 py-2 px-3 bg-primary" [(ngModel)]="medium_type">
                <mat-option value="SMS">SMS</mat-option>
                <mat-option value="Mail">Mail</mat-option>
            </mat-select>
            <button class="btn btn-primary medium-filter border-radius-15 py-2 px-4" (click)="openMailTemplate(true)">
                Mail
            </button>
            <input type="text" class="border-0 py-2 w-60 bg-conversation" style="outline: none;"
                placeholder="Start conversation" [(ngModel)]="send_text">
            <img class="cursor" src="../../../../../assets/images/_link.svg" alt="attachment">
            <button class="btn btn-primary d-flex align-items-center border-radius-15 py-2 for-disabled"
                [disabled]="!send_text" (click)="sendMsg()">
                {{convJSON.send_btn_label}}
                <mat-icon class="ms-2" style="font-size: 22px;">send</mat-icon>
            </button>
        </div> -->
    </div>
</div>

<ng-container *ngIf="display_mail_popup">
    <div class="mail-template">
        <div class="modal-header bg-light px-4 py-3">
            <h5 class="text-white font-size-14 mb-0">New Message</h5>
            <mat-icon class="text-white cursor" (click)="openMailTemplate(false)">close</mat-icon>
        </div>
        <div class="d-flex align-items-center px-4 my-2 py-1">
            <p class="mb-0 me-3">To</p>
            <p class="mb-0 text-primary"><b>{{source_email}}</b></p>
        </div>
        <mat-divider class="my-0 mx-4"></mat-divider>
        <form [formGroup]="mail_template" (ngSubmit)="onMailSubmit()">
            <div class="px-4 my-2 py-1">
                <input class="border-0 bg-white w-100" type="text" placeholder="Subject" formControlName="subject">
            </div>
            <!-- <mat-divider class="my-0 mx-3"></mat-divider> -->
            <div class="modal-body px-4 py-1">
                <ckeditor [editor]="Editor" formControlName="msg_body" [config]="ckconfigQue">
                </ckeditor>
                <div class="d-flex align-items-center mt-3 w-100">
                    <button mat-raised-button matTooltip="Use AI to write the mail.Type the prompt and click here"
                        aria-label="Button that displays a tooltip when focused or hovered over" mat-button
                        type="button" class="btn btn-primary font-size-12"
                        (click)="workeAI(mail_template.value.msg_body)">Worke AI</button>
                    <div class="d-flex align-items-center justify-content-end w-100">
                        <div class="d-flex align-items-center" *ngIf="fileChoosen">
                            <p class="mb-0 me-2 ms-2 font-size-14 w-75 text-ellipsis">{{fileChoosen}}</p>
                            <mat-icon (click)="removeFile()">close</mat-icon>
                        </div>
                        <div class="d-flex align-items-center justify-content-end me-2 cursor w-75" id="upload-btn"
                            (change)="fileUpload($event)">
                            <label class="fw-400 cursor d-flex align-items-center" for="attach-file">
                                <mat-icon style="transform: rotate(152deg);">attachment</mat-icon>
                                <input type="file" id="attach-file"
                                    accept="image/*,video/*,audio/*,.pdf,.doc,.docx,.xml,.csv" hidden />
                            </label>
                        </div>
                    </div>
                    <!-- <div class="d-flex align-items-center justify-content-end"> -->
                    <button type="submit" class="btn btn-primary font-size-12">Send</button>
                    <!-- </div> -->
                </div>
            </div>

        </form>
    </div>
</ng-container>