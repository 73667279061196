import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { Page404Component } from './page404/page404.component';
import { MaterialModule } from '../core/common/material/material.module';
import { HomeModule } from './home/home.module';
import { ToastrModule } from 'ngx-toastr';
import { FormBuilderComponent } from './form-builder/form-builder.component';
import { CreateFormComponent } from './form-builder/create-form/create-form.component';
import { FormSettingsComponent } from './form-builder/form-settings/form-settings.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SettingsModelComponent } from './form-builder/create-form/settings-model/settings-model.component';
import { NotificationSettingsComponent } from './form-builder/form-settings/notification-settings/notification-settings.component';
import { FormIntegartionsComponent } from './form-builder/form-settings/form-integartions/form-integartions.component';
import { FormleadSettingsComponent } from './form-builder/form-settings/formlead-settings/formlead-settings.component';
import { FormthankUscreenComponent } from './form-builder/form-settings/formthank-uscreen/formthank-uscreen.component';
import { HeaderCalendarComponent } from './header-calendar/header-calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormSubmissionsComponent } from './form-builder/form-submissions/form-submissions.component';
import { FormShareComponent } from './form-builder/form-settings/form-share/form-share.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { ChatBotComponent } from './chat-bot/chat-bot.component';
import { NodataFoundComponent } from './nodata-found/nodata-found.component';
import { TablePopoverComponent } from './table-popover/table-popover.component';
import { PipesModule } from '../core/layouts/pipes/pipes.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { OnboardingScreensComponent } from './onboarding-screens/onboarding-screens.component';
import { SettingsModule } from './settings/settings.module';

@NgModule({
  declarations: [
    Page404Component,
    FormBuilderComponent,
    CreateFormComponent,
    FormSettingsComponent,
    SettingsModelComponent,
    NotificationSettingsComponent,
    FormIntegartionsComponent,
    FormleadSettingsComponent,
    FormthankUscreenComponent,
    HeaderCalendarComponent,
    FormSubmissionsComponent,
    FormShareComponent,
    ComingSoonComponent,
    ChatBotComponent,
    NodataFoundComponent,
    TablePopoverComponent,
    UserProfileComponent,
    OnboardingScreensComponent
  ],
  imports: [
    NgSelectModule,
    CommonModule,
    PagesRoutingModule,
    MaterialModule,
    // HomeModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right'
    }),
    NgbNavModule,
    FullCalendarModule,
    PipesModule,
    SettingsModule
  ],
  exports: [
    ComingSoonComponent,
    NodataFoundComponent,
    TablePopoverComponent
  ]
})
export class PagesModule { }
