<h6 class="text-primary">{{filesJSON.files_heading}}</h6>
<div class="row">
    <div class="col-10">
        <ngx-dropzone (change)="onSelect($event)" [multiple]="false" [draggable]="false" class="bg-gray-light"
            style="height: 61px;">
            <ngx-dropzone-label><span class="text-primary fw-500">{{filesJSON.dropfile_placeholder_text2}}</span>
                {{filesJSON.dropfile_placeholder_text3}}
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview [removable]="true" (removed)="onRemove(f)" ngProjectAs="ngx-dropzone-preview"
                *ngFor="let f of files" [file]="f">
            </ngx-dropzone-image-preview>
        </ngx-dropzone>
    </div>
    <div class="col-2 d-flex align-items-center justify-content-center">
        <div class="" style="width:60px;height:60px" *ngIf="show_loader">
            <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                <path fill="black"
                    d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                    <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s"
                        from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                </path>
            </svg>
        </div>
        <button *ngIf="!show_loader" class="btn btn-primary px-4" type="button" [disabled]="!this.files[0]" (click)="onUpload()">Upload</button>
    </div>
</div>

<div class="table-responsive table-height mt-4">
    <table class="table align-middle table-nowrap table-hover mb-0 file-table">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">{{filesJSON.col_one_label}}</th>
                <th scope="col">{{filesJSON.col_two_label}}</th>
                <!-- <th scope="col">{{filesJSON.col_three_label}}</th> -->
                <th scope="col">{{filesJSON.col_four_label}}</th>
                <th scope="col" *ngIf="is_task !== 'task'">{{filesJSON.col_five_label}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of filesListArray; let i= index;">
                <td>
                    {{i+1}}
                </td>
                <td>
                    <a href="{{presignedurl}}{{item.media_url}}" target="_blank" class="text-dark fw-medium" download>
                        {{item.name}}
                    </a>
                </td>
                <td>{{item.updated_at | dateConversion }}</td>
                <!-- <td>
                    {{ ( item.size/1024 | number : '1.0-0') + ' KB'}}
                </td> -->
                <td>
                    <!-- <div class="dropdown" ngbDropdown>
                        <a class="font-size-16 text-muted dropdown-toggle" role="button" ngbDropdownToggle
                            data-toggle="dropdown" aria-haspopup="true"> -->
                    <mat-icon class="cursor" (click)="confirmDelete(item.id)">delete</mat-icon>
                    <!-- </a>

                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                            <div class="dropdown-divider"></div>
                            <a ngbDropdownItem href="javascript: void(0);">{{filesJSON.remove_label}}</a>
                        </div> -->
                    <!-- </div> -->
                </td>
                <td *ngIf="is_task !== 'task'">
                    <label class="switch">
                        <input type="checkbox" [id]="item.id" [(ngModel)]="item.is_visible"
                            (change)="toggleVisibility($event,item)">
                        <span class="slider round"></span>
                    </label>
                </td>
            </tr>
        </tbody>
    </table>
    <div>
        <mat-paginator [length]="count" [pageSize]="10" (page)="page($event)" aria-label="Select page"
            showFirstLastButtons>
        </mat-paginator>
    </div>
</div>