<div class="container-fluid">
    <div class="row mt-3 mb-4">
        <div class="col-md-4 d-flex align-items-center">
            <!-- <mat-icon class="text-primary">arrow_back_ios </mat-icon> -->
            <p class="m-0 heading font-size-18 fw-700 text-primary">{{ companiesJson.heading_label }}</p>
            <mat-form-field appearance="outline" class="contactsfilter main-filter ms-3 mat-hint-none">
                <mat-select placeholder="{{ companiesJson.heading_label }}" (ngModelChange)="onFilterChange($event)"
                    [(ngModel)]="filter" class="pt-1">
                    <mat-option class="font-size-12" [value]="list.value" *ngFor="let list of companiesdropDown">{{
                        list.label
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-8 d-flex justify-content-end align-items-center">
            <mat-form-field appearance="outline" class="me-3 mat-hint-none position-relative searching-box"
                style="top: 2px">
                <input style="outline: none" matInput [(ngModel)]="search_global" (ngModelChange)="search($event)"
                    placeholder={{companiesJson.search_placeholder}} />
                <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>

            <button class="btn btn-primary d-flex align-items-center me-3 font-size-13" (click)="openAddCompanyModal()">
                <mat-icon>add</mat-icon>
                {{companiesJson.label_add_companie}}
            </button>
            <button mat-raised-button matTooltip="Add Task Board Stages" *ngIf="loggedInUser.data.role === 'ADMIN'"
                aria-label="Button that displays a tooltip when focused or hovered over" type="button"
                class="btn border-0 p-0 d-flex align-items-center"
                (click)="openTaskBoardPreview()"><!--[disabled]="selection.selected.length == 0"-->
                <span class="p-1 me-3 rounded bg-gray-light d-flex align-items-center justify-content-center">
                    <!-- <mat-icon class="font-size-26 position-relative" style="top: 5px">add</mat-icon> -->
                    <img src="../../../assets/images/icons_add.svg" class="cursor" width="16">
                </span>
            </button>
            <button mat-raised-button matTooltip="Export"
                aria-label="Button that displays a tooltip when focused or hovered over" type="button"
                class="btn border-0 p-0 d-flex align-items-center"><!--[disabled]="selection.selected.length == 0"-->
                <mat-icon class="me-3 text-primary" (click)="downloadCompanies()">exit_to_app</mat-icon>
            </button>
            <button mat-raised-button matTooltip="Import"
                aria-label="Button that displays a tooltip when focused or hovered over" type="button"
                class="btn border-0 p-0 d-flex align-items-center">
                <mat-icon class="me-3 text-primary"
                    (click)="importModalDialog(importExportModel, 'import')">move_to_inbox</mat-icon>
            </button>
            <!-- <mat-icon class="me-3 text-primary">local_offer</mat-icon>
            <mat-icon class="me-3 text-primary">cloud_upload</mat-icon> -->
            <!-- <mat-icon class="me-3"> reorder</mat-icon> -->
            <!-- <div class="d-flex contacts-filter-lable">
                <span class="me-3 text-primary">{{companiesJson.Filter_label}}</span>
                <span mat-raised-button class="menuTrigger text-primary material-icons cursor"
                    [matMenuTriggerFor]="filter_search_menu">filter_list</span>
                <mat-menu #filter_search_menu="matMenu">
                    <mat-form-field appearance="outline" class="mx-3 my-2 mat-hint-none"
                        (click)="$event.stopPropagation()">
                        <input style="outline: none" matInput placeholder={{companiesJson.search_placeholder}} />
                        <mat-icon matPrefix>search</mat-icon>
                    </mat-form-field>

                    <div class="" *ngFor="let item of filtersLables; let i = index">
                        <button mat-menu-item id="{{ i }}" (click)="selectFilterLable($event)">
                            <mat-checkbox [checked]="item.checked">{{ item.labelName }}</mat-checkbox>
                        </button>
                    </div>
                </mat-menu>
            </div> -->
        </div>
    </div>

    <div class="table-responsive table-height mt-4 fixed-table">
        <table class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
            <thead>
                <tr class="border-top-table">
                    <th scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            class="mat_checkbox"></mat-checkbox>
                    </th>
                    <ng-container *ngFor="let col of filtersLables">
                        <th *ngIf="col.checked" scope="col"
                            class="text-primary font-size-14 pb-3 table-sticky-header bg-white" style="z-index: 999;">
                            {{col.labelName}}
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody *ngIf="!display_no_data">
                <tr class="cursor" *ngFor="let item of companiesListArray; let i = index"
                    [routerLink]="['../crm-details']" [queryParams]="{ id: item.id, showtype: 'company'}">
                    <td>
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(item) : null" [checked]="selection.isSelected(item)"
                            class="mat_checkbox"></mat-checkbox>
                    </td>
                    <td>
                        <app-table-popover [inputType]="'text'" [inputValue]="item.name" [labelName]="'Name'"
                            [formName]="'name'" (update)="onUpdatePopOver($event, item)"></app-table-popover>
                    </td>
                    <td>{{item.email ? item.email : "-"}}</td>
                    <td>
                        <app-table-popover [inputType]="'text'" [inputValue]="item.phonenumber"
                            [labelName]="'Mobile Number'" [formName]="'phonenumber'"
                            (update)="onUpdatePopOver($event, item)"></app-table-popover>
                    </td>
                    <td>
                        <app-table-popover [inputType]="'text'" [inputValue]="item.website" [labelName]="'Website'"
                            [formName]="'website'" (update)="onUpdatePopOver($event, item)"></app-table-popover>
                    </td>
                    <!-- <td>{{item.tags}}</td> -->
                    <!-- <td>Total contacts</td> -->
                    <td>
                        <app-table-popover [inputType]="'dropdown'" [inputValue]="item.country" [labelName]="'Country'"
                            [formName]="'country'" [dropDownItems]="countryjson" [selectedItem]="item.country"
                            (update)="onUpdatePopOver($event, item)"></app-table-popover>
                    </td>
                    <td>
                        <app-table-popover [inputType]="'dropdown'"
                            [inputValue]="(item.info?.owner_info?.fname || '-') + ' ' + (item.info?.owner_info?.lname || '')"
                            [formName]="'info.owner_info'" [labelName]="'Owner'" [dropDownItems]="staffMembers"
                            [selectedItem]="item.owner" (update)="onUpdatePopOver($event, item)"></app-table-popover>
                    </td>

                    <td>{{item.source ? item.source : "-"}}</td>
                    <!-- <td>{{item.updated_at| date: 'd/M/yy, h:mm a'}}</td> -->
                    <!-- <td>followers</td>
                    <td>{{item.city}}</td>
                    <td>Number Of Employees</td>
                    <td>Annual Revenue</td>
                    <td>Skype</td> -->
                    <!-- <td>Other Phone</td> -->
                    <!-- <td>Other Email</td> -->
                    <!-- <td>Linkedin</td>
                    <td>Twitter</td>
                    <td>Instagram</td>
                    <td>Facebook</td>
                    <td>State</td>-->
                    <!-- <td>{{item.zipcode}}</td> -->
                    <!--<td>{{item.address}}</td>
                    <td>Address Line 2</td>
                    <td>Shipping City</td>
                    <td>Shipping State</td>
                    <td>Shipping Country</td>
                    <td>Shipping Zipcode</td>
                    <td>Shipping Address Line 1</td>
                    <td>Shipping Address Line 2</td>
                    <td>Total Activities</td>
                    <td>Done Activities</td>
                    <td>Activities To Do</td>
                    <td>Next Activities Date</td>
                    <td>Last Activities Date</td>
                    <td>Lost Deals</td>
                    <td>Open Deals</td>
                    <td>Closed Deals</td>
                    <td>Industry</td> -->
                    <td>{{(item.created_at | dateConversion) || '-'}}</td>
                    <td (click)="$event.stopPropagation()">
                        <div class="dropdown" ngbDropdown>
                            <mat-icon style="z-index: 99;" [matMenuTriggerFor]="menuadd">more_vert</mat-icon>
                            <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                                <button mat-menu-item (click)="openAddCompanyModal(item)" class="py-2">
                                    Edit
                                </button>
                                <button mat-menu-item (click)="confirmDelete(item.id)" class="py-2">
                                    Delete
                                </button>
                            </mat-menu>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
    </div>
    <div>
        <mat-paginator [length]="companiesObj.total" [pageSize]="10" (page)="page($event)" aria-label="Select page"
            hidePageSize="true" showFirstLastButtons>
        </mat-paginator>
    </div>
</div>

<ng-template #importExportModel let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-primary">
            {{companiesJson.label_import}}
        </h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="contact-dotted-border text-center mb-3">
            <input type="file" class="form-control" (change)="onFileChange($event)" />
        </div>
        <a class="cursor" (click)="downloadSampleFile()">Download Sample
            File</a>

        <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-primary" [disabled]="!enableImportBtn" (click)="importCompanies()">
                {{companiesJson.label_import}}
            </button>
        </div>
    </div>
</ng-template>