<div class="container-fluid mt-3">
    <h5 class="heading font-size-18 fw-700">{{formintegrationJson.heading}}</h5>
    <div class="row mt-3">
        <div class="col-md-12">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <ul ngbNav #customNav="ngbNav" class="nav-tabs nav-tabs-custom mt-0 me-3">
                            <li [ngbNavItem]="1" class="me-2">
                                <a ngbNavLink class="d-flex">
                                    <span class="d-sm-block font-size-14">{{formintegrationJson.tab_one}}</span>
                                </a>
                                <ng-template ngbNavContent>
                                    <div class="text-center p-5" *ngIf="webHookList.length === 0">
                                        <h6 class="fw-600">{{formintegrationJson.tab_heading}}</h6>
                                        <p>{{formintegrationJson.webtook_dsp}}
                                            <br>{{formintegrationJson.webtook_dsp_two}}
                                        </p>
                                        <button class="btn btn-primary"
                                            (click)="openWebhookModal(webhookmodal)">{{formintegrationJson.btn_add_hook}}</button>
                                    </div>
                                    <div *ngIf="webHookList.length > 0">
                                        <div class="d-flex justify-content-end mr-auto">
                                            <button class="btn btn-primary my-2 "
                                                (click)="openWebhookModal(webhookmodal)">{{formintegrationJson.btn_add_hook}}</button>
                                        </div>

                                        <table class="table align-middle table-nowrap table mb-0 table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>{{formintegrationJson.table_series}}</th>
                                                    <th>{{formintegrationJson.feild_url}}</th>
                                                    <th>{{formintegrationJson.field_body}}</th>
                                                    <th>{{formintegrationJson.actions}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr *ngFor="let item of webHookList; let i=index;">
                                                    <td>
                                                        {{ i+ 1 }}
                                                    </td>
                                                    <td>{{item.url}}</td>
                                                    <td>{{item.body}}</td>
                                                    <td>
                                                        <mat-icon style="font-size: 22px;" (click)="openWebhookModal(webhookmodal, item)">edit</mat-icon>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="customNav"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- As of not used but future case might required -->
<ng-template #webhookmodal let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-primary">{{formintegrationJson.model_heading}}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="row mb-3">
            <form [formGroup]="webhookForm">
                <div class="mt-3">
                    <div class="">
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder="{{formintegrationJson.feild_url}}"
                                formControlName="url" />
                        </mat-form-field>
                    </div>
                    <div class="">
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder="{{formintegrationJson.field_header}}"
                                formControlName="headers" />
                        </mat-form-field>
                    </div>
                    <div class="">
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder="{{formintegrationJson.field_body}}"
                                formControlName="body" />
                        </mat-form-field>
                    </div>
                    <div class="">
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder="{{formintegrationJson.field_params}}"
                                formControlName="params" />
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-end">
        <button class="btn btn-light" (click)="modal.dismiss('cross click')">{{formintegrationJson.btn_cancel}}</button>
        <button type="button" class="btn btn-primary small" (click)="submitWebhook()">
            {{formintegrationJson.btn_save}}
        </button>
    </div>
</ng-template>