<div>
    <div cdkDropListGroup class="d-flex dropListGroup flex-grow-1">
        <div class="main-container">
            <mat-card class="example-card">
                <mat-card-header>
                    <div class="container">

                        <p class="field-title text-primary">Enquiry</p>

                        <div>
                            <mat-icon
                                class="font-size-16 me-1 my-auto fw-600 c-gray material-icons-outlined">edit</mat-icon>
                            <mat-icon class="font-size-16 me-1 my-auto">more_vert</mat-icon>
                            <!-- <span>...</span> -->
                        </div>
                    </div>
                </mat-card-header>

                <mat-card-content>
                    <div class="example-list dropList">
                        <p class="text-primary">Contact Information</p>
                        <div class="row mt-3">
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Email' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Contact Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Company Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Pipeline & stage' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Amount' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Follow-up date' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Description' />
                                </mat-form-field>
                            </div>
                            <p class="text-primary">Address Information</p>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <p class="text-primary">Additional Information</p>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>


                        </div>
                    </div>
                </mat-card-content>
                <div class="d-flex px-3 text-primary py-3 cursor" (click)="openCustomFieldsDialog()">
                    <span class="material-icons text-primary">settings_applications</span>Customize Fields
                </div>
            </mat-card>
        </div>
        <div class="main-container">
            <mat-card class="example-card">
                <mat-card-header>
                    <div class="container">

                        <p class="field-title text-primary">Contacts</p>

                        <div>
                            <mat-icon
                                class="font-size-16 me-1 my-auto fw-600 c-gray material-icons-outlined">edit</mat-icon>
                            <mat-icon class="font-size-16 me-1 my-auto">more_vert</mat-icon>
                            <!-- <span>...</span> -->
                        </div>
                    </div>
                </mat-card-header>

                <mat-card-content>
                    <div class="example-list dropList">
                        <p class="text-primary">Contact Information</p>
                        <div class="row mt-3">
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Email' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Contact Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Company Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Pipeline & stage' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Amount' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Follow-up date' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Description' />
                                </mat-form-field>
                            </div>
                            <p class="text-primary">Address Information</p>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <p class="text-primary">Additional Information</p>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>


                        </div>
                    </div>
                </mat-card-content>
                <!-- <div class="d-flex px-3 text-primary py-3">
                    <span class="material-icons text-primary">settings_applications</span>Customize Fields
                </div> -->
                <div class="d-flex px-3 text-primary py-3 cursor" (click)="openCustomFieldsDialog()">
                    <span class="material-icons text-primary">settings_applications</span>Customize Fields
                </div>
            </mat-card>
        </div>
        <div class="main-container">
            <mat-card class="example-card">
                <mat-card-header>
                    <div class="container">

                        <p class="field-title text-primary">Company</p>

                        <div>
                            <mat-icon
                                class="font-size-16 me-1 my-auto fw-600 c-gray material-icons-outlined">edit</mat-icon>
                            <mat-icon class="font-size-16 me-1 my-auto">more_vert</mat-icon>
                            <!-- <span>...</span> -->
                        </div>
                    </div>
                </mat-card-header>

                <mat-card-content>
                    <div class="example-list dropList">
                        <p class="text-primary">Contact Information</p>
                        <div class="row mt-3">
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Email' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Contact Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Company Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Pipeline & stage' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Amount' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Follow-up date' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Description' />
                                </mat-form-field>
                            </div>
                            <p class="text-primary">Address Information</p>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <p class="text-primary">Additional Information</p>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>


                        </div>
                    </div>
                </mat-card-content>
                <div class="d-flex px-3 text-primary py-3">
                    <span class="material-icons text-primary">settings_applications</span>Customize Fields
                </div>
            </mat-card>
        </div>
        <div class="main-container">
            <mat-card class="example-card">
                <mat-card-header>
                    <div class="container">

                        <p class="field-title text-primary">Client</p>

                        <div>
                            <mat-icon
                                class="font-size-16 me-1 my-auto fw-600 c-gray material-icons-outlined">edit</mat-icon>
                            <mat-icon class="font-size-16 me-1 my-auto">more_vert</mat-icon>
                            <!-- <span>...</span> -->
                        </div>
                    </div>
                </mat-card-header>

                <mat-card-content>
                    <div class="example-list dropList">
                        <p class="text-primary">Contact Information</p>
                        <div class="row mt-3">
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Email' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Contact Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Company Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Pipeline & stage' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Amount' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Follow-up date' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Description' />
                                </mat-form-field>
                            </div>
                            <p class="text-primary">Address Information</p>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <p class="text-primary">Additional Information</p>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>


                        </div>
                    </div>
                </mat-card-content>
                <div class="d-flex px-3 text-primary py-3">
                    <span class="material-icons text-primary">settings_applications</span>Customize Fields
                </div>
            </mat-card>
        </div>
        <div class="main-container">
            <mat-card class="example-card">
                <mat-card-header>
                    <div class="container">

                        <p class="field-title text-primary">Client Projects</p>

                        <div>
                            <mat-icon
                                class="font-size-16 me-1 my-auto fw-600 c-gray material-icons-outlined">edit</mat-icon>
                            <mat-icon class="font-size-16 me-1 my-auto">more_vert</mat-icon>
                            <!-- <span>...</span> -->
                        </div>
                    </div>
                </mat-card-header>

                <mat-card-content>
                    <div class="example-list dropList">
                        <p class="text-primary">Contact Information</p>
                        <div class="row mt-3">
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Email' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Contact Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Company Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Pipeline & stage' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Amount' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Follow-up date' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Description' />
                                </mat-form-field>
                            </div>
                            <p class="text-primary">Address Information</p>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <p class="text-primary">Additional Information</p>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>


                        </div>
                    </div>
                </mat-card-content>
                <div class="d-flex px-3 text-primary py-3">
                    <span class="material-icons text-primary">settings_applications</span>Customize Fields
                </div>
            </mat-card>
        </div>
        <div class="main-container">
            <mat-card class="example-card">
                <mat-card-header>
                    <div class="container">

                        <p class="field-title text-primary">Deals</p>

                        <div>
                            <mat-icon
                                class="font-size-16 me-1 my-auto fw-600 c-gray material-icons-outlined">edit</mat-icon>
                            <mat-icon class="font-size-16 me-1 my-auto">more_vert</mat-icon>
                            <!-- <span>...</span> -->
                        </div>
                    </div>
                </mat-card-header>

                <mat-card-content>
                    <div class="example-list dropList">
                        <p class="text-primary">Contact Information</p>
                        <div class="row mt-3">
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Email' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Contact Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Company Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Pipeline & stage' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Amount' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Follow-up date' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Description' />
                                </mat-form-field>
                            </div>
                            <p class="text-primary">Address Information</p>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <p class="text-primary">Additional Information</p>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='First Name' />
                                </mat-form-field>
                            </div>


                        </div>
                    </div>
                </mat-card-content>
                <div class="d-flex px-3 text-primary py-3">
                    <span class="material-icons text-primary">settings_applications</span>Customize Fields
                </div>
            </mat-card>
        </div>
    </div>
</div>