<img src="assets/images/nav_logo.svg" width="55px" class="my-4 d-flex m-auto">
<div *ngFor="let menuGroup of menuList" class="text-white cursor mb-2" [routerLinkActive]="['is_active']" [routerLink]="[menuGroup.link]">
    <div class="d-flex flex-column align-items-center menu-group py-1" *ngIf="!menuGroup?.subItems"
    (mouseenter)="buttonEnter()">
        <!-- <mat-icon class="icon">{{menuGroup.icon}}</mat-icon> -->
        <img src="{{menuGroup.image}}" width="16" alt="{{ menuGroup.label }}" class="pt-1">
        <span class="font-size-11 fw-400 py-1"> {{ menuGroup.label }}</span>
    </div>
    <div class="d-flex flex-column align-items-center py-1" *ngIf="menuGroup?.subItems?.length>0"
        [ngClass]="{'is_active': levelOneTrigger.menuOpen}" aria-expanded="false">
        <button #button mat-button [matMenuTriggerFor]="menu" #levelOneTrigger="matMenuTrigger" (mouseenter)="buttonEnter(levelOneTrigger)" (mouseleave)="buttonLeave(levelOneTrigger, button)"
            (mouseenter)="levelOneTrigger.openMenu()" (menuOpened)="menuOpened(levelOneTrigger)" (menuClosed)="menuClosed(levelOneTrigger)"
            class="d-flex flex-column align-items-center menu-btn text-white">
            <!-- <mat-icon class="icon">{{menuGroup.icon}}</mat-icon> -->
            <img src="{{menuGroup.image}}" width="16" class="pt-1">
            <span class="font-size-11 fw-400 py-1"> {{ menuGroup.label }}</span>
        </button>
        <mat-menu #menu="matMenu" xPosition="before" class="matmenu-list" hasBackdrop="false">
            <span (mouseleave)="levelOneTrigger.closeMenu()">
                <ng-container *ngFor="let subItem of menuGroup.subItems">
                    <li [routerLink]="subItem.link" class="p-0" *ngIf="subItem" mat-menu-item>
                        <a class="nav-link font-size-13">{{subItem.label}}
                        </a>
                    </li>
                </ng-container>
            </span>
        </mat-menu>
    </div>
</div>