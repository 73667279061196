<div class="container-fluid">
    <ng-container *ngIf="loggedInUser.data.role === 'ADMIN'">
        <div class="d-flex justify-content-between mt-3">
            <div class="heading font-size-16 fw-700 text-primary">Tags</div>
            <div class="d-flex align-items-center justify-content-around">
                <button class="btn btn-primary d-flex align-items-center me-3" (click)="openAddTagModal(addTag)">
                    <mat-icon>add</mat-icon>Add Tag
                </button>
                <mat-form-field appearance="outline" class="tagsfilter ms-3 mat-hint-none d-none">
                    <mat-select placeholder="All" class="pt-1">
                        <mat-option value="All">All</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="table-responsive table-height mt-4 h-80 overflow-auto">
            <table class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
                <thead>
                    <tr>
                        <!-- <th scope="col">
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                        </th> -->
                        <th scope="col" class="text-primary pb-3" *ngFor="let col of column_names">{{col}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="" *ngFor="let item of tagsListArray; let i = index">
                        <!-- <td>
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(item) : null"
                                [checked]="selection.isSelected(item)"></mat-checkbox>
                        </td> -->
                        <td>
                            {{ item.name }}
                        </td>
                        <td>
                            <p class="border-radius-50 m-0 p-2 tag-color" [style]="{'background-color': item.colour}">
                            </p>
                        </td>
                        <td>
                            <div class="d-flex align-items-center">
                                <mat-icon class="me-2 font-size-18 cursor"
                                    (click)="openAddTagModal(addTag,item)">edit</mat-icon>
                                <mat-icon class="font-size-18 cursor" (click)="deleteMeeting(item.id)">delete</mat-icon>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <mat-paginator [length]="totalTags" [pageSize]="10" (page)="page($event)" aria-label="Select page"
                    hidePageSize="true" showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="loggedInUser.data.role !== 'ADMIN'">
        <div class="d-flex align-items-center justify-content-center">
            <h6 class="text-secondary">You are not authorized to view this.</h6>
        </div>
    </ng-container>
</div>
<ng-template #addTag let-modal>
    <div class="modal-header pb-0 border-0">
        <h6 class="modal-title text-primary fw-600" id="label">{{addTagForm.value.id ? 'Update' : 'Add'}} Tag</h6>
        <mat-icon class="cursor" (click)="modalRef.close()">close</mat-icon>
    </div>
    <div class="modal-body">
        <form [formGroup]="addTagForm" (ngSubmit)="onSubmit()">
            <label class="mb-2 fw-500 text-primary">Tag Name <small
                class="text-danger">*</small></label>
            <mat-form-field appearance="outline" class="w-100">
                <input style="outline: none" matInput placeholder="Enter tag name" formControlName="name" />
                <mat-error class="error d-flex align-item-center" *ngIf="submitted && f['name'].errors">
                    <mat-icon class="font-size-12 mt-1 me-2">info</mat-icon>
                    <span *ngIf="f['name'].errors['required']">Tag name required</span>
                </mat-error>
            </mat-form-field>
            <label class="mb-2 fw-500 text-primary d-flex align-items-center">Tag Color <p
                    class="p-2 ms-3 mb-0 border-radius-50" [style]="'background:' + addTagForm.value.colour"></p>
            </label>
            <div class="d-flex align-items-center flex-wrap">
                <p class="p-3 m-2 border-radius-50 border-light-1 cursor" id={{color}} (click)="selectedTagColor(color)"
                    *ngFor="let color of tagColorsArray" [style]="'background:' + color">
                </p>
            </div>
            <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary">Save</button>
            </div>
        </form>
    </div>
</ng-template>