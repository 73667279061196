import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  user_details = JSON.parse(sessionStorage.getItem('currentUser')!);
  constructor(private http: HttpClient) { }

  getTasks(page: any, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    return this.http.get(
      `${environment.urlPrefix}${constant.task}?page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&offset=10`
    );
  }

  getTasksBySearch(name: any) {
    return this.http.get(
      `${environment.urlPrefix}${constant.task}?name=${name}`
    );
  }
  getTaskById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.task}${id}`);
  }
  editTask(data: any) {
    return this.http.put(
      `${environment.urlPrefix}${constant.task}${data?.id}/?source_id=${data.source_id}&source_type=${data.source_type}`,
      data
    );
  }
  editActivity(data: any) {
    return this.http.put(
      `${environment.urlPrefix}${constant.activity}${data?.id}/`,
      data
    );
  }
  getTaskStages(data: any) {
    return this.http.get(
      `${environment.urlPrefix}${constant.stage}?source_id=${data.source_id}&source_type=${data.source_type}`
    );
  }
  getNotes(id: string, page: number) {
    return this.http.get(
      `${environment.urlPrefix}${constant.notes}?source_id=${id}&page=${page}&offset=10`
    );
  }
  saveNotes(data: any) {
    return this.http.post(`${environment.urlPrefix}${constant.notes}`, data);
  }
  editNotes(data: any) {
    return this.http.put(
      `${environment.urlPrefix}${constant.notes}${data.id}/`,
      data
    );
  }
  deleteNotes(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.notes}${id}/`);
  }
  deleteFiles(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.files}${id}/`);
  }
  getFiles(id: string, page: number) {
    return this.http.get(
      `${environment.urlPrefix}${constant.files}?page=${page}&limit=10&source_id=${id}`
    );
  }
  getFollowUps(page: any, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    return this.http.get(
      `${environment.urlPrefix}${constant.activity}?type=followup&page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&limit=10`
    );
  }

  getFollowUpsBySearch(name: any) {
    return this.http.get(
      `${environment.urlPrefix}${constant.activity}?type=followup&search=${name}`
    );
  }
  getCalls(page: any, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    return this.http.get(
      `${environment.urlPrefix}${constant.activity}?type=call&page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&limit=10`
    );
  }

  getCallsBySearch(name: any) {
    return this.http.get(
      `${environment.urlPrefix}${constant.activity}?type=call&search=${name}`
    );
  }

  getEmails(page: any, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    return this.http.get(
      `${environment.urlPrefix}${constant.activity}?type=email&page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&limit=10`
    );
  }

  getEmailsBySearch(name: any) {
    return this.http.get(
      `${environment.urlPrefix}${constant.activity}?type=email&search=${name}`
    );
  }

  getMeetings(page: any, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    return this.http.get(
      `${environment.urlPrefix}${constant.activity}?type=meeting&page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&limit=10`
    );
  }
  getMeetingsBySearch(name: any) {
    return this.http.get(
      `${environment.urlPrefix}${constant.activity}?type=meeting&search=${name}`
    );
  }

  getActivitiesTaskById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.activity}${id}`);
  }

  getNotifications() {
    return this.http.get(`${environment.urlPrefix}${constant.notification}`);
  }

  notificationsRead() {
    return this.http.get(`${environment.urlPrefix}${constant.notification}?read_all=True`);
  }

  putNotification(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.notification}${body.id}/`, body);
  }

  //Appointment
  getAppointments(page: any) {
    return this.http.get(`${environment.urlPrefix}${constant.appointments}?page=${page}&offset=10`);
  }
  putAppointmentStatus(id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.appointments}${id}/`, body);
  }

  getAppointmentById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.appointments}${id}/`);
  }

  //DashBoard Counts
  getTasksCount() {
    return this.http.get(`${environment.urlPrefix}${constant.task}?task_count`);
  }

  getEnquiresCount() {
    return this.http.get(`${environment.urlPrefix}${constant.leads_crm}?due_today`);
  }

  getActivityCount() {
    return this.http.get(`${environment.urlPrefix}${constant.activity}?due_date`);
  }

  getAppointmentCount() {
    return this.http.get(`${environment.urlPrefix}${constant.appointment_count}`);
  }

  getPersonalCalendar(count: number) {
    return this.http.get(`${environment.urlPrefix}${constant.bookedorgMeetings}?page=${count}&limit=10`);
  }
}
