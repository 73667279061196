import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class FormBuilderService {

  BASE_URL: string = environment.urlPrefix;


  constructor(private http: HttpClient) { }

  getForms() {
    return this.http.get(`${this.BASE_URL}${constant.form}`);
  }

  getFormData(id: string) {
    return this.http.get(`${this.BASE_URL}${constant.form}${id}/`);
  }

  createForm(payload: any) {
    return this.http.post(`${this.BASE_URL}${constant.form}`, payload);
  }
  putForm(payload: any, id: string){
    return this.http.put(`${this.BASE_URL}${constant.form}${id}/`, payload);
  }

  deleteForm(id: string) {
    return this.http.delete(`${this.BASE_URL}${constant.form}${id}/`);
  }

  getFormSubmissions(id: string){
    return this.http.get(`${this.BASE_URL}${constant.form_submissions}?form_id=${id}`);

  }

  getFormFields(id: string) {
    return this.http.get(`${this.BASE_URL}${constant.form_fields}${id}/`);
  }
  postFormFields(payload: any) {
    return this.http.post(`${this.BASE_URL}${constant.form_fields}`, payload);
  }
  putFormFields(payload: any, id: string) {
    return this.http.put(`${this.BASE_URL}${constant.form_fields}${id}/`, payload);
  }
  deleteFormFields(id: string) {
    return this.http.delete(`${this.BASE_URL}${constant.form_fields}${id}/`);
  }

  getFormNotificationsMe(id: string) {
    return this.http.get(`${this.BASE_URL}${constant.form_notifications_me}${id}/`);
  }
  postFormNotificationsMe(payload: any) {
    return this.http.post(`${this.BASE_URL}${constant.form_notifications_me}`, payload);
  }
  putFormNotificationsMe(payload: any, id: string) {
    return this.http.put(`${this.BASE_URL}${constant.form_notifications_me}${id}/`, payload);
  }

  getFormIntegrationWebHooks(id: string) {
    return this.http.get(`${this.BASE_URL}${constant.form_integration_webhooks}${id}/`);
  }
  postFormIntegrationWebHooks(payload: any) {
    return this.http.post(`${this.BASE_URL}${constant.form_integration_webhooks}`, payload);
  }
  putFormIntegrationWebHooks(payload: any, id: string) {
    return this.http.put(`${this.BASE_URL}${constant.form_integration_webhooks}${id}/`, payload);
  }

  getFormThankYou(id: string) {
    return this.http.get(`${this.BASE_URL}${constant.form_thank_you}${id}/`);
  }
  postFormThankYou(payload: any) {
    return this.http.post(`${this.BASE_URL}${constant.form_thank_you}`, payload);
  }
  putFormThankYou(payload: any, id: string) {
    return this.http.put(`${this.BASE_URL}${constant.form_thank_you}${id}/`, payload);
  }

  getFormCRM(id: string) {
    return this.http.get(`${this.BASE_URL}${constant.form_crm}${id}/`);
  }
  postFormCRM(payload: any) {
    return this.http.post(`${this.BASE_URL}${constant.form_crm}`, payload);
  }
  putFormCRM(payload: any, id: string) {
    return this.http.put(`${this.BASE_URL}${constant.form_crm}${id}/`, payload);
  }

}
