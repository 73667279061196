import { Component } from '@angular/core';

@Component({
  selector: 'app-nodata-found',
  templateUrl: './nodata-found.component.html',
  styleUrls: ['./nodata-found.component.scss']
})
export class NodataFoundComponent {

}
