<div class="container-fluid">
    <div class="row mt-4 mb-4">
        <div class="col-md-4 d-flex align-items-center">
            <!-- <mat-icon class="text-primary">arrow_back_ios </mat-icon>
            <p class="m-0 text-primary fw-600 font-size-16 text-primary">{{ invoiceJson.back_label }}</p> -->
        </div>
        <div class="col-md-8 d-flex justify-content-end align-items-center">

            <mat-form-field appearance="outline" class="me-3 mat-hint-none position-relative searching-box d-none"
                style="top: 2px">
                <input style="outline: none" matInput placeholder="Search" />
                <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
            <!-- <div ngbDropdown>
                <button class="btn btn-primary d-flex align-items-center me-3" id="addinvoice" ngbDropdownToggle>
                    <mat-icon>add</mat-icon>
                    {{invoiceJson.label_add_invoice}}
                </button>
                <div ngbDropdownMenu="addinvoice">
                    <button ngbDropdownItem>Normal Invoice</button>
                    <button ngbDropdownItem>Recurring Payment</button>
                    <button ngbDropdownItem>Split Payment</button>
                </div>
            </div> -->
            <!-- <mat-icon class="me-3 text-primary">local_offer</mat-icon>
            <mat-icon class="me-3 text-primary">cloud_upload</mat-icon>
            <mat-icon> reorder</mat-icon> -->
        </div>
    </div>

    <div class="table-responsive table-height mt-4">
        <table class="table align-middle table-nowrap table-hover mb-0 file-table">
            <thead>
                <tr class="border-top-table">
                    <!-- <th scope="col">
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                    </th> -->
                    <th scope="col" *ngFor="let col of table_cols" class="text-primary pb-3">{{col}}</th>
                </tr>
            </thead>
            <tbody *ngIf="!display_no_data">
                <tr class="cursor" *ngFor="let item of paymentArray; let i = index">
                    <!-- <td>
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(item) : null"
                            [checked]="selection.isSelected(item)"></mat-checkbox>
                    </td> -->
                    <td>
                        {{ item.reference_id }}
                    </td>
                    <td>
                        {{ item.info?.user?.first_name ? (item.info?.user?.first_name + item.info?.user?.last_name) : '-' }}
                    </td>
                    <td>
                        {{ item.transaction?.amount ? (item.transaction?.amount | currencyConversion) : '-' }}
                    </td>
                    <!-- <td>
                        {{ item.issue_date | date: 'M/d/yy'}}
                    </td> -->
                    <td>
                        {{ item.mode}}
                    </td>
                    <!-- <td>{{ item.billed_to[0].value }}</td>
                    <td>{{ item.billed_to[2].value }}</td>
                    <td>{{ item.billed_from[1].value }}</td> -->
                    <!-- <td>{{ item.status }}</td> -->
                    <td>{{ item.source_type | titlecase}}</td>
                    <!-- <td>{{ item.total }}</td>
                    <td>{{ item.creation_status }}</td> -->
                    <td>{{ item.created_at | dateConversion}}</td>
                    <!-- <td>
                        <div class="dropdown" ngbDropdown>
                            <mat-icon [matMenuTriggerFor]="menuadd">more_vert</mat-icon>
                            <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                                <button mat-menu-item class="py-2">
                                    Download
                                </button>
                            </mat-menu>
                        </div>
                    </td> -->
                </tr>
            </tbody>
        </table>
        <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
        <div>
            <mat-paginator [length]="total_count" [pageSize]="10" (page)="page($event)" aria-label="Select page"
                hidePageSize="true" showFirstLastButtons>
            </mat-paginator>
        </div>
    </div>
</div>