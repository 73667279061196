<div class="row no-gutters">
    <div class="col-md-4 pe-0 h-100">
        <app-crm-info></app-crm-info>
    </div>
    <div class="col-md-8 h-100 ps-0 p-3">
        <div class="crm-client-content h-100">
            <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom mt-0 mb-5"
                (navChange)="tabChange($event)">
                <li [ngbNavItem]="1">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Timeline</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-time-line></app-time-line>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Task Board</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-task-board></app-task-board>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Notes</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-notes-crm></app-notes-crm>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4" *ngIf="selected_type !== 'deal'">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Activity</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-activity-crm></app-activity-crm>
                    </ng-template>
                </li>
                <li [ngbNavItem]="5">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Files</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-files-modal></app-files-modal>
                    </ng-template>
                </li>
                <!-- <li [ngbNavItem]="6" *ngIf="selected_type !== 'lead' && selected_type !== 'deal'">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Pipelines</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-deals-modal></app-deals-modal>
                    </ng-template>
                </li> -->
                <li [ngbNavItem]="7" *ngIf="selected_type !== 'lead' && selected_type !== 'deal'">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Projects</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-project-modal></app-project-modal>
                    </ng-template>
                </li>
                <!-- later appointments tab should be there for enquires only -->
                <!-- <li [ngbNavItem]="8" *ngIf="selected_type === 'lead'">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Appointments</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="h-25rem overflow-auto">
                            <app-appointment></app-appointment>
                        </div>
                    </ng-template>
                </li> -->
                <li [ngbNavItem]="8" *ngIf="selected_type === 'company'">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Contacts</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-contacts [companyId]="selected_id"></app-contacts>
                    </ng-template>
                </li>
                <li [ngbNavItem]="9" *ngIf="selected_type === 'lead'">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Conversations</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="h-25rem">
                            <app-client-conversations></app-client-conversations>
                            <!-- <app-conversations></app-conversations> -->
                        </div>
                    </ng-template>
                </li>
                <!-- <li [ngbNavItem]="10">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Tickets</span>
                    </a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li> -->
            </ul>
            <div [ngbNavOutlet]="customNav"></div>
        </div>
    </div>
</div>