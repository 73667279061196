import { Component, Renderer2 } from '@angular/core';
import { map, Observable } from 'rxjs';
import { MENU } from '../../data/menu_list';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'menu-overlay-pane'
      }
    }
  ]
})
export class SidebarComponent {

  menuList: any[];
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map((result: any) => result.matches)
    );
  menu_opend = false;
  // @ViewChild('levelOneTrigger') trigger!: MatMenuTrigger;
  prevButtonTrigger: any;
  enteredButton = false;
  trigger: any;

  constructor(private breakpointObserver: BreakpointObserver, private ren: Renderer2) {
    this.menuList = MENU;
  }

  menuOpened(event: any) {
    this.menu_opend = true;
    this.trigger = event;
    event.openMenu();
  }

  menuClosed(trigger: any) {
    this.menu_opend = false;
    this.trigger = trigger;
    trigger.closeMenu();
  }

  buttonEnter(trigger?: any) {
    this.enteredButton = true;
    if (!trigger && this.trigger) {
      this.trigger.closeMenu();
    } else if (trigger) {
      if (this.prevButtonTrigger && this.prevButtonTrigger !== trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.menu_opend = false;
        trigger.openMenu();
      }
      else if (!this.menu_opend) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
      }
      else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
      }
    }
  }

  buttonLeave(trigger: any, button: any) {
    this.trigger = trigger;
    if (this.enteredButton && !this.menu_opend) {
      trigger.closeMenu();
    }
  }
}
