import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private http: HttpClient) { }

  getAllClients(page: number, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `${filter_param}=` : '';
    
    const params = new HttpParams()
      .set('page', page)
      // .set(filter_param ? filter_param : '', filter ? filter : '')
      .set('offset', 10);
    return this.http.get(`${environment.urlPrefix}${constant.clients}?${filter_param ? filter_param : ''}${filter ? filter : ''}`, { params });
  }

  getAllProjectsByContact(contact_id: string, page: number) {
    return this.http.get(`${environment.urlPrefix}${constant.client_projects}?contact_id=${contact_id}&page=${page}&limit=10`);
  }

  getAllProjectsByCompanyId(company_id: string, page: number) {
    return this.http.get(`${environment.urlPrefix}${constant.client_projects}?source_id=${company_id}&page=${page}&limit=10`);
  }

  getClientById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.clients}${id}/`);
  }

  createClient(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.clients}`, body);
  }

  updateClient(id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.clients}${id}/`, body);
  }

  deleteClient(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.clients}${id}/`);
  }

  getProjectDetailsById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.client_projects}${id}/`);
  }
  getAllProjects(page: number, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    const params = new HttpParams()
      .set('page', page)
      // .set(filter_param ? filter_param : '', filter ? filter : '')
      .set('offset', 10);
    return this.http.get(`${environment.urlPrefix}${constant.client_projects}?${filter_param ? filter_param : ''}${filter ? filter : ''}`, { params });
  }
  getClientProjects(id: string, page: number, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    const params = new HttpParams()
      .set('client_id', id)
      .set('page', page)
      // .set(filter_param ? filter_param : '', filter ? filter : '')
      .set('offset', 10);
    return this.http.get(`${environment.urlPrefix}${constant.client_projects}?${filter_param ? filter_param : ''}${filter ? filter : ''}`, { params });
  }
  createClientProject(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.client_projects}?client_id=${body.client_id}`, body);
  }
  getStoreId() {
    return this.http.get(`${environment.urlPrefix}${constant.store}`);
  }
  getStoreProducts(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.store}${id}` + '/products/');
  }
  deleteClientProject(client_id: string, project_id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.client_projects}${project_id}/`);
  }
  updateClientProject(project_id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.client_projects}${project_id}/`, body);
  }
  getAllProjectsBySearch(name: string) {
    return this.http.get(`${environment.urlPrefix}${constant.client_projects}?name=${name}`);
  }
  getClientsBySearch(name: string) {
    return this.http.get(`${environment.urlPrefix}${constant.clients}?name=${name}`);
  }
  // getAllProjects(project_id:any) {
  //   return this.http.get(`${environment.urlPrefix}`)
  // }

  //conversations

  getConversation(id: any) {
    return this.http.get(`${environment.urlPrefix}${constant.conversation}?source_id=${id}`);
  }

  postConversation(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.conversation}`, body);
  }

  worke_ai(body: any) {
    return this.http.post(`${environment.worke_ai_url}${constant.worke_ai}`, body);
  }
}
