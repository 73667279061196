<div class="row align-items-center py-2 d-md-flex d-none z-index-header"
    style="box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;">
    <div class="col-md-6 d-flex align-items-center">
        <div class="ps-2 d-flex align-items-center" style="width:180px">
            <img src="{{selectedMenu?.color_img}}" width="20">
            <h1 class="text-primary ms-2 text-capitalize">{{selectedMenu?.label}}</h1>
        </div>
        <!-- <div class="d-flex justify-content-center">
            <mat-form-field appearance="outline" class="search-select mat-hint-none bg-search-left">
                <mat-select [(ngModel)]="selectopt">
                    <mat-option value="all">All</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-divider [vertical]="true"></mat-divider>
            <mat-form-field appearance="outline" class="search mat-hint-none bg-search-right">
                <input style="outline: none" matInput placeholder={{headerJson.input_search}} />
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div> -->
    </div>
    <div class="col-md-3 text-end">
        <!-- <p class="mb-0">{{headerJson.text}} <label class="text-primary fw-600">{{headerJson.text2}}</label></p> -->
    </div>
    <div class="col-md-3">
        <div class="d-flex justify-content-around align-items-center">
            <div class="dropdown d-inline-block" ngbDropdown>
                <button mat-raised-button matTooltip="Add"
                    aria-label="Button that displays a tooltip when focused or hovered over" mat-button
                    class="btn header-item border-0 p-0" [matMenuTriggerFor]="menuadd" id="page-header-create-dropdown">
                    <span class="p-2 rounded bg-gray-light d-flex align-items-center justify-content-center">
                        <!-- <mat-icon class="font-size-26 position-relative" style="top: 5px">add</mat-icon> -->
                        <img src="../../../assets/images/icons_add.svg" class="cursor" width="16">
                    </span>
                </button>
                <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                    <button mat-menu-item *ngFor="let item of addmenuList" (click)="openModalDialog(item.menu_title)"
                        class="py-2">
                        <label class="border-radius-50 p-1 px-2 menu-icon me-2">
                            <img src="../../../assets/images/{{item.menu_icon}}.svg" width="18">
                        </label>
                        <label class="d-flex align-items-center"> 
                            <!-- <mat-icon
                                class="me-0 text-primary">add</mat-icon> -->
                                <span
                                class="position-relative menu-title fw-500"> {{item.menu_title}} </span></label>
                    </button>
                </mat-menu>
            </div>
            <button mat-raised-button matTooltip="Personal calendar"
                aria-label="Button that displays a tooltip when focused or hovered over" type="button"
                class="btn border-0 p-0 d-flex align-items-center" routerLink="header-calendar">
                <!-- <mat-icon class="font-size-20 cursor" routerLink="header-calendar">calendar_today</mat-icon> -->
                <span class="p-2 rounded bg-gray-light d-flex align-items-center justify-content-center">
                    <img src="../../../assets/images/icons_calender.svg" class="cursor"
                        width="16">
                </span>
            </button>
            <button mat-raised-button matTooltip="Settings"
                aria-label="Button that displays a tooltip when focused or hovered over" type="button"
                class="btn border-0 p-0 d-flex align-items-center" routerLink="settings">
                <!-- <mat-icon class="font-size-20 cursor" routerLink="settings">settings</mat-icon> -->
                <span class="p-2 rounded bg-gray-light d-flex align-items-center justify-content-center">
                    <img src="../../../assets/images/icons_settings.svg" class="cursor"
                        width="16">
                </span>
            </button>
            <div class="notification-dropdown d-inline-block border-0" ngbDropdown #notifydrop="ngbDropdown">
                <button mat-raised-button matTooltip="Activity log"
                    aria-label="Button that displays a tooltip when focused or hovered over" type="button"
                    class="btn border-0 p-0 d-flex align-items-center" id="page-header-notifications-dropdown"
                    ngbDropdownToggle>
                    <!-- <mat-icon class="font-size-20 cursor">local_activity</mat-icon> -->
                    <span class="p-2 rounded bg-gray-light d-flex align-items-center justify-content-center">
                        <img src="../../../assets/images/icons_activity.svg" class="cursor" width="16">
                    </span>
                    <!-- <span class="badge bg-danger rounded-pill text-white font-size-10"
                        style="top: -17px;">{{activity_logs.length}}</span> -->
                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                    style="position: relative; height: 400px; overflow-y: scroll;width: 350px;"
                    aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
                    <div class="p-3 pb-0">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="m-0">Activity Log</h6>
                            </div>
                        </div>
                    </div>
                    <div class="notification">
                        <!-- <div class="border-bottom border-2 p-3" *ngFor="let item of notification_list">
                            <div class="row">
                                <div class="col-2">
                                    <div class="avatar-xs d-flex justify-content-between">
                                        <span
                                            class="avatar-title rounded-circle text-capitalize">{{item.first_name[0]}}{{item.last_name[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-10 ps-0">
                                    <h6 class="font-size-16">{{item.name}}</h6>
                                    <label class="notification-message text-light font-size-13">{{item.dsp}}</label>
                                    <div class="d-flex justify-content-between font-size-13">
                                        <label class="text-light">{{item.due_date}} hours ago</label>
                                        <div class="">
                                            <input type="checkbox">&nbsp;<label class="m-auto text-light">Mark as
                                                read</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <div class="border-bottom border-2 p-3" *ngFor="let log of activity_logs">
                            <div class="row">
                                <!-- <div class="col-2">
                                    <div class="avatar-xs me-2">
                                        <span class="avatar-title rounded-circle">
                                            {{log.name[0]}}
                                        </span>
                                    </div>
                                </div> -->
                                <div class="col-12">
                                    <p class="mb-0 notification-message">{{log.text}}</p>
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex align-items-center text-green">
                                            <!-- <mat-icon class="font-size-20">assignment_turned_in</mat-icon> -->
                                            <label class="font-size-14">{{log.source_type | titlecase}}</label>
                                        </div>
                                        <label class="text-light font-size-13">{{log.on_date | dateConversion}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dropdown d-inline-block" ngbDropdown>
                <button mat-raised-button matTooltip="Your profile and logout"
                    aria-label="Button that displays a tooltip when focused or hovered over" type="button"
                    class="btn header-item d-flex align-items-center border-0 btn-outline-light ps-0" ngbDropdownToggle
                    id="page-header-user-dropdown">
                    <div class="avatar-xs">
                        <span class="p-2 rounded bg-gray-light d-flex align-items-center justify-content-center">
                            <!-- <mat-icon>person</mat-icon> -->
                            <img src="../../../assets/images/icons_profile.svg" width="16">
                        </span>
                        <!-- <img *ngIf="currentUser.image" class="rounded-circle avatar-xs" src={{currentUser.image}} alt=""> -->
                    </div>
                    <!-- <i class="mdi mdi-chevron-down ms-2 d-none d-xl-inline-block"></i> -->
                </button>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <a class="dropdown-item" routerLink="user-profile"><i class="bx bx-user font-size-16 align-middle me-1"></i>
                        {{profile_name.data.fname}} {{profile_name.data.lname}}</a>

                    <div class="dropdown-divider my-0"></div>
                    <a class="dropdown-item" href="javascript: void(0);" (click)="logout()"><i
                            class="bx bx-power-off font-size-16 align-middle me-1"></i>
                        {{headerJson.logout_btn}}</a>
                </div>
            </div>
        </div>
    </div>
</div>