import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';
import * as XLSX from 'xlsx';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  public create_pipline = new BehaviorSubject<any>('');
  create_new_pipline = this.create_pipline.asObservable();
  getCompaniesList = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) { }
  //leads
  getAllLeads(page: number, lead_type: any, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    return this.http.get(`${environment.urlPrefix}${constant.leads_crm}?type=${lead_type}&page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&offset=10`);
  }
  getAllLeadswithoutPagination(lead_type: any) {
    return this.http.get(`${environment.urlPrefix}${constant.leads_crm}?type=${lead_type}`);
  }
  getAllLeadForExport() {
    return this.http.get(`${environment.urlPrefix}${constant.exportLeads}`);
  }
  getLeadById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.leads_crm}?id=${id}`);
  }
  createLead(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.leads_crm}`, body);
  }
  updateLead(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.leads_crm}`, body);
  }
  deleteLead(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.leads_crm}?id=${id}`);
  }
  getCustomLeadFields() {
    return this.http.get(`${environment.urlPrefix}${constant.custom_lead_fields_crm}`);
  }
  getLeadsBySearch(name: string) {
    return this.http.get(`${environment.urlPrefix}${constant.leads_crm}?search=${name}`);
  }
  //contacts
  getAllContacts(page: number, filter_param?: string, filter?: string, company_id?: string) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    company_id = company_id ? `&company_id=${company_id}` : '';
    return this.http.get(`${environment.urlPrefix}${constant.contacts_crm}?page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}${company_id ? company_id : ''}&offset=10`);
  }
  getContactById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.contacts_crm}?id=${id}`);
  }
  getContactsDropdownList() {
    return this.http.get(`${environment.urlPrefix}${constant.contacts_crm}`);
  }
  getAllContactsForExport() {
    return this.http.get(`${environment.urlPrefix}${constant.exportContacts}`);
  }
  createContact(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.contacts_crm}`, body);
  }
  updateContact(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.contacts_crm}`, body);
  }
  deleteContact(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.contacts_crm}?id=${id}`);
  }
  getCustomContactFields() {
    return this.http.get(`${environment.urlPrefix}${constant.custom_contact_fields_crm}`);
  }
  getContactsBySearch(name: string) {
    return this.http.get(`${environment.urlPrefix}${constant.contacts_crm}?search=${name}`);
  }
  //companies
  getAllCompanies(page: number, filter_param?: string, filter?: string) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    return this.http.get(`${environment.urlPrefix}${constant.companies_crm}?page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&offset=10`);
  }
  getCompanyById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.companies_crm}?id=${id}`);
  }
  getCompaniesDropdownList() {
    return this.http.get(`${environment.urlPrefix}${constant.companies_crm}`);
  }
  // getCompaniesDropdownList() {
  //   if (this.getCompaniesList.getValue() !== null) {  // (&& refreshType !== "force")
  //     return this.getCompaniesList.asObservable();
  //   } else {
  //     this.http.get(`${environment.urlPrefix}${constant.companies_crm}`).subscribe((data) => {
  //       this.getCompaniesList.next(data);
  //     }, err => {
  //       this.getCompaniesList.next(err);
  //     });
  //     return this.getCompaniesList.asObservable();
  //   }
  // }
  createCompany(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.companies_crm}`, body);
  }
  updateCompany(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.companies_crm}`, body);
  }
  deleteCompany(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.companies_crm}?id=${id}`);
  }
  getCompaniesBySearch(name: string) {
    return this.http.get(`${environment.urlPrefix}${constant.companies_crm}?search=${name}`);
  }
  getAllCompaniesForExport() {
    return this.http.get(`${environment.urlPrefix}${constant.exportCompanies}`);
  }
  //pipelines
  createdNewPipelines(type: string) {
    this.create_pipline.next(type);
  }

  getPipelines() {
    return this.http.get(`${environment.urlPrefix}${constant.pipelines}`);
  }

  getPiplineById(pipelineId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.pipelines}?id=${pipelineId}`);
  }

  postPipelines(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.pipelines}`, body);
  }

  putPipelines(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.pipelines}`, body);
  }

  deletePipeline(pipelineId: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.pipelines}?id=${pipelineId}`);
  }

  getStageByPipelineId(pipelineId: any) {
    return this.http.get(`${environment.urlPrefix}${constant.stages}?pipeline=${pipelineId}`);
  }

  postSingleStage(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.stages}`, body);
  }

  putSingleStage(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.stages}`, body);
  }

  deleteStage(stageId: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.stages}?id=${stageId}`);
  }

  blukStageCreation(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.bulkstages}`, body);
  }

  getCards(stageId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.cards}?stage=${stageId}`);
  }

  getCardsById(cardId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.cards}?id=${cardId}`);
  }

  updateCard(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.cards}`, body);
  }

  createBulkCard(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.bulkCards}`, body);
  }

  getUnusedCustomFields() {
    return this.http.get(`${environment.urlPrefix}${constant.custom_fields}`);
  }

  TemplateCardCreation(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.templatesCard}`, body);
  }

  PutTemplateCard(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.templatesCard}`, body);
  }

  getTemplateCard(pipelineId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.templatesCard}?pipeline=${pipelineId}`);
  }

  customFieldCreation(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.templatesFields}`, body);
  }

  getFieldsByTemaplateCardId(templateId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.templatesFields}?template_card=${templateId}`);
  }

  deleteCustomField(fieldId: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.templatesFields}?id=${fieldId}`);
  }

  getFieldsbyCardId(cardId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.card_fields}?pipeline_card=${cardId}`);
  }

  UpdateField(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.card_fields}`, body);
  }

  //templates
  getTemplates() {
    return this.http.get(`${environment.urlPrefix}${constant.templates}`);
  }

  getTemplatesDetailsById(templateId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.templates}?id=${templateId}`);
  }

  createPipelineWithTemplate(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.template}`, body);
  }

  importContacts(formData: FormData) {
    return this.http.post(`${environment.urlPrefix}${constant.importContacts}`, formData);
  }

  importCompanies(formData: FormData) {
    return this.http.post(`${environment.urlPrefix}${constant.importCompanies}`, formData);
  }

  jsonToCsv(json: any, fileName: string) {
    const sheet = XLSX.utils.json_to_sheet(json);
    const book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, sheet, 'Sheet1');
    XLSX.writeFile(book, `${fileName}.csv`);
  }

  downloadSample(module: string) {
    let fileUrl = "";

    if (/^(companies)$/.test(module.toLowerCase())) {
      fileUrl = environment.preSendUrl + 'image-cee340c9-84fc-4aff-a0c3-fd6dcb21f62a-1683279066.csv';
    }
    if (/^(contacts)$/.test(module.toLowerCase())) {
      fileUrl = environment.preSendUrl + 'image-a6620697-3f6a-4f92-a7a1-d146f55b61ea-1683281484.csv';
    }

    const fileName = `${module}_Sample_Data.csv`;

    this.http.get(fileUrl, { responseType: 'blob' })
      .subscribe((blob: Blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
      });
  }

}
