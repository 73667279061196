import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss']
})
export class ChatBotComponent implements OnInit {

  ngOnInit(): void {
    // Parent page

    function receiveMessage(event: any) {
      if (event.origin !== environment.chatBotUrl) {
        return;
      }
      const sessionValues = {
        ...JSON.parse(sessionStorage.getItem('currentUser') ?? "{}")
      };

      const iframe: any = document.getElementById('chat-widget-value');
      iframe?.contentWindow.postMessage(sessionValues, '*');
    }
    window.addEventListener('message', receiveMessage, false);

  }


}
