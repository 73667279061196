<div class="container-fluid">
    <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" (navChange)="tabChange($event)"
        class="nav-tabs nav-tabs-custom mt-0">
        <li [ngbNavItem]="1" class="me-2">
            <a ngbNavLink class="d-flex">
                <span class="d-sm-block font-size-14">Open</span>
            </a>
            <ng-template ngbNavContent>
                <div class="row mt-3 mb-3">
                    <div class="col-md-4 d-flex align-items-center">
                        <!-- <mat-icon class="text-primary">arrow_back_ios </mat-icon> -->
                        <p class="m-0 heading font-size-18 fw-700 text-primary">{{ leadJson.heading_label }}</p>
                        <mat-form-field appearance="outline" class="contactsfilter ms-3 mat-hint-none main-filter">
                            <mat-select placeholder="{{ leadJson.heading_label }}"
                                (ngModelChange)="onFilterChange($event)" [(ngModel)]="filter" class="pt-1">
                                <mat-option class="font-size-12" [value]="list.value"
                                    *ngFor="let list of leadsdropDown">{{
                                    list.label }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-8 d-flex justify-content-end align-items-center">
                        <mat-form-field appearance="outline" class="me-3 mat-hint-none position-relative searching-box"
                            style="top: 2px">
                            <input style="outline: none" matInput placeholder={{leadJson.search_placeholder}}
                                [(ngModel)]="search_global" (ngModelChange)="search($event)" />
                            <mat-icon matPrefix>search</mat-icon>
                        </mat-form-field>

                        <button class="btn btn-primary d-flex align-items-center me-3 font-size-13"
                            (click)="addLeadModal()">
                            <mat-icon>add</mat-icon>
                            {{leadJson.label_add_lead}}
                        </button>

                        <button mat-raised-button matTooltip="Add Task Board Stages"
                            *ngIf="loggedInUser.data.role === 'ADMIN'"
                            aria-label="Button that displays a tooltip when focused or hovered over" type="button"
                            class="btn border-0 p-0 d-flex align-items-center"
                            (click)="openTaskBoardPreview()"><!--[disabled]="selection.selected.length == 0"-->
                            <span
                                class="p-1 me-3 rounded bg-gray-light d-flex align-items-center justify-content-center">
                                <!-- <mat-icon class="font-size-26 position-relative" style="top: 5px">add</mat-icon> -->
                                <img src="../../../assets/images/icons_add.svg" class="cursor" width="16">
                            </span>
                        </button>
                        <button mat-raised-button matTooltip="Export"
                            aria-label="Button that displays a tooltip when focused or hovered over" type="button"
                            class="btn border-0 p-0 d-flex align-items-center"><!--[disabled]="selection.selected.length == 0"-->
                            <mat-icon class="me-2 text-primary" (click)="downloadEnquiries()">exit_to_app</mat-icon>
                        </button>

                        <!-- <mat-icon class="me-3 text-primary">local_offer</mat-icon> -->
                        <!-- <mat-icon class="me-3 text-primary">cloud_upload</mat-icon> -->
                        <!-- <mat-icon class="me-3"> reorder</mat-icon> -->
                        <!-- <div class="d-flex contacts-filter-lable">
                            <span class="me-3 text-primary">{{leadJson.Filter_label}}</span>
                            <span mat-raised-button class="menuTrigger text-primary material-icons cursor"
                                [matMenuTriggerFor]="filter_search_menu">filter_list</span>

                            <mat-menu #filter_search_menu="matMenu">
                                <mat-form-field appearance="outline" class="mx-3 my-2 mat-hint-none"
                                    (click)="$event.stopPropagation()">
                                    <input style="outline: none" matInput placeholder={{leadJson.search_placeholder}} />
                                    <mat-icon matPrefix>search</mat-icon>
                                </mat-form-field>

                                <div class="" *ngFor="let item of filtersLables; let i = index">
                                    <button mat-menu-item id="{{ i }}" (click)="selectFilterLable($event)">
                                        <mat-checkbox [checked]="item.checked">{{ item.lableName }}</mat-checkbox>
                                    </button>
                                </div>
                            </mat-menu>
                        </div> -->
                    </div>
                </div>
                <script>
                    var popoverTriggerList = [].slice.call(document.querySelectorAll('.edit-icon'));
                    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
                        return new bootstrap.Popover(popoverTriggerEl);
                    });

                </script>

                <div class="table-responsive table-height mt-4 fixed-table">
                    <table class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
                        <thead>
                            <tr class="border-top-table">
                                <th scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                                    <mat-checkbox (change)="$event ? masterToggle() : null" class="mat_checkbox"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                                </th>
                                <ng-container *ngFor="let col of filtersLables">
                                    <th *ngIf="col.checked === true" scope="col"
                                        class="text-primary font-size-14 pb-3 table-sticky-header bg-white"
                                        style="z-index: 999;">
                                        {{col.lableName}}</th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody *ngIf="!display_no_data">
                            <tr class="cursor" *ngFor="let item of leadsListArray; let i = index"
                                [routerLink]="['../crm-details']" [queryParams]="{ id: item.id, showtype: 'lead'}">
                                <td>
                                    <mat-checkbox (click)="$event.stopPropagation()" class="mat_checkbox"
                                        (change)="$event ? selection.toggle(item) : null"
                                        [checked]="selection.isSelected(item)"></mat-checkbox>
                                </td>

                                <td>
                                    <app-table-popover [inputType]="'text'" [inputValue]="item.requirement_title"
                                        [labelName]="'Name'" [formName]="'requirement_title'"
                                        (update)="onUpdatePopOver($event, item.id)"></app-table-popover>

                                </td>
                                <td>
                                    {{ item?.name ? item.name : "-" }}
                                </td>
                                <!-- <td>
                                    {{ item.next_activity }}
                                </td> -->
                                <td>{{ item.email ? item.email : "-" }}</td>
                                <td>{{item.phone_number ? item.phone_number : "-"}}</td>
                                <!-- <td>{{ item.tags ? item.tags : "-" }}</td> -->
                                <td>{{ item.source_type ? item.source_type : "-" }}</td>
                                <td>
                                    {{(item.created_at ? (item.created_at | dateConversion ) : "-")}}
                                </td>
                                <!-- <td>{{ item.updated_at | date: 'd/M/yy, h:mm a' }}</td> -->
                                <!-- <td *ngIf="item.info.owner_info">{{ item.info ? item.info.owner_info?.fname : "" }}
                                    {{item.info ?
                                    item.info.owner_info?.lname : ""}}</td> -->
                                <!--<td *ngIf="!item.info.owner_info">-</td> -->
                                <td>
                                    <app-table-popover [inputType]="'dropdown'"
                                        [inputValue]="item.info?.owner_info?.fname + ' ' + item.info?.owner_info?.lname"
                                        [formName]="'info.owner_info'" [labelName]="'Owner'"
                                        [dropDownItems]="staffMembers" [selectedItem]="item.owner"
                                        (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
                                </td>
                                <td>
                                    <app-table-popover [inputType]="'dateTime'" [inputValue]="item.due_date"
                                        [labelName]="'Due Date'" [formName]="'due_date'"
                                        (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
                                </td>
                                <!-- <td>{{ item.company_id}}</td>
                                <td>website</td>
                                <td>Mailing Street</td>
                                <td>Mailing Address</td>
                                <td>Mailing City</td>
                                <td>Mailing Country</td>
                                <td>Mailing State</td>
                                <td>Shipping Address</td>
                                <td>Shipping City</td>
                                <td>Shipping Country</td>
                                <td>Zip Code</td>-->
                                <td (click)="$event.stopPropagation()" class="mat-hint-none">
                                    <mat-form-field appearance="outline" class="w-75">
                                        <mat-select placeholder='Select' [(ngModel)]="item.type">
                                            <mat-option (click)="moveLeadTo(item,'LOST','ACTIVE')"
                                                value="LOST">Move to Lost</mat-option>
                                            <mat-option (click)="moveLeadTo(item,'WON','ACTIVE')"
                                                value="WON">Move to Won</mat-option>
                                            <mat-option (click)="moveLeadTo(item,'JUNK','ACTIVE')"
                                                value="JUNK"> Move to Junk</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td (click)="$event.stopPropagation()">
                                    <div class="dropdown" ngbDropdown>
                                        <mat-icon style="z-index: 99;"
                                            [matMenuTriggerFor]="menuadd">more_vert</mat-icon>
                                        <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                                            <button mat-menu-item (click)="addLeadModal(item)" class="py-2">
                                                Edit
                                            </button>
                                            <button mat-menu-item (click)="confirmDelete(item.id,'ACTIVE')"
                                                class="py-2">
                                                Delete
                                            </button>
                                            <!-- <button mat-menu-item (click)="moveLeadTo(item,'LOST','ACTIVE')"
                                                class="py-2">
                                                Move to Lost
                                            </button>
                                            <button mat-menu-item (click)="moveLeadTo(item, 'WON','ACTIVE')"
                                                class="py-2">
                                                Move to Won
                                            </button>
                                            <button mat-menu-item (click)="moveLeadTo(item, 'JUNK','ACTIVE')"
                                                class="py-2">
                                                Move to Junk
                                            </button> -->
                                        </mat-menu>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
                </div>
                <div>
                    <mat-paginator [length]="leadsObj.total" [pageSize]="page_size" (page)="page($event, 'ACTIVE')"
                        aria-label="Select page" hidePageSize="true" showFirstLastButtons>
                    </mat-paginator>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="2" class="me-2">
            <a ngbNavLink class="d-flex">
                <span class="d-sm-block font-size-14">Lost</span>
            </a>
            <ng-template ngbNavContent>
                <div class="table-responsive table-height mt-4 fixed-table">
                    <table class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
                        <thead>
                            <tr>
                                <!-- <th scope="col">
                                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                                </th> -->
                                <ng-container *ngFor="let col of filtersLables">
                                    <th *ngIf="col.checked === true" scope="col" style="z-index: 999;"
                                        class="text-primary font-size-14 pb-3 table-sticky-header bg-white">
                                        {{col.lableName}}</th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody *ngIf="!display_no_data">
                            <tr class="cursor" *ngFor="let item of leadsListArray; let i = index"
                                [routerLink]="['../crm-details']" [queryParams]="{ id: item.id, showtype: 'lead'}">
                                <!-- <td>
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(item.id) : null"
                                        [checked]="selection.isSelected(item.id)"></mat-checkbox>
                                </td> -->
                                <td>
                                    <!-- {{ item.requirement_title ? item.requirement_title : "-" }} -->
                                    <app-table-popover [inputType]="'text'" [inputValue]="item.requirement_title"
                                        [formName]="'requirement_title'" [labelName]="filtersLables[0].lableName"
                                        (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
                                </td>
                                <td>
                                    {{ item?.name ? item.name : "-" }}
                                </td>
                                <!-- <td>
                                    {{ item.next_activity }}
                                </td> -->
                                <td>
                                    {{ item.email ? item.email : "-" }}
                                </td>
                                <td>{{item.phone_number ? item.phone_number : "-"}}</td>
                                <!-- <td>{{ item.tags ? item.tags : "-" }}</td> -->
                                <td>{{ item.source_type ? item.source_type : "-" }}</td>
                                <td>{{ (item.created_at ? (item.created_at| dateConversion) : "-")}}</td>
                                <td>
                                    <app-table-popover [inputType]="'dropdown'"
                                        [inputValue]="item.info?.owner_info?.fname + ' ' + item.info?.owner_info?.lname"
                                        [formName]="'info.owner_info'" [labelName]="'Owner'"
                                        [dropDownItems]="staffMembers" [selectedItem]="item.owner"
                                        (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
                                </td>
                                <!-- <td>{{ item.updated_at | date: 'd/M/yy, h:mm a' }}</td> -->
                                <!-- <td *ngIf="item.info.owner_info">{{ item.info ? item.info.owner_info?.fname : "" }}
                                    {{item.info ?
                                    item.info.owner_info?.lname : ""}}</td> -->
                                <!-- <td *ngIf="!item.info.owner_info">-</td> -->
                                <td>
                                    <app-table-popover [inputType]="'dateTime'" [inputValue]="item.due_date"
                                        [formName]="'due_date'" [labelName]="filtersLables[7].lableName"
                                        (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
                                </td>
                                <!-- <td *ngIf="item.due_date">{{(item.due_date ? item.due_date : "") | dateConversion }}</td> -->
                                <!-- <td *ngIf="!item.due_date">-</td> -->
                                <!-- <td>{{ item.company_id}}</td>
                                <td>website</td>
                                <td>Mailing Street</td>
                                <td>Mailing Address</td>
                                <td>Mailing City</td>
                                <td>Mailing Country</td>
                                <td>Mailing State</td>
                                <td>Shipping Address</td>
                                <td>Shipping City</td>
                                <td>Shipping Country</td>
                                <td>Zip Code</td>
                                <td>Title</td> -->
                                <td (click)="$event.stopPropagation()" class="mat-hint-none">
                                    <mat-form-field appearance="outline" class="w-75">
                                        <mat-select placeholder='Select' [(ngModel)]="item.type">
                                            <mat-option (click)="moveLeadTo(item,'ACTIVE','LOST')"
                                                value="ACTIVE">Move to Open</mat-option>
                                            <mat-option (click)="moveLeadTo(item,'WON','LOST')"
                                                value="WON">Move to Won</mat-option>
                                            <mat-option (click)="moveLeadTo(item,'JUNK','LOST')"
                                                value="JUNK"> Move to Junk</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td (click)="$event.stopPropagation()">
                                    <div class="dropdown" ngbDropdown>
                                        <mat-icon style="z-index: 99;"
                                            [matMenuTriggerFor]="menuadd">more_vert</mat-icon>
                                        <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                                            <button mat-menu-item (click)="addLeadModal(item)" class="py-2">
                                                Edit
                                            </button>
                                            <button mat-menu-item (click)="confirmDelete(item.id,'LOST')" class="py-2">
                                                Delete
                                            </button>
                                            <!-- <button mat-menu-item (click)="moveLeadTo(item,'ACTIVE','LOST')"
                                                class="py-2">
                                                Move to Open
                                            </button>
                                            <button mat-menu-item (click)="moveLeadTo(item, 'WON','LOST')" class="py-2">
                                                Move to Won
                                            </button>
                                            <button mat-menu-item (click)="moveLeadTo(item, 'JUNK','LOST')"
                                                class="py-2">
                                                Move to Junk
                                            </button> -->
                                        </mat-menu>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
                </div>
                <div>
                    <mat-paginator [length]="leadsObj.total" [pageSize]="10" (page)="page($event, 'LOST')"
                        aria-label="Select page" hidePageSize="true" showFirstLastButtons>
                    </mat-paginator>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="3" class="me-2">
            <a ngbNavLink class="d-flex">
                <span class="d-sm-block font-size-14">Junk</span>
            </a>
            <ng-template ngbNavContent>
                <div class="table-responsive table-height mt-4 fixed-table">
                    <table class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
                        <thead>
                            <tr>
                                <!-- <th scope="col">
                                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                                </th> -->
                                <ng-container *ngFor="let col of filtersLables">
                                    <th *ngIf="col.checked === true" scope="col" style="z-index: 999;"
                                        class="text-primary font-size-14 pb-3 table-sticky-header bg-white">
                                        {{col.lableName}}</th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody *ngIf="!display_no_data">
                            <tr class="cursor" *ngFor="let item of leadsListArray; let i = index"
                                [routerLink]="['../crm-details']" [queryParams]="{ id: item.id, showtype: 'lead'}">
                                <!-- <td>
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(item.id) : null"
                                        [checked]="selection.isSelected(item.id)"></mat-checkbox>
                                </td> -->
                                <td>
                                    <!-- {{ item.requirement_title ? item.requirement_title : "-" }} -->
                                    <app-table-popover [inputType]="'text'" [inputValue]="item.requirement_title"
                                        [formName]="'requirement_title'" [labelName]="filtersLables[0].lableName"
                                        (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
                                </td>
                                <td>
                                    {{ item?.name ? item.name : "-" }}
                                </td>
                                <!-- <td>
                                    {{ item.next_activity }}
                                </td> -->
                                <td>{{ item.email ? item.email : "-" }}</td>
                                <td>{{item.phone_number ? item.phone_number : "-"}}</td>
                                <!-- <td>{{ item.tags ? item.tags : "-" }}</td> -->
                                <td>{{ item.source_type ? item.source_type : "-" }}</td>
                                <td>{{ (item.created_at ? (item.created_at | dateConversion ): "")}}</td>
                                <td>
                                    <app-table-popover [inputType]="'dropdown'"
                                        [inputValue]="item.info?.owner_info?.fname + ' ' + item.info?.owner_info?.lname"
                                        [formName]="'info.owner_info'" [labelName]="'Owner'"
                                        [dropDownItems]="staffMembers" [selectedItem]="item.owner"
                                        (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
                                </td>
                                <td>
                                    <app-table-popover [inputType]="'dateTime'" [inputValue]="item.due_date"
                                        [formName]="'due_date'" [labelName]="filtersLables[7].lableName"
                                        (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
                                </td>
                                <!-- <td>{{ item.updated_at | date: 'd/M/yy, h:mm a' }}</td> -->
                                <!-- <td *ngIf="item.info.owner_info">{{ item.info ? item.info.owner_info?.fname : "" }}
                                    {{item.info ?
                                    item.info.owner_info?.lname : ""}}</td>
                                <td *ngIf="!item.info.owner_info">-</td> -->
                                <!-- <td *ngIf="item.due_date">{{(item.due_date ? item.due_date : "") | dateConversion }}
                                </td>
                                <td *ngIf="!item.due_date">-</td> -->
                                <!-- <td>{{ item.company_id}}</td>
                                <td>website</td>
                                <td>Mailing Street</td>
                                <td>Mailing Address</td>
                                <td>Mailing City</td>
                                <td>Mailing Country</td>
                                <td>Mailing State</td>
                                <td>Shipping Address</td>
                                <td>Shipping City</td>
                                <td>Shipping Country</td>
                                <td>Zip Code</td>
                                <td>Title</td> -->
                                <td (click)="$event.stopPropagation()" class="mat-hint-none">
                                    <mat-form-field appearance="outline" class="w-75">
                                        <mat-select placeholder='Select' [(ngModel)]="item.type">
                                            <mat-option (click)="moveLeadTo(item,'ACTIVE','JUNK')"
                                                value="ACTIVE">Move to Open</mat-option>
                                            <mat-option (click)="moveLeadTo(item,'WON','JUNK')"
                                                value="WON">Move to Won</mat-option>
                                            <mat-option (click)="moveLeadTo(item,'LOST','JUNK')"
                                                value="LOST"> Move to Lost</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td (click)="$event.stopPropagation()">
                                    <div class="dropdown" ngbDropdown>
                                        <mat-icon style="z-index: 99;"
                                            [matMenuTriggerFor]="menuadd">more_vert</mat-icon>
                                        <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                                            <button mat-menu-item (click)="addLeadModal(item)" class="py-2">
                                                Edit
                                            </button>
                                            <button mat-menu-item (click)="confirmDelete(item.id,'JUNK')" class="py-2">
                                                Delete
                                            </button>
                                            <!-- <button mat-menu-item (click)="moveLeadTo(item,'ACTIVE','JUNK')"
                                                class="py-2">
                                                Move to Open
                                            </button>
                                            <button mat-menu-item (click)="moveLeadTo(item, 'WON','JUNK')" class="py-2">
                                                Move to Won
                                            </button>
                                            <button mat-menu-item (click)="moveLeadTo(item, 'LOST','JUNK')"
                                                class="py-2">
                                                Move to Lost
                                            </button> -->
                                        </mat-menu>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
                </div>
                <div>
                    <mat-paginator [length]="leadsObj.total" [pageSize]="10" (page)="page($event, 'JUNK')"
                        aria-label="Select page" hidePageSize="true" showFirstLastButtons>
                    </mat-paginator>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="4" class="me-2">
            <a ngbNavLink class="d-flex">
                <span class="d-sm-block font-size-14">Won</span>
            </a>
            <ng-template ngbNavContent>
                <div class="table-responsive table-height mt-4 fixed-table">
                    <table class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
                        <thead>
                            <tr>
                                <!-- <th scope="col">
                                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                                </th> -->
                                <ng-container *ngFor="let col of filtersLables">
                                    <th *ngIf="col.checked === true" scope="col" style="z-index: 999;"
                                        class="text-primary font-size-14 pb-3 table-sticky-header bg-white">
                                        {{col.lableName}}</th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody *ngIf="!display_no_data">
                            <tr class="cursor" *ngFor="let item of leadsListArray; let i = index"
                                [routerLink]="['../crm-details']" [queryParams]="{ id: item.id, showtype: 'lead'}">
                                <!-- <td>
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(item.id) : null"
                                        [checked]="selection.isSelected(item.id)"></mat-checkbox>
                                </td> -->
                                <td>
                                    <app-table-popover [inputType]="'text'" [inputValue]="item.requirement_title"
                                        [formName]="'requirement_title'" [labelName]="filtersLables[0].lableName"
                                        (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
                                </td>
                                <td>
                                    {{ item?.name ? item.name : "-" }}
                                </td>
                                <td>{{ item.email ? item.email : "-" }}</td>
                                <td>{{item.phone_number ? item.phone_number : "-"}}</td>
                                <!-- <td>{{ item.tags ? item.tags : "-" }}</td> -->
                                <td>{{ item.source_type ? item.source_type : "-" }}</td>
                                <td>{{ (item.created_at ? (item.created_at | dateConversion) : "")}}</td>
                                <td>
                                    <app-table-popover [inputType]="'dropdown'"
                                        [inputValue]="item.info?.owner_info?.fname + ' ' + item.info?.owner_info?.lname"
                                        [formName]="'info.owner_info'" [labelName]="'Owner'"
                                        [dropDownItems]="staffMembers" [selectedItem]="item.owner"
                                        (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
                                </td>
                                <td>
                                    <app-table-popover [inputType]="'dateTime'" [inputValue]="item.due_date"
                                        [formName]="'due_date'" [labelName]="filtersLables[7].lableName"
                                        (update)="onUpdatePopOver($event, item.id)"></app-table-popover>
                                </td>
                                <!-- <td>{{ item.updated_at | date: 'd/M/yy, h:mm a' }}</td> -->
                                <!-- <td>{{ item.company_id}}</td>
                                <td>website</td>
                                <td>Mailing Street</td>
                                <td>Mailing Address</td>
                                <td>Mailing City</td>
                                <td>Mailing Country</td>
                                <td>Mailing State</td>
                                <td>Shipping Address</td>
                                <td>Shipping City</td>
                                <td>Shipping Country</td>
                                <td>Zip Code</td>
                                <td>Title</td> -->
                                <td (click)="$event.stopPropagation()" class="mat-hint-none">
                                    <mat-form-field appearance="outline" class="w-75">
                                        <mat-select placeholder='Select' [(ngModel)]="item.type">
                                            <mat-option (click)="moveLeadTo(item,'ACTIVE','WON')"
                                                value="ACTIVE">Move to Open</mat-option>
                                            <mat-option (click)="moveLeadTo(item,'LOST','WON')"
                                                value="LOST">Move to Lost</mat-option>
                                            <mat-option (click)="moveLeadTo(item,'JUNK','WON')"
                                                value="JUNK"> Move to Junk</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td (click)="$event.stopPropagation()">
                                    <div class="dropdown" ngbDropdown>
                                        <mat-icon style="z-index: 99;"
                                            [matMenuTriggerFor]="menuadd">more_vert</mat-icon>
                                        <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                                            <button mat-menu-item (click)="addLeadModal(item)" class="py-2">
                                                Edit
                                            </button>
                                            <button mat-menu-item (click)="confirmDelete(item.id,'WON')" class="py-2">
                                                Delete
                                            </button>
                                            <!-- <button mat-menu-item (click)="moveLeadTo(item,'ACTIVE','WON')"
                                                class="py-2">
                                                Move to Open
                                            </button>
                                            <button mat-menu-item (click)="moveLeadTo(item, 'LOST','WON')" class="py-2">
                                                Move to Lost
                                            </button>
                                            <button mat-menu-item (click)="moveLeadTo(item, 'JUNK','WON')" class="py-2">
                                                Move to Junk
                                            </button> -->
                                        </mat-menu>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
                </div>
                <div>
                    <mat-paginator [length]="leadsObj.total" [pageSize]="10" (page)="page($event, 'WON')"
                        aria-label="Select page" hidePageSize="true" showFirstLastButtons>
                    </mat-paginator>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="customNav"></div>
</div>