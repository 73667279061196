import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { ADDLEADJSON } from 'src/app/core/data/leads_json';
import { CrmService } from 'src/app/core/service/crm.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { ReplaySubject, Subject, finalize, takeUntil } from 'rxjs';
import { countryData } from 'src/app/core/data/countryData';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-lead-modal',
  templateUrl: './lead-modal.component.html',
  styleUrls: ['./lead-modal.component.scss']
})
export class LeadModalComponent implements OnInit {
  public bankFilterCtrl: FormControl = new FormControl();
  public productFilter: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  public filteredProducts: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroyProducts = new Subject<void>();
  countryjson = countryData;
  title = 'FormArray Example in Angular Reactive forms';
  submitted = false;
  leadForm!: FormGroup;
  leadJson = ADDLEADJSON[0];
  errorJson = ERRORJSON[0];
  @Input() lead: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  contactListArray: any = [];
  companiesListArray: any = [];
  staffMembers: any = [];
  info: any = {};
  time_slotes = [
    '12:00 AM',
    '12:30 AM',
    '1:00 AM',
    '1:30 AM',
    '2:00 AM',
    '2:30 AM',
    '3:00 AM',
    '3:30 AM',
    '4:00 AM',
    '4:30 AM',
    '5:00 AM',
    '5:30 AM',
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM'
  ];
  products_list: any = [];
  loading = false;
  clear_all: any;
  minDate = new Date();
  @ViewChild('myFirstInput') myFirstInput!: ElementRef;
  loggedInUser: any;
  tagsListArray: any;

  constructor(public ngbActiveModal: NgbActiveModal, private service: CrmService, private settingsService: SettingsService, private product_service: SetUpService, private authService: AuthService) { }

  ngOnInit() {
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });

    this.filteredProducts.next(this.products_list.slice());
    this.productFilter.valueChanges.pipe(takeUntil(this._onDestroyProducts)).subscribe(() => {
      this.filterProducts();
    });
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.intilizeForm();
    this.getContactsList();
    this.getCompaniesList();
    this.organisationMembersList();
    this.getProducts();
    this.getTags();
    // console.log("lead - ", this.lead);
    if (this.lead?.id) {
      this.leadForm.controls["phone_number"].disable();
      this.leadForm.controls["ccode"].disable();
      this.leadForm.controls["email"].disable();
      this.leadForm.controls["name"].disable();
      this.leadForm.controls["source_type"].disable();
    } else {
      this.info['owner_info'] = {
        fname: this.loggedInUser.data.fname,
        lname: this.loggedInUser.data.lname
      };
    }
    // this.getExtraFieldsData();
    // this.leadForm.get('product')?.valueChanges.subscribe((product: any) => {
    //   console.log(product, "product log's");
    //   this.products_list = this.products_list.filter((item: any) => {
    //     item.name === product
    //   })

    // })

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.myFirstInput.nativeElement.focus();
    }, 0);
  }

  getExtraFieldsData() {
    // if (!this.lead) {
    //   this.getCustomFieldsData();
    // } else {
    //   if (this.lead.custom_contact_fields) {
    //     for (const data of this.lead.custom_contact_fields) {
    //       console.log(data);
    //       this.addfieldsData(data);
    //     }
    //   }
    // }
  }

  preventDefault() {
    return false;
  }

  intilizeForm() {
    // console.log(this.lead);

    this.leadForm = new FormGroup({
      id: new FormControl(this.lead ? this.lead.id : ''),
      requirement_title: new FormControl(this.lead ? this.lead.requirement_title : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9_\s]{1,}$/)]),
      requirement_description: new FormControl('sdzcxvfbdf', [Validators.required]), //backend kept these fields and are mandatory which are not in the UI , for testing case using the static data for now
      requirement_budget: new FormControl('xdvxdvbv', [Validators.required]),
      requirement_currency: new FormControl('400', [Validators.required]),
      channel: new FormControl('fsdvfv', [Validators.required]),
      name: new FormControl(this.lead?.name ? this.lead.name : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9_\s]{1,}$/)]),
      email: new FormControl(this.lead?.email ? this.lead.email : '', Validators.compose([Validators.required, Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)])),//eslint-disable-line 
      company_id: new FormControl(this.lead?.company_id ? this.lead.company_id : ''),
      contact: new FormControl(this.lead?.contact ? this.lead.contact : ''),
      phone_number: new FormControl(this.lead?.phone_number ? this.lead.phone_number : '', Validators.compose([Validators.required, Validators.pattern("[0-9]+"), Validators.minLength(6), Validators.maxLength(12)])),
      owner: new FormControl(this.lead?.owner ? this.lead.owner : this.loggedInUser.data.id, [Validators.required]),
      assignee: new FormControl(this.lead?.assignee ? this.lead.assignee : []),
      tags: new FormControl(this.lead?.tags ? this.lead.tags : ''),
      source_type: new FormControl(this.lead?.source_type ? this.lead.source_type : 'manual'),
      product: new FormControl(this.lead?.product ? this.lead.product : []),
      due_date: new FormControl(this.lead?.due_date ? this.lead.due_date : ''),
      type: new FormControl(this.lead?.type ? this.lead.type : 'ACTIVE'),
      description: new FormControl(this.lead ? this.lead.description : ''),
      custom_lead_fields: new FormArray([]),
      ccode: new FormControl(this.lead?.ccode ? this.lead.ccode : this.authService.getCompanyData().ccode, [Validators.required])
    });
  }

  // get custom_lead_fields(): FormArray {
  //   return this.leadForm.get("custom_lead_fields") as FormArray;
  // }

  // custom_fields(data: any): FormGroup {
  //   return new FormGroup({
  //     label: new FormControl(data ? data.label : ''),
  //     data_type: new FormControl(data ? data.data_type : ''),
  //     options: new FormControl(data ? data.options : ''),
  //     data: new FormControl(data ? data.data : ''),
  //     is_active: new FormControl(data ? data.is_active : true),
  //     id: new FormControl(data ? data.id : '')
  //   });
  // }

  // addfieldsData(data: any) {
  //   this.custom_lead_fields.push(this.custom_fields(data));
  // }

  addNewName = (name: any) => ({ first_name: name, id: '' });

  onSelectContact(contact: any) {
    // console.log(contact, "contact selected");
    this.leadForm.controls["phone_number"].enable();
    this.leadForm.controls["ccode"].enable();
    this.leadForm.controls["email"].enable();
    if (contact?.id) {
      this.leadForm.patchValue({ email: contact.email, contact: contact.id, phone_number: contact.phone_number, ccode: contact.ccode, name: contact.first_name });
      this.leadForm.controls["phone_number"].disable();
      this.leadForm.controls["ccode"].disable();
      this.leadForm.controls["email"].disable();
    } else {
      this.leadForm.patchValue({ contact: contact.id, name: contact.first_name });
    }
  }

  searchContact(term: any) {
    if (term.term.length >= 3) {
      this.service.getContactsBySearch(term.term).subscribe((result: any) => {
        this.contactListArray = result.data;
      });
    } else if (term.term.length === 0) {
      this.getContactsList();
    }
  }

  get f() {
    return this.leadForm.controls;
  }

  onSubmit() {
    console.log("create enquire - ", this.leadForm);
    this.submitted = true;
    if (this.leadForm.valid) {
      this.loading = true;
      let valid_mailId: any;
      let valid_phone_number, valid_ccode: any;
      let valid_name: any;
      let valid_channel: any;
      const assignee_departments: any = [];

      if (this.leadForm.value.assignee.length !== 0) {
        this.staffMembers.forEach((item: any) => {
          this.leadForm.value.assignee.filter((assigne: any) => {
            if (assigne === item.id) {
              assignee_departments.push(item.department_info.id);
            }
          });
        });
      }


      if (this.leadForm.controls["email"].status === 'DISABLED') {
        valid_mailId = this.leadForm.controls["email"].value;
      } else {
        valid_mailId = this.leadForm.value.email;
      }

      if (this.leadForm.controls["phone_number"].status === 'DISABLED') {
        valid_phone_number = this.leadForm.controls["phone_number"].value;
        valid_ccode = this.leadForm.controls["ccode"].value;
      } else {
        valid_phone_number = this.leadForm.value.phone_number;
        valid_ccode = this.leadForm.value.ccode;
      }

      if (this.leadForm.controls["name"].status === 'DISABLED') {
        valid_name = this.leadForm.controls["name"].value;
      } else {
        valid_name = this.leadForm.value.email;
      }

      if (this.leadForm.controls["source_type"].status === 'DISABLED') {
        valid_channel = this.leadForm.controls["source_type"].value;
      } else {
        valid_channel = this.leadForm.value.email;
      }

      const owner_department = this.staffMembers.filter((member: any) => member.id === this.leadForm.value.owner);
      // console.log(owner_department);
      

      if (this.leadForm.value.id) {
        let info_body;
        if (Object.keys(this.info).length === 0) {
          info_body = this.lead.info;
        } else {
          info_body = this.info;
        }

        this.service.updateLead({ ...this.leadForm.value, email: valid_mailId, phone_number: valid_phone_number, ccode: valid_ccode, name: valid_name, source_type: valid_channel, info: info_body, assignee_department: assignee_departments ? assignee_departments : [], owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : '' }).pipe(finalize(() => this.loading = false)).subscribe({
          next: () => {
            this.passBack();
            this.ngbActiveModal.dismiss();
          }
        });
      } else {
        this.service.createLead({ ...this.leadForm.value, email: valid_mailId, phone_number: valid_phone_number, ccode: valid_ccode, info: this.info, assignee_department: assignee_departments ? assignee_departments : [], owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : '' }).pipe(finalize(() => this.loading = false)).subscribe({
          next: () => {
            this.passBack();
            this.ngbActiveModal.dismiss();
          }
        });
      }
    }
  }

  getContactsList() {
    this.service.getContactsDropdownList().subscribe({
      next: (resp: any) => {
        this.contactListArray = resp.data;
      }
    });
  }

  getCompaniesList() {
    this.service.getCompaniesDropdownList().subscribe({
      next: (resp: any) => {
        this.companiesListArray = resp.data;
      }
    });
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  getProducts() {
    this.product_service.getStore().subscribe({
      next: (resp: any) => {
        this.product_service.getActiveProducts(resp.data.id).subscribe({
          next: (products_resp: any) => {
            console.log(products_resp);
            this.products_list = products_resp.data;
          }
        });
      }
    });
  }

  // getCustomFieldsData() {
  //   this.service.getCustomLeadFields().subscribe({
  //     next: (resp: any) => {
  //       console.log("custom fields - ", resp.Data);
  //       if (resp.Data) {
  //         for (const data of resp.Data) {
  //           console.log(data);
  //           this.addfieldsData(data);
  //         }
  //       }
  //     }
  //   });
  // }

  onSelectOwner(owner: any) {
    this.info['owner_info'] = {
      fname: owner.fname,
      lname: owner.lname
    };
  }

  passBack() {
    this.passEntry.emit(true);
  }

  clearContactFields(e: any) {
    console.log(e, "clear event");
    this.leadForm.patchValue({ email: '', contact: '', phone_number: '', name: '' });
  }


  //*******************************Search filters START******************************************** *//
  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterProducts() {
    if (!this.products_list) {
      return;
    }
    // get the search keyword
    let search = this.productFilter.value;
    if (!search) {
      this.filteredProducts.next(this.products_list.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredProducts.next(
      this.products_list.filter((name: any) => (name.name.toLowerCase().indexOf(search) > -1)
      ));
  }

  getTags() {
    this.settingsService.getTags().subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }

}
