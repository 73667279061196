import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CrmService } from 'src/app/core/service/crm.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { MatStepper } from '@angular/material/stepper';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-team-pipeline',
  templateUrl: './create-team-pipeline.component.html',
  styleUrls: ['./create-team-pipeline.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class CreateTeamPipelineComponent implements OnInit {
  errorJson = ERRORJSON[0];
  selectedStage: any;
  pipeline_form!: FormGroup;
  formStages!: FormGroup;
  closeStage: any[] = [
    // {
    //   name: 'Won',
    //   id: '',
    //   is_close: true,
    //   is_success: true,
    //   newStageAdd: false,
    //   stageSelect: "false"
    // },
    // {
    //   name: 'Closed',
    //   id: '',
    //   is_close: true,
    //   is_success: false,
    //   newStageAdd: false,
    //   stageSelect: "false"
    // }
  ];
  listArray = ['Standard', 'Administrator', 'sales executive'];
  addCustomField = false;
  field_types = [
    {
      type: 'Text box',
      value: 'TEXT'
    },
    {
      type: 'Checkbox',
      value: 'CHECK_BOX'
    },
    {
      type: 'Radio',
      value: 'RADIO_BUTTON'
    },
    {
      type: 'DropDown',
      value: 'SELECT_DROPDOWN'
    },
    // {
    //   type: 'Upload',
    //   value: 'FILE'
    // },
    {
      type: 'Text Area',
      value: 'TEXT_AREA'
    }
  ];
  pipelineFields: any[] = [];
  stageUpdate = false;
  @Input() pipelineId: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  customFieldsForm!: FormGroup;
  templateField: any[] = [];
  customFields: any[] = [];
  allStagesList: any[] = [];
  selectedStageIndex!: number;
  closedStageIndex!: number;
  @Input() stepperCount: any;
  @ViewChild('myFirstInput') myFirstInput!: ElementRef;
  mandatory_fields = [
    {
      label_name: 'Record Name',
      mandatory: true
    },
    {
      label_name: 'Contact',
      mandatory: true
    },
    {
      label_name: 'Email',
      mandatory: true
    },
    {
      label_name: 'Phone Number',
      mandatory: true
    },
    {
      label_name: 'Due Date',
      mandatory: true
    },
    {
      label_name: 'Budget',
      mandatory: false
    }
  ];
  templatePrice = false;
  submitted = false;
  @ViewChild('steppers') steppers!: MatStepper;
  create_pipeline = false;

  constructor(private crmService: CrmService, private ngbActiveModal: NgbActiveModal,
    private toaster: ToastrService) { }

  ngOnInit() {
    this.intilizeForm();
    if (this.pipelineId) this.getPipelineDetailByid();
    if (this.stepperCount === 2) {
      this.getTemplateCard();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.myFirstInput.nativeElement.focus();
    }, 0);
  }

  getPipelineDetailByid() {
    this.crmService.getPiplineById(this.pipelineId).subscribe({
      next: (resp: any) => {
        this.intilizeForm(resp.data[0]);
      }
    });
  }

  /********************************Pipeline Start*********************************************/
  intilizeForm(data?: any) {
    this.pipeline_form = new FormGroup({
      id: new FormControl(data ? data.id : ''),
      card_name: new FormControl(data ? data.card_name : ''),
      card_name_plural: new FormControl(data ? data.card_name_plural : ''),
      name: new FormControl(data ? data.name : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9_\s]{1,}$/)])
    });
    this.intilizeStageForm(data?.id);
  }

  get pipelines() {
    return this.pipeline_form.controls;
  }

  createPipeline() {
    this.submitted = true;
    if (this.pipeline_form.valid) {
      if (this.pipeline_form.value.id) {
        this.crmService.putPipelines(this.pipeline_form.value).subscribe((resp: any) => {
          this.intilizeStageForm(resp.data.id);
          this.getStagesBasedonPipeline();
          this.steppers.next();
        });
      } else {
        this.crmService.postPipelines(this.pipeline_form.value).subscribe((resp: any) => {
          this.intilizeStageForm(resp.data.id);
          this.pipelineId = resp.data.id;
          this.steppers.next();
          this.create_pipeline = true;
          // this.crmService.TemplateCardCreation({ card_name: resp.data.name, pipeline: resp.data.id }).subscribe({
          //   next: () => { //@typescript-eslint/no-empty-function
          //     // creating a template after pipeline is created
          //   }
          // });
        });
      }
    }
  }
  /********************************Pipeline Ends*********************************************/

  /**********************************Stage Start***********************************/
  dropStages(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      const stagesArray: any[] = [], closedStages: any[] = [];
      moveItemInArray(
        this.FormOpt.controls,
        event.previousIndex,
        event.currentIndex
      );
      this.allStagesList.forEach(element => {
        if (element.is_close) {
          closedStages.push(element.id);
        }
      });
      this.FormOpt.controls.forEach((element: any) => {
        if (element.value.id) stagesArray.push(element.value.id);
      });
      const stage = stagesArray.concat(closedStages);
      if (stagesArray.length > 0) this.crmService.putPipelines({ stage_order: stage, ...this.pipeline_form.value }).subscribe((resp: any) => { //@typescript-eslint/no-empty-function
      });
    }
  }

  intilizeStageForm(pipelineId: any) {
    this.formStages = new FormGroup({
      stages: new FormArray([]),
      pipeline: new FormControl(pipelineId)
    });
  }

  get FormOpt() {
    return this.formStages.get('stages') as FormArray;
  }

  get f() {
    return this.formStages.controls;
  }

  onChanges(ev: any, item: any, index: number) {
    if (ev.label) {
      this.closeStage[index] = {
        name: item.name.label,
        is_close: true,
        is_success: item.is_success,
        newStageAdd: true,
        stageSelect: "true",
        id: item.id ? item.id : ''
      };
      if (item.id) {
        this.stageUpdate = true;
        this.closedStageIndex = index;
      }
    }
    else if (ev === "success") {
      this.closeStage[0] = {
        name: "Won",
        is_close: true,
        is_success: true,
        newStageAdd: false,
        stageSelect: "false",
        id: item.id ? item.id : ''
      };
    } else if (ev === "error") {
      this.closeStage[1] = {
        name: "Closed",
        is_close: true,
        is_success: false,
        newStageAdd: false,
        stageSelect: "false",
        id: item.id ? item.id : ''
      };
    }
  }

  addNew(data?: any) {
    this.FormOpt.push(new FormGroup({
      name: new FormControl(data ? data.name : '', Validators.required),
      is_close: new FormControl(data ? data.is_close : false),
      is_success: new FormControl(data ? data.is_success : false),
      id: new FormControl(data ? data.id : '')
    }));
  }

  deleteFormOption(item: any, index_option: number) {
    this.FormOpt.removeAt(index_option);
  }

  getStagesBasedonPipeline() {
    this.FormOpt.clear();
    this.crmService.getStageByPipelineId(this.pipelineId).subscribe((resp: any) => {
      this.allStagesList = resp.data;
      this.closeStage = [];
      resp.data.forEach((element: any) => {
        if (!element.is_close) {
          this.addNew(element);
        } else {
          this.closeStage.push({
            name: element.name,
            is_close: element.is_close,
            is_success: element.is_success,
            newStageAdd: false,
            stageSelect: "false",
            id: element.id
          });
        }
      });
    });
  }

  onStageUpdate() {
    const stage = this.formStages.controls['stages'].value.concat(this.closeStage);
    if (this.formStages.controls['stages'].value.length === 0) {
      this.toaster.error('Please Add a stage');
      return;
    }
    if (this.formStages.valid) {
      const body = {
        pipeline: this.formStages.controls['pipeline'].value,
        stages: stage
      };
      this.crmService.blukStageCreation(body).subscribe(() => {
        this.pipelineId = this.formStages.controls['pipeline'].value;
        this.getTemplateCard();
        this.steppers.next();
      });
    }
  }

  stageDelete(stageId: string, stageType: string, index_option: number) {
    if (stageId) {
      this.crmService.deleteStage(stageId).subscribe({
        next: () => {
          this.getStagesBasedonPipeline();
        }
      });
    } else {
      if (stageType === 'open') {
        this.FormOpt.removeAt(index_option);
      } else {
        this.closeStage.splice(index_option, 1);
      }
    }
  }

  conditionalInputs(index: number) {
    if (this.allStagesList.length > 0) {
      this.stageUpdate = true;
      this.selectedStageIndex = index;
    }
  }

  conditionalInputCloseStage(index: number) {
    if (this.allStagesList.length > 0) {
      this.stageUpdate = true;
      this.closedStageIndex = index;
    }
  }

  addNewClosedStage() {
    this.closeStage.push(
      {
        name: '',
        id: '',
        is_close: true,
        is_success: false,
        newStageAdd: false,
        stageSelect: "false"
      }
    );
  }

  updateSingleStage(data: any) {
    if (data.value.id) {
      this.crmService.putSingleStage({ pipeline: this.pipelineId, ...data.value, data: [] }).subscribe({
        next: (resp: any) => {
          this.stageUpdate = false;
          this.getStagesBasedonPipeline();
        }
      });
    } else {
      this.crmService.postSingleStage({ pipeline: this.pipelineId, ...data.value }).subscribe({
        next: (resp: any) => {
          this.stageUpdate = false;
          this.getStagesBasedonPipeline();
        }
      });
    }
  }

  closedStageupdate(stageData: any) {
    if (stageData.id) {
      this.crmService.putSingleStage({ pipeline: this.pipelineId, ...stageData, data: [] }).subscribe({
        next: (resp: any) => {
          this.stageUpdate = false;
        }
      });
    } else {
      this.crmService.postSingleStage({ pipeline: this.pipelineId, ...stageData }).subscribe({
        next: (resp: any) => {
          this.stageUpdate = false;
        }
      });
    }
  }

  getGlobalFields() {
    this.crmService.getUnusedCustomFields().subscribe((resp: any) => {
      this.customFields = resp.data.filter((ele: any) =>
        !this.pipelineFields.find(custField => ele.label === custField.label)
      );
    });
  }

  getTemplateCard() {
    this.crmService.getTemplateCard(this.pipelineId).subscribe((resp: any) => {
      this.templateField = resp.data;
      this.templatePrice = resp.data[0].price_on;
      this.getFields();
      setTimeout(() => {
        this.getGlobalFields();
      }, 1000);
    });
  }

  changeIconLost(event: any, item: any, index: number) {
    if (event.value === "true") {
      this.closeStage[index] = {
        ...item,
        is_success: true,
        stageSelect: event.value
      };
    } else {
      this.closeStage[index] = {
        ...item,
        is_success: false,
        stageSelect: event.value
      };
    }
  }

  onStepperChange(stepper: string) {
    if (stepper === "stages") {
      this.steppers.next();
      this.intilizeStageForm(this.pipeline_form.value.id);
      this.getStagesBasedonPipeline();
    } else if (stepper === 'fields') {
      this.getTemplateCard();
      this.steppers.next();
    }
  }
  /**********************************Fields Start***********************************/
  AddNewField() {
    this.addCustomField = true;
    this.intilizeCustomForm();
  }

  getFields() {
    this.crmService.getFieldsByTemaplateCardId(this.templateField[0].id).subscribe({
      next: (resp: any) => {
        this.pipelineFields = resp.data;
      }
    });
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      this.intilizeCustomForm(event.container.data[event.previousIndex]);
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.fieldCreation();
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  /**********************************Fields End***********************************/

  /**********************Custom Feild Creation Start******************************* */

  backtocustomList() {
    this.addCustomField = false;
  }

  intilizeCustomForm(data?: any) {
    this.customFieldsForm = new FormGroup({
      "label": new FormControl(data ? data.label : '', Validators.required),
      "data_type": new FormControl(data ? data.data_type : '', Validators.required),
      "url": new FormControl(data ? data.url : ''),
      "template_card": new FormControl(this.templateField[0].id, Validators.required),
      "options": new FormArray([])
    });
  }

  get fieldOptions() {
    return this.customFieldsForm.get('options') as FormArray;
  }

  addNewOption() {
    this.fieldOptions.push(new FormControl('', Validators.required));
  }

  onTypeChange(ev: any) {
    this.fieldOptions.removeAt(0);
    if (ev.value !== 'TEXT' && ev.value !== 'TEXT_AREA') this.addNewOption();
  }

  fieldCreation(type?: string) {
    if (this.customFieldsForm.valid) {
      this.crmService.customFieldCreation(this.customFieldsForm.value).subscribe(() => {
        if (type === 'save') this.addCustomField = false;
        this.getFields();
        this.getGlobalFields();
      });
    }
  }

  deleteCustomFields(id: string) {
    this.crmService.deleteCustomField(id).subscribe({
      next: () => {
        this.getFields();
      }
    });
  }

  changeBudget() {
    this.crmService.PutTemplateCard({ id: this.templateField[0].id, pipeline: this.pipelineId, price_on: this.templatePrice }).subscribe({
      next: () => {
        this.getTemplateCard();
      }
    });
  }

  /**********************Custom Feild Creation End******************************* */
  modelClose() {
    this.ngbActiveModal.dismiss();
    this.passEntry.emit("success");
    if (this.create_pipeline) this.crmService.createdNewPipelines('true');
  }
}
