import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray, FormGroupDirective } from '@angular/forms';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { ACTIVITYJSON } from '../../../../core/data/crm_modal_json';
import { CommonService } from 'src/app/core/service/common.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { ClientsService } from 'src/app/core/service/clients.service';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'app-activity-crm',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {
  @Input() isModal!: boolean;
  @Output() closeModalEvent = new EventEmitter<string>();
  activityJSON = ACTIVITYJSON[0];
  errorjson = ERRORJSON[0];
  selected_id!: string;
  selected_type!: string;
  activity_show = false;
  activity_type: any;
  associated_dropdown = 'All';
  submitedActivityForm = false;
  activity_form!: FormGroup;
  activity_filter = 'all';
  activityList_filter = 'all';
  activitiesArray: any = [];
  staffMembers: any = [];
  source_id_details: any;
  info: any = {};
  loggedInUser: any;
  guestsMeetingList: any = [];
  related_to: any = [];
  minDate = new Date();
  source_name!: string;
  activities_total_count!: number;
  pageEvent!: PageEvent;
  currentPage = 0;

  constructor(private route: ActivatedRoute, private service: CommonService, private crm_service: CrmService, private client_service: ClientsService, private settingsService: SettingsService, private confirmationMsz: ConfirmationMszService) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      // console.log("route params timeline - ", params);
      this.selected_id = params.id;
      this.selected_type = params.showtype;
      this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
      this.initlizeActivityForm();
      this.activity_form.patchValue({ owner: this.loggedInUser.data.id });
      this.organisationMembersList();
      if (this.selected_id && !this.isModal) {
        // console.log("type - ", this.selected_type);
        this.getAllActivites(this.selected_id, 1);
        if (this.selected_type === 'contact') {
          // console.log("contact");

          this.crm_service.getContactById(this.selected_id).subscribe({
            next: (resp: any) => {
              this.source_id_details = resp.data[0];
              this.source_name = this.source_id_details.first_name + " " + this.source_id_details.last_name;
              this.activity_form.patchValue({ source_id: this.source_id_details.id, source_name: this.source_id_details.first_name + " " + this.source_id_details.last_name });
            }
          });
        } else if (this.selected_type === 'company') {
          // console.log("company");
          this.crm_service.getCompanyById(this.selected_id).subscribe({
            next: (resp: any) => {
              this.source_id_details = resp.data[0];
              this.source_name = this.source_id_details.name;
              this.activity_form.patchValue({ source_id: this.source_id_details.id, source_name: this.source_id_details.name });
            }
          });
        } else if (this.selected_type === 'lead') {
          // console.log("lead");
          this.crm_service.getLeadById(this.selected_id).subscribe({
            next: (resp: any) => {
              this.source_id_details = resp.data[0];
              this.source_name = this.source_id_details.requirement_title;
              this.activity_form.patchValue({ source_id: this.source_id_details.id, source_name: this.source_id_details.requirement_title });
            }
          });
        }
      }
    });
    if (this.isModal) {
      this.organisationMembersList();
      this.activity_show = true;
      this.activity_type = 'followup';
      this.activity_form.patchValue({ type: this.activity_type });
      this.activity_form.controls['related_to_type'].setValidators(Validators.required);
      this.searchTrigger();
    }


    // console.log(this.activity_form.get('search'), this.activity_form.get('search')?.valueChanges);
  }


  getAllActivites(id: string, page: number) {
    this.service.getActivitysById(id, page).subscribe({
      next: (resp: any) => {
        // console.log("get activities - ", resp);
        this.activities_total_count = resp.count;
        this.activitiesArray = resp.data;
      }
    });
  }

  createActivity(body: any, formDirective: any) {
    // console.log(this.activity_form);
    let department: any;
    for (const item of this.staffMembers) {
      if (item.id === body.owner) {
        this.info['owner_info'] = {
          fname: item.fname,
          lname: item.lname
        };
        department = item.department_info.id;
      }
    }
    this.service.createActivity({ ...body, info: this.info, source_name: this.source_name, department: department }).subscribe({
      next: () => {
        this.activity_show = false;
        this.closeModalEvent.emit('close');
        this.guestsMeetingList = [];
        this.getAllActivites(this.selected_id, 1);
        formDirective.resetForm();
        this.activity_form.reset();
        this.submitedActivityForm = false;
        this.initlizeActivityForm();
        this.activity_form.patchValue({ type: this.activity_type });
        this.patchDefaultValues();
      }
    });
  }

  currentActivity(content: string, formDirective: FormGroupDirective) {
    this.submitedActivityForm = false;
    formDirective.resetForm();
    this.activity_form.reset();
    this.initlizeActivityForm();
    this.activity_show = true;
    this.activity_type = content;
    // console.log(this.activity_type);

    this.activity_form.patchValue({ type: this.activity_type });
    this.activity_form.patchValue({ owner: this.loggedInUser.data.id });
    if (this.isModal) {
      this.searchTrigger();
    }
    if (!this.isModal) {
      this.patchDefaultValues();
    }
  }

  searchTrigger() {
    this.activity_form.get('search')!.valueChanges.subscribe((change: any) => {
      // console.log(change, "search");
      // console.log(this.activity_type, this.selected_type, "activity type");
      if (change.length >= 3) {
        if (this.selected_type === 'lead' && this.isModal === true) {
          this.getLeadsList(change);
        } else if (this.selected_type === 'contact' && this.isModal === true) {
          this.getContactsList(change);
        } else if (this.selected_type === 'company' && this.isModal === true) {
          this.getCompaniesList(change);
        } else if (this.selected_type === 'project' && this.isModal === true) {
          this.getAllProjects(change);
        }
      }
    });
  }

  initlizeActivityForm() {
    this.activity_form = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9,-/@_\s]{1,}$/)]),
      due_date: new FormControl('', Validators.required),
      source_id: new FormControl('', Validators.required),
      source_name: new FormControl({ value: '', disabled: true }),
      owner: new FormControl('', Validators.required),
      source_type: new FormControl(this.selected_type, Validators.required),
      type: new FormControl('', Validators.required),
      location: new FormControl(''),
      meeting_link: new FormControl(''),
      guest: new FormArray([]),
      description: new FormControl(''),
      search: new FormControl(''),
      related_to_type: new FormControl('')
    });
  }

  get guestList() {
    return this.activity_form.get('guest') as FormArray;
  }

  addMailsToTheList(mail: any) {
    this.guestList.push(new FormControl(mail));
  }

  addnew = (option: any) => ({ mail: option });

  onSelectEmail(e: any) {
    // console.log("event", e);
    this.guestsMeetingList = e;
  }

  get f() {
    return this.activity_form.controls;
  }

  submitActivityForm(formDirective: FormGroupDirective) {
    const newdata = this.activity_form;
    console.log(newdata);

    this.submitedActivityForm = true;
    if (this.activity_form.valid) {
      if (this.guestsMeetingList) {
        this.guestsMeetingList.map((data: any) => {
          this.addMailsToTheList(data.mail);
        });
      }
      this.createActivity(this.activity_form.value, formDirective);
    }
  }

  updateActivity(id: any, status: boolean) {
    this.service.updateActivity(id, { mark_as_done: status, outcome: status ? 'completed' : 'pending' }).subscribe(() => {
      this.getAllActivites(this.selected_id, 1);
    });
  }

  deleteActivity(id: string) {
    this.service.deleteActivty(id).subscribe(() => {
      this.getAllActivites(this.selected_id, 1);
    });
  }

  confirmDelete(fileId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteActivity(fileId);
      }
    });
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  getLeadsList(name: string) {
    this.crm_service.getLeadsBySearch(name).subscribe({
      next: (resp: any) => {
        this.related_to = resp.data;
      }
    });
  }

  getContactsList(name: string) {
    this.crm_service.getContactsBySearch(name).subscribe({
      next: (resp: any) => {
        this.related_to = resp.data;
      }
    });
  }

  getCompaniesList(name: string) {
    this.crm_service.getCompaniesBySearch(name).subscribe({
      next: (resp: any) => {
        this.related_to = resp.data;
      }
    });
  }

  getAllProjects(name: string) {
    this.client_service.getAllProjectsBySearch(name).subscribe({
      next: (resp: any) => {
        this.related_to = resp.data;
      }
    });
  }

  onRelatedTochange(ev: any) {
    // console.log(ev);

    this.selected_type = ev.value;
    this.activity_form.patchValue({ source_type: this.selected_type });
    this.related_to = [];
    // this.activity_form.controls['search'].setValue('');

  }

  patchDefaultValues() {
    // console.log(this.loggedInUser, "LOG user");

    if (this.selected_type === 'contact') {
      this.activity_form.patchValue({ source_id: this.source_id_details.id, source_name: this.source_id_details.first_name + this.source_id_details.last_name });

    } else if (this.selected_type === 'company') {
      this.activity_form.patchValue({ source_id: this.source_id_details.id, source_name: this.source_id_details.name });

    } else if (this.selected_type === 'lead') {
      this.activity_form.patchValue({ source_id: this.source_id_details.id, source_name: this.source_id_details.requirement_title });
    }
  }

  selectedSource(name: string) {
    this.source_name = name;
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getAllActivites(this.selected_id, this.currentPage);
  }

}
