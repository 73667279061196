<div class="modal-header">
  <h5 *ngIf="!contact?.id" class="modal-title text-primary fw-600">Add Contact</h5>
  <h5 *ngIf="contact?.id" class="modal-title text-primary fw-600">Update Contact</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="ngbActiveModal.dismiss()"></button>
</div>

<div class="modal-body">
  <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <label class="fw-600 mb-2 text-primary">{{addcontact.label_first_name}} <small
            class="text-danger">*</small></label>
        <mat-form-field appearance="outline" class="w-100">
          <input style="outline: none" matInput placeholder="{{addcontact.label_first_name}}"
            formControlName="first_name" #myInput />
          <mat-error class="error text-danger d-flex" *ngIf="submitted && f['first_name'].errors">
            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
            <span *ngIf="f['first_name'].errors['required']"> {{addcontact.label_first_name}}
              {{errorJson.required}}</span>
            <span *ngIf="f['first_name'].errors['pattern']">Only alphanumeric are accepted.</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <label class="fw-600 mb-2 text-primary">{{addcontact.label_last_name}}
          <!-- <small class="text-danger">*</small> -->
        </label>
        <mat-form-field appearance="outline" class="w-100">
          <input style="outline: none" matInput placeholder="{{addcontact.label_last_name}}"
            formControlName="last_name" />
          <mat-error class="error text-danger d-flex" *ngIf="submitted && f['last_name'].errors">
            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
            <!-- <span *ngIf="f['last_name'].errors['required']"> {{addcontact.label_last_name}}
              {{errorJson.required}}</span> -->
            <span *ngIf="f['last_name'].errors['pattern']">{{errorJson.invalid}} {{addcontact.label_last_name}}</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
        <label class="fw-600 mb-2 text-primary">{{addcontact.label_email}} <small class="text-danger">*</small></label>
        <mat-form-field appearance="outline" class="w-100">
          <input style="outline: none" matInput placeholder="{{addcontact.label_email}}" formControlName="email" />
          <mat-error class="error text-danger d-flex" *ngIf="submitted && f['email'].errors">
            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
            <span *ngIf="f['email'].errors['required']"> {{addcontact.label_email}} {{errorJson.required}}</span>
            <span *ngIf="f['email'].errors['pattern']">{{errorJson.invalid}} {{addcontact.label_email}}</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
        <label class="fw-600 mb-2 text-primary">{{addcontact.label_phone}} <small class="text-danger">*</small></label>
        <div class="d-flex align-items-center">
          <mat-form-field appearance="outline" class="me-1">
            <mat-select placeholder="Country code" formControlName="ccode">
              <mat-option>
                <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                  noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let country of filteredBanks | async" [ngClass]="{'d-none': country.IAC === ''}"
                value={{country.IAC}}>{{country.IAC}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100">
            <input style="outline: none" matInput placeholder="{{addcontact.label_phone}}"
              formControlName="phone_number" />
            <mat-error class="error text-danger d-flex" *ngIf="submitted && f['phone_number'].errors">
              <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
              <span *ngIf="f['phone_number'].errors['required']"> {{addcontact.label_phone}}
                {{errorJson.required}}</span>
              <span
                *ngIf="f['phone_number'].errors['pattern'] || f['phone_number'].errors['minlength'] || f['phone_number'].errors['maxlength']">{{errorJson.invalid}}
                {{addcontact.label_phone}}</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
        <label class="fw-600 mb-2 text-primary">{{addcontact.label_company}}</label>
        <mat-form-field appearance="outline" class="w-100">
          <mat-select formControlName="company" placeholder="{{addcontact.label_company}}" class="pt-1">
            <mat-option *ngFor="let company of companiesListArray" [value]="company.id">{{company.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
        <label class="fw-600 mb-2 text-primary">{{addcontact.label_tag}}</label>
        <mat-form-field appearance="outline" class="w-100">
          <mat-select formControlName="tags" placeholder="{{addcontact.label_tag}}" class="pt-1">
            <mat-option [value]="item.id" *ngFor="let item of tagsListArray">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <label class="fw-600 mb-2 text-primary">{{addcontact.Source}}</label>
        <mat-form-field appearance="outline" class="w-100">
          <mat-select formControlName="source" placeholder={{addcontact.Source}} class="pt-1">
            <mat-option value="Whatsapp">Whatsapp</mat-option>
            <mat-option value="manual">Manual entry</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <label class="fw-600 mb-2 text-primary">{{addcontact.owner}} <small class="text-danger">*</small></label>
        <mat-form-field appearance="outline" class="w-100">
          <mat-select formControlName="owner" placeholder={{addcontact.owner}} class="pt-1">
            <mat-option *ngFor="let staff of staffMembers" (click)="onOwnerSelect(staff)"
              [value]="staff.id">{{staff.fname}}
              {{staff.lname}}</mat-option>
          </mat-select>
          <mat-error class="error text-danger d-flex" *ngIf="submitted && f['owner'].errors">
            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
            <span *ngIf="f['owner'].errors['required']">
              {{addcontact.owner}} {{errorJson.required}}
            </span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <label class="fw-600 mb-2 text-primary">Assignee</label>
        <ng-select class="form-control" [items]="staffMembers" bindValue="id" bindLabel="fname" [addTag]="false"
            [multiple]="true" placeholder="Select staff" formControlName="assignee">
        </ng-select>
        <!-- <div class="error d-flex mt-2" *ngIf="submitted && f['assignee'].errors">
            <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
            <span *ngIf="f['assignee'].errors['required']"> Staff
                {{errorJson.required}}</span>
        </div> -->
    </div>

      <!-- <div formArrayName="custom_contact_fields">
        <div *ngFor="let field of custom_contact_fields.controls; let i=index">
          <div class="row" [formGroupName]="i">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <label class="fw-600 mb-2 text-primary">{{field.value.label}}</label>
              <mat-form-field appearance="outline" class="w-100" *ngIf="field.value.data_type === 'text'">
                <input style="outline: none" matInput placeholder="{{field.value.label}}" formControlName="data" />
              </mat-form-field>

              <mat-form-field appearance="outline" class="w-100" *ngIf="field.value.data_type === 'dropdown'">
                <mat-select formControlName="owner" placeholder="{{field.value.label}}" class="pt-1">
                  <mat-option *ngFor="let option of options" [value]="option.id">{{option.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div> -->

      <div class="mt-0">
        <div class="mt-3 d-flex justify-content-end modal-footer">
          <!-- <div class="d-flex">
            <span class="material-icons text-primary">settings_applications</span>
            <span class="text-primary"></span> {{addcontact.customize_text}}
          </div> -->
          <div class="d-flex">
            <button class="btn btn-light me-2" type="button" (click)="ngbActiveModal.dismiss()">
              {{addcontact.cancel_btn}}
            </button>
            <button class="btn btn-primary" type="submit" [disabled]="loading">{{addcontact.save_btn}}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>