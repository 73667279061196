import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './core/service/auth.service';
import { environment } from 'src/environments/environment';
import { SetUpService } from './core/service/set-up.service';
import { InternetService } from './core/service/internet.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'worke_dashboard';
  currentUser: any;
  isOnline$ : any;
  constructor(private router: Router, private authService: AuthService,private setupService: SetUpService,
    private internetService: InternetService) { }

  ngOnInit() {
    this.isOnline$ = this.internetService.isOnline$;
    if (localStorage.getItem('currentUser')) {
      sessionStorage.setItem('currentUser', localStorage.getItem('currentUser')!);
    }
    if (sessionStorage.getItem('currentUser')) {
      this.authService.getData();
      this.setupService.getSetupInfo();
    }

    const data = {};
    parent.postMessage(data, environment.baseUrl);

  }
}
