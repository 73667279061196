import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FORMLEAD } from 'src/app/core/data/form_builder_json';
import { CrmService } from 'src/app/core/service/crm.service';
import { FormBuilderService } from 'src/app/core/service/form-builder.service';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-formlead-settings',
  templateUrl: './formlead-settings.component.html',
  styleUrls: ['./formlead-settings.component.scss']
})
export class FormleadSettingsComponent implements OnInit {

  @Input() formId = '';


  CRMForm!: FormGroup;

  ownerAssign = false;
  pipelineAssign = false;
  formleadJson = FORMLEAD[0];
  pipeline_list: string[] = ['Sales pipeline', 'Deals'];
  stages_list: string[] = ['Todo', 'Inprogress', 'Completed'];
  form_fields_list: any = [];

  staffList: any = [];
  pipelineList: any = [];
  stageList: any = [];

  constructor(
    private _settingService: SettingsService,
    private _formServices: FormBuilderService,
    private _crmServices: CrmService,
    private formBuilder: FormBuilder
  ) {
    this.initializeCRMForm();
  }

  ngOnInit(): void {
    this.getStaffInfo();
    this.getFormCRM();
    this.getPipelines();
    this.getFormFields(this.formId);
  }

  initializeCRMForm(data?: any) {
    this.CRMForm = this.formBuilder.group({
      id: new FormControl(data?.id ? data.id : ''),
      enquiry_owner: new FormControl(data?.enquiry_owner ? data.enquiry_owner : ''),
      is_card: new FormControl(data?.is_card ? data?.is_card : false),
      pipeline: new FormControl(data?.pipeline ? data.pipeline : ''),
      stage: new FormControl(data?.stage ? data.stage : ''),
      is_enquiry: new FormControl(data?.is_enquiry ? data.is_enquiry : false),
      form_id: new FormControl(this.formId, Validators.required),
      name: new FormControl(data?.name ? data.name : ''),
      email: new FormControl(data?.email ? data.email : ''),
      phone: new FormControl(data?.phone ? data.phone : ''),
      title: new FormControl(data?.title ? data.title : ''),
      budget: new FormControl(data?.budget ? data.budget : ''),
      // due_date: new FormControl(data?.due_date ? data.due_date : ''),
      card_owner: new FormControl(data?.card_owner ? data.card_owner : '')

    });
    if (data) {
      this.getStages();
    }
  }

  getStaffInfo() {
    this._settingService.getStaff_info().subscribe((resp: any) => {
      this.staffList = resp?.data;
    });
  }

  getPipelines() {
    this._crmServices.getPipelines().subscribe((resp: any) => {
      this.pipelineList = resp.data;
    });
  }

  getStages() {
    this.CRMForm.value.stage = '';
    if(!this.CRMForm.value.pipeline){
      return;
    }
    this._crmServices.getStageByPipelineId(this.CRMForm.value.pipeline).subscribe((resp: any) => {
      this.stageList = resp.data;
    });
  }

  getFormCRM() {
    this._formServices.getFormCRM(this.formId).subscribe((resp: any) => {
      this.initializeCRMForm(resp.data);
    });
  }

  onCRMFormSubmit() {
    if (this.CRMForm.value.id) {
      this._formServices.putFormCRM(this.CRMForm.value, this.formId).subscribe((resp: any) => {
        this.initializeCRMForm(resp.data);
      });
    } else {
      this._formServices.postFormCRM(this.CRMForm.value).subscribe((resp: any) => {
        this.initializeCRMForm(resp.data);
      });
    }
  }

  onAssignChange(type: string) {

    if (type === 'pipeline') {
      this.CRMForm.patchValue({
        is_card: false
      });

    } else if (type === 'enquire') {
      this.CRMForm.patchValue({
        is_enquiry: false
      });
    }
  }

  getFormFields(id: string) {
    this._formServices.getFormFields(id).subscribe((resp: any) => {
      this.form_fields_list = resp.data;
    });
  }
}
