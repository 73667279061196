<div class="container-fluid">
  <div class="row mt-3 mb-4">
    <div class="col-md-4 d-flex align-items-center">
      <!-- <mat-icon class="text-primary">arrow_back_ios </mat-icon> -->
      <p class="m-0 heading font-size-18 fw-700 text-primary" *ngIf="!companyId">{{contactsJson.heading_label}}</p>
      <mat-form-field appearance="outline" class="contactsfilter main-filter ms-3 mat-hint-none">
        <mat-select placeholder="Contacts" (ngModelChange)="onFilterChange($event)" [(ngModel)]="filter" class="pt-1">
          <mat-option class="font-size-12" [value]="item.value"
            *ngFor="let item of select_type">{{item.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-8 d-flex justify-content-end align-items-center">
      <!-- <button class="btn btn-outline-primary d-flex align-items-center me-3" (click)="openDialog(addtolistModel)">
        <mat-icon>playlist_add</mat-icon>
        {{contactsJson.add_to_list_label}}
      </button> -->

      <mat-form-field appearance="outline" class="me-3 mat-hint-none position-relative searching-box" style="top: 2px">
        <input style="outline: none" matInput [(ngModel)]="search_global" (ngModelChange)="search($event)"
          placeholder={{contactsJson.search_contact_placeholder}} />
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>

      <button class="btn btn-primary d-flex align-items-center me-3 font-size-13" *ngIf="!companyId"
        (click)="openContactsModalDialog()">
        <mat-icon>add</mat-icon>
        {{contactsJson.label_add_contact}}
      </button>
      <button mat-raised-button matTooltip="Add Task Board Stages" *ngIf="!companyId && loggedInUser.data.role === 'ADMIN'"
        aria-label="Button that displays a tooltip when focused or hovered over" type="button"
        class="btn border-0 p-0 d-flex align-items-center"
        (click)="openTaskBoardPreview()"><!--[disabled]="selection.selected.length == 0"-->
        <span class="p-1 me-3 rounded bg-gray-light d-flex align-items-center justify-content-center">
          <!-- <mat-icon class="font-size-26 position-relative" style="top: 5px">add</mat-icon> -->
          <img src="../../../assets/images/icons_add.svg" class="cursor" width="16">
        </span>
      </button>
      <button mat-raised-button matTooltip="Export"
        aria-label="Button that displays a tooltip when focused or hovered over" type="button"
        class="btn border-0 p-0 d-flex align-items-center"><!-- [disabled]="selection.selected.length == 0"-->
        <mat-icon class="me-3 text-primary" (click)="downloadContacts($event)">exit_to_app</mat-icon>
      </button>
      <button mat-raised-button matTooltip="Import" *ngIf="!companyId"
        aria-label="Button that displays a tooltip when focused or hovered over" type="button"
        class="btn border-0 p-0 d-flex align-items-center">
        <mat-icon class="me-3 text-primary"
          (click)="importEexportModalDialog(importExportModel, 'import')">move_to_inbox</mat-icon>
      </button>

      <!-- <mat-icon> format_list_bulleted</mat-icon> -->
      <!-- <div class="d-flex contacts-filter-lable">
        <span class="me-3 text-primary" (click)="checkedContacts()">{{contactsJson.Filter_label}}</span>
        <span mat-raised-button class="menuTrigger text-primary material-icons cursor"
          [matMenuTriggerFor]="filter_search_menu">filter_list</span>

        <mat-menu #filter_search_menu="matMenu">
          <mat-form-field appearance="outline" class="mx-3 my-2 mat-hint-none" (click)="$event.stopPropagation()">
            <input style="outline: none" matInput placeholder={{contactsJson.search_placeholder}} />
            <mat-icon matPrefix>search</mat-icon>
          </mat-form-field>

          <div class="" *ngFor="let item of filtersLables; let i = index">
            <button mat-menu-item id="{{ i }}" (click)="selectFilterLable($event)">
              <mat-checkbox [checked]="item.checked">{{ item.lableName }}</mat-checkbox>
            </button>
          </div>
        </mat-menu>
      </div> -->
    </div>
  </div>

  <div class="table-responsive table-height mt-4 fixed-table">
    <table class="table align-middle table-nowrap table-hover mb-0 w-100">
      <thead>
        <tr class="border-top-table">
          <th scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" class="mat_checkbox"></mat-checkbox>
          </th>
          <ng-container *ngFor="let col of filtersLables">
            <th *ngIf="col.checked" scope="col" class="text-primary font-size-14 pb-3 table-sticky-header bg-white"
              style="z-index: 999;">{{col.lableName}}</th>
          </ng-container>
        </tr>
      </thead>
      <tbody *ngIf="!display_no_data">
        <tr class="cursor" *ngFor="let item of contactListArray; let i = index" [routerLink]="['../crm-details']"
          [queryParams]="{ id: item.id, showtype: 'contact'}">
          <td>
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(item) : null"
              [checked]="selection.isSelected(item)" class="mat_checkbox"></mat-checkbox>
          </td>
          <td>
            <!-- {{ item.first_name ? item.first_name : "-" }} {{item.last_name}} -->
            <app-table-popover [inputType]="'text'" [inputValue]="item.first_name" [labelName]="'Name'"
              [formName]="'first_name'" (update)="onUpdatePopOver($event, item)"></app-table-popover>
          </td>
          <td>{{ item.email ? item.email : "-" }}</td>
          <td>
            <!-- {{ item.phone_number ? item.phone_number : "-" }} -->
            <app-table-popover [inputType]="'text'" [inputValue]="item.phone_number" [labelName]="'Mobile Number'"
              [formName]="'phone_number'" (update)="onUpdatePopOver($event, item)"></app-table-popover>
          </td>
          <!-- <td>Next activity</td> -->
          <td>
            <!-- {{item.company_name ? item.company_name : "-"}} -->
            <app-table-popover [inputType]="'dropdown'" [inputValue]="item.company_name" [formName]="'company'"
              [labelName]="'Company Name'" [dropDownItems]="companiesListArray" [selectedItem]="item.company"
              (update)="onUpdatePopOver($event, item)"></app-table-popover>
          </td>
          <td>
            <app-table-popover [inputType]="'dropdown'"
              [inputValue]="(item.info?.owner_info?.fname || '-') + ' ' + (item.info?.owner_info?.lname || '')"
              [formName]="'info.owner_info'" [labelName]="'Owner'" [dropDownItems]="staffMembers"
              [selectedItem]="item.owner" (update)="onUpdatePopOver($event, item)"></app-table-popover>
          </td>
          <td>{{item.source ? item.source : "-"}}</td>
          <td *ngIf="item.created_at">{{ item.created_at | dateConversion }}</td>
          <td *ngIf="!item.created_at">-</td>
          <!-- <td>{{item.first_name}}</td>
          <td>{{item.last_name}}</td> -->
          <!-- <td>Last Activity Date & Time</td>
          <td>{{item.updated_by}}</td>
          <td>{{item.updated_at | date: 'M/d/yy, h:mm a'}}</td>
          <td>Mailing Street</td>
          <td>Mailing Address</td>
          <td>Mailing City</td>
          <td>Mailing Country</td>
          <td>Mailing State</td>
          <td>Shipping Address</td>
          <td>Shipping Street</td>
          <td>Shipping City</td>
          <td>Shipping Country</td>
          <td>Zip Code</td>
          <td>Title</td> -->
          <td (click)="$event.stopPropagation()">
            <div class="dropdown" ngbDropdown>
              <mat-icon style="z-index: 99;" [matMenuTriggerFor]="menuadd">more_vert</mat-icon>
              <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                <button mat-menu-item (click)="openContactsModalDialog(item)" class="py-2">
                  Edit
                </button>
                <!-- <button mat-menu-item (click)="confirmDelete(item.id)" class="py-2">
                  Delete
                </button> -->
              </mat-menu>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <app-nodata-found *ngIf="display_no_data"></app-nodata-found>

  </div>
  <div>
    <mat-paginator [length]="contactsObj.total" [pageSize]="page_size" (page)="page($event)" aria-label="Select page"
      hidePageSize="true" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>

<ng-template #addtolistModel let-modal>
  <div class="modal-header">
    <h5 class="modal-title text-primary">{{contactsJson.add_to_list_label}}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row px-2 mb-3">
      <label class="mb-2 fw-600 text-primary">{{contactsJson.lists_label}}<small class="text-danger">*</small></label>
      <ng-select class="form-control" [items]="listArray" bindLabel="group_name" [addTag]="addOptionToList"
        [multiple]="true" placeholder='Select' [(ngModel)]="selected_group">
      </ng-select>
    </div>
  </div>
  <div class="modal-footer border-0 d-flex justify-content-end mb-5">
    <button type="button" class="d-flex align-items-center btn btn-primary small" (click)="addToList()">
      <mat-icon class="me-1">playlist_add_check</mat-icon> {{contactsJson.add_to_list_label}}
    </button>
  </div>
</ng-template>

<ng-template #importExportModel let-modal>
  <div class="modal-header">
    <h5 class="modal-title text-primary" *ngIf="importModalType === 'import'">
      {{contactsJson.label_import}}
    </h5>
    <h5 class="modal-title text-primary" *ngIf="importModalType === 'export'">
      {{contactsJson.label_export}}
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="contact-dotted-border text-center mb-3">
      <input type="file" class="form-control" (change)="onFileChange($event)" />
    </div>
    <a class="cursor" (click)="downloadSampleFile()">Download Sample
      File</a>

    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-primary" *ngIf="importModalType === 'import'" [disabled]="!enableImportBtn"
        (click)="importContacts()">
        {{contactsJson.import_btn}}
      </button>
      <button type="button" class="btn btn-primary" *ngIf="importModalType === 'export'">
        {{contactsJson.export_btn}}
      </button>
    </div>
  </div>
</ng-template>