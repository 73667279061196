<div class="d-flex align-items-center editableData">
    <div class="flex-grow-1">
        <span *ngIf="inputType==='text'|| inputType ==='textarea'">{{ inputValue ? inputValue : "-" }}</span>
        <span *ngIf="inputType==='date' || inputType ==='dateTime'">{{inputValue!== '-' ? (inputValue | dateConversion) : "-"}}</span>
        <!-- <span *ngIf="inputType==='dateTime'">{{inputValue ? (inputValue | dateConversion) : "-" }}</span> -->
        <span *ngIf="inputType==='dropdown'">{{inputValue ? inputValue : "-" }}</span>
        <span *ngIf="inputType==='multiselect' && inputValue==='-'">
            <span> - </span>
        </span>
        <span *ngIf="inputType==='multiselect' && inputValue!=='-'">
            <div *ngFor="let item of inputValue">
                <span class="me-1" *ngIf="labelName === 'Staff'">{{item.fname}} {{item.lname}}</span>
                <span class="me-1" *ngIf="labelName === 'Products'">{{item.name}}</span>
            </div>
            <!-- <p *ngFor="let item of inputValue">
                <span class="me-1">{{item.name}}</span>
            </p> -->
        </span>
    </div>
    <div class="a ps-2" (click)="$event.stopPropagation()">

        <mat-icon class="fs-6 edit-icon rounded-top rounded-bottom" mat-button
            [matMenuTriggerFor]="popoverMenu">edit</mat-icon>

        <!-- <mat-menu #popoverMenu="matMenu" xPosition="after" yPosition="below"
        [overlapTrigger]="false" [backdropClass]="'custom-popover-backdrop'"> -->

        <mat-menu #popoverMenu="matMenu" [hasBackdrop]="true" [backdropClass]="'custom-popover-backdrop'"
            style="width: 300px;">
            <div class="d-flex flex-column">
                <div class="p-2 align-self-center">
                    {{labelName}}
                </div>
                <div class="p-3 bd-highlight border-top border-bottom" style="width: 280px;"
                    (click)="$event.stopPropagation()">
                    <input *ngIf="inputType==='text'" class="w-100" type="text" [(ngModel)]="modal" />
                    <!--pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"-->

                    <mat-form-field appearance="outline" class="w-100" *ngIf="inputType==='textarea'">
                        <textarea style="outline: none" matInput placeholder="Description" [(ngModel)]="modal"
                            style="height:100px"></textarea>
                    </mat-form-field>

                    <mat-form-field *ngIf="inputType==='dateTime'" appearance="outline" class="w-100">
                        <input matInput [ngxMatDatetimePicker]="picker" [min]="minDate"
                            placeholder="Choose a date & time" [(ngModel)]="modal">
                        <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-100" *ngIf="inputType==='date'">
                        <input style="outline: none" matInput [matDatepicker]="picker" [min]="minDate"
                            placeholder="Closure date" [(ngModel)]="modal" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <!-- <mat-error class="error text-danger d-flex" *ngIf="submitted && f['due_date'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['due_date'].errors['required']">Due date
                                {{errorJson.required}}</span>
                        </mat-error> -->
                    </mat-form-field>

                    <mat-form-field *ngIf="inputType==='dropdown'" appearance="outline" class="w-100">
                        <mat-select placeholder="Select" class="pt-1" [(ngModel)]="selectedItem">

                            <span *ngIf="labelName==='Owner'">
                                <mat-option *ngFor="let item of dropDownItems" (click)="onChange(item)"
                                    [value]="item.id"> {{item.fname}} {{item.lname}} </mat-option>
                            </span>

                            <span *ngIf="labelName==='Country'">
                                <mat-option *ngFor="let item of dropDownItems" (click)="onChange(item)"
                                    value={{item.Entity}}> {{item.Entity}} </mat-option>
                            </span>

                            <span *ngIf="labelName!=='Owner' && labelName!=='Country'">
                                <mat-option *ngFor="let item of dropDownItems" (click)="onChange(item)"
                                    [value]="item.id"> {{item.name}} </mat-option>
                            </span>

                        </mat-select>
                    </mat-form-field>

                    <ng-select class="form-control"
                        *ngIf="inputType==='multiselect' && labelName ==='Staff' && dropDownItems"
                        [items]="dropDownItems" bindValue="id" bindLabel="fname" [addTag]="false" [multiple]="true"
                        placeholder="Select staff" [(ngModel)]="selectedItem">
                    </ng-select>

                    <ng-select class="form-control"
                        *ngIf="inputType==='multiselect' && labelName==='Products' && dropDownItems "
                        [items]="dropDownItems" bindValue="id" bindLabel="name" [multiple]="true" placeholder="Product"
                        [(ngModel)]="selectedItem">
                    </ng-select>

                </div>
                <div class="p-2 d-flex justify-content-evenly">
                    <button class="btn btn-light" (click)="onCancel()">Cancel</button>
                    <button class="btn btn-primary" (click)="onSave()">Save</button>
                </div>
            </div>
        </mat-menu>
    </div>
</div>