import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class TaskBoardService {

  constructor(private http: HttpClient) { }

  getStages(id: string, type: string) {
    const source_id = id ? `source_id=${id}&` : '';
    return this.http.get(`${environment.urlPrefix}${constant.stage}?${source_id ? source_id : ''}source_type=${type}`);
    // return this.http.get(`${environment.urlPrefix}${constant.stage}?source_type=${type}`);
  }
  createStage(id: any, type: string, body: any) {
    const source_id = id ? `source_id=${id}&` : '';
    return this.http.post(`${environment.urlPrefix}${constant.stage}?${source_id ? source_id : ''}source_type=${type}`, body);
    // return this.http.post(`${environment.urlPrefix}${constant.stage}?source_type=${type}`, body);
  }
  updateStage(id: string, type: string, body: any) {
    const source_id = id ? `source_id=${id}&` : '';
    return this.http.put(`${environment.urlPrefix}${constant.stage}${body.id}/?${source_id ? source_id : ''}source_type=${type}`, body);
    // return this.http.put(`${environment.urlPrefix}${constant.stage}${body.id}/?source_type=${type}`, body);
  }

  deleteStage(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.stage}${id}/`);
  }


  getTaskByStage(source_id: string, type: string, stage_id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.task}?source_id=${source_id}&source_type=${type}&stage=${stage_id}`);
  }
  createTask(id: string, type: string, body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.task}?source_id=${id}&source_type=${type}`, body);
  }
  updateTask(source_id: string, type: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.task}${body.id}/?source_id=${source_id}&source_type=${type}`, body);
  }
  deleteTask(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.task}${id}`);
  }

  getAllTasks(source_id: string, type: string) {
    return this.http.get(`${environment.urlPrefix}${constant.task}?source_id=${source_id}&source_type=${type}`);
  }

  getStaffForSource(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.staff_in_task}`, body);
  }
}
