<div class="mb-0">
  <div class="p-3">
    <div class="header p-4 d-flex justify-content-between bg-grey-light border-radius-8">
      <div class="">
        <h6 class="fw-600">{{ taskDetails?.name }}</h6>
        <label class="text-light font-size-13">{{
          taskDetails?.info?.fname
          }}</label>
      </div>
      <div>
        <mat-select class="status_dropdown bg-primary border-radius-8 p-2 px-3"
          (selectionChange)="onSelectStatus($event)" [(ngModel)]="status_dropdown">
          <div *ngFor="let data of stageList">
            <mat-option [value]="data.name">{{ data?.name | titlecase }}</mat-option>
          </div>
        </mat-select>
      </div>
    </div>
    <div class="mt-4">
      <h6 class="fw-700">{{ showType | titlecase }} Details</h6>
      <div class="row no-gutters mt-2">
        <div class="col-md-2">
          <p class="text-name-secondary">Name</p>
          <p class="text-name-secondary">Due Date</p>
          <p class="text-name-secondary" *ngIf="showType === 'task'">Stage</p>
          <p class="text-name-secondary" *ngIf="showType === 'task'">
            Priority
          </p>
          <p class="text-name-secondary" *ngIf="showType !== 'task'">Status</p>
          <p class="text-name-secondary">Related To</p>
          <p class="text-name-secondary">Owner</p>
          <p class="text-name-secondary">Description</p>
        </div>
        <div class="col-md-6">
          <p class="text-name">{{ taskDetails?.name }}</p>
          <p class="text-name">{{ taskDetails?.due_date | dateConversion }}</p>
          <p class="text-name" *ngIf="showType === 'task'">
            {{ taskDetails?.stage_name | titlecase }}
          </p>
          <p class="text-name bg-tag d-inline-flex align-items-center px-2 border-radius-50"
            *ngIf="showType === 'task'">
            {{ taskDetails?.priority ? taskDetails?.priority : "-" }}
          </p>
          <p class="text-name" *ngIf="showType !== 'task'">
            {{ taskDetails?.outcome | titlecase}}
          </p>
          <p class="text-name">{{ taskDetails?.source_type }}</p>
          <p *ngIf="showType === 'task'" class="text-name">
            {{ taskDetails?.info?.fname ? taskDetails?.info?.fname : "-" }}
          </p>
          <p *ngIf="showType !== 'task'" class="text-name">
            {{ taskDetails?.info?.owner_info?.fname ? taskDetails?.info?.owner_info?.fname : "-" }}
          </p>
          <p class="text-name">
            {{ taskDetails?.description ? taskDetails?.description : "-" }}
          </p>
        </div>
      </div>
    </div>
    <div class="notes mt-4">
      <h6>Notes</h6>
      <div class="bg-gray-light border-radius-8 p-3">
        <div class="notes bg-white border-radius-8">
          <textarea class="form-control content-input border-radius-0 m-0 font-size-15" aria-label="With textarea"
            placeholder="What's this note about?" (click)="expandContent()"
            [ngClass]="{ 'height-class': contentExpand === true }" [(ngModel)]="notesContent"></textarea>
        </div>
        <div class="row col-md-8 mt-2" *ngIf="contentExpand">
          <div class="d-flex align-items-center">
            <button *ngIf="!edit"
              class="btn border-radius-50 border-1 bg-primary fw-600 font-size-13 text-white px-4 me-3"
              [disabled]="!notesContent" (click)="saveNotes()">
              Save
            </button>
            <button *ngIf="edit"
              class="btn border-radius-50 border-1 bg-primary fw-600 font-size-13 text-white px-4 me-3"
              [disabled]="!notesContent" (click)="updateNotes()">
              Edit
            </button>
            <button class="btn border-radius-50 border-1 bg-white fw-600 font-size-13 text-primary px-4 me-3"
              (click)="clearNotes()">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div class="">
        <ul class="list-group mt-2" *ngIf="notesData?.count > 0">
          <li class="list-group-item cursor" *ngFor="let note of notesData?.data">
            <div class="row d-flex align-items-center">
              <div class="col-md-10">
                <div class="d-flex align-items-center">
                  <h6 class="bg-primary text-white border-radius-50 p-2 me-2 fw-400 font-size-11">
                    {{ note?.info ? note?.info?.fname?.charAt(0) : '' }}{{ note?.info ? note?.info?.lname?.charAt(0) :
                    '' }}
                  </h6>
                  <h6 class="text-primary fw-600 font-size-16 me-2">
                    {{ note.info.fname }} {{ note?.info?.lname }}
                  </h6>
                  <h6 class="fw-400 font-size-13">
                    {{ note.created_at | date : "d/M/yy, h:mm a" }}
                  </h6>
                </div>
                <div class="ps-5">
                  <p class="m-0 font-size-13 text-break">{{ note.notes }}</p>
                </div>
              </div>
              <div class="col-md-2 visiblity-on-hover">
                <div class="d-flex justify-content-end align-items-center">
                  <div class="bg-white p-2 border-radius-50 d-flex align-items-center" (click)="editNotes(note)">
                    <mat-icon style="font-size: 22px">edit</mat-icon>
                  </div>
                  <mat-icon (click)="confirmDelete(note.id)">delete</mat-icon>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="mt-2">
          <mat-paginator [length]="notesData.count" [pageSize]="10" (page)="notesPage($event)" aria-label="Select page"
            showFirstLastButtons>
          </mat-paginator>
        </div>
      </div>
    </div>
    <div class="files mt-5">
      <h6>Files</h6>

      <div class="row">
        <div class="col-10">
          <ngx-dropzone (change)="onSelect($event)" [multiple]="false" class="bg-gray-light" style="height: 61px">
            <ngx-dropzone-label>Drag a file here or <span class="text-primary fw-500">Browse</span>
              for a file to upload
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview [removable]="true" (removed)="onRemove(f)" ngProjectAs="ngx-dropzone-preview"
              *ngFor="let f of files" [file]="f">
            </ngx-dropzone-image-preview>
          </ngx-dropzone>
        </div>
        <div class="col-2 d-flex align-items-center justify-content-center">
          <div class="" style="width:60px;height:60px" *ngIf="show_loader">
            <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
              <path fill="black"
                d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50"
                  to="360 50 50" repeatCount="indefinite" />
              </path>
            </svg>
          </div>
          <button *ngIf="!show_loader" class="btn btn-primary px-4" type="button" [disabled]="!this.files[0]" (click)="onUpload()">Upload</button>
        </div>
      </div>

      <div class="table-responsive table-height mt-4">
        <table class="table align-middle table-nowrap table-hover mb-0 file-table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{ filesJSON.col_one_label }}</th>
              <th scope="col">{{ filesJSON.col_two_label }}</th>
              <th scope="col">{{ filesJSON.col_four_label }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of filesListArray; let i = index">
              <td>
                {{ i + 1 }}
              </td>
              <td>
                <a href="{{ presignedurl }}{{ item.media_url }}" target="_blank" class="text-dark fw-medium" download>
                  {{ item.name }}
                </a>
              </td>
              <td>{{ item.updated_at | date : "M/d/yy, h:mm a" }}</td>
              <td>
                <mat-icon class="cursor" (click)="confirmDeleteFile(item.id)">delete</mat-icon>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <mat-paginator [length]="filesTotal" [pageSize]="10" (page)="page($event)" aria-label="Select page"
            showFirstLastButtons>
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>