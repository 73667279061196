import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/core/service/settings.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { STAFF } from 'src/app/core/data/settings_json';
import { ReplaySubject, Subject, finalize, takeUntil } from 'rxjs';
import { countryData } from 'src/app/core/data/countryData';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  countryjson = countryData;
  errorjson = ERRORJSON[0];
  staffjson = STAFF[0];
  user_profile!: FormGroup;
  user_data: any;
  submited = false;
  loggedInUser: any;
  files: any;
  fileChosen: any;
  attachedFile: any;
  presignedurl = environment.preSendUrl;

  constructor(private awsUtilService: AwsUtilsService, private settings_service: SettingsService) { }

  ngOnInit(): void {
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.getLoggedUserInfo();
    this.initializeForm();

  }

  initializeForm() {
    this.submited = false;
    this.user_profile = new FormGroup({
      id: new FormControl(this.user_data?.id ? this.user_data.id : ''),
      fname: new FormControl(this.user_data?.fname ? this.user_data.fname : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z_\s]{1,}$/)]),
      lname: new FormControl(this.user_data?.lname ? this.user_data.lname : '', [Validators.pattern(/^[A-Za-z][A-Za-z_\s]{1,}$/)]),
      email: new FormControl(this.user_data?.email ? this.user_data.email : '', Validators.compose([Validators.required, Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)])),//eslint-disable-line
      phone_number: new FormControl(this.user_data?.phone_number ? this.user_data.phone_number : '', Validators.compose([Validators.required, Validators.pattern("[0-9]+"), Validators.minLength(6), Validators.maxLength(12)])),
      ccode: new FormControl(this.user_data?.ccode ? this.user_data.ccode : '+91', [Validators.required]),
      designation: new FormControl(this.user_data?.designation ? this.user_data.designation : ''),
      specialisation: new FormControl(this.user_data?.specialisation ? this.user_data.specialisation : ''),
      qualification: new FormControl(this.user_data?.qualification ? this.user_data.qualification : ''),
      gender: new FormControl(this.user_data?.gender ? this.user_data.gender : ''),
      age: new FormControl(this.user_data?.age ? this.user_data.age : ''),
      image: new FormControl(this.user_data?.image ? this.user_data.image : '')
    });
  }

  get f() {
    return this.user_profile.controls;
  }

  onSubmit() {
    this.submited = true;
    if (this.user_profile.valid) {
      this.settings_service.putStaffInfo(this.user_profile.value.id, this.user_profile.value).subscribe(() => {//@typescript-eslint/no-empty-function
      });
    }

  }

  getLoggedUserInfo() {
    this.settings_service.getStaff_infoById(this.loggedInUser.data.id).subscribe({
      next: (resp: any) => {
        this.user_profile.patchValue({ ...resp.data });
      }
    });
  }

  notesFileUpload(e: any) {
    console.log(event);

    this.files = e.target.files[0];
    // console.log(e.target.files[0]);
    this.fileChosen = this.files.name;
    this.awsUtilService.getUploadFileLink(e.target.files[0].type).subscribe((resp) => {
      // console.log("first api - ", resp);
      this.attachedFile = resp.data.file_name;

      this.awsUtilService.uploadFile(e.target.files[0], resp.data.url).subscribe(() => {
        this.user_profile.patchValue({ image: this.attachedFile });
      });
    });
  }

  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

}
