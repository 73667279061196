import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CONVERSATIONSJSON } from 'src/app/core/data/crm_modal_json';
import { ClientsService } from 'src/app/core/service/clients.service';
import { CrmService } from 'src/app/core/service/crm.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss']
})
export class ConversationsComponent implements OnInit {
  convJSON = CONVERSATIONSJSON[0];
  date!: Date;
  medium_type = 'Mail';
  send_text: any;
  mail_template!: FormGroup;
  // modalref!: NgbModalRef;
  display_mail_popup = false;
  selected_id!: string;
  selected_type!: string;
  source_email!: string;
  loggedInUser: any;
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  container: any;
  conversations_array: any = [];
  fileChoosen: any;
  attachedFile: any;
  presignedurl = environment.preSendUrl;
  converstion: any = [];

  constructor(private route: ActivatedRoute, private crm_service: CrmService, private client_service: ClientsService, private awsUtilService: AwsUtilsService) { }

  ngOnInit(): void {
    this.date = new Date();
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    // console.log(this.loggedInUser);
    this.chatToTheBottom();

    this.route.queryParams.subscribe((params: any) => {
      // console.log("route params - ", params);
      this.selected_id = params.id;
      this.selected_type = params.showtype;
      if (this.selected_id) {
        this.getPreviousConversation(this.selected_id);
        if (this.selected_type === 'lead') {
          this.crm_service.getLeadById(this.selected_id).subscribe({
            next: (resp: any) => {
              // console.log("lead - ", resp.data[0]);
              this.source_email = resp.data[0].email;
              this.mail_template.patchValue({ mail_to: this.source_email });
            }
          });
        }
        if (this.selected_type === 'project') {
          this.client_service.getProjectDetailsById(this.selected_id).subscribe({
            next: (resp: any) => {
              // console.log("project - ", resp);
              this.source_email = resp.data.email;
              this.mail_template.patchValue({ mail_to: this.source_email });
            }
          });
        }
      }
    });
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'underline', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
    this.initilizeMailTemplateForm();
  }

  initilizeMailTemplateForm() {
    this.mail_template = new FormGroup({
      mail_to: new FormControl('', Validators.required),
      mail_from: new FormControl(this.loggedInUser.data.email, Validators.required),
      subject: new FormControl(''),
      msg_body: new FormControl('', Validators.required),
      msg_type: new FormControl('EMAIL', Validators.required)
    });
  }

  workeAI(draft: any) {
    this.client_service.worke_ai({ prompt: "Draft an email body " + draft + " in html tags" }).subscribe({
      next: (resp: any) => {
        this.mail_template.patchValue({ msg_body: resp.data.content.choices[0].message.content });
      }
    });
  }

  onMailSubmit() {
    // console.log(this.mail_template.value);
    if (this.mail_template.valid) {
      const body: any = {
        'type': this.mail_template.value.msg_type,
        'message': {
          'to': this.mail_template.value.mail_to,
          // 'to': 'sahithy@syoft.com',
          'from': 'support@worke.io',
          'subject': this.mail_template.value.subject,
          'body': this.mail_template.value.msg_body
        },
        'media_name': this.fileChoosen ? this.fileChoosen : "",
        'media_url': this.attachedFile ? this.attachedFile : "",
        'from_user_id': '',
        'to_user_id': '',
        'org_id': '',
        'from_Staff': true,
        'source_type': this.selected_type,
        'source_id': this.selected_id,
        'start_time': '',
        'end_time': '',
        'time_zone': '',
        'info': ''
      };
      // console.log(body, "conv msg");

      this.client_service.postConversation(body).subscribe({
        next: (resp: any) => {
          this.converstion.push(body);
          this.fileChoosen = '';
          this.attachedFile = '';
          this.mail_template.reset();
          this.initilizeMailTemplateForm();
          this.mail_template.patchValue({ mail_to: this.source_email });
          // this.converstion = resp.data;
          // this.getPreviousConversation(this.selected_id);
          this.chatToTheBottom();

        }
      });
      this.display_mail_popup = false;
    }
  }

  sendMsg() {
    // console.log(this.send_text, "msg entered");

    // if (this.send_text) {
    //   let body: any = {
    //     'type': "text",
    //     'message': {
    //       'to': "",
    //       'from': "",
    //       'subject': "",
    //       'body': this.send_text
    //     },
    //     'media_name': "",
    //     'media_url': "",
    //     'from_user_id': '',
    //     'to_user_id': '',
    //     'org_id': '',
    //     'from_staff': true,
    //     'source_type': this.selected_type,
    //     'source_id': this.selected_id,
    //     'start_time': '',
    //     'end_time': '',
    //     'time_zone': '',
    //     'info': ''
    //   }
    //   this.sampleMsgData.push(body);
    //   this.send_text = '';
    //   this.chatToTheBottom();
    // }
  }

  openMailTemplate(template_status: boolean) {
    this.display_mail_popup = template_status;
  }

  fileUpload(e: any) {
    // console.log(e.target.files[0]);
    this.fileChoosen = e.target.files[0].name;
    this.awsUtilService.getUploadFileLink(e.target.files[0].type).subscribe((resp) => {
      // console.log(resp);

      this.attachedFile = this.presignedurl + resp.data.file_name;
      this.awsUtilService.uploadFile(e.target.files[0], resp.data.url).subscribe(() => {//@typescript-eslint/no-empty-function
      });
    });
  }

  removeFile() {
    this.fileChoosen = '';
  }

  getPreviousConversation(id: string) {
    this.client_service.getConversation(id).subscribe({
      next: (resp: any) => {
        this.converstion = resp.data;
        this.chatToTheBottom();

      }
    });
  }

  chatToTheBottom() {
    setTimeout(() => {
      this.container = document.getElementById("syoft-msgContainer");
      this.container.scrollTop = this.container.scrollHeight;
    }, 0);
  }
}
