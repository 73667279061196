<div class="container-fluid" *ngIf="featureShow()">
    <div class="row mb-2 mt-3 justify-content-end">
        <div class="col-sm-6">
            <div class="heading font-size-18 fw-700 d-flex cursor" style="width: fit-content;" routerLink="/setup">
                <mat-icon class="heading font-size-24 me-2 " matTooltip="Back">keyboard_arrow_left</mat-icon>
                <p class="heading font-size-18">

                    {{formbuilderJson.heading}}
                </p>

            </div>
        </div>
        <div class="col-sm-6 text-end">
            <button type="button" class="btn btn-primary mb-2 mr-2" (click)="openModel(createForm)">
                {{formbuilderJson.create_form}}
            </button>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table align-middle table-nowrap table mb-0 table-bordered">
            <thead>
                <tr>
                    <th>{{formbuilderJson.create_form}}</th>
                    <th *ngFor="let item of tableHeads;">{{item}}</th>
                </tr>
            </thead>
            <tbody>

                <tr *ngFor="let form of forms_list; let i=index;">
                    <td>
                        {{ i+ 1 }}
                    </td>
                    <td>{{form.name}}</td>
                    <td>
                        <p class="cursor badge bg-primary text-wrap fs-6 mt-2 ms-3" routerLink="../form-submissions"
                            [queryParams]="{ id: form.id }">
                            {{formbuilderJson.submissions}}</p>
                    </td>
                    <td>
                        <mat-icon style="font-size: 22px;" routerLink="../form-settings"
                            [queryParams]="{ id: form.id }">edit</mat-icon>
                        <mat-icon (click)="deleteForm(form.id)">delete</mat-icon>

                    </td>
                </tr>
            </tbody>
        </table>
        <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
    </div>
</div>

<ng-template #createForm let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-primary">
            {{formbuilderJson.create_heading}}
        </h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form">
            <mat-label class="fw-600 mb-2">{{formbuilderJson.name_feild}}</mat-label>
            <mat-form-field appearance="outline" class="w-100">
                <input style="outline: none;" matInput type="text" required placeholder={{formbuilderJson.name_feild}}
                    formControlName="name">
                <mat-error class="error text-danger d-flex" *ngIf="submitted && f['name'].errors">
                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                    <span *ngIf="f['name'].errors['required']"> {{formbuilderJson.name_feild}}
                        {{errorJson.required}}</span>
                    <span *ngIf="f['name'].errors['pattern']">Only alphanumeric are accepted.</span>
                </mat-error>
            </mat-form-field>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end mb-5">
        <button type="button" class="btn btn-light" (click)="modelService.dismissAll()">
            {{formbuilderJson.cancel_btn}}
        </button>
        <button type="button" class="btn btn-primary" (click)="createformbuilder()">
            {{formbuilderJson.submit_btn}}
        </button>
    </div>
</ng-template>