import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss']
})
export class BadgesComponent implements OnInit{
  badgeForm!: FormGroup;
  submitted = false;
  badgesArray:any = [];
  constructor(public ngbActiveModal: NgbActiveModal, private settingsService: SettingsService) { }

  ngOnInit(): void {
    this.initilizeForm();
  }

  initilizeForm() {
    this.badgeForm = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', [Validators.required]),
      color: new FormControl('#ffffff', [Validators.required]),
      type: new FormControl('')
    });
  }

  get f() {
    return this.badgeForm.controls;
  }

  onSubmit() { 
    console.log(this.badgeForm.value);
    this.submitted = true;
    if(this.badgeForm.valid) {
      this.badgesArray.push(this.badgeForm.value);
    }
  }

}
