<div class="container-fluid mt-3">
    <h5 class="heading font-size-18 fw-700">{{thankupageJson.heading}}</h5>
    <form [formGroup]="thankYouForm">
        <div class="row mt-3">

            <div class="col-md-12">
                <div class="d-flex align-items-center">
                    <label class="switch me-3">
                        <input type="checkbox" formControlName="show" (change)="onThankYouFormSubmit()">
                        <span class="slider round"></span>
                    </label>
                    <label>{{thankupageJson.show_checkbox}}</label>
                </div>
            </div>
            <div class="mt-3 w-75" *ngIf="thankYouForm.value.show">
                <div class="">
                    <mat-label>{{thankupageJson.feild_title}}</mat-label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="{{thankupageJson.feild_title}}"
                            formControlName="title" />
                    </mat-form-field>
                </div>
                <div class="">
                    <mat-label>{{thankupageJson.feild_description}}</mat-label>
                    <textarea placeholder="{{thankupageJson.feild_description}}" class="w-100 h-15rem"
                        formControlName="description"></textarea>
                </div>
                <div class="d-flex justify-content-end ">
                    <button class="btn btn-primary mt-3 mr-5" (click)="onThankYouFormSubmit()">
                        {{thankupageJson.btn_save}}
                    </button>
                </div>
                
            </div>
            
            

        </div>
    </form>
</div>