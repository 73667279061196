import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FORMBUILDER } from 'src/app/core/data/form_builder_json';
import { FormBuilderService } from 'src/app/core/service/form-builder.service';

@Component({
  selector: 'app-form-submissions',
  templateUrl: './form-submissions.component.html',
  styleUrls: ['./form-submissions.component.scss']
})
export class FormSubmissionsComponent implements OnInit {

  formId = '';
  submissions: any = [];
  headers: any = [];
  COMPONENT_LABELS: any = FORMBUILDER[0];


  constructor(
    private route: ActivatedRoute,
    private _formServices: FormBuilderService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.formId = params.id;
      this.getFormSubmissions();
    });
  }

  getFormSubmissions() {
    this._formServices.getFormSubmissions(this.formId).subscribe((resp: any) => {
      this.submissions = resp.data;
      this.generateList();
    });
  }

  generateList() {
    // Iterate through the array and remove duplicates
    this.submissions.forEach((ele: any) => {
      const items = Object.entries(ele.data);
      items.forEach((it: any) => {
        if (!this.headers.find((ite: any) => ite.id === it[0])) {
          this.headers.push({ name: it[1].name, id: it[0] });
        }
      });
    });

    
  }


}
