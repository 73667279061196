<div class="container">
    <h5 class="text-primary text-center mt-2">User Profile</h5>
    <div class="d-flex align-item-center justify-content-center">
        <div class="bg-white border-light-1 border-radius-4 w-50 p-4 m-2">
            <form [formGroup]="user_profile" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="mb-3" style="position: relative;">
                        <div class="bg-black border-radius-8 text-center" id="upload-btn"
                            (change)="notesFileUpload($event)"
                            style="position: absolute; left:90px; top:-4px; color:rgb(251, 248, 248); height:24px; width:24px">
                            <label for="attach-file">
                                <mat-icon class="font-size-16" style="position: relative;top:3px">edit</mat-icon>
                                <input type="file" id="attach-file" accept="image/*" hidden />
                            </label>
                        </div>
                        <img *ngIf="user_profile.value?.image" class="border-radius-50"
                            style="width:100px; height:100px;" src="{{presignedurl}}{{user_profile.value.image}}">
                        <img class="border-radius-50" style="width:100px; height:100px;"
                            *ngIf="!user_profile.value?.image" src="../../../../assets/images/default_image.png">
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="fw-600 mb-2">{{staffjson.field_name}} <small class="text-danger">*</small></label>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput
                                placeholder='{{staffjson.enter_placeholder}} {{staffjson.field_name}}'
                                formControlName="fname" />
                            <mat-error class="error text-danger d-flex" *ngIf="submited && f['fname'].errors">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <span *ngIf="f['fname'].errors['required']">{{staffjson.field_name}}
                                    {{errorjson.required}}</span>
                                <span *ngIf="f['fname'].errors['pattern']">{{errorjson.invalid}}
                                    {{staffjson.field_name}}</span>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="fw-600 mb-2">{{staffjson.field_lastname}} </label>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput
                                placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_lastname}}"
                                formControlName="lname" />
                            <mat-error class="error text-danger d-flex" *ngIf="submited && f['lname'].errors">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <span *ngIf="f['lname'].errors['pattern']">{{errorjson.invalid}}
                                    {{staffjson.field_lastname}}</span>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label class="fw-600 mb-2">{{staffjson.field_email}} <small
                                class="text-danger">*</small></label>

                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput
                                placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_email}}"
                                formControlName="email" type="email" />
                            <mat-error class="error text-danger d-flex" *ngIf="submited && f['email'].errors">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <span *ngIf="f['email'].errors['required']">{{staffjson.field_email}}
                                    {{errorjson.required}}</span>
                                <span *ngIf="f['email'].errors['pattern']">{{errorjson.invalid}}
                                    {{staffjson.field_email}}</span>
                            </mat-error>
                        </mat-form-field>
                        <p class="text-light font-size-13">This email is used for user’s account
                            settings and changing it will affect the user’s log in.</p>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label class="fw-600 mb-2">{{staffjson.field_number}} <small
                                class="text-danger">*</small></label>
                        <div class="d-flex">
                            <mat-form-field appearance="outline" class="me-2">
                                <mat-select formControlName="ccode"
                                    placeholder="{{staffjson.enter_placeholder}} Country Code">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                            noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let country of filteredBanks | async"
                                        [ngClass]="{'d-none': country.IAC === ''}"
                                        value={{country.IAC}}>{{country.IAC}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="{{staffjson.field_number}}"
                                    formControlName="phone_number" type="text" />
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submited && f['phone_number'].errors">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                    <span *ngIf="f['phone_number'].errors['required']">{{staffjson.field_number}}
                                        {{errorjson.required}}</span>
                                    <span
                                        *ngIf="f['phone_number'].errors['pattern'] || f['phone_number'].errors['minlength'] || f['phone_number'].errors['maxlength']">{{errorjson.invalid}}
                                        {{staffjson.field_number}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label class="fw-600 mb-2">{{staffjson.field_designation}} </label>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput
                                placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_designation}}"
                                formControlName="designation" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label class="fw-600 mb-2">{{staffjson.field_specialisation}} </label>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput
                                placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_specialisation}}"
                                formControlName="specialisation" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label class="fw-600 mb-2">{{staffjson.field_qualification}} </label>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput
                                placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_qualification}}"
                                formControlName="qualification" />
                        </mat-form-field>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label class="fw-600 mb-2">{{staffjson.field_gender}} </label>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-select formControlName="gender" placeholder="Select">
                                <mat-option value="Female">Female</mat-option>
                                <mat-option value="Male">Male</mat-option>
                                <mat-option value="Other">Other</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label class="fw-600 mb-2">{{staffjson.field_age}} </label>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput
                                placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_age}}"
                                formControlName="age" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="text-center my-2">
                    <button type="submit" class="btn btn-primary px-5">
                        {{staffjson.save_btn}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>