<div class="container pipeline-stepper">
  <mat-stepper [linear]="true" #steppers [selectedIndex]="stepperCount ? stepperCount : 0">
    <mat-step label="Basic Info" [stepControl]="pipeline_form" #stepperStep1>
      <form [formGroup]="pipeline_form">
        <div class="subcontainer">
          <h4 class="fw-600">Pipeline info</h4>
          <div class="form-group">
            <label class="w-100 mb-2">Pipeline name <small class="text-danger">*</small></label>
            <mat-form-field appearance="outline" class="w-50 mat-hint-none">
              <input style="outline: none" matInput placeholder='Name' formControlName="name" #myFirstInput />
              <!-- <mat-error class="error text-danger d-flex" *ngIf="submitted && pipelines['name'].errors">
                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                <span *ngIf="pipelines['name'].errors['required']"> Pipeline name
                  {{errorJson.required}}</span>
              </mat-error> -->
            </mat-form-field>
          </div>
          <label>You can use letters, numbers combination</label>
          <h4>How would you like to name the records in this Pipeline?</h4>
          <div class="form-group sub-form-group">
            <label class="w-20 mb-2 label">Plural</label>
            <mat-form-field appearance="outline" class="w-50 mat-hint-none">
              <input style="outline: none" matInput placeholder='' formControlName="card_name_plural" />
            </mat-form-field>
          </div>
          <div class="form-group sub-form-group">
            <label class="w-20 mb-2 label">Singular</label>
            <mat-form-field appearance="outline" class="w-50 mat-hint-none">
              <input style="outline: none" matInput placeholder='' formControlName="card_name" />
            </mat-form-field>
          </div>
          <div class="mb-3">
            <!-- <h4>Pipeline Permissions</h4>
          <p class="p-perm">Permissions</p>
          <ng-select class="" [items]="listArray" bindLabel="group_name" [addTag]="true" [multiple]="true"
            placeholder='Select'>
          </ng-select> -->
          </div>
          <div class="d-flex justify-content-end gap-3">
            <button class="btn btn-light" (click)="modelClose()">Cancel</button>
            <button class="btn btn-primary" [disabled]="stepperStep1.stepControl.invalid" *ngIf="pipeline_form.value.id"
              (click)="onStepperChange('stages')">Next</button>
            <button class="btn btn-primary" [disabled]="stepperStep1.stepControl.invalid"
              (click)="createPipeline()">Save</button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step label="Stages" [stepControl]="formStages">
      <div class="subcontainer overflow-auto" style="height: 555px;">
        <form [formGroup]="formStages">
          <h4 class="fw-600">Open Stages</h4>
          <div cdkDropList class="example-list" formArrayName="stages" (cdkDropListDropped)="dropStages($event)">
            <div class="example-box mb-3" *ngFor="let item of FormOpt.controls;let i =index" cdkDrag
              [formGroupName]="i">
              <mat-icon>drag_indicator</mat-icon>
              <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                <input style="outline: none" matInput placeholder='' formControlName="name"
                  (click)="conditionalInputs(i)" />
              </mat-form-field>
              <div class="d-flex align-items-center"
                *ngIf="allStagesList.length!==0 && stageUpdate && selectedStageIndex === i">
                <mat-icon class="d-flex align-items-center font-size-16 mx-2 cursor" style="color: rgb(0, 128, 55);"
                  (click)="updateSingleStage(item)">done</mat-icon>
              </div>
              <mat-icon class="text-danger cursor stage-delete"
                (click)="stageDelete(item.value.id,'open',i)">delete</mat-icon>
            </div>
            <div (click)="addNew()" class="cursor">
              <p>+ Stage</p>
            </div>
          </div>

        </form>
        <h4 class="fw-600">Closed Stages</h4>
        <div cdkDropList class="example-list" (cdkDropListDropped)="dropStages($event)">
          <div class="example-box mb-3" *ngFor="let stage of closeStage;let i=index;" cdkDrag>
            <mat-icon>drag_indicator</mat-icon>
            <!-- <ng-select class="w-100 closed_stages me-3" bindLabel="group_name" [addTag]="true" placeholder='Select'
              (change)="onChanges($event,stage,i)" [(ngModel)]="stage.name">
              <ng-option value="{{item.name}}" *ngFor="let item of closeStage">{{item.name}}</ng-option>
            </ng-select> -->
            <mat-form-field appearance="outline" class="w-100 mat-hint-none">
              <input style="outline: none" matInput placeholder='' [(ngModel)]="stage.name"
                (click)="conditionalInputCloseStage(i)" />
            </mat-form-field>
            <div class="d-flex align-items-center"
              *ngIf="allStagesList.length!==0 && stageUpdate && closedStageIndex === i">
              <mat-icon class="d-flex align-items-center font-size-16 mx-2 cursor" style="color: rgb(0, 128, 55);"
                (click)="closedStageupdate(stage)">done</mat-icon>
            </div>
            <mat-icon class="text-danger cursor stage-delete me-2"
              (click)="stageDelete(stage.id,'closed',i)">delete</mat-icon>
            <!-- <mat-icon class="text-success" *ngIf="stage.is_success && !stage.newStageAdd">thumb_up</mat-icon>
            <mat-icon class="text-danger" *ngIf="!stage.is_success && !stage.newStageAdd">thumb_down</mat-icon> -->
            <mat-form-field appearance="outline" class="mat-hint-none border-0" style="width:70px;">
              <mat-icon *ngIf="!stage.is_success" class="d-inline-flex align-items-center p-0 ps-3 text-danger"
                matPrefix>thumb_down</mat-icon>
              <mat-icon *ngIf="stage.is_success" class="d-inline-flex align-items-center p-0 ps-3 text-success"
                matPrefix>thumb_up</mat-icon>
              <mat-select class="border-0" (selectionChange)="changeIconLost($event,stage,i)">
                <mat-option value=true>
                  <mat-icon class="text-success">thumb_up</mat-icon>
                </mat-option>
                <mat-option value=false>
                  <mat-icon class="text-danger">thumb_down</mat-icon>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="cursor" (click)="addNewClosedStage()">
            <p>+ Stage</p>
          </div>
        </div>
        <div class="d-flex justify-content-end gap-3">
          <button class="btn btn-light" (click)="modelClose()">Cancel</button>
          <button class="btn btn-primary" (click)="onStageUpdate()" *ngIf="allStagesList.length===0">Save</button>
          <button class="btn btn-primary" (click)="onStepperChange('fields')" *ngIf="allStagesList.length!==0">Next</button>
          <!-- <button class="btn btn-primary" matStepperNext (click)="onStepperChange('fields')">Previews</button> -->
        </div>
      </div>
    </mat-step>
    <mat-step label="Fields">
      <div class="row" *ngIf="!addCustomField">
        <div class="col-md-12 mb-3" cdkDropListGroup>
          <button class="d-flex btn btn-primary float-end" (click)="AddNewField()"><mat-icon>add</mat-icon>&nbsp;Custom
            Fields</button>
        </div>
        <div class="col-md-6">
          <p class="fw-500 font-size-16">Deal Information</p>
          <div class="subcontainer scroll_droplist" style="min-height: 200px;height: 60vh;">
            <div class="field-box drag_box" *ngFor="let fields of mandatory_fields">
              <label class="field-name ps-0"
                [ngClass]="{'removedField': !templateField[0]?.price_on && !fields.mandatory}">
                <span class="line-border me-2"
                  [ngStyle]="{'border-color': fields.mandatory ? '#FF0000' : '#e58f46'}"></span>
                {{fields.label_name}}</label>
              <!-- <mat-icon class="show_data text-danger" *ngIf="!fields.mandatory && templateField[0]?.price_on" (click)="changeBudget(false)">delete</mat-icon>
              <mat-icon class="show_data" *ngIf="!fields.mandatory && !templateField[0]?.price_on" (click)="changeBudget(true)">edit</mat-icon> -->
              <label class="switch" *ngIf="!fields.mandatory" style="width: 44px;">
                <input type="checkbox" [(ngModel)]="templatePrice" (change)="changeBudget()">
                <span class="slider round"></span>
              </label>
            </div>
            <div cdkDropList class="field-list" [cdkDropListData]="pipelineFields" (cdkDropListDropped)="drop($event)">
              <!--[cdkDragDisabled]="item.disabled"-->
              <div class="field-box drag_box cursor-move" style="justify-content: flex-start;"
                *ngFor="let item of pipelineFields" cdkDrag cdkDragDisabled="true">
                <label class="mb-0">
                  <mat-icon>drag_indicator</mat-icon>
                </label>
                <span class="field-name">{{item?.label}}</span>
                <mat-icon class="show_data text-danger" (click)="deleteCustomFields(item.id)">delete</mat-icon>
              </div>
              <div class="card p-5 text-center me-2" *ngIf="pipelineFields.length===0">
                <label>Drop your fields</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <p class="fw-500 font-size-16">All Fields</p>
          <!-- <mat-form-field appearance="outline" class="w-100 mat-hint-none">
            <input style="outline: none" matInput placeholder='Search' />
          </mat-form-field> -->
          <div class="subcontainer mb-3">
            <div cdkDropList class="field-list scroll_droplist" style="height: 60vh;" [cdkDropListData]="customFields"
              (cdkDropListDropped)="drop($event)"><!--cdkDropListSortingDisabled-->
              <div class="field-box drag_box cursor-move" style="justify-content: flex-start;"
                *ngFor="let item of customFields" cdkDrag>
                <span class="field-name">{{item.label}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 d-flex justify-content-end gap-3">
          <button class="btn btn-light" (click)="modelClose()">Done</button>
        </div>
      </div>
      <div class="p-4" *ngIf="addCustomField">
        <form [formGroup]="customFieldsForm">
          <div class="row py-3">
            <div class="col-md-12">
              <p class="mx-0">Edit Deal fields</p>
            </div>
            <div class="col-md-12">
              <mat-form-field appearance="outline">
                <input style="outline: none" matInput placeholder="Enter label Name" formControlName="label" />
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field appearance="outline">
                <mat-select placeholder="Select a type" formControlName="data_type"
                  (selectionChange)="onTypeChange($event)">
                  <mat-option *ngFor="let option of field_types" [value]="option.value">{{option.type}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-12" formArrayName="options">
              <div class="" *ngFor="let item of fieldOptions.controls;let i =index">
                <mat-form-field appearance="outline">
                  <input style="outline: none" matInput placeholder="Enter Option" [formControlName]="i" />
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-12"
              *ngIf="customFieldsForm.value.data_type === 'CHECK_BOX' || customFieldsForm.value.data_type === 'RADIO_BUTTON' || customFieldsForm.value.data_type === 'SELECT_DROPDOWN'">
              <button class="btn border-1 bg-white fw-600 font-size-13 text-primary px-4 me-3"
                (click)="addNewOption()">Add option</button>
            </div>
            <div class="d-flex py-3 text-end justify-content-end">
              <button class="btn border-1 bg-white fw-600 font-size-13 text-primary px-4 me-3"
                (click)="backtocustomList()">Cancel</button>
              <button class="btn bg-primary fw-600 font-size-13 text-white px-4 me-3"
                (click)="fieldCreation('save')">Save</button>
            </div>
          </div>
        </form>
      </div>
    </mat-step>
  </mat-stepper>
</div>