import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http: HttpClient) { }

  getInvoiceList(page: number) {
    return this.http.get(`${environment.urlPrefix}${constant.invoice}?page=${page}&limit=10`);
  }

  retryForInvoice(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.invoice}${id}${constant.invoice_retry}`);
  }

  getPaymentTransactions(page:number) {
    return this.http.get(`${environment.urlPrefix}${constant.payment_transactions}?page=${page}&limit=10`);
  }
}
