<div class="container-fluid mt-3">
    <h5 class="heading font-size-18 fw-700">{{notificationJson.heading}}</h5>
    <div class="row mt-3" *ngIf="showType === 'notification'">
        <div class="col-md-6">
            <div class="card cursor" (click)="onEmailaddress()">
                <div class="card-body py-4 d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                        <mat-icon class="font-size-14">{{notificationJson.circle_icon}}</mat-icon>
                        <h6>{{notificationJson.notify_email}}</h6>
                    </div>
                    <mat-icon>{{notificationJson.forward_icon}}</mat-icon>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card cursor" (click)="onEmailsubmitter()">
                <div class="card-body py-4 d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                        <mat-icon class="font-size-14">{{notificationJson.circle_icon}}</mat-icon>
                        <h6>{{notificationJson.notify_submiter}}</h6>
                    </div>
                    <mat-icon>{{notificationJson.forward_icon}}</mat-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="showType === 'email_notify'">
        <div class="card-body">
            <form [formGroup]="notifyMeForm">
                <div class="d-flex cursor" (click)="onBack()">
                    <mat-icon class="text-primary">{{notificationJson.back_icon}} </mat-icon>
                    <p class="m-0 text-primary fw-600 font-size-16 text-primary">{{notificationJson.back_text}}</p>
                </div>
                <div class="d-flex align-items-center mt-4">
                    <label class="switch me-3">
                        <input type="checkbox" formControlName="status">
                        <span class="slider round"></span>
                    </label>
                    <h6 class="mb-0">{{notificationJson.heading_email_notify}}</h6>
                </div>
                <div class="d-flex align-items-center mt-4">
                    <label class="switch me-3">
                        <input type="checkbox" formControlName="send_as_pdf">
                        <span class="slider round"></span>
                    </label>
                    <h6 class="mb-0">{{notificationJson.pdf_attachment}}</h6>
                </div>
                <div class="mt-3">
                    <div class="">
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder="{{notificationJson.feild_email}}"
                                formControlName="send_to" />
                        </mat-form-field>
                    </div>
                    <!-- <div class="">
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder="{{notificationJson.field_send_from}}"
                                formControlName="send_from" />
                        </mat-form-field>
                    </div> -->
                    <div class="">
                        <mat-label>{{notificationJson.feild_subject}}</mat-label>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder="{{notificationJson.feild_subject}}"
                                formControlName="subject" />
                        </mat-form-field>
                    </div>
                    <div class="">
                        <mat-label>{{notificationJson.message}}</mat-label>
                        <textarea placeholder="{{notificationJson.message}}" class="w-100 h-15rem"
                            formControlName="body"></textarea>
                    </div>
                </div>
                <div class="d-flex justify-content-end mt-3">
                    <button type="button" class="btn btn-primary" (click)="onNotifyMeFormSubmit()">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div class="card" *ngIf="showType === 'email_submitter'">
        <div class="card-body">
            <div class="d-flex cursor" (click)="onBack()">
                <mat-icon class="text-primary">{{notificationJson.back_icon}} </mat-icon>
                <p class="m-0 text-primary fw-600 font-size-16 text-primary">{{notificationJson.back_text}}</p>
            </div>
            <form [formGroup]="notifyMeForm">
                <div class="d-flex align-items-center mt-4">
                    <label class="switch me-3">
                        <input type="checkbox" formControlName="status">
                        <span class="slider round"></span>
                    </label>
                    <h6 class="mb-0">{{notificationJson.heading_submitter_email}}</h6>
                </div>
                <div class="d-flex align-items-center mt-4">
                    <label class="switch me-3">
                        <input type="checkbox" formControlName="send_as_pdf">
                        <span class="slider round"></span>
                    </label>
                    <h6 class="mb-0">{{notificationJson.pdf_attachment}}</h6>
                </div>

                <div class="mt-3">
                    <div class="">
                        <mat-label>{{notificationJson.feild_label_email}}</mat-label>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-select placeholder="{{notificationJson.feild_email}}" class="pt-1" formControlName="email">
                                <mat-option [value]="opt.id" *ngFor="let opt of form_fields_list">{{opt.label}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- <div class="">
                        <mat-label>{{notificationJson.feild_label_replyto}}</mat-label>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder="{{notificationJson.feild_reply_to}}"
                                formControlName="reply_to" />
                        </mat-form-field>
                    </div> -->
                    <div class="">
                        <mat-label>{{notificationJson.feild_sender_name}}</mat-label>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput formControlName="sender_name"
                                placeholder="{{notificationJson.placeholder_sendername}}" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="d-flex justify-content-end mt-3">
                    <button type="button" class="btn btn-primary" (click)="onNotifyMeFormSubmit()">
                        Save
                    </button>
                </div>
            </form>
        </div>
        

    </div>
</div>