<div class="modal-header">
    <h5 *ngIf="!lead?.id" class="modal-title text-primary fw-600">Add Enquiry</h5>
    <h5 *ngIf="lead?.id" class="modal-title text-primary fw-600">Update Enquiry</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="ngbActiveModal.dismiss()"></button>
</div>

<div class="modal-body">
    <form [formGroup]="leadForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{leadJson.label_name}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder="{{leadJson.label_name}}"
                        formControlName="requirement_title" #myFirstInput />
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['requirement_title'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['requirement_title'].errors['required']"> {{leadJson.label_name}}
                            {{errorJson.required}}</span>
                        <span *ngIf="f['requirement_title'].errors['pattern']">Only alphanumeric are accepted.</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-6 col-sm-12">
                <label class="fw-600 mb-2 text-primary">Description</label>
                <mat-form-field appearance="outline" class="w-100">
                    <textarea style="outline: none" matInput placeholder="Description" formControlName="description"
                        style="height:100px"></textarea>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 text-primary">{{leadJson.label_company}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="company_id" placeholder="{{leadJson.label_company}}" class="pt-1">
                        <mat-option *ngFor="let company of companiesListArray"
                            [value]="company.id">{{company.name}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['company_id'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['company_id'].errors['required']"> {{leadJson.label_company}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">Contact Name <small class="text-danger">*</small></label>
                <ng-select *ngIf="!leadForm.value.id" class="form-control" [items]="contactListArray"
                    bindLabel="first_name" [addTag]="addNewName" [multiple]="false" (change)="onSelectContact($event)"
                    placeholder="Contact name" (search)="searchContact($event)" formControlName="name"
                    (clear)="clearContactFields($event)">
                </ng-select>
                <mat-form-field appearance="outline" class="w-100" *ngIf="leadForm.value.id">
                    <mat-select formControlName="name" placeholder="contact name" class="pt-1">
                        <mat-option *ngFor="let member of contactListArray"
                            [value]="member.first_name">{{member.first_name}}</mat-option>
                    </mat-select>
                    <mat-error class="error d-flex" *ngIf="submitted && f['name'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['name'].errors['required']"> Name
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
                <div *ngIf="submitted && f['name'].errors"
                    class="text-danger error font-size-12 d-flex align-items-center">
                    <mat-icon class="font-size-12 d-flex align-items-center me-1">info</mat-icon>
                    <span *ngIf="f['name'].errors['required']">Contact name
                        {{errorJson.required}}</span>
                    <span *ngIf="f['name'].errors['pattern']">{{errorJson.invalid}} contact name</span>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 text-primary">{{leadJson.label_email}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder={{leadJson.label_email}}
                        formControlName="email" />
                    <mat-error class="error text-danger d-flex align-items-center"
                        *ngIf="submitted && f['email'].errors">
                        <mat-icon class="font-size-12 d-flex align-items-center me-1">info</mat-icon>
                        <span *ngIf="f['email'].errors['required']">{{leadJson.label_email}}
                            {{errorJson.required}}</span>
                        <span *ngIf="f['email'].errors['pattern']">{{errorJson.invalid}} {{leadJson.label_email}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{leadJson.label_phone}} <small
                        class="text-danger">*</small></label>
                <div class="d-flex align-items-center">
                    <mat-form-field appearance="outline" class="me-1">
                        <mat-select placeholder="Country code" formControlName="ccode">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of filteredBanks | async"
                                [ngClass]="{'d-none': country.IAC === ''}"
                                value={{country.IAC}}>{{country.IAC}}</mat-option>
                        </mat-select>
                        <!-- <mat-error class="error text-danger d-flex" *ngIf="submitted && f['ccode'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['ccode'].errors['required']">Country code
                                {{errorJson.required}}</span>
                        </mat-error> -->
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="{{leadJson.label_phone}}"
                            formControlName="phone_number" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['phone_number'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['phone_number'].errors['required']">Phone number
                                {{errorJson.required}}</span>
                            <span
                                *ngIf="f['phone_number'].errors['pattern'] || f['phone_number'].errors['minlength'] || f['phone_number'].errors['maxlength']">{{errorJson.invalid}}
                                phone number</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 text-primary">{{leadJson.label_tag}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="tags" placeholder="Select Tag" class="pt-1">
                        <mat-option [value]="item.id" *ngFor="let item of tagsListArray">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{leadJson.Source}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="source_type" placeholder={{leadJson.Source}} class="pt-1">
                        <mat-option value="whatsapp">Whatsapp</mat-option>
                        <mat-option value="manual">Manual entry</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 text-primary">{{leadJson.label_owner}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="owner" placeholder={{leadJson.label_owner}} class="pt-1">
                        <mat-option *ngFor="let member of staffMembers" (click)="onSelectOwner(member)"
                            [value]="member.id">{{member.fname}}
                            {{member.lname}}</mat-option>
                    </mat-select>
                    <mat-error class="error d-flex" *ngIf="submitted && f['owner'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['owner'].errors['required']"> {{leadJson.label_owner}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">Assignee</label>
                <ng-select class="form-control" [items]="staffMembers" bindValue="id" bindLabel="fname" [addTag]="false"
                    [multiple]="true" placeholder="Select staff" formControlName="assignee">
                </ng-select>
                <!-- <div class="error d-flex mt-2" *ngIf="submitted && f['assignee'].errors">
                    <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                    <span *ngIf="f['assignee'].errors['required']"> Staff
                        {{errorJson.required}}</span>
                </div> -->
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{leadJson.product}}</label>
                <ng-select class="form-control" [items]="products_list" bindLabel="name" bindValue="id"
                    [multiple]="true" placeholder="product" formControlName="product">
                </ng-select>
                <!-- <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="product" placeholder="Select Product" class="pt-1">
                        <mat-option *ngFor="let product of products_list"
                            [value]="product.id">{{product.name}}</mat-option>
                    </mat-select> -->
                <!-- <mat-error class="error d-flex" *ngIf="submitted && f['product'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['product'].errors['required']"> {{leadJson.product}}
                            {{errorJson.required}}</span>
                    </mat-error> -->
                <!-- </mat-form-field> -->
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="mb-2 fw-600 text-primary font-size-14">Due Date & Time</label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput [ngxMatDatetimePicker]="picker" [min]="minDate" placeholder="Choose a date & time"
                        formControlName="due_date">
                    <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
                </mat-form-field>
            </div>

            <!-- <div formArrayName="custom_lead_fields">
                <div *ngFor="let field of custom_lead_fields.controls; let i=index">
                  <div class="row" [formGroupName]="i">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <label class="fw-600 mb-2 text-primary">{{field.value.label}}</label>
                      <mat-form-field appearance="outline" class="w-100" *ngIf="field.value.data_type === 'text'">
                        <input style="outline: none" matInput placeholder="{{field.value.label}}" formControlName="data" />
                      </mat-form-field> -->

            <!-- <mat-form-field appearance="outline" class="w-100" *ngIf="field.value.data_type === 'dropdown'">
                        <mat-select formControlName="data" placeholder="{{field.value.label}}" class="pt-1">
                          <mat-option *ngFor="let option of options" [value]="option.id">{{option.name}}</mat-option>
                        </mat-select>
                      </mat-form-field> -->
            <!-- </div>
                  </div>
                </div>
              </div> -->
        </div>

        <div class="mt-0">
            <div class="mt-3 d-flex justify-content-end modal-footer">
                <!-- <div class="d-flex" routerLink="settings/custom-fields" (click)="ngbActiveModal.dismiss()">
                    <span class="material-icons text-primary">settings_applications</span>
                    <span class="text-primary cursor"></span> {{leadJson.customize_text}}
                </div> -->
                <div class="d-flex">
                    <button class="btn btn-light me-2" type="button" (click)="ngbActiveModal.dismiss()">
                        {{leadJson.cancel_btn}}
                    </button>
                    <button class="btn btn-primary" [disabled]="loading" type="submit">{{leadJson.save_btn}}</button>
                </div>
            </div>
        </div>
    </form>
</div>