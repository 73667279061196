import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { COMPANIESJSON } from 'src/app/core/data/companies_json';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { CrmService } from 'src/app/core/service/crm.service';
import { CompanyModalComponent } from '../../shared/company-modal/company-modal.component';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { PageEvent } from '@angular/material/paginator';
import { HttpClient } from '@angular/common/http';
import { TaskBoardComponent } from '../../clients/client-managment/task-board/task-board.component';
import { SettingsService } from 'src/app/core/service/settings.service';
import { countryData } from 'src/app/core/data/countryData';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  modalRef!: NgbModalRef;
  previewModal!: NgbModalRef;
  companiesJson = COMPANIESJSON[0];
  errorjson = ERRORJSON[0];
  pageEvent!: PageEvent;
  page_size = 10;
  currentPage = 0;
  filtersLables = [
    {
      position: 1,
      labelName: 'Name',
      checked: true
    },
    {
      position: 19,
      labelName: 'Email',
      checked: true
    },
    {
      position: 17,
      labelName: 'Mobile Number',
      checked: true
    },
    {
      position: 2,
      labelName: 'Website',
      checked: true
    },
    // {
    //   position: 3,
    //   labelName: 'Company Owner',
    //   checked: true
    // },
    // {
    //   position: 4,
    //   labelName: 'Tags',
    //   checked: true
    // },
    // {
    //   position: 5,
    //   labelName: 'Total Contacts',
    //   checked: true
    // },
    {
      position: 6,
      labelName: 'Country',
      checked: true
    },
    {
      position: 7,
      labelName: 'Owner',
      checked: true
    },
    {
      position: 8,
      labelName: 'Channel',
      checked: true
    },
    // {
    //   position: 9,
    //   labelName: 'Last Updated',
    //   checked: true
    // },
    {
      position: 10,
      labelName: 'Followers',
      checked: false
    },
    {
      position: 12,
      labelName: 'City',
      checked: false
    },
    {
      position: 13,
      labelName: 'Number Of Employees',
      checked: false
    },
    {
      position: 14,
      labelName: 'Annual Revenue',
      checked: false
    },
    {
      position: 16,
      labelName: 'Skype',
      checked: false
    },

    {
      position: 18,
      labelName: 'Other Phone',
      checked: false
    },

    {
      position: 20,
      labelName: 'Other Email',
      checked: false
    },
    {
      position: 21,
      labelName: 'Linkedin',
      checked: false
    },
    {
      position: 22,
      labelName: 'Twitter',
      checked: false
    },
    {
      position: 23,
      labelName: 'Instagram',
      checked: false
    },
    {
      position: 24,
      labelName: 'Facebook',
      checked: false
    },
    {
      position: 25,
      labelName: 'State',
      checked: false
    },
    // {
    //   position: 26,
    //   labelName: 'Zipcode',
    //   checked: true
    // },
    {
      position: 27,
      labelName: 'Address Line 1',
      checked: false
    },
    {
      position: 28,
      labelName: 'Address Line 2',
      checked: false
    },
    {
      position: 29,
      labelName: 'Shipping City',
      checked: false
    },
    {
      position: 30,
      labelName: 'Shipping State',
      checked: false
    },
    {
      position: 31,
      labelName: 'Shipping Country',
      checked: false
    },
    {
      position: 32,
      labelName: 'Shipping Zipcode',
      checked: false
    },
    {
      position: 33,
      labelName: 'Shipping Address Line 1',
      checked: false
    },
    {
      position: 34,
      labelName: 'Shipping Address Line 2',
      checked: false
    },
    {
      position: 35,
      labelName: 'Total Activities',
      checked: false
    },
    {
      position: 36,
      labelName: 'Done Activities',
      checked: false
    },
    {
      position: 37,
      labelName: 'Activities To Do',
      checked: false
    },
    {
      position: 38,
      labelName: 'Next Activities Date',
      checked: false
    },
    {
      position: 39,
      labelName: 'Last Activities Date',
      checked: false
    },
    {
      position: 40,
      labelName: 'Lost Deals',
      checked: false
    },
    {
      position: 41,
      labelName: 'Open Deals',
      checked: false
    },
    {
      position: 42,
      labelName: 'Closed Deals',
      checked: false
    },
    {
      position: 43,
      labelName: 'Industry',
      checked: false
    },
    {
      position: 44,
      labelName: 'Created On',
      checked: true
    },
    {
      position: 45,
      labelName: 'Action',
      checked: true
    }
  ];
  companiesdropDown = [
    {
      label: 'All',
      value: 'all'
    },
    {
      label: 'Todays',
      value: 'todays'
    },
    {
      label: 'Last 7 days',
      value: 'this_week'
    },
    {
      label: 'Iframe',
      value: 'iframe'
    },
    {
      label: 'Chatbot',
      value: 'chatbot'
    },
    {
      label: 'Facebook',
      value: 'facebook'
    },
    {
      label: 'Manual',
      value: 'manual'
    }
    // 'Recently Created Companies',
    // 'Recently Modified Companies',
    // 'Not Yet Viewed'
  ];
  filter: any = this.companiesdropDown[0].value;
  selected_filter!: string;
  selected_filter_value: any;
  public selection = new SelectionModel<any>(true, []);
  companiesObj: any = {};
  companiesListArray: any = [];
  companyForm!: FormGroup;
  submited = false;
  search_global!: string;
  selectedFile: any;
  enableImportBtn = false;
  loggedInUser: any;
  display_no_data = false;
  staffMembers: any = [];
  countryjson = countryData;

  constructor(private modalService: NgbModal, private service: CrmService, private confirmationMsz: ConfirmationMszService,
    private http: HttpClient,
    private settingsService: SettingsService) { }

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);

    this.getCompaniesList(1);
  }

  onFilterChange(e: any) {
    // console.log(e);
    this.filter = e;
    if (this.filter === 'this_week') {
      this.selected_filter = 'weeks';
      this.selected_filter_value = true;
    } else if (this.filter === 'todays') {
      this.selected_filter = 'todays';
      this.selected_filter_value = true;
    } else if (this.filter === 'iframe') {
      this.selected_filter = 'source';
      this.selected_filter_value = 'iframe';
    } else if (this.filter === 'chatbot') {
      this.selected_filter = 'source';
      this.selected_filter_value = 'chatbot';
    } else if (this.filter === 'manual') {
      this.selected_filter = 'source';
      this.selected_filter_value = 'manual';
    } else if (this.filter === 'facebook') {
      this.selected_filter = 'source';
      this.selected_filter_value = 'facebook';
    } else {
      this.selected_filter = '';
      this.selected_filter_value = '';
    }
    // console.log(this.selected_filter, this.selected_filter_value);

    this.getCompaniesList(1, this.selected_filter, this.selected_filter_value);
  }

  getCompaniesList(page: any, filter_param?: string, filter?: any) {
    this.service.getAllCompanies(page, filter_param, filter).subscribe({
      next: (resp: any) => {
        this.companiesObj = resp;
        this.companiesListArray = resp.data;
        if (this.companiesListArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }
        this.organisationMembersList();
      }
    });
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getCompaniesList(this.currentPage, this.selected_filter, this.selected_filter_value);
  }

  removeCompany(companyId: string) {
    this.service.deleteCompany(companyId).subscribe({
      next: () => {
        this.getCompaniesList(1, this.selected_filter, this.selected_filter_value);
      }
    });
  }

  confirmDelete(companyId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.removeCompany(companyId);
      }
    });
  }
  //filter dropdown fun
  selectFilterLable(event: any) {
    event.stopPropagation();
    // event.preventDefault();
    // if (event.target) {
    //   event.target.classList.toggle('selected');
    // }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.companiesListArray.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.companiesListArray.forEach((row: any) => {
      this.selection.select(row);
    });
  }

  openAddCompanyModal(company?: any) {
    this.modalRef = this.modalService.open(CompanyModalComponent, { size: 'lg', scrollable: true });
    this.modalRef.componentInstance.company = company;
    this.modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry) this.getCompaniesList(1);
    });
  }

  search(name: any) {
    if (name.length >= 3) {
      console.log(name.length);
      this.service.getCompaniesBySearch(name).subscribe({
        next: (resp: any) => {
          this.companiesObj = resp;
          this.page_size = this.companiesObj.total;
          this.companiesListArray = resp.data;
          if (this.companiesListArray.length === 0) {
            this.display_no_data = true;
          } else {
            this.display_no_data = false;
          }
        }
      });
    } else if (name.length < 1) {
      console.log(name.length);
      this.page_size = 10;
      this.getCompaniesList(1);
    }
  }

  importModalDialog(importExportModel: any, type: any) {
    this.modalService.open(importExportModel, { size: 'md' });
  }

  onFileChange(event: any) {
    this.enableImportBtn = true;
    this.selectedFile = event.target.files[0];
  }

  importCompanies() {
    const formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);

    this.service.importCompanies(formData).subscribe((resp: any) => {
      this.modalService.dismissAll('cross click');
      this.getCompaniesList(1);
    });
  }

  downloadCompanies() {
    const outputContacts: any = [];
    if (this.selection.selected.length === 0) {
      this.service.getAllCompaniesForExport().subscribe((resp: any) => {
        resp.data.forEach((company: any) => {
          outputContacts.push({
            "Name": company.name,
            "Email": company.email,
            "Phone": company.phonenumber,
            "Website": company.website,
            "Address": company.address,
            "City": company.city,
            "Country": company.country,
            "Zipcode": company.zipcode
          });
        });
        this.service.jsonToCsv(outputContacts, "Companies");
      });
    } else {
      this.selection.selected.forEach((company: any) => {
        outputContacts.push({
          "Name": company.name,
          "Email": company.email,
          "Phone": company.phonenumber,
          "Website": company.website,
          "Address": company.address,
          "City": company.city,
          "Country": company.country,
          "Zipcode": company.zipcode
        });
      });
      this.service.jsonToCsv(outputContacts, "Companies");
    }
  }

  // downloadFile() {
  //   const url = 'assets/myfile.txt';
  //   this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = 'myfile.txt';
  //     link.click();
  //   });
  // }

  downloadSampleFile() {
    this.service.downloadSample('Companies');
  }

  openTaskBoardPreview() {
    this.previewModal = this.modalService.open(TaskBoardComponent, { size: 'lg', scrollable: true });
    this.previewModal.componentInstance.crm_type = 'company';
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  onUpdatePopOver(event: any, data: any) {
    const input: any = { id: data.id, custom_contact_fields: [] };

    if (event.type === 'dropdown') {
      if (event.labelName === 'Owner') {
        input.info = {
          "owner_info": {
            "id": event.value.id,
            "fname": event.value.fname,
            "lname": event.value.lname
          }
        };
        input.owner = event.value.id;
        // this.staffMembers.forEach((staff: any) => {
        //   if (staff.id === event.value.id) input.owner_department = staff.department_info.id;
        // });
        input.owner_department = event.value.department_info?.id ? event.value.department_info?.id : '';
      }
      else if (event.labelName === "Country") {
        input.country = event.value.Entity;
      }
    } else
      input[event.formName] = event.value;

    this.service.updateCompany(input).subscribe((resp: any) => {
      this.getCompaniesList(1);
    });
  }
}
