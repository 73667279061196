import { Component, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
// import { ActivityModalComponent } from 'src/app/pages/shared/activity-modal/activity-modal.component';
import { AppointmentModalComponent } from 'src/app/pages/shared/appointment-modal/appointment-modal.component';
import { CompanyModalComponent } from 'src/app/pages/shared/company-modal/company-modal.component';
import { ContactsModalComponent } from 'src/app/pages/shared/contacts-modal/contacts-modal.component';
import { LeadModalComponent } from 'src/app/pages/shared/lead-modal/lead-modal.component';
import { PipelineModalComponent } from 'src/app/pages/shared/pipeline-modal/pipeline-modal.component';
import { header } from '../../data/menu_list';
import { AuthService } from '../../service/auth.service';
import { AddTaskComponent } from 'src/app/pages/shared/add-task/add-task.component';
import { CommonService } from '../../service/common.service';
import { ActivityModalComponent } from 'src/app/pages/shared/activity-modal/activity-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() selectedMenu: any;
  task_modelref!: NgbModalRef;
  profile_name = JSON.parse(sessionStorage.getItem('currentUser')!);
  selectopt = 'all';
  headerJson = header[0];
  addmenuList = [
    {
      menu_title: 'Contact',
      menu_icon: 'icons_contacts'
    },
    {
      menu_title: 'Enquiry',
      menu_icon: 'icons_enquiry'
    },
    {
      menu_title: 'Company',
      menu_icon: 'icons_company'
    },
    {
      menu_title: 'Pipeline records',
      menu_icon: 'icons_pipeline'
    },
    {
      menu_title: 'Appointment',
      menu_icon: 'worke icons_calender'
    },
    {
      menu_title: 'Activity',
      menu_icon: 'worke icons_activityId'
    },
    {
      menu_title: 'Task',
      menu_icon: 'icons_task'
    }
  ];

  notification_list: any = [{
    first_name: 'sai',
    last_name: 'Krishna',
    name: 'Sinnor was assigned a task',
    dsp: 'Send project proposal document to the open lead',
    due_date: '2'
  },
  {
    first_name: 'sai',
    last_name: 'Krishna',
    name: 'Sinnor was assigned a task',
    dsp: 'Send project proposal document to the open lead',
    due_date: '2'
  },
  {
    first_name: 'sai',
    last_name: 'Krishna',
    name: 'Sinnor was assigned a task',
    dsp: 'Send project proposal document to the open lead',
    due_date: '2'
  },
  {
    first_name: 'sai',
    last_name: 'Krishna',
    name: 'Sinnor was assigned a task',
    dsp: 'Send project proposal document to the open lead',
    due_date: '2'
  },
  {
    first_name: 'sai',
    last_name: 'Krishna',
    name: 'Sinnor was assigned a task',
    dsp: 'Send project proposal document to the open lead',
    due_date: '2'
  },
  {
    first_name: 'sai',
    last_name: 'Krishna',
    name: 'Sinnor was assigned a task',
    dsp: 'Send project proposal document to the open lead',
    due_date: '2'
  },
  {
    first_name: 'sai',
    last_name: 'Krishna',
    name: 'Sinnor was assigned a task',
    dsp: 'Send project proposal document to the open lead',
    due_date: '2'
  }];
  activity_logs: any[] = [];
  constructor(private authservice: AuthService, private modalService: NgbModal, private commonservice: CommonService) { }

  ngOnInit() {
    this.getActivityLogs();
  }

  logout() {
    this.authservice.logout();
  }

  openModalDialog(dialogName: string) {
    if (dialogName === 'Contact') {
      this.modalService.open(ContactsModalComponent, { size: 'lg', scrollable: true });
    } else if (dialogName === 'Enquiry') {
      this.modalService.open(LeadModalComponent, { size: 'lg', scrollable: true });
    } else if (dialogName === 'Appointment') {
      this.modalService.open(AppointmentModalComponent, { size: 'lg', scrollable: true });
    } else if (dialogName === 'Company') {
      this.modalService.open(CompanyModalComponent, { size: 'lg', scrollable: true });
    } else if (dialogName === 'Pipeline records') {
      this.modalService.open(PipelineModalComponent, { size: 'lg', scrollable: true });
    } else if (dialogName === 'Task') {
      this.task_modelref = this.modalService.open(AddTaskComponent, { size: 'lg', scrollable: true });
      this.task_modelref.componentInstance.is_global = true;
    } else if (dialogName === 'Activity') {
      this.modalService.open(ActivityModalComponent, { size: 'lg', scrollable: true });
    }
  }

  getActivityLogs() {
    this.commonservice.getActivity().subscribe((resp: any) => {
      this.activity_logs = resp.data;
    });
  }
}
