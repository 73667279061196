
export const colorCodes = [
  {
    color: "#DE350B"
  },
  {
    color: "#0052CC"
  },
  {
    color: "#00875A"
  },
  {
    color: "#5243AA"
  }
];