<div class="bg-crm-info h-100 p-3">
  <div class="container-fluid info-screen-overflow bg-white border-radius-10">
    <div class="d-flex align-items-center pt-2 pb-2">
      <div class="background-on-hover me-2 d-flex align-items-center">
        <mat-icon mat-raised-button matTooltip="Back" class="btn border-0 p-0"
          aria-label="Button that displays a tooltip when focused or hovered over" class="text-primary cursor"
          [routerLink]="['/clients/client-projects']" routerLinkActive="router-link-active">arrow_back_ios </mat-icon>
      </div>
      <div class="person-name">
        <h5 class="text-primary fw-500 font-size-16  mb-0" *ngIf="project">{{project.name | titlecase}}</h5>
        <!-- <div class="d-flex align-items-center">
          <mat-icon style="color: #9197B3;font-size: 22px">check_circle_outline</mat-icon>
          <p class="text-name-secondary ps-1 m-0">{{project.project_status}}</p>
        </div> -->
      </div>
      <!-- <mat-icon>more_vert</mat-icon> -->
    </div>
    <div class="overflow-auto" style="height: 88%">
      <div class="mb-3">
        <mat-accordion>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div>
                  <div class="d-flex align-items-center">
                    <p class="fw-600 text-primary mb-1">Related Project</p>
                    <mat-icon class="mb-1">expand_more</mat-icon>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="avatar-group d-flex align-items-center">
                      <div
                        class="avatar-xs avatar-group-item bg-primary text-white fw-400 border-radius-50 d-flex align-items-center justify-content-center">
                        {{(project?.name ?
                        project?.name[0] : "-" ) | titlecase}}
                      </div>
                    </div>
                    <p class="fw-400 mb-0 ms-2 text-inner-info">{{(project?.name ?
                      project?.name : "-") | titlecase}}</p>

                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="project">
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-name-secondary">Title:</p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name text-ellipsis w-100">
                    <app-table-popover [inputType]="'text'" [inputValue]="project?.name" [labelName]="'Name'"
                      [formName]="'name'" (update)="onUpdatePopOver($event, project)"></app-table-popover>
                  </p>
                </div>
              </div>
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-name-secondary">Product:</p>
                </div>
                <div class="col-md-8 info-accord">
                  <app-table-popover [inputType]="'multiselect'" [inputValue]="project.product_details"
                    [formName]="'service_ids'" [labelName]="'Products'" [dropDownItems]="productsListArray"
                    [selectedItem]="project?.service_ids"
                    (update)="onUpdatePopOver($event, project, 'enquiry')"></app-table-popover>
                  <!--                   
                  <ng-container *ngIf="project?.product?.length !== 0">
                    <p class="text-name w-100" *ngFor="let product of project.product">{{product}}</p>
                  </ng-container>
                  <p class="text-name w-100" *ngIf="project?.product?.length === 0">-</p> -->
                </div>
              </div>
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-name-secondary">Status:</p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name">
                    <!-- {{project.project_status ? project.project_status : "-"}} -->
                    <app-table-popover [inputType]="'dropdown'" [inputValue]="(project?.project_status | titlecase)"
                      [formName]="'project_status'" [labelName]="'Status'" [dropDownItems]="statusList"
                      [selectedItem]="project?.project_status?.toLowerCase() === 'pending' ? 1 : 2"
                      (update)="onUpdatePopOver($event, project)"></app-table-popover>
                  </p>
                </div>
              </div>
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-name-secondary">Due date:</p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name">
                    <app-table-popover [inputType]="'dateTime'" [inputValue]="project?.due_date" [formName]="'due_date'"
                      [labelName]="'Due Date'" (update)="onUpdatePopOver($event, project)"></app-table-popover>
                  </p>
                </div>
              </div>
              <ng-container *ngIf="!clientproject_seemore">
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-name-secondary">Owner:</p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name text-ellipsis w-100">
                      <app-table-popover [inputType]="'dropdown'"
                        [inputValue]="(project_owner?.fname || '-') + ' ' + (project_owner?.lname || '-')"
                        [formName]="'info.owner_info'" [labelName]="'Owner'" [dropDownItems]="allStaffMembers"
                        [selectedItem]="project?.owner" (update)="onUpdatePopOver($event, project)"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-name-secondary">Budget:</p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">
                      <app-table-popover [inputType]="'text'" [inputValue]="project?.cost" [labelName]="'Budget'"
                        [formName]="'cost'" (update)="onUpdatePopOver($event, project)"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-name-secondary">Tag</p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="border-radius-50 m-0 p-2 tag-color me-2" style="height: 2px;"
                        [style]="{'background-color': project?.colour}"></span>
                      <p class="text-name text-ellipsis w-100" style="margin-bottom: 0px !important;">
                        <app-table-popover [inputType]="'dropdown'" [inputValue]="project?.tag_name" [formName]="'tag'"
                          [labelName]="'Tag'" [dropDownItems]="tagsListArray" [selectedItem]="project?.tag"
                          (update)="onUpdatePopOver($event, project, 'project')"></app-table-popover>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-name-secondary">Source:</p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">{{project?.source ? project?.source : "-"}}</p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-name-secondary">Description:</p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name text-ellipsis w-100" style="margin-bottom: 0px !important;">
                      <app-table-popover [inputType]="'text'" [inputValue]="project?.description"
                        [formName]="'description'" [labelName]="'Description'"
                        (update)="onUpdatePopOver($event, project)"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-name-secondary">Updated At:</p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">{{project?.updated_at ? (project?.updated_at | dateConversion) : "-"}}</p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-name-secondary">Created At:</p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">{{project?.created_at ? (project?.created_at | dateConversion) : "-"}}</p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-name-secondary">Staff:</p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">

                      <app-table-popover [inputType]="'multiselect'" [inputValue]="staffMembers"
                        [formName]="'staff_ids'" [labelName]="'Staff'" [dropDownItems]="allStaffMembers"
                        [selectedItem]="project?.staff_ids"
                        (update)="onUpdatePopOver($event, project)"></app-table-popover>

                    </p>
                  </div>
                </div>
              </ng-container>
              <p class="text-primary cursor fw-500" (click)="seeMore('project')"><span *ngIf="clientproject_seemore">See
                  8
                  more</span><span *ngIf="!clientproject_seemore">See less</span></p>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="mb-3">
        <mat-accordion>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div>
                  <div class="d-flex align-items-center">
                    <p class="fw-600 text-primary mb-1">Related Client</p>
                    <mat-icon class="mb-1">expand_more</mat-icon>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="avatar-group d-flex align-items-center">
                      <div
                        class="avatar-xs avatar-group-item bg-primary text-white fw-400 border-radius-50 d-flex align-items-center justify-content-center">
                        {{(client?.name ?
                        client?.name[0] : "-" ) | titlecase}}
                      </div>
                    </div>
                    <p class="fw-400 mb-0 ms-2 text-inner-info">{{(client?.name ?
                      client?.name : "-") | titlecase}}</p>

                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="client">
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-name-secondary">Name:</p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name">{{client?.name ? client?.name : "-"}}</p>
                </div>
              </div>
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-name-secondary">Email:</p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name">{{client?.email ? client?.email : "-"}}</p>
                </div>
              </div>
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-name-secondary">Mobile:</p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name">{{client?.phone_number ? client?.phone_number : "-"}}</p>
                </div>
              </div>
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-name-secondary">Projects:</p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name">{{client?.num_projects ? client?.num_projects : "-"}}</p>
                </div>
              </div>
              <ng-container *ngIf="!client_seemore">
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-name-secondary">Owner:</p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name text-ellipsis w-100">
                      <app-table-popover [inputType]="'dropdown'"
                        [inputValue]="(client_owner?.fname || '-') + ' ' + (client_owner?.lname || '-')" [formName]=""
                        [labelName]="'Owner'" [dropDownItems]="staffMembers" [selectedItem]="client?.owner"
                        (update)="onUpdatePopOver($event, client, 'client')"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-name-secondary">Tag</p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="border-radius-50 m-0 p-2 tag-color me-2" style="height: 2px;"
                        [style]="{'background-color': client?.colour}"></span>
                      <p class="text-name text-ellipsis w-100" style="margin-bottom: 0px !important;">
                        <app-table-popover [inputType]="'dropdown'" [inputValue]="client?.tag_name" [formName]="'tag'"
                          [labelName]="'Tag'" [dropDownItems]="tagsListArray" [selectedItem]="client?.tag"
                          (update)="onUpdatePopOver($event, client, 'client')"></app-table-popover>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-name-secondary">Source:</p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">{{client?.source ? client?.source : "-"}}</p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-name-secondary">Country:</p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">
                      <app-table-popover [inputType]="'dropdown'" [inputValue]="client.country" [formName]="'country'"
                        [labelName]="'Country'" [dropDownItems]="countryjson" [selectedItem]="client?.country"
                        (update)="onUpdatePopOver($event, client, 'client')"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-name-secondary">Updated At:</p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">{{client?.updated_at ? (client?.updated_at | dateConversion) : "-"}}</p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-name-secondary">Created At:</p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">{{client?.created_at ? (client?.created_at | dateConversion) : "-"}}</p>
                  </div>
                </div>
              </ng-container>
              <p class="text-primary fw-500 cursor" (click)="seeMore('client')"><span *ngIf="client_seemore">See 6
                  more</span><span *ngIf="!client_seemore">See less</span></p>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>