import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { InvoiceService } from 'src/app/core/service/invoice.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent {
  table_cols = [
    'Payment ID',
    'Customer Name',
    'Amount',
    // 'Created At',
    'Mode',
    // 'Status',
    'Source',
    // 'Related To',
    // 'Type',
    // 'Total Amount',
    // 'Payment Status',
    'Created On'
    // 'Actions'
  ];
  total_count!: number;
  paymentArray: any = [];
  currentPage = 0;
  pageEvent!: PageEvent;
  display_no_data = false;

  constructor(private service: InvoiceService) { }

  ngOnInit(): void {
    this.getPaymentTransactions(1);
  }

  getPaymentTransactions(page: number) {
    this.service.getPaymentTransactions(page).subscribe({
      next: (resp: any) => {
        // console.log(resp);
        this.total_count = resp.count;
        this.paymentArray = resp.data;
        if(this.paymentArray.length === 0) {
          this.display_no_data = true;
        }
      }
    });
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getPaymentTransactions(this.currentPage);
  }

}
