import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  activeId = 1;
  loggedInUser: any;
  settings_menu = [
    {
      title: "General",
      slug: "general_settings",
      route: "../settings",
      icon: "mib",
      id: 1
    },
    {
      title: "Users & Controls",
      slug: "user_controls",
      route: "users&controls",
      icon: "mib",
      id: 2
    },
    {
      title: "Communication Settings",
      slug: "communications",
      route: "sms",
      icon: "mib",
      id: 3
    },
    {
      title: "Payment Settings",
      slug: "payments_settings",
      route: "payments",
      icon: "mib",
      id: 4
    },
    // {
    //   title: "Manage My Plans",
    //   slug: "features",
    //   route: "",
    //   icon: "mib",
    //   id: 5
    // },
    {
      title: "Custom Fields",
      slug: "form_fields",
      route: "custom-fields",
      icon: "mib",
      id: 6
    },
    {
      title: "Tags",
      slug: "tag",
      route: "tags",
      icon: "mib",
      id: 7
    },
    // {
    //   title: "Custom Doman URL",
    //   slug: "custom_domain",
    //   route: "",
    //   icon: "mib",
    //   id: 8
    // },
    {
      title: "Integrations",
      slug: "integration",
      route: "integrations",
      icon: "mib",
      id: 9
    },
    {
      title: "Data Administration",
      slug: "data_administration",
      route: "data-administration",
      icon: "mib",
      id: 10
    },
    {
      title: "Email",
      slug: "email",
      route: "",
      icon: "mib",
      id: 11
    },
    // {
    //   title: "Whats app",
    //   slug: "whats_app",
    //   route: "",
    //   icon: "mib",
    //   id: 12
    // },
    {
      title: "Developer",
      slug: "developer",
      route: "",
      icon: "mib",
      id: 12
    },
    {
      title: "Security",
      slug: "security",
      route: "",
      icon: "mib",
      id: 13
    },
    {
      title: "Biling",
      slug: "biling",
      route: "billing",
      icon: "mib",
      id: 14
    },
    {
      title: "SMS Notifications",
      slug: "sms_notifications",
      route: "sms",
      icon: "mib",
      id: 15
    },
    {
      title: "Email notifications",
      slug: "email_notifications",
      route: "email-notifications",
      icon: "mib",
      id: 16
    },
    {
      title: "Whatsapp",
      slug: "whatsapp",
      route: "whatsapp-notificaions",
      icon: "mib",
      id: 17
    }
  ];

  constructor(private router: Router) {
    this.settings_menu.forEach((item: any) => {
      if (this.router.url.includes(item.route) && item.route !== '') {
        this.activeId = item.id;
      }
    });

  }

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  }

}
