import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedRoutingModule } from './shared-routing.module';
import { ContactsModalComponent } from './contacts-modal/contacts-modal.component';
import { MaterialModule } from 'src/app/core/common/material/material.module';
import { NgbDatepickerModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentModalComponent } from './appointment-modal/appointment-modal.component';
import { LeadModalComponent } from './lead-modal/lead-modal.component';
import { CompanyModalComponent } from './company-modal/company-modal.component';
import { PipelineModalComponent } from './pipeline-modal/pipeline-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MobileInputComponent } from './mobile-input/mobile-input.component';
import { TaskDetailComponent } from './task-detail/task-detail.component';
import { CallDetailComponent } from './call-detail/call-detail.component';
import { FollowupDetailComponent } from './followup-detail/followup-detail.component';
import { BookedappointmentDetailComponent } from './bookedappointment-detail/bookedappointment-detail.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { MeetingDetailComponent } from './meeting-detail/meeting-detail.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ClientModalComponent } from './client-modal/client-modal.component';
import { ClientProjectModalComponent } from './client-project-modal/client-project-modal.component';
import { ActivityModalComponent } from './activity-modal/activity-modal.component';
import { ClientManagmentModule } from "../clients/client-managment/client-managment.module";
import { TemplateBuilderComponent } from './template-builder/template-builder.component';
import { EnquireDetailsComponent } from './enquire-details/enquire-details.component';
import { AddTaskComponent } from './add-task/add-task.component';
import { ManagementModalModule } from '../crm/management-modal/management-modal.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { TaskDetailModalComponent } from './task-detail-modal/task-detail-modal.component';
import { TaskboardPreviewComponent } from './taskboard-preview/taskboard-preview.component';
import { PipesModule } from 'src/app/core/layouts/pipes/pipes.module';
import { PagesModule } from "../pages.module";
import { BadgesComponent } from './badges/badges.component';

@NgModule({
  declarations: [
    ContactsModalComponent,
    AppointmentModalComponent,
    LeadModalComponent,
    CompanyModalComponent,
    PipelineModalComponent,
    MobileInputComponent,
    TaskDetailComponent,
    CallDetailComponent,
    FollowupDetailComponent,
    BookedappointmentDetailComponent,
    OrderDetailComponent,
    MeetingDetailComponent,
    ClientModalComponent,
    ClientProjectModalComponent,
    ActivityModalComponent,
    TemplateBuilderComponent,
    EnquireDetailsComponent,
    AddTaskComponent,
    TaskDetailModalComponent,
    TaskboardPreviewComponent,
    BadgesComponent
  ],
  exports: [
    AppointmentModalComponent,
    TaskDetailComponent,
    OrderDetailComponent,
    CallDetailComponent,
    FollowupDetailComponent,
    BookedappointmentDetailComponent,
    MeetingDetailComponent,
    EnquireDetailsComponent,
    TaskDetailModalComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MaterialModule,
    FormsModule,
    NgbDatepickerModule,
    NgSelectModule,
    ManagementModalModule,
    NgxDropzoneModule,
    // ClientManagmentModule,
    NgbNavModule,
    MatDatepickerModule,
    PipesModule,
    PagesModule
  ]
})
export class SharedModule { }
