import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SetUpService {

  meetingSettings = new BehaviorSubject<any>(null);
  calendarMeetings = new BehaviorSubject<any>(null);
  workingHours = new BehaviorSubject<any>(null);
  setupSetting = new BehaviorSubject<any>(null);
  current_user = JSON.parse(sessionStorage.getItem('currentUser')!);
  constructor(private http: HttpClient) { }
  //Setup the Organization Services
  getSetupInfo() {
    this.http.get(`${environment.urlPrefix}${constant.setup}`).subscribe((resp: any) => {
      this.setupSetting.next(resp.data);
    });
  }

  updateSetupStatus(id: string, status: { status: boolean; }) {
    return this.http.put(`${environment.urlPrefix}${constant.setup}${id}/`, status);
  }

  getSetupInformation(type: string) {
    if (type) {
      this.http.get(`${environment.urlPrefix}${constant.setup}`).subscribe((resp: any) => {
        this.setupSetting.next(resp.data);
      });
      return this.setupSetting.asObservable();
    } else {
      return this.setupSetting.asObservable();
    }
  }

  checkFeature(type: string) {
    let status;
    this.setupSetting.getValue()?.forEach((value: any) => {
      if (value.name === type) {
        status = value.status;
      }
    });
    return status;
  }

  //Products
  getStore() {
    return this.http.get(`${environment.urlPrefix}${constant.store}`);
  }

  getProductCategories(storeId: string, searchTerm?: string) {
    if (searchTerm) {
      return this.http.get(`${environment.urlPrefix}${constant.store}${storeId}${constant.product_categories}?name=${searchTerm}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.store}${storeId}${constant.product_categories}`);
    }
  }

  getActiveCategories(storeId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.store}${storeId}${constant.product_categories}?status=True`);
  }

  postProductCategories(storeId: string, body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.store}${storeId}${constant.product_categories}`, body);
  }

  putProductCategories(storeId: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.store}${storeId}${constant.product_categories}${body.id}/`, body);
  }

  deleteProductCategories(storeId: string, id: any) {
    return this.http.delete(`${environment.urlPrefix}${constant.store}${storeId}${constant.product_categories}${id}/`);
  }

  getProduct(storeId: string, searchTerm?: string) {
    if (searchTerm) {
      return this.http.get(`${environment.urlPrefix}${constant.store}${storeId}${constant.products}?name=${searchTerm}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.store}${storeId}${constant.products}`);
    }
  }

  getActiveProducts(storeId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.store}${storeId}${constant.products}?status=True`);
  }

  getProductById(storeId: string, id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.store}${storeId}${constant.products}${id}/`);
  }

  postProduct(storeId: string, body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.store}${storeId}${constant.products}`, body);
  }

  putProduct(storeId: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.store}${storeId}${constant.products}${body.id}/`, body);
  }

  deleteProduct(storeId: string, id: any) {
    return this.http.delete(`${environment.urlPrefix}${constant.store}${storeId}${constant.products}${id}/`);
  }

  updateVariant(productId: string, body: any) {
    return this.http.put(`${environment.urlPrefix}/product${constant.products}${productId}${constant.variants}${body.id}/`, body);
  }

  deleteVariant(variantId: string, productId: string) {
    return this.http.delete(`${environment.urlPrefix}/product${constant.products}${productId}${constant.variants}${variantId}/`);
  }

  getDefaultTaskStage() {
    return this.http.get(`${environment.urlPrefix}${constant.stage}?source_id=${this.current_user.data.organisation}`);
  }

  //Categories
  getAllCategories() {
    return this.http.get(`${environment.urlPrefix}${constant.categories}`);
  }
  getAllActiveCategories() {
    return this.http.get(`${environment.urlPrefix}${constant.categories}?active=true`);
  }
  deleteCategory(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.categories}${id}/`);
  }
  putCategory(id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.categories}${id}/`, body);
  }
  postCategory(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.categories}`, body);
  }

  //Appointments
  getAllAppointments(page: any) {
    return this.http.get(`${environment.urlPrefix}${constant.appointments}?page=${page}&offset=10`);
  }
  postAppointment(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.appointments}`, body);
  }
  putAppointmentStatus(id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.appointments}${id}/`, body);
  }
  putAppointmentReschedule(id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.appointmentReschedule}${id}/`, body);
  }
  deleteAppointment(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.appointments}${id}/`);
  }

  postTransaction(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.transaction}`, body);
  }

  getSlotsBasedOnStaffId(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.slots}`, body);
  }

  //Questionnaries
  getQuestionnaire(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.appointmentQuestionnaire}?consultation_id=${id}`);
  }

  postQuestionnaire(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.appointmentQuestionnaire}`, body);
  }

  putQuestionnaire(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.appointmentQuestionnaire}${body.id}/`, body);
  }

  deleteQuestionnaire(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.appointmentQuestionnaire}${id}/`);
  }

  //consultations

  getAllConsulations(page?: any) {
    return this.http.get(`${environment.urlPrefix}${constant.consultations}`);//?page=${page}&offset=10
  }

  getConsultationByID(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.consultations}${id}/`);
  }

  postConsultation(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.consultations}`, body);
  }

  putConsultation(id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.consultations}${id}/`, body);
  }

  deleteConsultations(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.consultations}${id}/`);
  }

  getConsulationsBycategoryId(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.consultations}?category_id=${id}`);
  }

  getConsulationsBycategoryIdActive(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.consultations}?category_id=${id}&active=true`);
  }

  //Calender-Meetings

  postProfileMeeting(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.profileMeetings}`, body);
  }

  putProfileMeeting(id: any, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.profileMeetings}${id}/`, body);
  }

  // getProfileMeetings() {
  //   return this.http.get(`${environment.urlPrefix}${constant.profileMeetings}`);
  // }

  getProfileMeetings(refreshType: string): Observable<any> {
    if (this.meetingSettings.getValue() !== null && refreshType !== "force") {
      return this.meetingSettings.asObservable();
    } else {
      this.http.get(`${environment.urlPrefix}${constant.profileMeetings}`).subscribe((data) => {
        this.meetingSettings.next(data);
      });
      return this.meetingSettings.asObservable();
    }
  }

  // getWorkingHours() {
  //   return this.http.get(`${environment.urlPrefix}${constant.profileWorkingHours}`);
  // }

  getWorkingHours(refreshType: string) {
    if (this.workingHours.getValue() !== null && refreshType !== "force") {
      return this.workingHours.asObservable();
    } else {
      this.http.get(`${environment.urlPrefix}${constant.profileWorkingHours}`).subscribe((data) => {
        this.workingHours.next(data);
      }, err => {
        this.workingHours.next(err);
      });
      return this.workingHours.asObservable();
    }
  }

  getOrgWorkHours(){
    if(!this.current_user) this.current_user = JSON.parse(sessionStorage.getItem('currentUser')!);
    return this.http.get(`${environment.urlPrefix}${constant.profileWorkingHours}?organisation_id=${this.current_user.data.organisation}`);
  }

  postWorkingHours(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.profileWorkingHours}`, body);
  }

  putWorkingHours(id: any, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.profileWorkingHours}${id}/`, body);
  }


  postCalendarMeetings(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.profileCalMeetings}`, body);
  }

  putCalendarMeetings(id: any, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.profileCalMeetings}${id}/`, body);
  }

  mettingsSortOrder(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.profilemeetingsorder}`, body);
  }

  // getCalendarMeetings() {
  //   return this.http.get(`${environment.urlPrefix}${constant.profileCalMeetings}`);
  // }

  getCalendarMeetings(refreshType: string) {
    if (this.calendarMeetings.getValue() !== null && refreshType !== "force") {
      return this.calendarMeetings.asObservable();
    } else {
      this.http.get(`${environment.urlPrefix}${constant.profileCalMeetings}`).subscribe((data) => {
        this.calendarMeetings.next(data);
      });
      return this.calendarMeetings.asObservable();
    }
  }

  getCalendarMeetingById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.profileCalMeetings}${id}/`);
  }

  deleteCalendarMeetings(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.profileCalMeetings}${id}/`);
  }


  //Knowledge Base
  getArticles(page: number, offset: number) {
    return this.http.get(`${environment.urlPrefix}${constant.article}?page=${page}&limit=${offset || 10}`);
  }
  createArticle(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.article}`, body);
  }
  updateArticle(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.article}`, body);
  }
  deleteArticle(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.article}?id=${id}`);
  }

  getGroups(page: number, offset: number) {
    return this.http.get(`${environment.urlPrefix}${constant.group}?page=${page}&limit=${offset || 10}`);
  }

  searchGroup(searchTerm: string, type: string) {
    if (type === 'articles') {
      return this.http.get(`${environment.urlPrefix}${constant.group}?search=${searchTerm}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.article}?search=${searchTerm}`);
    }
  }

  createGroup(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.group}`, body);
  }
  updateGroup(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.group}`, body);
  }
  deleteGroup(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.group}?id=${id}`);
  }
}
