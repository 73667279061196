import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { homeJson, MYBOARD } from 'src/app/core/data/homeModule_json';
import { colorCodes } from 'src/app/core/data/colorcode';
import { HomeService } from 'src/app/core/service/home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  activeId = 1;
  myboardJson = MYBOARD[0];
  activity_logs = [];
  notification_list: any[] = [];
  homeTextjson = homeJson[0];
  tabs = [
    {
      id: 1,
      title: 'Tasks',
      route: 'task'
    },
    {
      id: 2,
      title: 'Follow ups',
      route: 'follow-up'
    },
    {
      id: 3,
      title: 'Calls',
      route: 'calls'
    },
    {
      id: 4,
      title: 'Email',
      route: 'email'
    },
    {
      id: 5,
      title: 'Meetings',
      route: 'meetings'
    },
    {
      id: 6,
      title: 'Appointments',
      route: 'appointments'
    },
    // {
    //   id: 7,
    //   title: 'Orders',
    //   route: 'orders'
    // },
    // {
    //   id: 8,
    //   title: 'Enquiries',
    //   route: 'enquire'
    // }
    {
      id: 9,
      title: 'Calendar meetings',
      route: 'calendar-bookings'
    }
  ];
  taskCount!: number;
  colorCodes = colorCodes;
  enquireCount!: number;
  activityCount!: number;
  aptCount: any;

  constructor(private router: Router, private homeService: HomeService) {
    this.tabs.forEach((item: any) => {
      if (this.router.url.includes(item.route)) this.activeId = item.id;
    });
  }

  ngOnInit() {
    this.getNotification();
    this.getTaskCount();
    this.getEnquireCount();
    this.getActivityCount();
    this.getAppointmentCount();
  }

  tabChange(event: any) {
    this.activeId = event.nextId;
    // this.router.navigate([],{ relativeTo: this.route,queryParams: { showType: this.activeId } })
  }

  getNotification() {
    this.homeService.getNotifications().subscribe((resp: any) => {
      this.notification_list = resp.data;
      for (let i = 0; i < this.notification_list.length; i++) {
        const colorCount = (i % 4);
        this.notification_list[i].colorCode = this.colorCodes[colorCount].color;
      }
    });
  }

  markasRead(item: any) {
    this.homeService.putNotification(item).subscribe(() => {
      this.getNotification();
    });
  }

  markAllasRead() {
    this.homeService.notificationsRead().subscribe(() => {
      this.getNotification();
    });
  }

  getTaskCount() {
    this.homeService.getTasksCount().subscribe((resp: any) => {
      this.taskCount = resp.count;
    });
  }

  getEnquireCount() {
    this.homeService.getEnquiresCount().subscribe((resp: any) => {
      this.enquireCount = resp.total;
    });
  }

  getActivityCount() {
    this.homeService.getActivityCount().subscribe((resp: any) => {
      this.activityCount = resp.count;
    });
  }

  getAppointmentCount() {
    this.homeService.getAppointmentCount().subscribe((resp: any) => {
      this.aptCount = resp.data.appointments_count;
    });
  }
}
