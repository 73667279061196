import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  user_details = JSON.parse(sessionStorage.getItem('currentUser')!);
  getStaffInfoList = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) { }

  getGeneralSettings() {
    return this.http.get(`${environment.urlPrefix}${constant.general_settings}`);
  }

  postGeneralSettings(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.general_settings}`, body);
  }

  getCompanySettings() {
    return this.http.get(`${environment.urlPrefix}${constant.company_settings}`);
  }

  postCompanySettings(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.company_settings}`, body);
  }

  getLocationSettings() {
    return this.http.get(`${environment.urlPrefix}${constant.locations}`);
  }

  getLocationSettingsById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.locations}${id}/`);
  }

  postLocationSettings(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.locations}`, body);
  }

  putLocationSettings(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.locations}${body.id}/`, body);
  }

  deleteLocationSettings(id: any) {
    return this.http.delete(`${environment.urlPrefix}${constant.locations}${id}`);
  }

  getWorkingHours() {
    return this.http.get(`${environment.urlPrefix}${constant.working_hours}`);
  }

  postWorkingHours(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.working_hours}`, body);
  }

  // getStaff_info() {
  //   return this.http.get(`${environment.urlPrefix}${constant.staff_info}?active=true`);
  // }
  getStaff_info(refreshType?: string) {
    // return this.http.get(`${environment.urlPrefix}${constant.staff_info}?active=true`);
    if (this.getStaffInfoList.getValue() !== null && refreshType !== "force") {
      return this.getStaffInfoList.asObservable();
    } else {
      this.http.get(`${environment.urlPrefix}${constant.staff_info}?active=true`).subscribe((data) => {
        this.getStaffInfoList.next(data);
      }, err => {
        this.getStaffInfoList.next(err);
      });
      return this.getStaffInfoList.asObservable();
    }
  }

  getStaff_bookings() {
    return this.http.get(`${environment.urlPrefix}${constant.staff_info}?active=true`);//?booking_status=true
  }

  getStaff_infoByPagination(page: number) {
    return this.http.get(`${environment.urlPrefix}${constant.staff_info}?page=${page}&offset=10`);
  }

  getStaff_infoById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.staff_info}${id}/`);
  }

  postStaffInfo(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.staff_info}`, body);
  }

  putStaffInfo(id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.staff_info}${id}/`, body);
  }

  getRolesPermissions(active: boolean) {
    if (!active) {
      return this.http.get(`${environment.urlPrefix}${constant.roles_permissions}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.roles_permissions}?active=true`);
    }
  }

  postRolesPermissions(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.roles_permissions}`, body);
  }

  getTeams(active: boolean) {
    if (!active) {
      return this.http.get(`${environment.urlPrefix}${constant.teams}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.teams}?active=true`);
    }
  }

  postTeams(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.teams}`, body);
  }

  putTeams(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.teams}${body.id}/`, body);
  }

  deleteTeams(id: any) {
    return this.http.delete(`${environment.urlPrefix}${constant.teams}${id}/`);
  }

  //integration Apis
  getZoomKeys() {
    return this.http.get(`${environment.urlPrefix}${constant.zoom}`);
  }

  postZoomKeys(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.zoom}`, body);
  }

  updateZoomKeys(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.zoom}${body.id}/`, body);
  }

  deleteZoomKeys(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.zoom}${id}/`);
  }

  getGoogle() {
    return this.http.get(`${environment.urlPrefix}${constant.google}${constant.get_google_connect}?user=${this.user_details.data.id}`);
  }

  googleRegister() {
    const body = { name: this.user_details.data.email.split('@')[0] };
    return this.http.post(`${environment.urlPrefix}${constant.google}${constant.google_register}`, body);
  }

  connectTogmail() {
    return this.http.get(`${environment.urlPrefix}${constant.google}${constant.google_authorize}`);
  }

  disconnectTogmail() {
    return this.http.delete(`${environment.urlPrefix}${constant.google}${constant.google_disconnect}`);
  }

  getGoogleCalendar() {
    return this.http.get(`${environment.urlPrefix}${constant.google}${constant.get_google_calendar}`);
  }

  getOutlook() {
    return this.http.get(`${environment.urlPrefix}${constant.outlook}${this.user_details.data.id}${constant.get_outlook}`);
  }

  connectToOutlook(type: string) {
    if (type === 'connect') {
      return this.http.get(`${environment.urlPrefix}${constant.outlook}${this.user_details.data.id}${constant.connect_outlook}`);
    } else {
      return this.http.delete(`${environment.urlPrefix}${constant.outlook}${this.user_details.data.id}${constant.disconnect_outlook}`);
    }
  }

  //Custom Tags
  getTags(page?: number) {
    if (page) {
      return this.http.get(`${environment.urlPrefix}${constant.custom_tags}?page=${page}&offset=10`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.custom_tags}`);
    }
  }

  getTagById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.custom_tags}${id}/`);
  }

  postTags(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.custom_tags}`, body);
  }

  putTags(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.custom_tags}${body.id}/`, body);
  }

  deletetTags(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.custom_tags}${id}`);
  }

  getIframeDefaultBot() {
    return this.http.get(`${environment.urlPrefix}${constant.iframe_default_bot}`);
  }
}
