import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { FORMINTEGRATIONS } from 'src/app/core/data/form_builder_json';
import { FormBuilderService } from 'src/app/core/service/form-builder.service';

@Component({
  selector: 'app-form-integartions',
  templateUrl: './form-integartions.component.html',
  styleUrls: ['./form-integartions.component.scss']
})
export class FormIntegartionsComponent implements OnInit {

  @Input() formId = '';


  webhookForm!: FormGroup;
  webHookList: any = [];
  submited = false;
  formintegrationJson = FORMINTEGRATIONS[0];
  errorJson = ERRORJSON[0];

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private _formServices: FormBuilderService
  ) {
    this.integartionWebhook();
  }

  ngOnInit() {
    if (this.formId) {
      this.getFormIntegration();
    }
  }


  integartionWebhook(data?: any) {
    this.webhookForm = this.fb.group({
      id: new FormControl(data?.id ? data.id : ''),
      url: new FormControl(data?.url ? data.url : '', Validators.required),
      headers: new FormControl(data?.headers ? data.headers : '', Validators.required),
      body: new FormControl(data?.body ? data.body : '', Validators.required),
      params: new FormControl(data?.params ? data.params : '', Validators.required),
      form_id: new FormControl(this.formId)
    });
  }

  getFormIntegration() {
    this._formServices.getFormIntegrationWebHooks(this.formId).subscribe((resp: any) => {
      this.webHookList = resp.data;
    });
  }

  get f() {
    return this.webhookForm.controls;
  }

  openWebhookModal(webhookmodal: any, data?: any) {
    this.modalService.open(webhookmodal, { size: 'md' });
    if (data) {
      this.integartionWebhook(data);
    } else {
      this.integartionWebhook();
    }
  }

  submitWebhook() {

    if (!this.webhookForm.valid || this.submited) {
      return;
    }

    this.submited = true;


    if (this.webhookForm.value.id) {
      this._formServices.putFormIntegrationWebHooks(this.webhookForm.value, this.webhookForm.value.id).subscribe((resp: any) => {
        this.resetModal();
      }, () => { this.submited = false; });
    } else {
      this._formServices.postFormIntegrationWebHooks(this.webhookForm.value).subscribe((resp: any) => {
        this.resetModal();
      }, () => { this.submited = false; });
    }
  }

  resetModal() {
    this.modalService.dismissAll();
    this.getFormIntegration();
    this.submited = false;
  }
}