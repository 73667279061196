<div class="container-fluid">
    <!-- <div class="d-flex justify-content-between mt-3">
        <div class="heading font-size-18 fw-700 text-primary">{{show_type | titlecase}}</div>
    </div> -->
    <div class="row mt-3 mb-4">
        <div class="col-md-4 d-flex align-items-center cursor">
            <mat-icon class="text-primary" (click)="onbackChange()">arrow_back_ios </mat-icon>
            <div class="heading font-size-18 fw-700 text-primary" (click)="onbackChange()">{{show_type | titlecase}}
            </div>
        </div>
        <!-- <div class="col-md-8 d-flex justify-content-end align-items-center pe-5">
            <mat-icon class="font-size-20 cursor">arrow_back_ios </mat-icon>
            <mat-icon class="font-size-20 cursor">arrow_forward_ios </mat-icon>
        </div> -->
    </div>
    <div class="row">
        <div class="col-md-4" *ngIf="show_type === 'task'">
            <div class="bg-crm-info h-100">
                <div class="container-fluid h-100">
                    <div class="d-flex justify-content-between align-items-center pt-2 pb-2">
                        <h5 class="text-primary fw-700">Associated with</h5>
                        <!-- <div class="">
                            <mat-icon class="font-size-19">more_vert</mat-icon>
                        </div> -->
                    </div>
                    <div class="overflow-auto" style="height: 88%">
                        <mat-accordion>
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <h6 class="fw-600 mb-0 text-primary me-2"
                                            *ngIf="taskDetails?.source_type === 'lead'">Enquiry</h6>
                                        <h6 class="fw-600 mb-0 text-primary me-2"
                                            *ngIf="taskDetails?.source_type === 'contact'">Contact</h6>
                                        <h6 class="fw-600 mb-0 text-primary me-2"
                                            *ngIf="taskDetails?.source_type === 'company'">Company</h6>
                                        <h6 class="fw-600 mb-0 text-primary me-2"
                                            *ngIf="taskDetails?.source_type === 'project'">Client Project</h6>
                                        <h6 class="fw-600 mb-0 text-primary me-2"
                                            *ngIf="taskDetails?.source_type === 'deal'">Pipeline</h6>
                                        <mat-icon>expand_more</mat-icon>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="row no-gutters mt-2" *ngIf="taskDetails?.source_type === 'lead'">
                                    <div class="col-md-4">
                                        <p class="text-name-secondary">Name:</p>
                                        <p class="text-name-secondary">Email:</p>
                                        <p class="text-name-secondary">Phone:</p>
                                        <p class="text-name-secondary">Tag</p>
                                        <p class="text-name-secondary">Created At:</p>
                                        <p class="text-name-secondary">Due Date:</p>
                                        <p class="text-name-secondary">Owner :</p>
                                        <p class="text-name-secondary">Description :</p>
                                        <!-- <p class="text-primary fw-500">See 9 more</p> -->
                                    </div>
                                    <div class="col-md-8">
                                        <p class="text-name">{{relatedSourceDetails?.requirement_title}}</p>
                                        <p class="text-name">{{relatedSourceDetails?.email ? relatedSourceDetails?.email
                                            : "-"}}</p>
                                        <p class="text-name">
                                            {{relatedSourceDetails?.phone_number ? relatedSourceDetails?.phone_number :
                                            "-"}}</p>
                                        <p
                                            class="text-name bg-tag d-inline-flex align-items-center px-2 border-radius-50">
                                            <span *ngIf="relatedSourceDetails.tags" class="border-radius-50 m-0 p-2 tag-color me-2" style="height: 2px;"
                                            [style]="{'background-color': relatedSourceDetails?.colour}"></span>
                                            {{relatedSourceDetails.tags ?
                                            relatedSourceDetails.tags : "-"}}</p>
                                        <p *ngIf="relatedSourceDetails?.created_at" class="text-name">
                                            {{relatedSourceDetails?.created_at | dateConversion}}</p>
                                        <p *ngIf="!relatedSourceDetails?.created_at" class="text-name">-</p>
                                        <p class="text-name" *ngIf="relatedSourceDetails?.due_date ">
                                            {{relatedSourceDetails?.due_date | dateConversion}}</p>
                                        <p *ngIf="!relatedSourceDetails?.due_date" class="text-name">-</p>
                                        <p class="text-name">{{relatedSourceDetails?.info?.owner_info?.fname ?
                                            relatedSourceDetails?.info?.owner_info?.fname :
                                            "-"}}
                                            {{relatedSourceDetails?.info?.owner_info?.lname ?
                                            relatedSourceDetails?.info?.owner_info?.lname :
                                            "-"}} </p>
                                        <p class="text-name">{{relatedSourceDetails?.description ?
                                            relatedSourceDetails?.description :
                                            "-"}} </p>
                                    </div>
                                </div>
                                <div class="row no-gutters mt-2" *ngIf="taskDetails?.source_type === 'contact'">
                                    <div class="col-md-4">
                                        <p class="text-name-secondary">First name:</p>
                                        <p class="text-name-secondary">Last name:</p>
                                        <p class="text-name-secondary">Email:</p>
                                        <p class="text-name-secondary">Mobile:</p>
                                        <p class="text-name-secondary">Owner:</p>
                                        <p class="text-name-secondary">Tag</p>
                                        <p class="text-name-secondary">Source:</p>
                                        <p class="text-name-secondary">Updated At:</p>
                                        <p class="text-name-secondary">Created At:</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p class="text-name text-ellipsis w-100">{{relatedSourceDetails.first_name ?
                                            relatedSourceDetails.first_name : "-"}}</p>
                                        <p class="text-name text-ellipsis w-100">{{relatedSourceDetails.last_name ?
                                            relatedSourceDetails.last_name : "-"}}</p>
                                        <p class="text-name text-ellipsis w-100">{{relatedSourceDetails.email ?
                                            relatedSourceDetails.email : "-"}}</p>
                                        <p class="text-name text-ellipsis w-100">{{relatedSourceDetails.phone_number ?
                                            relatedSourceDetails.phone_number : "-"}}</p>
                                        <p class="text-name text-ellipsis w-100">{{relatedSourceDetails.info.owner_info
                                            ?
                                            relatedSourceDetails.info.owner_info.fname + " " +
                                            relatedSourceDetails.info.owner_info.lname : "-"}}</p>
                                        <p
                                            class="text-name bg-tag d-inline-flex align-items-center px-2 border-radius-50">
                                            <span *ngIf="relatedSourceDetails.tags" class="border-radius-50 m-0 p-2 tag-color me-2" style="height: 2px;"
                                            [style]="{'background-color': relatedSourceDetails?.colour}"></span>
                                            {{relatedSourceDetails.tags ?
                                            relatedSourceDetails.tags : "-"}}</p>
                                        <p class="text-name">{{relatedSourceDetails.source ? relatedSourceDetails.source
                                            : "-"}}</p>
                                        <p class="text-name">{{(relatedSourceDetails.updated_at ?
                                            relatedSourceDetails.updated_at : "") | dateConversion}}</p>
                                        <p class="text-name">{{(relatedSourceDetails.created_at ?
                                            relatedSourceDetails.created_at : "") | dateConversion}}</p>
                                    </div>
                                </div>
                                <div class="row no-gutters mt-2" *ngIf="taskDetails?.source_type === 'company'">
                                    <div class="col-md-4">
                                        <p class="text-name-secondary">Name:</p>
                                        <p class="text-name-secondary">Email:</p>
                                        <p class="text-name-secondary">Mobile:</p>
                                        <p class="text-name-secondary">Website:</p>
                                        <p class="text-name-secondary">Owner:</p>
                                        <p class="text-name-secondary">Tag:</p>
                                        <p class="text-name-secondary">Source:</p>
                                        <p class="text-name-secondary">Address:</p>
                                        <p class="text-name-secondary">City:</p>
                                        <p class="text-name-secondary">Zipcode:</p>
                                        <p class="text-name-secondary">Country:</p>
                                        <p class="text-name-secondary">Description:</p>
                                        <p class="text-name-secondary">Updated At:</p>
                                        <p class="text-name-secondary">Created At:</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p class="text-name">{{relatedSourceDetails?.name ? relatedSourceDetails?.name :
                                            "-"}}</p>
                                        <p class="text-name">{{relatedSourceDetails?.email ? relatedSourceDetails.email
                                            :
                                            "-"}}</p>
                                        <p class="text-name">{{relatedSourceDetails?.phonenumber ?
                                            relatedSourceDetails?.phonenumber :
                                            "-"}}</p>
                                        <p class="text-name">{{relatedSourceDetails?.website ?
                                            relatedSourceDetails?.website : "-"}}</p>
                                        <p class="text-name">{{relatedSourceDetails?.info?.owner_info ?
                                            relatedSourceDetails?.info?.owner_info?.fname + " " +
                                            relatedSourceDetails?.info?.owner_info?.lname : "-"}}</p>
                                        <p
                                            class="text-name bg-tag d-inline-flex align-items-center px-2 border-radius-50">
                                            <span *ngIf="relatedSourceDetails.tags" class="border-radius-50 m-0 p-2 tag-color me-2" style="height: 2px;"
                                            [style]="{'background-color': relatedSourceDetails?.colour}"></span>
                                            {{relatedSourceDetails?.tags ?
                                            relatedSourceDetails?.tags : "-"}}</p>
                                        <p class="text-name">{{relatedSourceDetails?.source?
                                            relatedSourceDetails?.source
                                            : "-"}}</p>
                                        <p class="text-name w-100 text-ellipsis">{{relatedSourceDetails?.address?
                                            relatedSourceDetails?.address : "-"}}</p>
                                        <p class="text-name">{{relatedSourceDetails?.city? relatedSourceDetails?.city :
                                            "-"}}</p>
                                        <p class="text-name">{{relatedSourceDetails?.zipcode?
                                            relatedSourceDetails?.zipcode : "-"}}</p>
                                        <p class="text-name">{{relatedSourceDetails?.country ?
                                            relatedSourceDetails?.country : "-"}}</p>
                                        <p class="text-name w-100 text-ellipsis ">{{relatedSourceDetails?.description ?
                                            relatedSourceDetails?.description : "-"}}
                                        </p>
                                        <p class="text-name">{{(relatedSourceDetails?.updated_at ?
                                            relatedSourceDetails?.updated_at : "")
                                            | dateConversion}}</p>
                                        <p class="text-name">{{(relatedSourceDetails?.created_at ?
                                            relatedSourceDetails?.created_at : "")
                                            | dateConversion}}</p>
                                    </div>
                                </div>
                                <div class="row no-gutters mt-2" *ngIf="taskDetails?.source_type === 'project'">
                                    <div class="col-md-4">
                                        <p class="text-name-secondary">Title:</p>
                                        <p class="text-name-secondary">Status:</p>
                                        <p class="text-name-secondary">Due date:</p>
                                        <p class="text-name-secondary">Budget:</p>
                                        <p class="text-name-secondary">Tag</p>
                                        <p class="text-name-secondary">Source:</p>
                                        <p class="text-name-secondary">Description:</p>
                                        <p class="text-name-secondary">Updated At:</p>
                                        <p class="text-name-secondary">Created At:</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p class="text-name text-ellipsis w-100">{{relatedSourceDetails?.name ?
                                            relatedSourceDetails?.name : "-"}}</p>
                                        <p class="text-name">{{relatedSourceDetails?.project_status ?
                                            relatedSourceDetails?.project_status : "-"}}</p>
                                        <p class="text-name">{{relatedSourceDetails?.due_date ?
                                            relatedSourceDetails?.due_date : "-"}}</p>
                                        <p class="text-name">{{relatedSourceDetails?.cost ? relatedSourceDetails?.cost :
                                            "-"}}</p>
                                        <p
                                            class="text-name bg-tag d-inline-flex align-items-center px-2 border-radius-50">
                                            <span *ngIf="relatedSourceDetails.tag" class="border-radius-50 m-0 p-2 tag-color me-2" style="height: 2px;"
                                            [style]="{'background-color': relatedSourceDetails?.colour}"></span>
                                            {{relatedSourceDetails?.tag
                                            ? relatedSourceDetails?.tag : "-"}}</p>
                                        <p class="text-name">{{relatedSourceDetails?.source ?
                                            relatedSourceDetails?.source : "-"}}</p>
                                        <p class="text-name w-100 text-ellipsis">{{relatedSourceDetails?.description ?
                                            relatedSourceDetails?.description : "-"}}</p>
                                        <p class="text-name">{{(relatedSourceDetails?.updated_at ?
                                            relatedSourceDetails?.updated_at : "-") | dateConversion}}</p>
                                        <p class="text-name">{{(relatedSourceDetails?.created_at ?
                                            relatedSourceDetails?.created_at : "-") | dateConversion}}</p>
                                    </div>
                                </div>
                                <div class="row no-gutters mt-2 cursor" *ngIf="taskDetails?.source_type === 'deal'">
                                    <div class="row align-items-center" *ngFor="let field of cardFields">
                                        <div class="col-md-4 info-accord">
                                            <p class="text-inner-info">{{field.label | titlecase}}</p>
                                        </div>
                                        <div class="col-md-8 info-accord">
                                            <p class="text-name text-ellipsis w-100" *ngIf="!field.url">: {{
                                                (field.data | titlecase) || "-" }}</p>
                                            <p class="text-name text-ellipsis w-100"
                                                *ngIf="field.url === 'status' || field.url === 'source'">
                                                : {{ (field.data | titlecase) || "-" }}</p>
                                            <p class="text-name text-ellipsis w-100"
                                                *ngIf="field.url && field.url !== 'status' && field.url !=='source'">:
                                                {{field.data_name || "-" }}</p>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>
        <div class="" [ngClass]="{'col-12' : show_type !== 'task' , 'col-md-8' : show_type === 'task'} ">
            <app-task-detail [taskId]="id" [showType]="show_type" *ngIf="show_type !== 'appointment'"></app-task-detail>
            <app-bookedappointment-detail [appointmentId]="id"
                *ngIf="show_type === 'appointment'"></app-bookedappointment-detail>

            <!-- <app-followup-detail [taskId]="id" *ngIf="show_type === 'followup'"></app-followup-detail> -->
            <!-- <app-call-detail *ngIf="show_type === 'calls'"></app-call-detail>
            <app-bookedappointment-detail *ngIf="show_type === 'appointment'"></app-bookedappointment-detail>
            <app-meeting-detail  *ngIf="show_type === 'meeting'"></app-meeting-detail>
            <app-enquire-details *ngIf="show_type === 'enquire'"></app-enquire-details> -->
        </div>
    </div>
</div>