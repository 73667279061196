<div class="h-28rem overflow-auto">
    <div class="d-flex justify-content-between align-items-center mb-3">
        <h6 class="text-primary">{{appointmentJson.aptHeading}}</h6>
    </div>

    <div class="table-responsive mt-3">
        <table class="table table-sm align-middle table-nowrap table-hover mb-0">
            <thead>
                <tr>
                    <th scope="col">{{appointmentJson.col_one_label}}</th>
                    <th scope="col">{{appointmentJson.col_two_label}}</th>
                    <th scope="col">{{appointmentJson.col_three_label}}</th>
                    <th scope="col">{{appointmentJson.col_four_label}}</th>
                    <th scope="col">{{appointmentJson.col_five_label}}</th>
                    <th scope="col">{{appointmentJson.col_six_label}}</th>
                </tr>
            </thead>
            <!-- -->

            <tbody>
                <tr *ngFor="let item of AptListArray; let i = index">
                    <td class="py-2">
                        {{ item.name }}
                    </td>
                    <td>
                        {{ item.client_name }}
                    </td>

                    <td>{{ item.email }}</td>
                    <td>
                        {{ item.number }}
                    </td>
                    <td>
                        {{ item.amount }}
                    </td>
                    <td>
                        {{ item.due_date | date: 'yyyy mm dd'}}
                    </td>
                </tr>
            </tbody>
        </table>
        <mat-paginator [length]="AptListArray.length" [pageSize]="10" aria-label="Select page" showFirstLastButtons>
        </mat-paginator>
    </div>
</div>