import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { MENU } from '../data/menu_list';

@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.scss']
})
export class LayoutsComponent {

  menuList: any[];
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map((result: any) => result.matches)
    );
  selectedMenu: any;

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, private cdr: ChangeDetectorRef) {
    this.menuList = MENU;
  }

  changeOfRoutes() {
    this.menuList.forEach((ele) => {
      if (ele.label.toLowerCase() === this.router.url.split('/')[1]) {
        this.selectedMenu = ele;
        this.cdr.detectChanges();
      } else if ('settings' === this.router.url.split('/')[1]) {
        this.selectedMenu = {
          "id": 21,
          "label": "Settings",
          "icon": "settings",
          "link": "/settings",
          "slug": "settings",
          "image": "./assets/images/settings.png",
          "color_img": "./assets/images/settings.png"
        };
      }
    });
  }
}
