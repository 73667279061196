import { Component } from '@angular/core';

@Component({
  selector: 'app-taskboard-preview',
  templateUrl: './taskboard-preview.component.html',
  styleUrls: ['./taskboard-preview.component.scss']
})
export class TaskboardPreviewComponent {

}
