import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-roles-permissions',
  templateUrl: './roles-permissions.component.html',
  styleUrls: ['./roles-permissions.component.scss']
})
export class RolesPermissionsComponent implements OnInit {
  errorjson = ERRORJSON[0];
  table_cols = ['Role', 'Active', 'Inactive'];
  rolesList: any[] = [];
  addRoleForm: any;
  submited = false;
  add_role_table_cols = ['Feature', 'Capabilities'];
  rolesPermissionModulesData = [
    {
      label: 'Appointments',
      value: 'services'
    },
    {
      label: 'Leads',
      value: 'leads'
    },
    {
      label: 'Clients',
      value: 'clients'
    },

    {
      label: 'Products',
      value: 'products'
    },
    {
      label: 'Tax',
      value: 'tax'
    },
    {
      label: 'Services',
      value: 'bookings'
    },
    {
      label: 'Invoice',
      value: 'invoice'
    },
    {
      label: 'Company Settings',
      value: 'company_settings'
    },
    {
      label: 'Genaral Settings',
      value: 'genaral_settings'
    },
    {
      label: 'Working Hours',
      value: 'working_hours'
    },
    {
      label: 'Packages',
      value: 'packages'
    },
    {
      label: 'Staff',
      value: 'staff'
    },
    {
      label: 'Task',
      value: 'tasks'
    },
    {
      label: 'Form Builder',
      value: 'custom_forms'
    },
    {
      label: 'Smart Popups',
      value: 'pushers'
    },
    {
      label: 'Live Chat',
      value: 'live_chat'
    }


  ];
  capabilities_list: any;
  templateRolesListType = true;

  constructor(private formBuilder: FormBuilder, private settingService: SettingsService) { }

  ngOnInit() {
    this.InitilizeRoles_Form();
    this.getRoles();
  }

  InitilizeRoles_Form() {
    this.addRoleForm = this.formBuilder.group({
      name: ['', Validators.required],
      permissions: this.formBuilder.array([])
    });
  }

  get f() {
    return this.addRoleForm.controls;
  }

  formPermissions() {
    return this.addRoleForm.get("permissions") as FormArray;
  }

  formElementsEdit(element: any, newElement: boolean) {
    return new FormGroup({
      name: new FormControl(newElement ? element.label : element[1].label),
      own: new FormControl(newElement ? element.label : element[1].own),
      global: new FormControl(newElement ? element.value : element[1].global),
      team: new FormControl(newElement ? false : element[1].team),
      create: new FormControl(newElement ? false : element[1].create),
      edit: new FormControl(newElement ? false : element[1].edit),
      delete: new FormControl(newElement ? false : element[1].delete)
    });
  }

  initalizeForm() {
    this.rolesPermissionModulesData.forEach((element, i) => {
      this.formPermissions().insert(i, this.formElementsEdit(element, true));
    });
  }

  addRole(type: boolean) {
    this.templateRolesListType = type;
    if (!this.templateRolesListType) {
      this.initalizeForm();
    }
  }

  addnewRoleSubmit() {
    this.submited = true;
    console.log(this.addRoleForm.value);
  }

  getRoles() {
    this.settingService.getRolesPermissions(false).subscribe((resp: any) => {
      this.rolesList = resp.data;
    });
  }
}
