import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LEADSJSON } from 'src/app/core/data/leads_json';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { LeadModalComponent } from '../../shared/lead-modal/lead-modal.component';
import { TaskBoardComponent } from '../../clients/client-managment/task-board/task-board.component';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { finalize } from 'rxjs';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class LeadsComponent implements OnInit {
  public selection = new SelectionModel<any>(true, []);
  modalref!: NgbModalRef;
  previewModal!: NgbModalRef;
  leadJson = LEADSJSON[0];
  currentPage = 0;
  activeId = 1;
  filtersLables = [
    {
      position: 1,
      lableName: 'Name',
      checked: true
    },
    {
      position: 2,
      lableName: 'Next Activity',
      checked: false
    },
    {
      position: 25,
      lableName: 'Conatct Name',
      checked: true
    },
    {
      position: 3,
      lableName: 'Email',
      checked: true
    },
    {
      position: 4,
      lableName: 'Mobile Number',
      checked: true
    },
    // {
    //   position: 5,
    //   lableName: 'Tag',
    //   checked: true
    // },
    {
      position: 6,
      lableName: 'Channel',
      checked: true
    },
    {
      position: 7,
      lableName: 'Created On',
      checked: true
    },
    // {
    //   position: 8,
    //   lableName: 'Last Modified',
    //   checked: true
    // },
    {
      position: 9,
      lableName: 'Owner',
      checked: true
    },
    {
      position: 24,
      lableName: 'Due date',
      checked: true
    },
    {
      position: 10,
      lableName: 'Company',
      checked: false
    },
    {
      position: 11,
      lableName: 'Website',
      checked: false
    },
    {
      position: 12,
      lableName: 'Mailing Street',
      checked: false
    },
    {
      position: 13,
      lableName: 'Mailing Address',
      checked: false
    },
    {
      position: 14,
      lableName: 'Mailing City',
      checked: false
    },
    {
      position: 15,
      lableName: 'Mailing Country',
      checked: false
    },
    {
      position: 16,
      lableName: 'Mailing State',
      checked: false
    },
    {
      position: 17,
      lableName: 'Shipping Address',
      checked: false
    },
    {
      position: 18,
      lableName: 'Shipping Street',
      checked: false
    },
    {
      position: 19,
      lableName: 'Shipping City',
      checked: false
    },
    {
      position: 20,
      lableName: 'Shipping Country',
      checked: false
    },
    {
      position: 21,
      lableName: 'Zip Code',
      checked: false
    },
    {
      position: 22,
      lableName: 'Title',
      checked: false
    },
    {
      position: 23,
      lableName: 'Move To',
      checked: true
    },
    {
      position: 24,
      lableName: 'Action',
      checked: true
    }
  ];
  leadsdropDown = [
    {
      label: 'All',
      value: 'all'
    },
    {
      label: 'Todays',
      value: 'todays'
    },
    {
      label: 'Last 7 days',
      value: 'this_week'
    },
    {
      label: 'Iframe',
      value: 'iframe'
    },
    {
      label: 'Chatbot',
      value: 'chatbot'
    },
    {
      label: 'Facebook',
      value: 'facebook'
    },
    {
      label: 'Manual',
      value: 'manual'
    },
    {
      label: 'Forms',
      value: 'forms'
    }
  ];
  filter: any = this.leadsdropDown[0].value;
  selected_filter!: string;
  selected_filter_value: any;
  leadsObj: any = {};
  leadsListArray: any = [];
  pageEvent!: PageEvent;
  search_global!: string;
  page_size = 10;
  loggedInUser: any;
  display_no_data = false;
  staffMembers: [] = [];
  moved_to:any;

  // @ViewChild('popoverMenu', { static: false }) popoverMenu!: MatMenuTrigger;

  @ViewChild(MatMenuTrigger) popoverMenuTrigger!: MatMenuTrigger;
  @ViewChild(MatMenu) popoverMenu!: MatMenu;
  headerName: any;

  constructor(private modalService: NgbModal,
    private service: CrmService,
    private confirmationMsz: ConfirmationMszService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);

    this.getLeadsList(1, 'ACTIVE');
    this.organisationMembersList();
  }

  onFilterChange(e: any) {
    // console.log(e);
    this.filter = e;
    if (this.filter === 'todays') {
      this.selected_filter = 'todays';
      this.selected_filter_value = true;
    } else if (this.filter === 'this_week') {
      this.selected_filter = 'weeks';
      this.selected_filter_value = true;
    } else if (this.filter === 'iframe') {
      this.selected_filter = 'source';
      this.selected_filter_value = 'iframe';
    } else if (this.filter === 'forms') {
      this.selected_filter = 'source';
      this.selected_filter_value = 'Form_Builder';
    } else if (this.filter === 'chatbot') {
      this.selected_filter = 'source';
      this.selected_filter_value = 'chatbot';
    } else if (this.filter === 'manual') {
      this.selected_filter = 'source';
      this.selected_filter_value = 'manual';
    } else if (this.filter === 'facebook') {
      this.selected_filter = 'source';
      this.selected_filter_value = 'facebook';
    } else {
      this.selected_filter = '';
      this.selected_filter_value = '';
    }
    // console.log(this.selected_filter, this.selected_filter_value);

    this.getLeadsList(1, 'ACTIVE', this.selected_filter, this.selected_filter_value);
  }

  tabChange(event: any) {
    this.currentPage = 0;
    this.activeId = event.nextId;
    if (this.activeId === 1) {
      this.getLeadsList(1, 'ACTIVE', this.selected_filter, this.selected_filter_value);
    } else if (this.activeId === 2) {
      this.getLeadsList(1, 'LOST');
    } else if (this.activeId === 3) {
      this.getLeadsList(1, 'JUNK');
    } else {
      this.getLeadsList(1, 'WON');

    }
  }

  getLeadsList(page: any, lead_type: any, filter_param?: string, filter?: any) {
    this.service.getAllLeads(page, lead_type, filter_param, filter).subscribe({
      next: (resp: any) => {
        this.leadsObj = resp;
        this.leadsListArray = resp.data;
        if (this.leadsListArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }
      }
    });
  }

  page(e: PageEvent, lead_type: string) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getLeadsList(this.currentPage, lead_type, this.selected_filter, this.selected_filter_value);
  }

  removeLead(leadId: string, lead_type: string) {
    this.service.deleteLead(leadId).subscribe({
      next: () => {
        this.getLeadsList(1, lead_type, this.selected_filter, this.selected_filter_value);
      }
    });
  }

  confirmDelete(leadId: string, lead_type: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.removeLead(leadId, lead_type);
      }
    });
  }

  moveLeadTo(body: any, to: string, from: string) {
    this.service.updateLead({ ...body, type: to, custom_lead_fields: [] }).subscribe(() => {
      this.getLeadsList(1, from);
    });
  }

  selectFilterLable(event: any) {
    event.stopPropagation();
    // $event.preventDefault();
    // if ($event.target) {
    //   $event.target.classList.toggle('selected');
    // }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.leadsListArray.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.leadsListArray.forEach((row: any) => {
      this.selection.select(row);
    });
  }

  addLeadModal(lead?: any) {
    this.modalref = this.modalService.open(LeadModalComponent, { size: 'lg', scrollable: true });
    this.modalref.componentInstance.lead = lead;
    this.modalref.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry) this.getLeadsList(1, 'ACTIVE');
    });
  }

  openTaskBoardPreview() {
    this.previewModal = this.modalService.open(TaskBoardComponent, { size: 'lg', scrollable: true });
    this.previewModal.componentInstance.crm_type = 'lead';
  }

  search(name: any) {
    if (name.length >= 3) {
      this.service.getLeadsBySearch(name).subscribe({
        next: (resp: any) => {
          this.leadsObj = resp;
          this.page_size = this.leadsObj.total;
          this.leadsListArray = resp.data;
          if (this.leadsListArray.length === 0) {
            this.display_no_data = true;
          } else {
            this.display_no_data = false;
          }
        }
      });
    } else if (name.length < 1) {
      this.page_size = 10;
      this.getLeadsList(1, 'ACTIVE');
    }
  }

  downloadEnquiries() {
    const outputContacts: any = [];
    if (this.selection.selected.length === 0) {
      this.service.getAllLeadForExport().subscribe((resp: any) => {
        resp.data.forEach((item: any) => {
          outputContacts.push({
            "Name": item.requirement_title,
            "Email": item.email,
            "Phone": item.phone_number,
            // "Tags": item.tags,
            "Channel": item.source_type,
            "Date & Time": item.created_at ? this.formatDate(item.created_at) : 'null',
            "Owner": item?.info?.owner_info?.fname + " " + item?.info?.owner_info?.lname,
            "Due Date": item.due_date ? this.formatDate(item.due_date) : 'null'
          });
        });
        this.service.jsonToCsv(outputContacts, "Enquiries");
      });
    } else {
      this.selection.selected.forEach((item: any) => {
        outputContacts.push({
          "Name": item.requirement_title,
          "Email": item.email,
          "Phone": item.phone_number,
          "Tags": item.tags,
          "Channel": item.source_type,
          "Date & Time": item.created_at ? this.formatDate(item.created_at) : 'null',
          "Owner": item?.info?.owner_info?.fname + " " + item?.info?.owner_info?.lname,
          "Due Date": item.due_date ? this.formatDate(item.due_date) : 'null'
        });
      });
      this.service.jsonToCsv(outputContacts, "Enquiries");
    }
  }

  formatDate(dateStr: string) {
    const dateString = dateStr;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  onEditClicked(events: any, item: any) {
    events.stopPropagation();
    alert('Edit clicked');
  }

  onUpdatePopOver(event: any, Id: any) {
    console.log(event);
    console.log(Id);

    const input: any = {
      id: Id,
      custom_lead_fields: []
    };

    if (event.type === 'dropdown' && event.labelName === 'Owner') {
      input.info = {
        "owner_info": {
          "id": event.value.id,
          "fname": event.value.fname,
          "lname": event.value.lname
        }
      };
      input.owner = event.value.id;
      input.owner_department = event.value.department_info?.id ? event.value.department_info?.id : '';

      // this.staffMembers.forEach((staff: any) => {
      //   if (staff.id === data.value.id) input.owner_department = staff.department_info.id;
      // });
    } else
      input[event.formName] = event.value;

    this.service.updateLead(input).subscribe((resp: any) => {
      console.log(resp);
      // this.getLeadsList(1, 'ACTIVE', this.selected_filter, this.selected_filter_value);
      if (this.activeId === 1) {
        this.getLeadsList(1, 'ACTIVE', this.selected_filter, this.selected_filter_value);
      } else if (this.activeId === 2) {
        this.getLeadsList(1, 'LOST');
      } else if (this.activeId === 3) {
        this.getLeadsList(1, 'JUNK');
      } else {
        this.getLeadsList(1, 'WON');

      }
    });
  }
}
