import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter( it => {
      const arr:any = Object.values(it);
      // @ts-ignore
      const new_arr = arr.filter(item =>{
        if(item){
          return item.toString().toLowerCase().includes(searchText);
        }
      });
      if(new_arr.length > 0){
        return it;
      }
    });
  }

}
