<div class=" h-28rem overflow-auto">
    <div class="table-responsive mt-3">
        <table class="table table-sm align-middle table-nowrap table-hover mb-0">
            <thead>
                <tr>
                    <th scope="col">{{projectJson.col_one_label}}</th>
                    <th scope="col">{{projectJson.col_two_label}}</th>
                    <th scope="col">{{projectJson.col_three_label}}</th>
                    <th scope="col">{{projectJson.col_four_label}}</th>
                    <th scope="col">{{projectJson.col_five_label}}</th>
                </tr>
            </thead>
            <!-- -->

            <tbody>
                <tr *ngFor="let item of projectsArray; let i = index">
                    <td class="py-2">
                        {{ item.name ? item.name : "-" }}
                    </td>
                    <td>
                        {{ item.project_status ? item.project_status : "-" }}
                    </td>

                    <td>{{ item.client_name ? item.client_name : "-" }}</td>
                    <td>
                        {{ item.cost ? item.cost : "-" }}
                    </td>
                    <td *ngIf="item.due_date">
                        {{ item.due_date | dateConversion}}
                    </td>
                    <td *ngIf="!item.due_date">-</td>
                </tr>
            </tbody>
        </table>
        <mat-paginator [length]="totalCount" [pageSize]="10" (page)="page($event)" aria-label="Select page"
            hidePageSize="true" showFirstLastButtons>
        </mat-paginator>
    </div>
</div>