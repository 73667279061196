import { Component } from '@angular/core';

@Component({
  selector: 'app-data-administration',
  templateUrl: './data-administration.component.html',
  styleUrls: ['./data-administration.component.scss']
})
export class DataAdministrationComponent {
  activeId: any = 5;
  attachedFile:any;
  fileChosen:any;
  thumbnail:any;

  tabChange(event: any) {
    this.activeId = event.nextId;
  }

  files: File[] = [];
  
  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  notesFileUpload(e: any) {
    this.attachedFile = e.target.files[0];
    this.fileChosen = e.target.files[0].name;
  }

  removeFile() {
    this.attachedFile.value = '';
    this.fileChosen = '';
  }
}
