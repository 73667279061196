import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SetUpService } from './set-up.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private addnewChanel = new Subject<boolean>();
  private activeTabStatus = new Subject<number>();

  private current_user = new BehaviorSubject<object>({});
  current_user_data = this.current_user.asObservable();
  generalSetting: any;

  constructor(private http: HttpClient, private router: Router,private setUpService: SetUpService,
    private settingsService: SettingsService) {
    /* tslint:disable:no-empty */
  }

  register(body: any) {
    return this.http.post<any>(`${environment.urlPrefix}${constant.register}`, body);
  }

  login(body: any) {
    return this.http.post<any>(`${environment.urlPrefix}${constant.login}`, body);
  }

  forgotPsw(body: JSON) {
    return this.http.post(`${environment.urlPrefix}${constant.forgot_psw}`, body);
  }

  resetPsw(body: JSON) {
    return this.http.post(`${environment.urlPrefix}${constant.reset_psw}`, body);
  }

  activateUser(id: string, otp: string) {
    return this.http.get(`${environment.urlPrefix}${constant.verification_code}${id}/?code=${otp}`);
  }

  resendactivation(userEmail: string) {
    return this.http.get(`${environment.urlPrefix}${constant.resend_verification_code}?email=${userEmail}`);
  }

  logout() {
    sessionStorage.removeItem('currentUser');
    localStorage.removeItem('currentUser');
    this.clearSubjectVariables();
    this.router.navigate(['']);
  }

  public setAddChanelStatus(status: boolean): void {
    this.addnewChanel.next(status);
  }
  public getAddChanelStatus(): Observable<boolean> {
    return this.addnewChanel.asObservable();
  }
  public setActiveTabStatus(status: number): void {
    this.activeTabStatus.next(status);
  }
  public getActiveTabStatus(): Observable<number> {
    return this.activeTabStatus.asObservable();
  }

  getData() {
    this.http.get(`${environment.urlPrefix}${constant.general_settings}`).subscribe((resp: any) => {
      this.generalSetting = resp.data;
    });
  }

  getCompanyData(){
    return this.generalSetting;
  }

  clearSubjectVariables() {
    this.setUpService.meetingSettings.next(null);
    this.setUpService.calendarMeetings.next(null);
    this.setUpService.workingHours.next(null);
    this.setUpService.setupSetting.next(null);
    this.settingsService.getStaffInfoList.next(null);
  } 
  
  //header Calendar
  getAllSlotsData(type_calendar:any,date: any){
    return this.http.get(`${environment.urlPrefix}${constant.header_calendar}date/?${type_calendar}=${date}&time_zone=${ this.generalSetting?.timezone || 'Asia/Calcutta'}`);
  }

  getSlotDetailsByid(id: string){
    return this.http.get(`${environment.urlPrefix}${constant.header_calendar}${id}/?time_zone=${ this.generalSetting?.timezone || 'Asia/Calcutta'}`);
  }
  
}
