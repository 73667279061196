export class constant {
  //REGISTER & LOGIN
  static readonly register = '/users/authorisation/register/';
  static readonly login = '/users/authorisation/login/';
  static readonly forgot_psw = '/users/authorisation/forgot-password/';
  static readonly reset_psw = '/users/authorisation/reset-password/';
  static readonly verification_code = '/users/authorisation/activate/';
  static readonly resend_verification_code = '/users/authorisation/resend-activation/';

  //SETTINGS
  static readonly general_settings = '/users/organisation/general_settings/';
  static readonly company_settings = '/users/organisation/information/';
  static readonly locations = '/users/organisation/locations/';
  static readonly working_hours = '/users/organisation/working_hours/';
  static readonly staff_info = '/users/staff/information/';
  static readonly roles_permissions = '/users/organisation/roles_permissions/';
  static readonly teams = '/users/organisation/departments/';
  static readonly zoom = '/consultations/zoom/';
  static readonly outlook = '/outlook/';
  static readonly get_outlook = '/isConnected/';
  static readonly connect_outlook = '/authorize/';
  static readonly disconnect_outlook = '/disconnect/';
  static readonly google = '/googler';
  static readonly google_register = '/register/';
  static readonly google_authorize = '/authorize/';
  static readonly google_disconnect = '/disconnect/';
  static readonly get_google_connect = '/getgoogleconnect/';
  static readonly get_google_calendar = '/calendarevent/';
  static readonly custom_tags = '/users/organisation/tag/';
  static readonly iframe_default_bot = '/bot/api/chatbot/default';

  //S3
  static readonly S3presignedUrl = '/users/aws_utils/pre_signed_url/';

  // Leads
  static readonly leads_crm = '/crm_service/Leads';
  static readonly custom_lead_fields_crm = '/crm_service/CustomLeadTemplate';
  static readonly exportLeads = '/crm_service/LeadsExport';

  //contacts
  static readonly contacts_crm = '/crm_service/Contacts';
  static readonly custom_contact_fields_crm = '/crm_service/CustomContactTemplate';
  static readonly importContacts = '/crm_service/ContactsImport';
  static readonly exportContacts = '/crm_service/ContactsExport';

  //company
  static readonly companies_crm = '/crm_service/Companys';
  static readonly importCompanies = '/crm_service/CompanyImport';
  static readonly exportCompanies = '/crm_service/CompanyExport';

  //timeline
  static readonly auditlog = '/audits/auditlogs/';

  //notes
  static readonly notes = '/common/notes/';

  //files
  static readonly files = '/common/files/';

  //activity
  static readonly activity = '/activities/activities/';

  //taskboard
  static readonly stage = '/client/stages/';
  static readonly task = '/client/tasks/';
  static readonly staff_in_task = '/users/users-info';

  //clients
  static readonly clients = '/client/clients/';
  static readonly projects = '/projects/';
  static readonly client_projects = '/client/projects/';
  static readonly delete_client_projects = '/client/';

  //appointments
  static readonly categories = '/consultations/category/';
  static readonly appointments = '/consultations/appointments/';
  static readonly appointment_count = '/consultations/appointments_count/';
  static readonly appointmentReschedule = '/consultations/appointments/reschedule/';
  static readonly appointmentQuestionnaire = '/consultations/questionnaire/';

  //Meetings
  static readonly profileMeetings = '/calendars/profiles/';
  static readonly profileCalMeetings = '/calendars/meetings/';
  static readonly profileWorkingHours = '/calendars/workinghours/';
  static readonly profilemeetingsorder = '/calendars/meetingorder/';
  static readonly bookedorgMeetings = '/calendars/bookings/';

  //product
  static readonly store = '/product/stores/';
  static readonly product_categories = '/categories/';
  static readonly products = '/products/';
  static readonly variants = '/variant/';

  // form-builder
  static readonly form = '/form_builder/form/';
  static readonly form_submissions = '/form_builder/form_submissions/';
  static readonly form_fields = '/form_builder/form_fields/';
  static readonly form_notifications_me = '/form_builder/notification_setting/';
  static readonly form_integration_webhooks = '/form_builder/web_hook_setting/';
  static readonly form_thank_you = '/form_builder/thank_you_page_setting/';
  static readonly form_crm = '/form_builder/crm_setting/';

  //consultations
  static readonly consultations = '/consultations/consultations/';

  //pipeline
  static readonly pipelines = '/crm_service/Pipelines';
  static readonly stages = '/crm_service/Stages';
  static readonly bulkstages = '/crm_service/BulkStage';
  static readonly cards = '/crm_service/Cards';
  static readonly bulkCards = '/crm_service/BulkCards';
  static readonly custom_fields = '/crm_service/GlobalField';
  static readonly templates = '/crm_service/GlobalPipeline';
  static readonly templatesCard = '/crm_service/TemplateCard';
  static readonly templatesFields = '/crm_service/TemplateFields';
  static readonly template = '/crm_service/CreatePipelineWithTemplate';
  static readonly card_fields = '/crm_service/CardField';

  //Notifications
  static readonly notification = '/notifications/notifications/';

  //Knowledge Base
  static readonly article = '/articles/articles/';
  static readonly group = '/articles/topics/';

  //conversations
  static readonly conversation = '/communication/communications/';
  static readonly worke_ai = '/botstudio/generatecontent';
  //Slots
  static readonly slots = '/calendars/slots/';

  //invoice
  static readonly invoice = '/documents/invoices/';
  static readonly invoice_retry = '/retry';

  //payments
  static readonly payment_transactions = '/payment/payments/transactions/';

  //payments
  static readonly payments = '/payment/payments/gateway/';
  static readonly transaction = '/payment/payments/transactions/';

  //Set-up
  static readonly setup = '/users/organisation/set_up/';

  //header
  static readonly header_calendar = '/calendars/bookings/';
}
