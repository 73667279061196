<div class="container-fluid mt-3">
    <h5 class="heading font-size-18 fw-700">{{formleadJson.heading}}</h5>
    <form [formGroup]="CRMForm">
        <div class="row mt-3 mb-5">
            <div class="col-md-12 mb-3">
                <div class="d-flex align-items-center">
                    <label>{{formleadJson.show_checkbox}}</label>
                    <label class="switch ms-3">
                        <input type="checkbox" (change)="onAssignChange('pipeline')" formControlName="is_enquiry">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
            <div class="col-md-5" *ngIf="CRMForm.value.is_enquiry">
                <div>
                    <label class="fw-600 mb-2 text-primary">{{formleadJson.feild_owner}}</label><br>
                    <mat-form-field appearance="outline" class="w-75">
                        <mat-select placeholder="{{formleadJson.placeholder_owner}}" class="pt-1"
                            formControlName="enquiry_owner">
                            <mat-option [value]="opt.id" *ngFor="let opt of staffList">{{opt.fname +" "+
                                opt.lname}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-12 mt-3">
                <div class="d-flex align-items-center">
                    <label>{{formleadJson.show_pipeline}}</label>
                    <label class="switch ms-3">
                        <input type="checkbox" (change)="onAssignChange('enquire')" formControlName="is_card">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
            <div class="col-md-5 mt-3" *ngIf="CRMForm.value.is_card">
                <div>
                    <label class="fw-600 mb-2 text-primary">{{formleadJson.pipeline_select}}</label><br>
                    <mat-form-field appearance="outline" class="w-75">
                        <!-- <input style="outline: none" matInput placeholder="{{formleadJson.pipeline_select}}" /> -->
                        <mat-select placeholder="{{formleadJson.pipeline_select}}" class="pt-1"
                            formControlName="pipeline" (selectionChange)="getStages()">
                            <mat-option [value]="opt.id" *ngFor="let opt of pipelineList">{{opt.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <label class="fw-600 mb-2 text-primary">{{formleadJson.stage_select}}</label><br>
                    <mat-form-field appearance="outline" class="w-75">
                        <mat-select placeholder="{{formleadJson.stage_select}}" class="pt-1" formControlName="stage">
                            <mat-option [value]="opt.id" *ngFor="let opt of stageList">{{opt.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <label class="fw-600 mb-2 text-primary">{{formleadJson.feild_owner}}</label><br>
                    <mat-form-field appearance="outline" class="w-75">
                        <mat-select placeholder="{{formleadJson.placeholder_owner}}" class="pt-1"
                            formControlName="card_owner">
                            <mat-option [value]="opt.id" *ngFor="let opt of staffList">{{opt.fname +" "+
                                opt.lname}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
        </div>
        <ng-container *ngIf="CRMForm.value.is_card || CRMForm.value.is_enquiry">
            <hr class="my-2">
            <p class="my-2">
                Please Select Form Fields
            </p>
            <div class="mt-3 row w-75">

                <div class="col">
                    <label class="fw-600 mb-2 text-primary">{{formleadJson.name}}</label><br>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select placeholder="{{formleadJson.name}}" class="pt-1" formControlName="name">
                            <ng-container *ngFor="let opt of form_fields_list">
                                <mat-option [value]="opt.id" *ngIf="opt.key === 'TEXT'">{{opt.label}}</mat-option>
                            </ng-container>

                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col">
                    <label class="fw-600 mb-2 text-primary">{{formleadJson.email}}</label><br>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select placeholder="{{formleadJson.email}}" class="pt-1" formControlName="email">
                            <ng-container *ngFor="let opt of form_fields_list">
                                <mat-option [value]="opt.id" *ngIf="opt.key === 'EMAIL'">{{opt.label}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="w-100"></div>

                <div class="col">
                    <label class="fw-600 mb-2 text-primary">{{formleadJson.phone_number}}</label><br>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select placeholder="{{formleadJson.phone_number}}" class="pt-1" formControlName="phone">
                            <ng-container *ngFor="let opt of form_fields_list">
                                <mat-option [value]="opt.id" *ngIf="opt.key === 'NUMBER'">{{opt.label}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col">
                    <label class="fw-600 mb-2 text-primary">{{formleadJson.title}}</label><br>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select placeholder="{{formleadJson.title}}" class="pt-1" formControlName="title">
                            <ng-container *ngFor="let opt of form_fields_list">
                                <mat-option [value]="opt.id" *ngIf="opt.key === 'TEXT'">{{opt.label}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="w-100"></div>
                <ng-container *ngIf="CRMForm.value.is_card">



                    <div class="col">
                        <label class="fw-600 mb-2 text-primary">{{formleadJson.budget}}</label><br>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-select placeholder="{{formleadJson.budget}}" class="pt-1" formControlName="budget">
                                <ng-container *ngFor="let opt of form_fields_list">
                                    <mat-option [value]="opt.id" *ngIf="opt.key === 'NUMBER'">{{opt.label}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- <div class="col">
                        <label class="fw-600 mb-2 text-primary">{{formleadJson.due_date}}</label><br>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-select placeholder="{{formleadJson.due_date}}" class="pt-1" formControlName="due_date">
                                <ng-container *ngFor="let opt of form_fields_list">
                                    <mat-option [value]="opt.id" *ngIf="opt.key === 'DATETIME'">{{opt.label}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div> -->
                </ng-container>

            </div>
            <div class="mt-2 mb-5 ">
                <button class="btn btn-primary" (click)="onCRMFormSubmit()">{{formleadJson.btn_save}}</button>
            </div>
        </ng-container>
    </form>
</div>