import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  user_details = JSON.parse(sessionStorage.getItem('currentUser')!);
  constructor(private http: HttpClient) { }

  //timeline
  getUserTimeline(id: string, page: any) {
    return this.http.get(`${environment.urlPrefix}${constant.auditlog}?source_id=${id}&page=${page}&offset=10`);
  }
  //files
  getAllFiles(id: string, page: any) {
    return this.http.get(`${environment.urlPrefix}${constant.files}?page=${page}&limit=10&source_id=${id}`);
  }
  fileUploadWorke(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.files}`, body);
  }
  fileUpdateWorke(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.files}${body.id}/`, body);
  }
  deleteFile(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.files}${id}/`);
  }
  //notes
  getAllNotes(id: string, page: number) {
    return this.http.get(`${environment.urlPrefix}${constant.notes}?source_id=${id}&page=${page}&offset=10`);
  }
  createNotes(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.notes}`, body);
  }
  updateNotes(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.notes}${body.id}/`, body);
  }
  deleteNotes(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.notes}${id}/`);
  }
  //activity
  getActivitysById(id: string, page: number) {
    return this.http.get(`${environment.urlPrefix}${constant.activity}?source_id=${id}&page=${page}&limit=10`);
  }
  createActivity(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.activity}`, body);
  }
  updateActivity(id: any, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.activity}${id}/`, body);
  }
  deleteActivty(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.activity}${id}/`);
  }

  getActivity() {
    return this.http.get(`${environment.urlPrefix}${constant.auditlog}?user_id=${this.user_details.data.id}`);
  }
}
