<div class="conversations h-100 p-3 mt-3">
    <div class="start-conversation text-center">
        <p class="bg-conversation px-3 py-2 border-radius-8 d-inline-flex">{{date | date:'mediumDate'}}</p>
        <p class="text-secondary">{{convJSON.start_conversation}}</p>
    </div>
    <div
        class="conversation-select bg-conversation d-flex align-items-center justify-content-between mt-5 p-3 border-radius-15">
        <mat-select class="medium-filter border-radius-15 py-2 px-3 bg-primary" [(ngModel)]="medium_type">
            <!-- <mat-option value="SMS">SMS</mat-option> -->
            <mat-option value="Mail">Mail</mat-option>
        </mat-select>
        <input type="text" class="border-0 py-2 w-60 bg-conversation" style="outline: none;"
            placeholder="Start conversation" [(ngModel)]="send_text">
        <img class="cursor" src="../../../../../assets/images/_link.svg" alt="attachment">
        <button class="btn btn-primary d-flex align-items-center border-radius-15 py-2 for-disabled"
            [disabled]="!send_text">
            {{convJSON.send_btn_label}}
            <mat-icon class="ms-2" style="font-size: 22px;">send</mat-icon>
        </button>
    </div>
</div>