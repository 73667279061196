import { Component, Input, OnInit, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FORMTABS, SHAREFORM } from 'src/app/core/data/form_builder_json';
import { FormBuilderService } from 'src/app/core/service/form-builder.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { SettingsService } from 'src/app/core/service/settings.service';


import {
  BUTTON_FIELD,
  CHECKBOX_FIELD,
  FILE_FIELD,
  RADIO_FIELD,
  SELECT_FIELD,
  SERVICE_SELECT,
  TEXTAREA_FIELD,
  TEXT_FIELD,
  DATE_FIELD
} from "../form-data";
import { AuthService } from 'src/app/core/service/auth.service';
import { MatTooltip } from '@angular/material/tooltip';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-form-share',
  templateUrl: './form-share.component.html',
  styleUrls: ['./form-share.component.scss']
})
export class FormShareComponent implements OnInit {

  activeId = 1;
  formtabsJson = FORMTABS[0];
  shareJson = SHAREFORM[0];
  organisation: string = JSON.parse(sessionStorage.getItem("currentUser") ?? "").data.organisation;
  organisationData: any;

  HTML_CODE = 'Loading';
  LINK = "Loading...";
  APPOINTMENT_CATEGORY: any = [];
  APPOINTMENT: any = [];
  STORE: any = {};
  PRODUCT_CATEGORY: any = [];
  PRODUCT: any = [];

  @Input() formId = '';
  @ViewChild('tp') _matTooltip!: MatTooltip;
  @ViewChild('htmltp') _matTooltipHtml!: MatTooltip;
  env = '';
  constructor(
    private _formServices: FormBuilderService,
    private _setUpService: SetUpService,
    private _settingService: SettingsService,
    @Inject(DOCUMENT) private document: Document
  ) { }


  ngOnInit(): void {

    this._settingService.getCompanySettings().subscribe((resp: any) => {
      this.organisationData = resp.data;
      this.LINK = `https://${this.organisationData?.slug}.worke.io/form/${this.formId}`;
      this.getFormFields(this.formId);
      this.initialGetDataCall();
    });
    const host = this.document.location.hostname.split('.')[0];
    if (host === 'stage') {
      this.env = 'staging';
    } else {
      this.env = 'services';
    }
  }

  getFormFields(id: string) {
    this._formServices.getFormFields(id).subscribe((resp: any) => {
      // setTimeout(() => {
      this.generateHtml(resp.data);
      // }, 5000);
    });
  }

  generateHtml(form_fields: any) {
    this.HTML_CODE = `<form id='worke-form-${this.formId}' style="max-width: 450px;" onsubmit="WorkeSubmissionHandling(event, 'worke-form-${this.formId}','${this.env}')">
<p id="worke-success-message"
      style="display: none; color:white; margin: 20px 15px; font-family: Trebuchet MS; font-size: 20px; background-color: rgb(75, 181, 67); padding: 10px; border-radius: 5px;">
      Form Submitted Successfully
</p>
<input type='text' value="${this.formId}"  style="display: none" name="form_id" required />
<input type='text' value="${this.organisation}"  style="display: none" name="organisation_id" required />`;

    form_fields.forEach((ele: any) => {
      let temp = "";
      if (ele.type === "TEXT") {
        temp = TEXT_FIELD(ele.label, ele.id, ele.key.toLowerCase());
        this.HTML_CODE += temp;
        return;
      }
      if (ele.type === "DATETIME") {
        temp = DATE_FIELD(ele.label, ele.id);
        this.HTML_CODE += temp;
        return;
      }
      if (ele.type === "TEXT_AREA") {
        temp = TEXTAREA_FIELD(ele.label, ele.id);
        this.HTML_CODE += temp;
        return;
      }
      if (ele.type === "CHECK_BOX") {
        temp = CHECKBOX_FIELD(ele.label, ele.options, ele.id);
        this.HTML_CODE += temp;
        return;
      }
      if (ele.type === "RADIO_BUTTON") {
        temp = RADIO_FIELD(ele.label, ele.options, ele.id);
        this.HTML_CODE += temp;
        return;
      }
      if (ele.type === "SELECT_DROPDOWN") {
        temp = SELECT_FIELD(ele.label, ele.options, ele.id);
        this.HTML_CODE += temp;
        return;
      }
      if (ele.type === "FILE") {
        temp = FILE_FIELD(
          ele.label,
          ele.id,
          ele.domain
        );
        this.HTML_CODE += temp;
        return;
      }
      if (ele.type === "API") {
        let API_LIST = [];
        switch (ele.key) {
          case "APPOINTMENT_CATEGORIES":
            API_LIST = this.APPOINTMENT_CATEGORY;
            break;
          case "APPOINTMENT":
            API_LIST = this.APPOINTMENT;
            break;
          case "PRODUCT_CATEGORIES":
            API_LIST = this.PRODUCT_CATEGORY;
            break;
          case "PRODUCT":
            API_LIST = this.PRODUCT;
            break;
          default:
            break;
        }
        temp = SERVICE_SELECT(
          ele.label,
          ele.options,
          ele.id,
          API_LIST
        );
        this.HTML_CODE += temp;
        return;
      }

    });
    this.HTML_CODE += `
    ${BUTTON_FIELD()}
</form>`;
    this.HTML_CODE += `\n<script src="https://worke.io/form.js"></script>`;

  }

  initialGetDataCall() {  // [TODO]: should be improved, as we are making unwanted api calls over here.
    this.getAppointmentCategories();
    this.getAppointment();
    this.getStore();
  }

  getAppointmentCategories() {
    this._setUpService.getAllCategories().subscribe((resp: any) => {
      this.APPOINTMENT_CATEGORY = resp.data;
    });
  }

  getAppointment() {
    this._setUpService.getAllConsulations().subscribe((resp: any) => {
      this.APPOINTMENT = resp.data;

    });
  }

  getStore() {
    this._setUpService.getStore().subscribe((resp: any) => {
      this.STORE = resp.data;
      this.getProductCategories();
      this.getProduct();
    });
  }

  getProductCategories() {
    this._setUpService.getProductCategories(this.STORE.id).subscribe((resp: any) => {
      this.PRODUCT_CATEGORY = resp.data;

    });

  }

  getProduct() {
    this._setUpService.getProduct(this.STORE.id).subscribe((resp: any) => {
      this.PRODUCT = resp.data;

    });
  }

  getName(arr: any, id: string) {
    return arr.find((item: any) => item.id === id).name;
  }

  onClipboardCopy(e: any) {
    setTimeout(() => {
      this._matTooltip.show();
      this._matTooltip.message = e ? 'Copied!' : 'Copy to clipboard';
    });
  }

  onClipboardCopyHtml(e: any) {
    setTimeout(() => {
      this._matTooltipHtml.show();
      this._matTooltipHtml.message = e ? 'Copied!' : 'Copy to clipboard';
    });
  }
}
