import { Injectable } from '@angular/core';
import { Observable, fromEvent, map, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InternetService {
  isOnline$: Observable<boolean>;

  constructor() {
    this.isOnline$ = fromEvent(window, 'online').pipe(
      map(() => true),
      startWith(navigator.onLine)
    );
  }
}
