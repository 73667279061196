import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentModalComponent } from '../appointment-modal/appointment-modal.component';
import { HomeService } from 'src/app/core/service/home.service';
import { environment } from 'src/environments/environment';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-bookedappointment-detail',
  templateUrl: './bookedappointment-detail.component.html',
  styleUrls: ['./bookedappointment-detail.component.scss']
})
export class BookedappointmentDetailComponent implements OnInit {
  presignedurl = environment.preSendUrl;
  appointment_status = 'pending';
  tabs = [
    {
      id: 1,
      title: 'Snooze'
    },
    {
      id: 2,
      title: 'Files'
    },
    {
      id: 3,
      title: 'Questionnaire'
    }
  ];
  files: File[] = [];
  @Input() appointmentId: any;
  appointmentdata: any;
  locationsDetails: any;
  questionnaire_list: any[]=[];
  display_no_data = false;


  constructor(private modalService: NgbModal, private homeService: HomeService, private settingService: SettingsService) { }

  ngOnInit() {
    this.getAppointmentbyId();
  }

  getAppointmentbyId() {
    this.display_no_data = false;
    this.homeService.getAppointmentById(this.appointmentId).subscribe((resp: any) => {
      this.appointmentdata = resp.data;
      this.questionnaire_list = this.appointmentdata.questionnaire;
      if(this.questionnaire_list.length === 0) this.display_no_data = true; 
      if (this.appointmentdata.org_address_id) this.getLocationData();
    });
  }

  getLocationData() {
    this.settingService.getLocationSettingsById(this.appointmentdata.org_address_id).subscribe((resp: any) => {
      this.locationsDetails = resp.data;
    });
  }

  reschedule() {
    const modalRef = this.modalService.open(AppointmentModalComponent, { size: 'lg' });
    modalRef.componentInstance.appointmentId = this.appointmentId;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry === 'success') this.getAppointmentbyId();
    });
  }
  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  onStatusChange() {
    this.homeService.putAppointmentStatus(this.appointmentId, { appointment_status: this.appointmentdata.appointment_status }).subscribe((resp: any) => {
      this.getAppointmentbyId();
    });
  }
}
