import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { mobile_json } from 'src/app/core/data/mobile_json';
import { STAFF, TEAMS } from 'src/app/core/data/settings_json';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { DayOffModelComponent } from '../day-off-model/day-off-model.component';
import { WorkHoursComponent } from '../work-hours/work-hours.component';
import { BadgesComponent } from '../../shared/badges/badges.component';

@Component({
  selector: 'app-users-control',
  templateUrl: './users-control.component.html',
  styleUrls: ['./users-control.component.scss']
})
export class UsersControlComponent implements OnInit {
  errorjson = ERRORJSON[0];
  staffjson = STAFF[0];
  teamjson = TEAMS[0];
  working_hours_model!: NgbModalRef;
  badges_model!: NgbModalRef;
  activeId = 1;
  table_cols = ['Name', 'Email', 'Department', 'Role', 'Action'];//'Last Login',
  day_table_cols = ['Day Off Title', 'Staff Name', 'Date Range', 'Status', 'Action'];
  staffListArray: any[] = [];
  templateStaffListType = true;
  addStaffForm: any;
  submited = false;
  mobilejson = mobile_json;
  public bankFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  rolesList: any[] = [];
  teamsList: any[] = [];
  table_teams_cols = ['Department', 'Users', 'Action'];
  passwordBox = false;
  templateTeamsList = true;
  isteamSubmitted = false;
  addTeamForm: any;
  selected_group: any;
  teamsSelectedStaff: any[] = [];
  @ViewChild('myFirstInput') myFirstInput!: ElementRef;
  @ViewChild('myFirstStaffInput') myFirstStaffInput!: ElementRef;
  pageCount = 1;
  hide = true;
  loggedInUser: any;
  branches: any;
  daysListArray: any[] = [
    {
      title: "Health Issues",
      date: new Date(),
      staff_name: "Rockey",
      status: true
    }
  ];

  constructor(private formBuilder: FormBuilder, private settingService: SettingsService,
    private modelservice: NgbModal, private confirmationMsz: ConfirmationMszService) { }

  ngOnInit() {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.filteredBanks.next(this.mobilejson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.getTeams(false);
    this.getStaff();
  }

  tabChange(ev: any) {
    this.activeId = ev.nextId;
    if (this.activeId === 1) {
      this.templateTeamsList = true;
    } else if (this.activeId === 2) {
      this.templateStaffListType = true;
    }
  }

  getStaff() {
    this.settingService.getStaff_infoByPagination(this.pageCount).subscribe((resp: any) => {
      this.staffListArray = resp.data;
    });
  }

  page(e: PageEvent) {
    if (e.pageIndex > e.previousPageIndex!) {
      this.pageCount = e.pageIndex + 1;
    } else if (e.pageIndex < e.previousPageIndex!) {
      this.pageCount -= 1;
    }
    this.getStaff();
  }

  addStaff(type: boolean, data?: any) {
    this.templateStaffListType = type;
    this.getRoles(true);
    this.getTeams(true);
    if (!this.templateStaffListType) this.InitilizeSaff_Form();
    if (data) {
      this.settingService.getStaff_infoById(data.id).subscribe((resp: any) => {
        this.InitilizeSaff_Form(resp.data);
      });
    }
    setTimeout(() => {
      this.myFirstStaffInput.nativeElement.focus();
    }, 0);
  }

  InitilizeSaff_Form(data?: any) {
    this.submited = false;
    this.addStaffForm = this.formBuilder.group({
      id: [data ? data.id : ''],
      fname: [data ? data.fname : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z_\s]{1,}$/)]],
      lname: [data ? data.lname : '', [Validators.pattern(/^[A-Za-z][A-Za-z_\s]{1,}$/)]],
      email: [{ value: data ? data.email : '', disabled: data?.id ? true : false }, Validators.compose([Validators.required, Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)])],//eslint-disable-line 
      phone_number: [data ? data.phone_number : '', Validators.compose([Validators.required, Validators.pattern("[0-9]+"), Validators.minLength(6), Validators.maxLength(12)])],
      ccode: [data ? data.ccode : '+91', [Validators.required]],
      password: [data ? data.password : '', Validators.compose([Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[^_`{|}~]{5,}$/)])],
      password_type: [''],
      role_id: [data ? data.role : '', Validators.required],
      department: [data ? data.department : '', Validators.required],
      designation: [data ? data.designation : ''],
      specialisation: [data ? data.specialisation : ''],
      qualification: [data ? data.qualification : ''],
      gender: [data ? data.gender : ''],
      age: [data ? data.age : ''],
      badge: ['Most popular'],
      enable_for_booking: [data ? data.enable_for_booking : true],
      locations: [data ? data.locations : '', Validators.required]
    });
    this.getLocationDetails();
    if (data?.id) {
      this.addStaffForm.controls['password'].clearValidators();
      this.addStaffForm.controls['password'].updateValueAndValidity();
    }
  }

  get f() {
    return this.addStaffForm.controls;
  }

  addnewStaffSubmit() {
    this.submited = true;
    if (this.addStaffForm.valid) {
      if (this.addStaffForm.value.id) {
        this.settingService.putStaffInfo(this.addStaffForm.value.id, this.addStaffForm.value).subscribe(() => {
          this.templateStaffListType = true;
          this.addStaffForm.reset();
          this.getStaff();
          this.settingService.getStaff_info('force').subscribe(() => { //@typescript-eslint/no-empty-function
          });
        });
      } else {
        this.settingService.postStaffInfo(this.addStaffForm.value).subscribe(() => {
          this.templateStaffListType = true;
          this.addStaffForm.reset();
          this.getStaff();
          this.settingService.getStaff_info('force').subscribe(() => { //@typescript-eslint/no-empty-function
          });
        });
      }
    }
  }

  getLocationDetails() {
    this.settingService.getLocationSettings().subscribe((resp: any) => {
      this.branches = resp.data;
    });
  }

  onStaffStatusChange(data: any) {
    const body = {
      status: data.status
    };
    this.settingService.putStaffInfo(data.id, body).subscribe(() => {//@typescript-eslint/no-empty-function
    });
  }

  addDayoff() {
    this.modelservice.open(DayOffModelComponent);
  }

  protected filterBanks() {
    if (!this.mobilejson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.mobilejson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.mobilejson.filter(bank => (bank.country_code.toLowerCase().indexOf(search) > -1) ||
        bank.country.toLowerCase().indexOf(search) > -1)
    );
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  isPassword() {
    // if (this.addStaffForm.value.password_type === "auto") {
    //   this.passwordBox = false;
    //   this.addStaffForm.controls['password'].clearValidators();
    //   this.addStaffForm.controls['password'].updateValueAndValidity();
    // } else {
    //   this.addStaffForm.controls['password'].setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[^_`{|}~]{5,}$/)]));
    //   this.addStaffForm.controls['password'].updateValueAndValidity();
    //   this.passwordBox = true;
    // }
  }

  addTeam(type: boolean, data?: any) {
    this.templateTeamsList = type;
    if (!this.templateTeamsList) this.InitilizeTeam_Form(data);
    if (data) {
      // this.settingService.getStaff_infoById(data.id).subscribe((resp: any) => {
      //   this.InitilizeTeam_Form(resp.data);
      // })
    }
    setTimeout(() => {
      this.myFirstInput.nativeElement.focus();
    }, 0);
  }

  InitilizeTeam_Form(data?: any) {
    this.addTeamForm = this.formBuilder.group({
      id: [data ? data.id : ''],
      name: [data ? data.name : '', Validators.required],
      description: [data ? data.description : '']
    });
    if (data) {
      this.teamsSelectedStaff = this.staffListArray.filter(item => {
        return data.users.some((ele: any) => {
          return ele === item.id;
        });
      });
    }
  }

  get teamForm() {
    return this.addTeamForm.controls;
  }

  addnewTeamSubmit() {
    this.isteamSubmitted = true;
    const staff: any[] = [];
    this.teamsSelectedStaff.forEach((ele) => {
      staff.push(ele.id);
    });
    const body = {
      // staff: staff,
      ...this.addTeamForm.value
    };
    if (this.addTeamForm.valid) {
      this.templateTeamsList = true;
      if (this.addTeamForm.value.id) {
        this.settingService.putTeams(body).subscribe(resp => {
          this.getTeams(false);
        });
      } else {
        this.settingService.postTeams(body).subscribe(resp => {
          this.getTeams(false);
        });
      }
    }
  }

  getTeams(active: boolean) {
    this.settingService.getTeams(active).subscribe((resp: any) => {
      this.teamsList = resp.data;
    });
  }

  confirmTeams(deleteId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteTeams(deleteId);
      }
    });
  }

  deleteTeams(id: string) {
    this.settingService.deleteTeams(id).subscribe(() => {
      this.getTeams(false);
    });
  }

  openStaffModel(model: any) {
    if (this.teamsSelectedStaff) this.selected_group = this.teamsSelectedStaff;
    this.modelservice.open(model, { size: 'md' });
  }

  addToList() {
    this.teamsSelectedStaff = this.staffListArray.filter(item => {
      return this.selected_group.some((ele: any) => {
        return ele === item.id;
      });
    });
    this.modelservice.dismissAll();
  }

  getRoles(active: boolean) {
    this.settingService.getRolesPermissions(active).subscribe((resp: any) => {
      this.rolesList = resp.data;
    });
  }

  openWorkingHoursModel() {
    this.working_hours_model = this.modelservice.open(WorkHoursComponent, { size: 'lg', scrollable: true });
  }

  manageBadges() {
    this.badges_model = this.modelservice.open(BadgesComponent, { scrollable: true, windowClass: 'badge-modal' });
    this.working_hours_model = this.modelservice.open(WorkHoursComponent, { size: 'lg', scrollable: true});
  }
}
