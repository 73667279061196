import { Component, OnInit } from '@angular/core';
import { CONVERSATIONSJSON } from '../../../../core/data/crm_modal_json';

@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss']
})
export class ConversationsComponent implements OnInit {
  convJSON = CONVERSATIONSJSON[0];
  date!: Date;
  medium_type = 'Mail';
  send_text: any;


  ngOnInit(): void {
    this.date = new Date();
  }
}
