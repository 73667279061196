export const COMPANYSETTINGS = [
  {
    heading: 'Company',
    company_name: 'Company Name',
    email: 'Company Email',
    domain: 'Company Website',
    country: 'Country',
    currency: 'Currency',
    timezone: 'Time Zone',
    ccode: 'Default phone country code',
    dateformat: 'Date Format',
    timeformat: 'Time Format',
    language: 'Language',
    dsp: 'Description',
    social_links: 'Socials',
    social_links_website: 'Your Website url here',
    social_links_insta: 'Your Instagram url here',
    social_links_twitter: 'Your Twitter url here',
    social_links_linkedin: 'Your Linkedin url here',
    social_links_utube: 'Your Youtube url here',
    slot_duration: 'Default Time slot step',
    booking_settings: 'Booking Settings',
    dropzone_label_1: "Drag a image here or",
    dropzone_label_2: "Browse",
    dropzone_label_3: "for a image to upload",
    compony_logo: 'Logo',
    generate: 'Generated',
    business_url: 'Business URL',
    bussinessValidationText: "Your business name can be a combination of small letters and numbers.",
    slot: 'Slot',
    save_btn: 'Save',
    save_proceed_btn: 'Save & Proceed'
  }
];

export const LOCATIONS = [
  {
    heading: 'Locations',
    branch_location: 'Branch Location',
    add_btn: 'Add',
    col_name: 'Name',
    col_address: 'Address',
    col_status: 'Status',
    col_action: 'Action',
    add_location: 'Location',
    update_btn: 'Update',
    branch_name: 'Branch Name',
    check_box_default: 'Mark as default branch',
    save_btn: 'Save',
    proceed_btn: 'Proceed',
    close_btn: 'Close'
  }
];

export const STAFF = [
  {
    heading: 'Users',
    add_btn: 'Staff Member',
    new: 'New',
    update: 'Update',
    enter_placeholder: 'Enter',
    field_name: 'First Name',
    field_lastname: 'Last Name',
    field_email: 'Email',
    field_number: 'Phone Number',
    field_role: 'Roles',
    field_team: 'Department',
    field_location: 'Locations',
    field_password: 'Password',
    field_badge: 'Employee Badge',
    field_booking: 'This user is available for booking from booking website',
    text: "Your password must be at least 5 characters long and include a combination of capital letters, small letters, numbers, and symbols.",
    field_designation: 'Designation',
    field_specialisation: 'Specialisation',
    field_qualification: 'Qualification',
    field_gender: 'Gender',
    field_age: 'Age',
    save_btn: 'Save',
    close_btn: 'Close'
  }
];

export const TEAMS = [
  {
    heading: 'Departments',
    department: 'Department',
    add_btn: 'Add New',
    update_btn: 'Update',
    edit_btn: 'Edit',
    delete_btn: 'Delete',
    enter_placeholder: 'Enter',
    field_name: 'Department Name',
    field_people: 'Add people',
    field_member_list: 'Members List',
    field_member: 'Select member',
    select_placeholder: 'Select',
    save_btn: 'Save',
    close_btn: 'Close'
  }
];

export const dayoffJson = [
  {
    heading: 'Day Off',
    title: 'Title',
    date_range: 'Day Range',
    update_btn: 'Update',
    save_btn: 'Save',
    close_btn: 'Close'
  }
];