import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { CommonService } from 'src/app/core/service/common.service';
import { FILESJSON } from '../../../../core/data/crm_modal_json';
import { PageEvent } from '@angular/material/paginator';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { environment } from 'src/environments/environment';
import { CrmService } from 'src/app/core/service/crm.service';
@Component({
  selector: 'app-files-modal',
  templateUrl: './files-modal.component.html',
  styleUrls: ['./files-modal.component.scss']
})
export class FilesModalComponent implements OnInit {
  @Input() is_task!: string;
  @Input() details: any;
  filesJSON = FILESJSON[0];
  selected_id!: string;
  selected_type!: string;
  files: File[] = [];
  fileName!: string;
  CurrentPage = 1;
  filesListArray: any = [];
  currentFilesList: any = [];
  presignedurl = environment.preSendUrl;
  currentPage = 0;
  pageEvent!: PageEvent;
  visibility = false;
  count!: number;
  show_loader = false;
  source_name: any;

  constructor(private route: ActivatedRoute, private service: CommonService, private awsUtilService: AwsUtilsService, private confirmationMsz: ConfirmationMszService, private crm_service: CrmService) { }

  ngOnInit(): void {
    if (this.is_task === 'task') {
      this.selected_id = this.details;
      this.selected_type = this.is_task;
      this.getAllFilesList(this.selected_id, 1);
    } else {
      this.route.queryParams.subscribe((params: any) => {
        this.selected_id = params.id;
        this.selected_type = params.showtype;
        if (this.selected_id) {
          this.getAllFilesList(this.selected_id, 1);
          if (this.selected_type === 'contact') this.getContactName(this.selected_id);
          else if (this.selected_type === 'lead') this.getLeadsName(this.selected_id);
          else if (this.selected_type === 'company') this.getCompanyName(this.selected_id);
        }
      });
    }
  }

  getAllFilesList(id: string, page: any) {
    this.service.getAllFiles(id, page).subscribe({
      next: (resp: any) => {
        // console.log("files - ", resp);
        this.count = resp.count;
        this.filesListArray = resp.data;
      }
    });
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getAllFilesList(this.selected_id, this.currentPage);
  }

  onSelect(event: any) {
    this.files.push(...event.addedFiles);
    // console.log("uploded file - ", this.files);
    // if (this.files && this.files.length > 1) {
    //   this.onRemove(this.files[0]);
    // }
  }

  onUpload() {
    // console.log("entered upload function");
    if (!this.files[0]) return;
    this.show_loader = true;
    this.awsUtilService.getUploadFileLink(this.files[0].type).subscribe((resp) => {
      console.log("first api - ", resp);
      this.fileName = resp.data.file_name;
      this.awsUtilService.uploadFile(this.files[0], resp.data.url).subscribe(() => {//@typescript-eslint/no-empty-function
        this.fileSubmit();
      }, () => {
        this.files = [];
        this.show_loader = false;
      });
    }, () => {
      this.files = [];
      this.show_loader = false;
    });
  }

  fileSubmit() {
    const payload = {
      source_name: this.source_name,
      source_id: this.selected_id,
      source_type: this.selected_type,
      name: this.files[0].name,
      is_visible: this.visibility,
      media_url: this.fileName
    };

    this.service.fileUploadWorke(payload).subscribe(() => {
      this.show_loader = false;
      this.getAllFilesList(this.selected_id, 1);
      this.files = [];
    }, () => {
      this.files = [];
    });
  }

  toggleVisibility(e: any, data: any) {
    this.visibility = e.target.checked;
    this.updateFile(data, this.visibility);
  }

  updateFile(data: any, visibility: boolean) {
    const updatePayload = {
      id: data.id,
      source_id: data.source_id,
      name: data.name,
      source_type: data.source_type,
      is_visible: visibility,
      media_url: data.media_url
    };
    this.service.fileUpdateWorke(updatePayload).subscribe(() => { //@typescript-eslint/no-empty-function
    });
  }

  deleteFile(id: string) {
    this.service.deleteFile(id).subscribe(() => {
      this.getAllFilesList(this.selected_id, 1);
    });
  }

  confirmDelete(fileId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteFile(fileId);
      }
    });
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  getContactName(id: string) {
    this.crm_service.getContactById(this.selected_id).subscribe({
      next: (resp: any) => {
        this.source_name = resp.data[0].first_name + ' ' + resp.data[0].last_name;
      }
    });
  }

  getLeadsName(id: string) {
    this.crm_service.getLeadById(id).subscribe({
      next: (resp: any) => {
        this.source_name = resp.data[0].requirement_title;
      }
    });
  }

  getCompanyName(id: string) {
    this.crm_service.getCompanyById(id).subscribe({
      next: (resp: any) => {
        this, this.source_name = resp.data[0].name;
      }
    });
  }
}
