import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ADDCONTACTJSON } from 'src/app/core/data/contacts_json';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { CrmService } from 'src/app/core/service/crm.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { ReplaySubject, Subject, finalize, takeUntil } from 'rxjs';
import { countryData } from 'src/app/core/data/countryData';
import { AuthService } from 'src/app/core/service/auth.service';
@Component({
  selector: 'app-contacts-modal',
  templateUrl: './contacts-modal.component.html',
  styleUrls: ['./contacts-modal.component.scss']
})
export class ContactsModalComponent implements OnInit {
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  @Input() contact: any = {};
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  countryjson = countryData;
  title = 'FormArray Example in Angular Reactive forms';
  submitted = false;
  contactForm!: FormGroup;
  addcontact = ADDCONTACTJSON[0];
  errorJson = ERRORJSON[0];
  staffMembers: any = [];
  companiesListArray: any = [];
  info: any = {};
  loading = false;
  @ViewChild('myInput') myInput!: ElementRef;
  loggedInUser: any;
  tagsListArray: any[]=[];

  constructor(public ngbActiveModal: NgbActiveModal, private service: CrmService, private settingsService: SettingsService,
    private toaster: ToastrService, private authService: AuthService) { }

  ngOnInit() {
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.initlizeContactForm();
    this.getCompaniesList();
    this.organisationMembersList();
    this.getTags();
    if (this.contact?.id) {
      this.contactForm.controls['email'].disable();
      this.contactForm.controls['source'].disable();
    } else {
      this.info['owner_info'] = {
        fname: this.loggedInUser.data.fname,
        lname: this.loggedInUser.data.lname
      };
    }
    // this.getExtraFieldsData();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.myInput.nativeElement.focus();
    }, 0);
  }

  getExtraFieldsData() {
    if (!this.contact) {
      this.getCustomFieldsData();
    } else {
      if (this.contact.custom_contact_fields) {
        for (const data of this.contact.custom_contact_fields) {
          // console.log(data);
          this.addfieldsData(data);
        }
      }
    }
  }

  preventDefault() {
    return false;
  }

  initlizeContactForm() {
    this.contactForm = new FormGroup({
      id: new FormControl(this.contact ? this.contact.id : ''),
      first_name: new FormControl(this.contact?.first_name ? this.contact.first_name : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9_\s]{1,}$/)]),
      last_name: new FormControl(this.contact?.last_name ? this.contact.last_name : '', [Validators.pattern(/^[A-Za-z][A-Za-z_\s]{1,}$/)]),
      email: new FormControl(this.contact?.email ? this.contact.email : '', Validators.compose([Validators.required, Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)])),//eslint-disable-line 
      company: new FormControl(this.contact?.company ? this.contact.company : ''),
      phone_number: new FormControl(this.contact?.phone_number ? this.contact.phone_number : '', Validators.compose([Validators.required, Validators.pattern("[0-9]+"), Validators.minLength(6), Validators.maxLength(12)])),
      tags: new FormControl(this.contact ? this.contact.tags : ''),
      owner: new FormControl(this.contact?.owner ? this.contact.owner : this.loggedInUser.data.id, [Validators.required]),
      assignee: new FormControl(this.contact?.assignee ? this.contact.assignee : []),
      source: new FormControl(this.contact?.source ? this.contact.source : 'manual'),
      custom_contact_fields: new FormArray([]),
      ccode: new FormControl(this.contact?.ccode ? this.contact.ccode : this.authService.getCompanyData().ccode, [Validators.required])
    });
  }

  get custom_contact_fields(): FormArray {
    return this.contactForm.get("custom_contact_fields") as FormArray;
  }

  custom_fields(data: any): FormGroup {
    return new FormGroup({
      label: new FormControl(data ? data.label : ''),
      data_type: new FormControl(data ? data.data_type : ''),
      options: new FormControl(data ? data.options : ''),
      data: new FormControl(data ? data.data : ''),
      is_active: new FormControl(data ? data.is_active : true),
      id: new FormControl(data ? data.id : '')
    });
  }

  addfieldsData(data: any) {
    this.custom_contact_fields.push(this.custom_fields(data));
  }

  get f() {
    return this.contactForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // console.log(this.contactForm, "contactforms", this.contactForm.value);
    if (this.contactForm.valid) {
      this.loading = true;
      const assignee_departments: any = [];

      if (this.contactForm.value.assignee.length !== 0) {
        this.staffMembers.forEach((item: any) => {
          this.contactForm.value.assignee.filter((assigne: any) => {
            if (assigne === item.id) {
              assignee_departments.push(item.department_info.id);
            }
          });
        });
      }

      const owner_department = this.staffMembers.filter((member: any) => member.id === this.contactForm.value.owner);

      if (this.contactForm.value.id) {
        let info_body;
        if (Object.keys(this.info).length === 0) {
          info_body = this.contact.info;
        } else {
          info_body = this.info;
        }

        this.service.updateContact({ ...this.contactForm.value, info: info_body, email: this.contactForm.controls["email"].value, source: this.contactForm.controls["source"].value, assignee_department: assignee_departments ? assignee_departments : [], owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : '' }).pipe(finalize(() => this.loading = false)).subscribe({
          next: () => {
            this.info = {};
            this.passBack();
            this.ngbActiveModal.dismiss();
          }
        });
      } else {
        this.service.createContact({ ...this.contactForm.value, info: this.info, assignee_department: assignee_departments ? assignee_departments : [], owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : '' }).pipe(finalize(() => this.loading = false)).subscribe((resp: any) => {
          if (resp.status === 200) {
            this.toaster.error(resp.message);
          } else {
            this.toaster.success(resp.message);
            this.passBack();
            this.ngbActiveModal.dismiss();
          }
        }, (err: any) => {
          this.toaster.error(err.message);
        });
      }
    }
  }

  getCompaniesList() {
    this.service.getCompaniesDropdownList().subscribe({
      next: (resp: any) => {
        this.companiesListArray = resp.data;
      }
    });
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  getCustomFieldsData() {
    this.service.getCustomContactFields().subscribe({
      next: (resp: any) => {
        // console.log("custom fields - ", resp.Data);
        if (resp.Data) {
          for (const data of resp.data) {
            // console.log(data);
            this.addfieldsData(data);
          }
        }
      }
    });
  }

  // onCompanySelect(company: any) {
  //   this.info['company_info'] = company;
  // }

  onOwnerSelect(owner: any) {
    this.info['owner_info'] = {
      fname: owner.fname,
      lname: owner.lname
    };
  }

  passBack() {
    this.passEntry.emit(true);
  }

  //*******************************Search filters START******************************************** *//
  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

  getTags() {
    this.settingsService.getTags().subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }
}
