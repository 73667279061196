export const LEADSJSON = [
  {
    heading_label: 'Enquiries',
    Filter_label: 'Filters',
    search_placeholder: 'Search',
    label_open_enquries: 'Open Enquiries',
    label_lost_enquries: 'Lost Enquiries',
    label_junk_enquries: 'Junk Enquiries',
    // back_label: 'Back',
    label_add_lead: 'Add Enquiry',
    modal_heading: 'Add New Enquiry',
    btn_cancel: 'Close',
    btn_save: 'Save'
  }
];

export const ADDLEADJSON = [
  {
    heading_label: 'Add Enquiry',
    label_name: 'Enquiry Name',
    label_company: 'Company Name',
    label_phone: 'Mobile Number',
    btn_add_phone: 'Add Phone',
    label_email: 'Email',
    btn_add_email: 'Add Email',
    label_tag: 'Tag',
    btn_add_tag: 'Add Tag',
    Source: 'Channel',
    product: 'Product',
    label_owner: 'Owner',
    customize_text: 'Customize your enquiry form',
    save_btn: 'Save',
    cancel_btn: 'Close',
    btn_cancel: 'Cancel'
  }
];