<div class="p-4" *ngIf="!addCustomField">
  <div class="row py-3">
    <div class="col-md-6">
      <p class="py-2">Edit Deal fields</p>
    </div>
    <div class="col-md-6 text-end">
      <button class="d-flex align-items-center btn btn-primary float-end"
        (click)="AddField()"><mat-icon>add</mat-icon>&nbsp;Custom
        Fields</button>
    </div>
  </div>
  <div class="row">

    <div class="col-sm-6">
      <div class="example-container">
        <p>Deal Information</p>

        <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="todo" [cdkDropListConnectedTo]="[doneList]"
          class="example-list" (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of todo" cdkDrag>
            <label class="mb-0">
              <mat-icon>drag_indicator</mat-icon>
            </label>
            <span class="field-name">Deal Name</span>
          </div>
        </div>
        <p>Address Information</p>

        <div cdkDropList #todoList1="cdkDropList" [cdkDropListData]="todo1" [cdkDropListConnectedTo]="[doneList]"
          class="example-list" (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of todo1" cdkDrag>
            <label class="mb-0">
              <mat-icon>drag_indicator</mat-icon>
            </label>
            <span class="field-name">Deal Name</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="example-container">
        <p>Unused Fields</p>

        <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="done"
          [cdkDropListConnectedTo]="[todoList,todoList1]" class="example-list" (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of done" cdkDrag>
            <label class="mb-0">
              <mat-icon>drag_indicator</mat-icon>
            </label>
            <span class="field-name">Deal Name</span>
          </div>
        </div>
      </div>
      <div class="d-flex py-3 text-end next_btn justify-content-end">

        <button class="btn  border-1 bg-white fw-600 font-size-13 text-primary px-4 me-3">Cancel</button>
        <button class="btn  border-1 bg-primary fw-600 font-size-13 text-white px-4 me-3">Save</button>
      </div>
    </div>
  </div>
</div>
<div class="p-4" *ngIf="addCustomField">
  <div class="row py-3">
    <div class="col-md-12">
      <p class="mx-0">Edit Deal fields</p>
    </div>
    <div class="col-md-12">
      <mat-form-field appearance="outline">
        <input style="outline: none" matInput placeholder="Enter label Name" />
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <mat-form-field appearance="outline">
        <mat-select placeholder="Select a type">
          <mat-option *ngFor="let option of field_types" [value]="option.value">{{option.type}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-flex py-3 text-end justify-content-end">
      <button class="btn border-1 bg-white fw-600 font-size-13 text-primary px-4 me-3" (click)="backtocustomList()">Cancel</button>
      <button class="btn bg-primary fw-600 font-size-13 text-white px-4 me-3">Save</button>
    </div>
  </div>
</div>