<div class="modal-header">
    <h6 class="modal-title text-primary fw-600" *ngIf="!taskForm.value.id">Add Task</h6>
    <h6 class="modal-title text-primary fw-600" *ngIf="taskForm.value.id">Edit Task</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancelTaskModal()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="taskForm" (ngSubmit)="onTaskSubmit()" class="taskForm">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <label class="fw-600 mb-2 text-primary">Task Name <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder='Task Title' formControlName="name"
                        #myFirstInput />
                    <mat-error *ngIf="submitted && f['name'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['name'].errors['required']">Task Title
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-6 col-sm-12">
                <label class="fw-600 mb-2 text-primary">Description</label>
                <mat-form-field appearance="outline" class="w-100">
                    <textarea style="outline: none" matInput placeholder="Description" formControlName="description"
                        style="height:100px"></textarea>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <label class="fw-600 mb-2 text-primary">Priority <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="priority" placeholder="Priority">
                        <mat-option value="High" class="filteropt">High</mat-option>
                        <mat-option value="Low" class="filteropt">Low</mat-option>
                        <mat-option value="Medium" class="filteropt">Medium</mat-option>
                    </mat-select>
                    <mat-error class="error d-flex" *ngIf="submitted && f['priority'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['priority'].errors['required']">Priority
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <label class="fw-600 mb-2 text-primary">Due Date <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput [ngxMatDatetimePicker]="picker" [min]="minDate" placeholder="Choose a date & time"
                        formControlName="due_date">
                    <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
                    <mat-error class="error d-flex" *ngIf="submitted && f['due_date'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['due_date'].errors['required']">Due Date
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3" *ngIf="is_global">
                <label class="fw-600 mb-2 text-primary w-100">Related to <small class="text-danger">*</small></label>
                <mat-radio-group aria-label="Select an option" formControlName="related_to_type"
                    (change)="onRelatedTochange($event)">
                    <mat-radio-button value="contact">Contact</mat-radio-button>
                    <mat-radio-button value="lead">Enquiry</mat-radio-button>
                    <mat-radio-button value="company">Company</mat-radio-button>
                    <mat-radio-button value="project">Client Project</mat-radio-button>
                </mat-radio-group>
                <mat-error class="error d-flex" *ngIf="submitted && f['related_to_type'].errors">
                    <mat-icon class="font-size-12">info</mat-icon>
                    <span *ngIf="f['related_to_type'].errors['required']">Related to
                        {{errorJson.required}}</span>
                </mat-error>
            </div>


            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3" *ngIf="is_global !== true">
                <label class="fw-600 mb-2 text-primary">Related To<small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100" *ngIf="selected_type === 'project'">
                    <input style="outline: none" matInput placeholder='Related to' formControlName="source_name" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100" *ngIf="selected_type === 'company'">
                    <input style="outline: none" matInput placeholder='Related to' formControlName="source_name" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100" *ngIf="selected_type === 'contact'">
                    <input style="outline: none" matInput placeholder='Related to' formControlName="source_name" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100" *ngIf="selected_type === 'lead'">
                    <input style="outline: none" matInput placeholder='Related to' formControlName="source_name" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100" *ngIf="selected_type === 'deal'">
                    <input style="outline: none" matInput placeholder='Related to' formControlName="source_name" />
                </mat-form-field>
                <mat-error class="error d-flex" *ngIf="submitted && f['source_name'].errors">
                    <mat-icon class="font-size-12">info</mat-icon>
                    <span *ngIf="f['source_name'].errors['required']">Related to
                        {{errorJson.required}}</span>
                </mat-error>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3" *ngIf="taskForm.value.related_to_type">
                <label class="fw-600 mb-2 text-primary" *ngIf="taskForm.value.related_to_type === 'contact'">Select
                    Contact <small class="text-danger">*</small></label>
                <label class="fw-600 mb-2 text-primary" *ngIf="taskForm.value.related_to_type === 'lead'">Select Enquiry
                    <small class="text-danger">*</small></label>
                <label class="fw-600 mb-2 text-primary" *ngIf="taskForm.value.related_to_type === 'company'">Select
                    Company <small class="text-danger">*</small></label>
                <label class="fw-600 mb-2 text-primary" *ngIf="taskForm.value.related_to_type === 'project'">Select
                    Client Project <small class="text-danger">*</small></label>

                <div>
                    <mat-form-field appearance="outline" class="w-100"
                        *ngIf="selected_type === 'project' || taskForm.value.related_to_type === 'project'">
                        <mat-select placeholder='Related to' formControlName="source_id">
                            <mat-option>
                                <ngx-mat-select-search formControlName="search" placeholderLabel="Search"
                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option (onSelectionChange)="selectedSource(item.id,item.name, item)"
                                *ngFor="let item of related_to" value={{item.id}}>{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100"
                        *ngIf="selected_type === 'company' || taskForm.value.related_to_type === 'company'">
                        <mat-select placeholder='Related to' formControlName="source_id">
                            <mat-option>
                                <ngx-mat-select-search formControlName="search" placeholderLabel="Search"
                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option (onSelectionChange)="selectedSource(item.id, item.name, item)"
                                *ngFor="let item of related_to" value={{item.id}}>{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100"
                        *ngIf="selected_type === 'contact' || taskForm.value.related_to_type === 'contact'">
                        <mat-select placeholder='Related to' formControlName="source_id">
                            <mat-option>
                                <ngx-mat-select-search formControlName="search" placeholderLabel="Search"
                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                                (onSelectionChange)="selectedSource(item.id, item.first_name + ' ' + item.last_name, item)"
                                *ngFor="let item of related_to" value={{item.id}}>{{item.first_name}}
                                {{item.last_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100"
                        *ngIf="selected_type === 'lead' || taskForm.value.related_to_type === 'lead'">
                        <mat-select placeholder='Related to' formControlName="source_id">
                            <mat-option>
                                <ngx-mat-select-search formControlName="search" placeholderLabel="Search"
                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option (onSelectionChange)="selectedSource(item.id, item.requirement_title, item)"
                                *ngFor="let item of related_to"
                                value={{item.id}}>{{item.requirement_title}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error class="error d-flex" *ngIf="submitted && f['source_id'].errors">
                        <mat-icon class="font-size-12">info</mat-icon>
                        <span *ngIf="f['source_id'].errors['required']">Related to
                            {{errorJson.required}}</span>
                    </mat-error>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3" *ngIf="taskForm.value.source_id">
                <label class="fw-600 mb-2 text-primary">Task Stage <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="current_stage" placeholder="Stage">
                        <mat-option *ngFor="let stage of stagesList" [value]="stage.id"
                            class="filteropt">{{stage.name}}</mat-option>
                    </mat-select>
                    <mat-error class="error d-flex" *ngIf="submitted && f['current_stage'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['current_stage'].errors['required']">Stage
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>


            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3" *ngIf="taskForm.value.source_id">
                <label class="fw-600 mb-2 text-primary">Assignee <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="assignee" placeholder="Assignee">
                        <mat-option *ngFor="let member of staffMembers" [value]="member.id" class="filteropt"
                            (click)="addStaffToList(member)">{{member.fname}} {{member.lname}}</mat-option>
                    </mat-select>
                    <mat-error class="error d-flex" *ngIf="submitted && f['assignee'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['assignee'].errors['required']">Assignee
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>


            <div class="d-flex align-items-center mt-3 d-none">
                <label class="fw-600 text-primary">Client Visiblity</label>
                <label class="switch ms-2">
                    <input type="checkbox" formControlName="client_visibility">
                    <span class="slider round"></span>
                </label>
            </div>
        </div>

        <div class="d-flex justify-content-end">
            <button class="btn btn-light me-2" type="button" (click)="cancelTaskModal()">
                Close
            </button>
            <button class="btn btn-primary" type="submit">Save</button>
        </div>
    </form>
</div>