import { Component } from '@angular/core';

@Component({
  selector: 'app-sms-settings',
  templateUrl: './sms-settings.component.html',
  styleUrls: ['./sms-settings.component.scss']
})
export class SmsSettingsComponent {
  activeId: any = 2;

  tabChange(event: any) {
    this.activeId = event.nextId;
    // this.router.navigate([],{ relativeTo: this.route,queryParams: { showType: this.activeId } })
  }
}
