import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { countryData } from 'src/app/core/data/countryData';
import { ClientsService } from 'src/app/core/service/clients.service';
// import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss']
})
export class ClientInfoComponent implements OnInit {
  panelOpenState = false;
  selected_id!: string;
  project: any;
  client: any;
  staffMembers: any = [];
  productsArray: any = [];
  client_seemore = true;
  clientproject_seemore = true;
  client_owner: any;
  project_owner: any;
  statusList: any = [
    {
      id: 1,
      name: "PENDING"
    },
    {
      id: 2,
      name: "COMPLETED"
    }
  ];
  allStaffMembers: any = [];
  selected_type: any = "";
  countryjson = countryData;
  tagsListArray: any;
  productsListArray: any = [];

  constructor(private route: ActivatedRoute, private service: ClientsService, private product_service: SetUpService, private settingsService: SettingsService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      // console.log("route params timeline - ", params);
      this.selected_id = params.id;
      this.selected_type = params.showtype;
      if (this.selected_id) {
        this.getRelatedIdInfo(this.selected_id);
      }
    });
  }

  seeMore(type: string) {
    if (type === 'client') {
      this.client_seemore = !this.client_seemore;
    } else if (type === 'project') {
      this.clientproject_seemore = !this.clientproject_seemore;
    }
  }

  getRelatedIdInfo(id: string) {
    this.organisationMembersList();
    this.getTags();
    this.getProducts();
    this.staffMembers = [];

    this.service.getProjectDetailsById(id).subscribe({
      next: (proj_resp: any) => {
        console.log("project - ", proj_resp);
        this.project = proj_resp.data;
        this.getStaffDataById(this.project.owner, 'project');
        if (this.project.tag) this.getTagData(this.project.tag, 'project');
        this.service.getClientById(this.project.client_id).subscribe({
          next: (client_resp: any) => {
            // console.log("client - ", client_resp);
            this.client = client_resp.data;
            this.getStaffDataById(this.client.owner, 'client');
            if (this.client.tag) this.getTagData(this.client.tag, 'client');
          }
        });

        if (this.project.staff_ids.length !== 0) {
          this.project.staff_ids.forEach((staff: any) => {
            this.settingsService.getStaff_infoById(staff).subscribe({
              next: (resp: any) => {
                this.staffMembers.push(resp.data);
              }
            });
          });
        }

        if (this.project.service_ids.length !== 0) {
          this.productsArray = [];
          this.project.service_ids.forEach((product: any) => {
            this.getProductById(product);
          });
        }


      }
    });
  }

  getTagData(id: string, type: string) {
    this.settingsService.getTagById(id).subscribe({
      next: (resp: any) => {
        if (type === 'client') {
          this.client.tag_name = resp.data.name;
          this.client.colour = resp.data.colour;
        } else if (type === 'project') {
          this.project.tag_name = resp.data.name;
          this.project.colour = resp.data.colour;
        }
      }
    });
  }

  getProductById(product_id: string) {
    this.product_service.getStore().subscribe({
      next: (resp: any) => {
        this.product_service.getProductById(resp.data.id, product_id).subscribe({
          next: (products_resp: any) => {
            // console.log(products_resp);
            this.productsArray.push(products_resp.data);
            this.project.product_details = this.productsArray;
            // this.products_list = products_resp.data;
            console.log(this.project);

          }
        });
      }
    });
  }

  getStaffDataById(id: string, type: string) {
    this.settingsService.getStaff_infoById(id).subscribe({
      next: (resp: any) => {
        if (type === 'client') {
          this.client_owner = resp.data;
        } else if (type === 'project') {
          this.project_owner = resp.data;
        }
      }
    });
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.drag_elements, event.previousIndex, event.currentIndex);
  // }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.allStaffMembers = resp?.data;
      }
    });
  }

  getTags() {
    this.settingsService.getTags().subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }

  getProducts() {
    this.product_service.getStore().subscribe({
      next: (resp: any) => {
        this.product_service.getActiveProducts(resp.data.id).subscribe({
          next: (products_resp: any) => {
            console.log(products_resp);
            this.productsListArray = products_resp.data;
          }
        });
      }
    });
  }

  onUpdatePopOver(event: any, data: any, type?: string) {
    const input: any = { id: data.id, custom_contact_fields: [] };

    if (event.type === 'dropdown') {
      if (event.labelName === 'Owner') {
        input.info = {
          "owner_info": {
            "id": event.value.id,
            "fname": event.value.fname,
            "lname": event.value.lname
          }
        };
        input.owner = event.value.id;
        input.owner_department = event.value.department_info?.id ? event.value.department_info?.id : '';
      }

      if (event.labelName === 'Tag') {
        input[event.formName] = event.value.id;
      } else if (event.labelName === "Status") {
        input.project_status = event.value.name;
      } else if (event.labelName === "Country") {
        input.country = event.value.Entity;
      }

    } else if (event.type === 'multiselect') {
      input[event.formName] = event.value;
      input.assignee_departments = [];
      this.allStaffMembers.forEach((element: any) => {
        if (event.value.includes(element.id)) {
          input.assignee_departments.push(element.department_info?.id);
        }
      });
    } else
      input[event.formName] = event.value;

    if (type === 'client')
      this.service.updateClient(input.id, input).subscribe(() => {
        this.getRelatedIdInfo(this.selected_id);
      });
    else
      this.service.updateClientProject(input.id, input).subscribe((resp: any) => {
        this.getRelatedIdInfo(this.selected_id);
      });

  }
}
