import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { ADDPIPELINEMODAL } from 'src/app/core/data/pipeline_json';
import { CrmService } from 'src/app/core/service/crm.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { CreateTeamPipelineComponent } from '../../pipeline/models/create-team-pipeline/create-team-pipeline.component';
import { finalize } from 'rxjs';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-pipeline-modal',
  templateUrl: './pipeline-modal.component.html',
  styleUrls: ['./pipeline-modal.component.scss']
})
export class PipelineModalComponent implements OnInit {
  user_details = JSON.parse(sessionStorage.getItem('currentUser')!);
  submitted = false;
  pipelineForm!: FormGroup;
  FormFields!: FormGroup;
  pipelineJson = ADDPIPELINEMODAL[0];
  errorJson = ERRORJSON[0];
  pipeline_list: any[] = [];
  selected_pipeline_stages: any[] = [];
  selected_pipeline: any;
  customFields: any;
  aptCategoriesArray: any[] = [];
  appointmentsArray: any;
  storeId: any;
  productCategory: any;
  productsList: any;
  @Input() pipelineData: any;
  checked: any[] = [];
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  contactsList: any = [];
  editCard = false;
  selectedPipelineData!: any[];
  price_on!: boolean;
  loading = false;
  minDate: Date = new Date();
  newEmail = false;
  staffMembers: any;
  loggedInUser: any;
  assignee_departments: any = [];
  tagsListArray: any[] = [];
  companiesList: any;

  constructor(private fb: FormBuilder, public ngbActiveModal: NgbActiveModal, private crmService: CrmService,
    private setupService: SetUpService, private modalService: NgbModal, private settingService: SettingsService) { }

  ngOnInit() {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.intilizeForm();
    this.getPipelines();
    this.getContacts();
    this.getStaffList();
    this.getTags();

    if (this.pipelineData) {
      this.selected_pipeline = this.pipelineData.pipelineId;
      this.getStagesBasedonPipeline();
      if (this.pipelineData.cardId) {
        this.editCard = true;
        this.getCardById();
      } else {
        this.getTemplateCard();
      }
    }
  }

  intilizeForm(data?: any) {
    this.pipelineForm = this.fb.group({
      pipeline: [this.pipelineData ? this.pipelineData.pipelineId : '', [Validators.required]],
      stage: [this.pipelineData ? this.pipelineData.selectedstageId : '', [Validators.required]],
      title: [data ? data[0]?.title : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9_\s]{1,}$/)]],
      contact: [data ? data[0]?.contact : '', [Validators.required]],
      email: [data ? data[0]?.contact_email : '', [Validators.required]],
      phone_number: [data ? data[0]?.contact_phone_number : '', [Validators.required]],
      contact_first_name: [data ? data[0]?.contact_first_name : '', [Validators.required]],
      price: [data ? data[0]?.price : '', [Validators.pattern("[0-9]+"), Validators.min(0)]],
      price_on: [this.price_on],
      due_date: [data ? data[0]?.due_date : '', [Validators.required]],
      owner: [data ? data[0]?.owner : this.loggedInUser.data.id, [Validators.required]],
      owner_department: [data ? data[0]?.owner_department : ''],
      assignee: new FormControl(data ? data[0].assignee : []),
      tags: new FormControl(data ? data[0].tags : ''),
      fields: new FormArray([])
    });
    if (data) {
      this.pipelineForm.controls["phone_number"].disable();
      this.pipelineForm.controls["email"].disable();
    }
  }

  get f() {
    return this.pipelineForm.controls;
  }

  fieldsForm(): FormArray {
    return this.pipelineForm.get('fields') as FormArray;
  }
  get fieldsFormArray(): FormArray {
    return this.pipelineForm.get('fields') as FormArray;
  }

  addFormFileds(element: any, data?: any) {
    return this.FormFields = new FormGroup({
      template_field: new FormControl(element ? element.id : ''),
      data: new FormControl(data ? data.data : ''),
      data_name: new FormControl(element ? element.label : ''),
      url: new FormControl(element ? element.url : ''),
      options: new FormArray([]),
      data_type: new FormControl(element ? element.data_type : '')
      // check_box: new FormGroup({})
    });
  }
  updateFormFields(element: any) {
    return this.FormFields = new FormGroup({
      template_field: new FormControl(element ? element.template_field : ''),
      data: new FormControl(element ? element.data : ''),
      data_name: new FormControl(element ? element.label : ''),
      url: new FormControl(element ? element.url : ''),
      options: new FormArray([]),
      data_type: new FormControl(element ? element.data_type : ''),
      id: new FormControl(element ? element.id : '')
    });
  }

  options(index: number) {
    return this.fieldsForm().at(index).get('options') as FormArray;
  }

  getPipelines() {
    this.crmService.getPipelines().subscribe({
      next: (resp: any) => {
        this.pipeline_list = resp.data;
        if (this.selected_pipeline) this.selectedPipelineData = this.pipeline_list.filter((ele: any) => ele.id === this.selected_pipeline);
      }
    });
  }

  onChangePipeline(ev: any) {
    this.selected_pipeline = ev.value;
    this.selectedPipelineData = this.pipeline_list.filter((ele: any) => ele.id === this.selected_pipeline);
    console.log(this.selectedPipelineData, "pipeline date");

    this.fieldsForm().clear();
    this.getStagesBasedonPipeline();
    this.getTemplateCard();
  }
  addNewName = (name: any) => ({ first_name: name, id: '' });

  async onContactChange(value: any) {
    console.log(value);
    this.pipelineForm.controls["phone_number"].enable();
    this.pipelineForm.controls["email"].enable();
    // const selected_contact = await this.contactsList.filter((e: any) => e.id === value);
    if (value?.id) {
      this.newEmail = false;
      this.pipelineForm.patchValue({ email: value.email, contact: value.id, phone_number: value.phone_number, contact_first_name: value.first_name });
      this.pipelineForm.controls["phone_number"].disable();
      this.pipelineForm.controls["email"].disable();
      this.pipelineForm.controls['contact'].setValidators(Validators.compose([Validators.required]));
      this.pipelineForm.controls['contact'].updateValueAndValidity();
      console.log(this.pipelineForm.value);
    } else {
      this.newEmail = true;
      console.log(this.pipelineForm.value);
      this.pipelineForm.controls['contact'].clearValidators();
      this.pipelineForm.controls['contact'].updateValueAndValidity();
    }
    if (this.editCard) {
      this.pipelineForm.patchValue({ email: value.email, phone_number: value.phone_number });
      this.updatePipelineCard();
    }
  }

  getStagesBasedonPipeline() {
    this.crmService.getStageByPipelineId(this.selected_pipeline).subscribe({
      next: (resp: any) => {
        this.selected_pipeline_stages = resp.data;
      }
    });
  }

  getTemplateCard() {
    this.crmService.getTemplateCard(this.selected_pipeline).subscribe({
      next: (resp: any) => {
        this.price_on = resp.data[0].price_on;
        this.pipelineForm.patchValue({ price_on: this.price_on });
        this.getFields(resp.data[0].id);
      }
    });
  }

  getStaffList() {
    this.settingService.getStaff_info().subscribe((data: any) => {
      this.staffMembers = data?.data;
    });
  }

  getTags() {
    this.settingService.getTags().subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }

  /***************************OnCard creation getting custom fields*******************/
  getFields(templateId: string) {
    this.crmService.getFieldsByTemaplateCardId(templateId).subscribe({
      next: (resp: any) => {
        this.customFields = resp.data;
        this.customFields.forEach((element: any, i: number) => {
          if (element.data_type === 'CHECK_BOX') {
            this.fieldsForm().push(this.addFormFileds(element));
            element.options.forEach((e: any) => {
              const serv: any = {
                checked: new FormControl(false),
                binder: new FormControl(e)
              };
              this.options(i).push(new FormGroup(serv));
            });
          } else {
            this.fieldsForm().push(this.addFormFileds(element));
            if (element.options) {
              element?.options.forEach((opt: string) => {
                this.options(i).push(new FormControl(opt));
              });
            }
          }
          if (element.url === '/consultations/category/') {
            this.getAPTCategoriesList();
          } else if (element.url === 'consultations/consultations/') {
            this.getAppointments();
          } else if (element.url === 'stores/?/categories/') {
            this.getStoreId('category');
          } else if (element.url === 'product/stores/?/products/') {
            this.getStoreId('products');
          } else if (element.url === 'crm_service/Contacts') {
            this.getContacts();
          } else if(element.url === 'crm_service/Companys'){
            this.getCompanys();
          }
        });
      }
    });
  }

  setAll(event: any, selectedvalue: any, index: number) {
    this.checked = [];
    this.fieldsForm().controls[index].value.options.forEach((element: any) => {
      if (element.checked) {
        this.checked.push(element.binder);
      }
    });
    if (event.checked) {
      this.checked.push(selectedvalue);
    } else {
      this.checked = this.checked.filter(item => !selectedvalue.includes(item));
    }
    // this.fieldsForm().controls[index].get('data')?.patchValue(this.checked);
    if (this.editCard) { // Checkbox Update
      const body = {
        data: "[" + this.checked + "]",
        id: this.fieldsForm().controls[index].value.id
      };
      this.crmService.UpdateField(body).subscribe({
        next: (resp: any) => {
          this.options(index).clear();
          resp.data.options.forEach((e: any) => {
            const serv: any = {
              checked: new FormControl(resp.data.data.includes(e) ? true : false),
              binder: new FormControl(e)
            };
            this.options(index).push(new FormGroup(serv));
          });
        }
      });
    }
  }

  cancelModal() {
    this.ngbActiveModal.dismiss();
    if (this.editCard) {
      const emitData = {
        type: 'onPiplinePage',
        cardId: this.pipelineData.cardId
      };
      this.passEntry.emit(emitData);
    }
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.pipelineForm, "pipelineForms");
    if (this.pipelineForm.valid) {
      this.loading = true;
      this.assignee_departments = [];

      // if (this.pipelineForm.value.assignee.length !== 0) {
      this.staffMembers.forEach((item: any) => {
        this.pipelineForm.value.assignee.filter((assigne: any) => {
          if (assigne === item.id) {
            this.assignee_departments.push(item.department_info.id);
          }
        });
        if (item.id === this.pipelineForm.value.owner) {
          this.pipelineForm.controls['owner_department'].setValue(item.department_info.id || null);
        }
        console.log(this.pipelineForm.value);
        
      });
      // }

      if (this.newEmail) {
        this.createContact();
      } else {
        this.bulkUpload();
      }
      // console.log(this.pipelineForm.value, "submitform");
    } else {
      this.pipelineForm.markAllAsTouched();
    }
  }

  bulkUpload() {
    this.pipelineForm.patchValue({ due_date: this.convert(this.pipelineForm.value.due_date) });
    const owner_data: any = this.staffMembers.filter((member: any) => member.id === this.pipelineForm.value.owner);
    const body = {
      assignee_department: this.assignee_departments ? this.assignee_departments : [],
      info: {
        owner_info: {
          fname: owner_data[0].fname,
          lname: owner_data[0].lname
        }
      },
      ...this.pipelineForm.value
    };
    console.log(body);
    this.crmService.createBulkCard(body).pipe(finalize(() => this.loading = false)).subscribe((resp: any) => {
      this.ngbActiveModal.dismiss();
      if (this.pipelineData.pipelineId) {
        const emitData = {
          type: 'onPiplinePage',
          stageId: resp.data.stage
        };
        this.passEntry.emit(emitData);
      }
    });
  }

  /**************************************Update Start**************************************** */

  getCardById() {
    this.crmService.getCardsById(this.pipelineData.cardId).subscribe((data: any) => {
      this.crmService.getTemplateCard(this.selected_pipeline).subscribe((resp: any) => {
        this.price_on = resp.data[0].price_on;
        this.intilizeForm(data.data);
        this.getFieldsBasedonCardId(this.pipelineData.cardId);
      });
    });
  }

  getFieldsBasedonCardId(cardId: string) {
    this.crmService.getFieldsbyCardId(cardId).subscribe((resp: any) => {
      console.log(resp, "stages");
      resp.data.forEach((element: any, i: number) => {
        if (element.url === '/consultations/category/') {
          this.getAPTCategoriesList();
        } else if (element.url === 'consultations/consultations/') {
          this.getAppointments();
        } else if (element.url === 'stores/?/categories/') {
          this.getStoreId('category');
        } else if (element.url === 'product/stores/?/products/') {
          this.getStoreId('products');
        } else if (element.url === 'crm_service/Contacts') {
          this.getContacts();
        }  else if(element.url === 'crm_service/Companys'){
          this.getCompanys();
        }
        if (element.data_type === 'CHECK_BOX') {
          this.fieldsForm().push(this.updateFormFields(element));
          element.options.forEach((e: any) => {
            const serv: any = {
              checked: new FormControl(element.data.includes(e) ? true : false),
              binder: new FormControl(e)
            };
            this.options(i).push(new FormGroup(serv));
          });
        } else {
          this.fieldsForm().push(this.updateFormFields(element));
          if (element.options.length > 0) {
            element?.options.forEach((opt: string) => {
              this.options(i).push(new FormControl(opt));
            });
          }
        }
      });
      console.log(this.pipelineForm.value);
    });
  }

  //*************Radio **************/
  radioChange(ev: any, item: any) {
    if (this.editCard) {
      item.value.data = ev.value;
      this.updateSigleField(item);
    }
  }

  //*****************Matselect, TextBox, text area, Date picker Change***************** */
  selectChange(ev: any, item: any) {
    if (this.editCard) this.updateSigleField(item);
  }

  updateSigleField(item: any) { //For Custom fields
    console.log(item.value);
    const body = {
      id: item.value.id,
      data: item.value.data
    };
    this.crmService.UpdateField(body).subscribe({
      next: () => { //@typescript-eslint/no-empty-function
      }
    });
  }

  updatePipelineCard() { // For pipeline or stage or title change
    this.submitted = true;
    if (this.editCard && this.pipelineForm.valid) {
      this.assignee_departments = [];
      let info: any;
      // if (this.pipelineForm.value.assignee.length !== 0) {
      this.staffMembers.forEach((item: any) => {
        this.pipelineForm.value.assignee.filter((assigne: any) => {
          if (assigne === item.id) {
            this.assignee_departments.push(item.department_info.id);
          }
        });
        if (item.id === this.pipelineForm.value.owner) {
          console.log(item.department_info.id);
          this.pipelineForm.controls['owner_department'].setValue(item.department_info.id || null);
          info = {
            owner_info: {
              fname: item.fname,
              lname: item.lname
            }
          };
        }
      });
      // }
      this.pipelineForm.patchValue({ due_date: this.convert(this.pipelineForm.value.due_date) });
      const body = {
        assignee_department: this.assignee_departments ? this.assignee_departments : [],
        pipeline: this.pipelineForm.value.pipeline,
        stage: this.pipelineForm.value.stage,
        title: this.pipelineForm.value.title,
        id: this.pipelineData.cardId,
        info: info,
        ...this.pipelineForm.value
      };
      this.crmService.updateCard(body).subscribe({
        next: () => {//@typescript-eslint/no-empty-function
        }
      });
    } else if (this.editCard && this.pipelineForm.invalid) {
      this.pipelineForm.markAllAsTouched();
    }
  }

  /**************************************Update END**************************************** */
  /**********************************Fields API Starts*************************************/
  getAPTCategoriesList() {
    this.setupService.getAllCategories().subscribe({
      next: (resp: any) => {
        this.aptCategoriesArray = resp.data;
      }
    });
  }

  getAppointments() {
    this.setupService.getAllConsulations().subscribe({
      next: (resp: any) => {
        this.appointmentsArray = resp.data;
      }
    });
  }

  getStoreId(type: string) {
    this.setupService.getStore().subscribe((resp: any) => {
      this.storeId = resp.data.id;
      if (type === 'category') {
        this.getProductCategory();
      } else {
        this.getProduct();
      }
    });
  }

  getProductCategory() {
    this.setupService.getProductCategories(this.storeId).subscribe((resp: any) => {
      this.productCategory = resp.data;
    });
  }

  getProduct() {
    this.setupService.getProduct(this.storeId).subscribe((resp: any) => {
      this.productsList = resp.data;
    });
  }

  getContacts() {
    this.crmService.getContactsDropdownList().subscribe((resp: any) => {
      this.contactsList = resp.data;
    });
  }

  searchContact(term: any) {
    if (term.term.length >= 3) {
      this.crmService.getContactsBySearch(term.term).subscribe((result: any) => {
        this.contactsList = result.data;
      });
    } else if (term.term.length === 0) {
      this.getContacts();
    }
  }

  getCompanys(){
    this.crmService.getCompaniesDropdownList().subscribe((resp: any) => {
      this.companiesList = resp.data;
    });
  }
  
  /**********************************Fields API Ends**************************************/

  openCustomPipelineDialog() {
    const modalRef = this.modalService.open(CreateTeamPipelineComponent, { size: 'lg', modalDialogClass: 'custom-pipeline-modal' });
    modalRef.componentInstance.pipelineId = this.pipelineForm.value.pipeline;
    modalRef.componentInstance.stepperCount = 2;
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  createContact() {
    this.settingService.getStaff_info().subscribe((data: any) => {
      const loggedMember = data.data.filter((staff: any) => staff.id === this.user_details.data.id);
      const owner_department = this.staffMembers.filter((member: any) => member.id === this.pipelineForm.value.owner);
      const body = {
        custom_contact_fields: [],
        phone_number: this.pipelineForm.value.phone_number,
        email: this.pipelineForm.value.email,
        first_name: this.pipelineForm.value.contact_first_name.first_name,
        info: {
          owner_info: {
            fname: loggedMember.fname,
            lname: loggedMember.lname
          }
        },
        owner: this.user_details.data.id,
        owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : ''
        // source: null
      };
      console.log(body);
      this.crmService.createContact(body).pipe(finalize(() => this.loading = false)).subscribe({
        next: (resp: any) => {
          this.newEmail = false;
          this.pipelineForm.controls['contact'].setValue(resp.data?.id);
          this.pipelineForm.patchValue({ contact: resp.data?.id, contact_first_name: resp.data.first_name });
          this.bulkUpload();
        }
      });
    });
  }

  clearContactFields(e: any) {
    this.pipelineForm.patchValue({ email: '', contact: '', phone_number: '', contact_first_name: '' });
    this.pipelineForm.controls["phone_number"].enable();
    this.pipelineForm.controls["email"].enable();
  }
}
