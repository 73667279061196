export const homeJson = [
  {
    title: 'Notifications',
    title2: 'My board',
    status: 'Status',
    duedate: 'Due Date',
    priority: 'Priority'
  }
];
export const MYBOARD =[
  {
    label_today: 'Today',
    label_tomorrow: 'Tomorrow',
    label_overdue: 'Overdue',
    label_donetoday: 'DoneToday'
  }
];