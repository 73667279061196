import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyConversionPipe } from './currency/currency-conversion.pipe';
import { DateConversionPipe } from './date/date-conversion.pipe';
import { FilterPipe } from './filter/filter.pipe';
import { MaterialModule } from '../../common/material/material.module';


@NgModule({
  declarations: [
    CurrencyConversionPipe,
    DateConversionPipe,
    FilterPipe
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports:[
    DateConversionPipe,
    CurrencyConversionPipe,
    FilterPipe
  ]
})
export class PipesModule { }
