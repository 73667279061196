import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ERRORJSON } from 'src/app/core/data/error_json';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit{
  errorjson = ERRORJSON[0];
  modalRef!: NgbModalRef;
  recharge_form!: FormGroup;
  submitted = false;
  display = 'subscription';
  recharge_amount: any;
  amount_options = ['1000','10000','25000','50000'];

  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    this.initilizeRechargeForm(); 
  }

  switchTo(displayType:any) {
    this.display = displayType;
  }

  initilizeRechargeForm() {
    this.recharge_form = new FormGroup({
      total_amount: new FormControl('', Validators.required),
      card_number: new FormControl('', Validators.required),
      expiration: new FormControl('', Validators.required),
      cvc: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required)
    });
  }

  get f() {
    return this.recharge_form.controls;
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.recharge_form.value);
  }

  onSelect(amount:any) {
    this.recharge_amount = amount;
  }

  openRechargeModal(content: any) {
    this.recharge_form.patchValue({total_amount:this.recharge_amount});
    this.modalRef = this.modalService.open(content, { size: 'md', centered: true });
  }

}
