<div class="modal-header">
    <h5 *ngIf="!company?.id" class="modal-title text-primary fw-600">Add Company</h5>
    <h5 *ngIf="company?.id" class="modal-title text-primary fw-600">Update Company</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="ngbActiveModal.dismiss()"></button>
</div>
<div class="modal-body">
    <div class="row mb-2">
        <form [formGroup]="companyForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <label class="fw-600 mb-2 text-primary">{{companiesJson.feild_company_name}} <span
                            class="text-danger">*</span></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder={{companiesJson.feild_company_name}}
                            formControlName="name" #myFirstInput />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['name'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['name'].errors['required']">{{companiesJson.feild_company_name}}
                                {{errorjson.required}}</span>
                            <span *ngIf="f['name'].errors['pattern']">Only alphanumeric are accepted.</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12">
                    <label class="fw-600 mb-2 text-primary">Description</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <textarea style="outline: none" matInput placeholder="Description" formControlName="description"
                            style="height:100px"></textarea>
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12">
                    <label class="fw-600 mb-2 text-primary">{{companiesJson.feild_webiste}} <small
                            class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder={{companiesJson.feild_webiste}}
                            formControlName="website" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['website'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['website'].errors['required']">{{companiesJson.feild_webiste}}
                                {{errorjson.required}}</span>
                            <span *ngIf="f['website'].errors['pattern']">Valid URL is
                                {{errorjson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                    <label class="fw-600 mb-2 text-primary">Email <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="Email" [disabled]="true"
                            formControlName="email" />
                        <mat-error class="error d-flex" *ngIf="submitted && f['email'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['email'].errors['required']">Email
                                {{errorjson.required}}</span>
                            <span *ngIf="f['email'].errors['pattern']">{{errorjson.invalid}} Email</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                    <label class="fw-600 mb-2 text-primary">Mobile Number <small class="text-danger">*</small></label>
                    <div class="d-flex align-items-center">
                        <mat-form-field appearance="outline" class="me-1">
                            <mat-select placeholder="Country code" formControlName="ccode">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                        noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let country of filteredBanks | async"
                                    [ngClass]="{'d-none': country.IAC === ''}"
                                    value={{country.IAC}}>{{country.IAC}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder="Mobile number"
                                formControlName="phonenumber" />
                            <mat-error class="error text-danger d-flex" *ngIf="submitted && f['phonenumber'].errors">
                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                <span *ngIf="f['phonenumber'].errors['required']">Phone Number
                                    {{errorjson.required}}</span>
                                <span
                                    *ngIf="f['phonenumber'].errors['pattern'] || f['phonenumber'].errors['minlength'] || f['phonenumber'].errors['maxlength']">{{errorjson.invalid}}
                                    Phone Number</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                    <label class="fw-600 mb-2 text-primary">Owner <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select formControlName="owner" placeholder="Owner" class="pt-1">
                            <mat-option *ngFor="let member of staffMembers" (click)="onOwnerSelect(member)"
                                [value]="member.id">{{member.fname}}
                                {{member.lname}}</mat-option>
                        </mat-select>
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['owner'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['owner'].errors['required']">Owner
                                {{errorjson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2 text-primary">Assignee</label>
                    <ng-select class="form-control" [items]="staffMembers" bindValue="id" bindLabel="fname"
                        [addTag]="false" [multiple]="true" placeholder="Select staff" formControlName="assignee">
                    </ng-select>
                    <!-- <div class="error d-flex mt-2" *ngIf="submitted && f['assignee'].errors">
                        <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                        <span *ngIf="f['assignee'].errors['required']"> Staff
                            {{errorJson.required}}</span>
                    </div> -->
                </div>
                <!-- <div class="col-md-6 col-sm-12">
                    <label class="fw-600 mb-2 text-primary">Related Contact Name</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="Enter Name"
                            formControlName="related_contact" />
                    </mat-form-field>
                </div> -->

                <div class="col-md-6 col-sm-12">
                    <label class="fw-600 mb-2 text-primary">Address <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <textarea style="outline: none" matInput placeholder="Address" formControlName="address"
                            style="height:100px"></textarea>
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['address'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['address'].errors['required']">Address
                                {{errorjson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-6 col-sm-12">
                    <label class="fw-600 mb-2 text-primary">City <span class="text-danger">*</span></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="City" formControlName="city" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['city'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['city'].errors['required']">City
                                {{errorjson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-6 col-sm-12">
                    <label class="fw-600 mb-2 text-primary">Zip Code <span class="text-danger">*</span></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="Zip Code" formControlName="zipcode" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['zipcode'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['zipcode'].errors['required']">Zip Code
                                {{errorjson.required}}</span>
                            <span *ngIf="f['zipcode'].errors['pattern']">Invalid zip code</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                    <label class="fw-600 font-size-14 mb-2">Country <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select placeholder='Select Country' formControlName="country">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of filteredBanks | async"
                                value={{country.Entity}}>{{country.Entity}}</mat-option>
                        </mat-select>
                        <mat-error class="error d-flex" *ngIf="submitted && f['country'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['country'].errors['required']">Country
                                {{errorjson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2 text-primary">Channel</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select formControlName="source" placeholder="Select Channel" class="pt-1">
                            <mat-option value="whatsapp">Whatsapp</mat-option>
                            <mat-option value="manual">Manual entry</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                    <label class="fw-600 mb-2 text-primary">Tag</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select formControlName="tags" placeholder="Select Tag" class="pt-1">
                            <mat-option [value]="item.id" *ngFor="let item of tagsListArray">{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-6 col-sm-12" *ngIf="!companyForm.value.id">
                    <label class="fw-600 mb-2 text-primary">Contact Name <span class="text-danger">*</span></label>

                    <ng-select class="form-control" [items]="contactListArray" bindLabel="first_name"
                        [addTag]="addNewName" [multiple]="false" (change)="onContactChange($event)"
                        placeholder="Contact Name" formControlName="contact_name" (search)="searchContact($event)"
                        (clear)="clearContactFields($event)">
                    </ng-select>
                    <div class="error d-flex mt-2" *ngIf="submitted && f['contact_name'].errors">
                        <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                        <span *ngIf="f['contact_name'].errors['required']">Contact Name
                            {{errorjson.required}}</span>
                        <span *ngIf="f['contact_name'].errors['pattern']">Only alphanumeric are accepted.</span>
                    </div>
                    <!-- <mat-form-field appearance="outline" class="w-100">
                        <mat-select formControlName="contact_id" placeholder="Contact Name" class="pt-1"
                            (selectionChange)="onContactChange($event)">
                            <mat-option>
                                <ngx-mat-select-search formControlName="search" placeholderLabel="Search"
                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option [value]="item.id" *ngFor="let item of contactListArray">{{item.first_name
                                + ' ' +
                                item.last_name}}</mat-option>
                        </mat-select>
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['contact_id'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['contact_id'].errors['required']">Contact Name
                                {{errorjson.required}}</span>
                            <span *ngIf="f['contact_id'].errors['pattern']">Invalid Contact Name</span>
                        </mat-error>
                    </mat-form-field> -->
                </div>

                <div class="col-md-6 col-sm-12" *ngIf="!companyForm.value.id">
                    <label class="fw-600 mb-2 text-primary">Contact Email <span class="text-danger">*</span></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="Contact Email"
                            formControlName="contact_email"
                            [disabled]="companyForm.value.contact_email ? true : false" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['contact_email'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['contact_email'].errors['required']">Contact Email
                                {{errorjson.required}}</span>
                            <span *ngIf="f['contact_email'].errors['pattern']">Invalid Contact Email</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-6 col-sm-12" *ngIf="!companyForm.value.id">
                    <label class="fw-600 mb-2 text-primary">Contact Mobile Number <span
                            class="text-danger">*</span></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="Contact Mobile Number"
                            formControlName="contact_number" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['contact_number'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['contact_number'].errors['required']">Contact Mobile Number
                                {{errorjson.required}}</span>
                            <span *ngIf="f['contact_number'].errors['pattern']">Invalid Contact Mobile Number</span>
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>

            <div class="d-flex align-items-center justify-content-end modal-footer mt-2">
                <div class="d-flex align-items-center justify-content-end">
                    <button class="btn btn-light me-2" type="button" (click)="ngbActiveModal.dismiss()">
                        {{companiesJson.btn_cancel}}
                    </button>
                    <button class="btn btn-primary" [disabled]="loading"
                        type="submit">{{companiesJson.btn_save}}</button>
                </div>
            </div>
        </form>
    </div>
</div>