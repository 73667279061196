<router-outlet *ngIf="isOnline$ | async; else offlineTemplate"></router-outlet>
<ngx-spinner type="ball-pulse" bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" [fullScreen]="false">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<ng-template #offlineTemplate>
    <div class="d-flex justify-content-center align-items-center">
        <div class="text-center">
            <img class="border-radius-8" src="../../../../assets/images/nodata_found.svg" height="160px" width="160px">
            <h4 class="mt-4">
                No Internet connection
            </h4>
        </div>
    </div>
</ng-template>