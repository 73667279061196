<div class="container-fluid">
    <div class="row mb-2 mt-3">
        <div class="col-sm-6">
            <div class="heading font-size-18 fw-700 d-flex cursor" style="width: fit-content;" routerLink="../form-builder">
                <mat-icon class="text-primary" >{{COMPONENT_LABELS.back_icon}} </mat-icon>
                <p>
                    {{COMPONENT_LABELS.submissions_data}}
                </p>
            </div>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table align-middle table-nowrap table mb-0 table-bordered">
            <thead>
                <tr>
                    <th>{{COMPONENT_LABELS.col_tab_1}}</th>
                    <th *ngFor="let item of headers;">{{item.name}}</th>
                </tr>
            </thead>
            <tbody>

                <tr *ngFor="let form of submissions; let i=index;">
                    <td>
                        {{ i+ 1 }}
                    </td>
                    <ng-container *ngFor="let ele of headers;">
                        <td>{{form.data[ele.id]?.value ? form.data[ele.id].value : ""}}</td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
</div>