import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CrmService } from 'src/app/core/service/crm.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { Location } from '@angular/common';
import { SettingsService } from 'src/app/core/service/settings.service';
import { countryData } from 'src/app/core/data/countryData';
import { forEach } from 'lodash';

@Component({
  selector: 'app-crm-info',
  templateUrl: './crm-info.component.html',
  styleUrls: ['./crm-info.component.scss']
})
export class CrmInfoComponent implements OnInit {
  panelOpenState = false;
  selected_id!: string;
  selected_type!: string;
  // info_array: any = [];
  contact_info: any;
  company_info: any;
  intro_obj: any = {};
  input_field_fname = false;
  input_field_lname = false;
  input_field_email = false;
  input_field_phno = false;
  input_field_cmpny_name = false;
  input_field_cmpny_email = false;
  input_field_cmpny_phno = false;
  input_field_cmpny_website = false;
  input_field_cmpny_address = false;
  input_field_cmpny_city = false;
  input_field_cmpny_zipcode = false;
  input_field_cmpny_description = false;
  cardFields: any[] = [];
  sliceItem = 5;
  storeId: any;
  cardDetails: any;
  enquiry_seemore = true;
  contact_seemore = true;
  company_seemore = true;
  stagesList: any[] = [];
  cardStageIndex!: number;
  contact_owner: any;
  enquiry_owner: any;
  company_owner: any;
  staffMembers: any = [];
  companiesListArray: any = [];
  productsListArray: any = [];
  countryjson = countryData;
  tagsListArray: any;
  product_details: any;

  constructor(private route: ActivatedRoute, private service: CrmService, private setupService: SetUpService,
    private _location: Location, private settingsService: SettingsService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      // console.log("route params timeline - ", params);
      this.selected_id = params.id;
      this.selected_type = params.showtype;
      if (this.selected_id) {
        this.getRelatedIdInfo(this.selected_id);
      }
    });
  }

  seeMore(type: string) {
    if (type === 'enquiry') {
      this.enquiry_seemore = !this.enquiry_seemore;
    } else if (type === 'contact') {
      this.contact_seemore = !this.contact_seemore;
    } else if (type === 'company') {
      this.company_seemore = !this.company_seemore;
    }
  }

  getRelatedIdInfo(id: string) {
    this.organisationMembersList();
    this.getCompaniesList();
    this.getTags();
    this.getProducts();

    if (this.selected_type === 'contact') {
      this.service.getContactById(id).subscribe({
        next: (resp: any) => {
          this.intro_obj = resp.data[0];
          this.contact_info = resp.data[0];
          this.getStaffDataById(this.contact_info.owner, 'contact');
          if (this.contact_info.tags) this.getTagData(this.contact_info.tags, 'contact');

          if (resp.data[0].company) {
            this.service.getCompanyById(resp.data[0].company).subscribe({
              next: (com_resp: any) => {
                this.company_info = com_resp.data[0];
                this.getStaffDataById(this.company_info.owner, 'company');
                if (this.company_info.tags) this.getTagData(this.company_info.tags, 'company');

              }
            });
          }
        }
      });
    } else if (this.selected_type === 'lead') {
      this.service.getLeadById(id).subscribe({
        next: (resp: any) => {
          // console.log("leads -", resp);
          this.intro_obj = resp.data[0];
          this.getStaffDataById(this.intro_obj.owner, 'lead');
          if (this.intro_obj.tags) this.getTagData(this.intro_obj.tags, 'lead');

          if (this.intro_obj.product.length !== 0) this.getProductById(this.intro_obj.product);
          if (resp.data[0].contact) {
            this.service.getContactById(resp.data[0].contact).subscribe({
              next: (con_resp: any) => {
                // console.log("contacts - ", con_resp);
                this.contact_info = con_resp.data[0];
                this.getStaffDataById(this.contact_info.owner, 'contact');
                if (this.contact_info.tags) this.getTagData(this.contact_info.tags, 'contact');


                if (resp.data[0].company_id) {
                  this.service.getCompanyById(resp.data[0].company_id).subscribe({
                    next: (comp_resp: any) => {
                      // console.log("companies - ", comp_resp);
                      this.company_info = comp_resp.data[0];
                      this.getStaffDataById(this.company_info.owner, 'company');
                      if (this.company_info.tags) this.getTagData(this.company_info.tags, 'company');

                    }
                  });
                }
              }
            });
          }
        }
      });
    } else if (this.selected_type === 'company') {
      this.service.getCompanyById(id).subscribe({
        next: (resp: any) => {
          this.intro_obj = resp.data[0];
          // console.log("companies - ", resp);
          this.company_info = resp.data[0];
          this.getStaffDataById(this.company_info.owner, 'company');
          if (this.company_info.tags) this.getTagData(this.company_info.tags, 'company');
        }
      });
    } else if (this.selected_type === 'deal') {
      this.getFieldsBasedonCardId();
    }
  }

  getStaffDataById(id: string, type: string) {
    this.settingsService.getStaff_infoById(id).subscribe({
      next: (resp: any) => {
        if (type === 'lead') {
          this.enquiry_owner = resp.data;
        } else if (type === 'contact') {
          this.contact_owner = resp.data;
        } else if (type === 'company') {
          this.company_owner = resp.data;
        }
      }
    });
  }

  getTagData(id: string, type: string) {
    this.settingsService.getTagById(id).subscribe({
      next: (resp: any) => {
        if (type === 'lead') {
          this.intro_obj.tag_name = resp.data.name;
          this.intro_obj.colour = resp.data.colour;
        } else if (type === 'contact') {
          this.contact_info.tag_name = resp.data.name;
          this.contact_info.colour = resp.data.colour;
        } else if (type === 'company') {
          this.company_info.tag_name = resp.data.name;
          this.company_info.colour = resp.data.colour;
        }
      }
    });
  }

  getProductById(product_ids: any) {
    this.setupService.getStore().subscribe({
      next: (resp: any) => {
        const produtNamesArray: any = [];
        product_ids.forEach((item: any) => {
          this.setupService.getProductById(resp.data.id, item).subscribe({
            next: (products_resp: any) => {
              console.log(products_resp);
              produtNamesArray.push(products_resp.data);
              this.intro_obj.product_details = produtNamesArray;
              this.product_details = produtNamesArray;
              // this.products_list = products_resp.data;
            }
          });
        });
      }
    });
  }

  conditionalInputs(field: string, status: boolean) {
    if (field === 'input_field_fname') {
      this.input_field_fname = status;
    } else if (field === 'input_field_lname') {
      this.input_field_lname = status;
    } else if (field === 'input_field_email') {
      this.input_field_email = status;
    } else if (field === 'input_field_phno') {
      this.input_field_phno = status;
    } else if (field === 'input_field_cmpny_name') {
      this.input_field_cmpny_name = status;
    } else if (field === 'input_field_cmpny_email') {
      this.input_field_cmpny_email = status;
    } else if (field === 'input_field_cmpny_phno') {
      this.input_field_cmpny_phno = status;
    } else if (field === 'input_field_cmpny_website') {
      this.input_field_cmpny_website = status;
    } else if (field === 'input_field_cmpny_address') {
      this.input_field_cmpny_address = status;
    } else if (field === 'input_field_cmpny_city') {
      this.input_field_cmpny_city = status;
    } else if (field === 'input_field_cmpny_zipcode') {
      this.input_field_cmpny_zipcode = status;
    } else if (field === 'input_field_cmpny_description') {
      this.input_field_cmpny_description = status;
    }
  }

  getContactsForDeals(id: any) {
    this.service;
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.info_array, event.previousIndex, event.currentIndex);
  // }
  /***************************Pipeline START****************************** */
  getFieldsBasedonCardId() {
    this.service.getFieldsbyCardId(this.selected_id).subscribe((resp: any) => {
      this.cardFields = resp.data;

      this.cardFields.forEach((ele: any) => {
        if (ele.url === '/consultations/category/') {
          this.getAPTCategoriesList();
        } else if (ele.url === 'consultations/consultations/') {
          this.getAppointments(ele);
        } else if (ele.url === 'stores/?/categories/' && ele.data) {
          this.getStoreId('category');
        } else if (ele.url === 'product/stores/?/products/' && ele.data) {
          this.getStoreId('products', ele);
        } else if (ele.url === 'crm_service/Contacts') {
          this.getContacts(ele);
        } else if(ele.url === 'crm_service/Companys'){
          this.getCompanyById(ele);
        }
      });
      this.getContactDetails();

    });
  }

  getContactDetails(): void {
    this.service.getCardsById(this.selected_id).subscribe((cards: any) => {
      this.cardDetails = cards?.data[0];
      this.getStages();
      this.service.getContactById(this.cardDetails?.contact).subscribe((contact: any) => {
        this.contact_info = contact.data[0];
        this.getStaffDataById(this.contact_info.owner, 'contact');
        if (this.contact_info.tags) this.getTagData(this.contact_info.tags, 'contact');
      });
    });

  }

  getStages() {
    this.service.getStageByPipelineId(this.cardDetails.pipeline).subscribe((stage: any) => {
      this.stagesList = stage.data;
      this.stagesList.forEach((element: any, i) => {
        if (this.cardDetails.stage === element.id) {
          this.cardStageIndex = i;
        }
      });
    });
  }

  stageUpdate(stageId: string, index: number) {
    this.service.updateCard({ id: this.selected_id, stage: stageId }).subscribe(() => {
      this.cardStageIndex = index;
    });
  }

  seeMoreFields() {
    this.sliceItem = this.cardFields.length;
  }

  /**********************************Fields API Starts*************************************/
  getAPTCategoriesList() {
    this.setupService.getAllCategories().subscribe({
      next: (resp: any) => {
        this.cardFields.filter(ele => {
          if (ele.url === '/consultations/category/') {
            const selectedItem = resp.data.filter((item: any) => item.id === ele.data);
            if (selectedItem.length !== 0) ele.data_name = selectedItem[0].name;
          }
        });
      }
    });
  }

  getAppointments(ele: any) {
    this.setupService.getConsultationByID(ele.data).subscribe({
      next: (resp: any) => {
        ele.data_name = resp.data.name;
        // this.cardFields.filter(ele => {
        //   if (ele.url === 'consultations/consultations/') {
        //     const selectedItem = resp.data.filter((item: any) => item.id === ele.data);
        //     if (selectedItem.length !== 0) ele.data_name = selectedItem[0].name;
        //   }
        // });
      }
    });
  }

  getStoreId(type: string, ele?: any) {
    this.setupService.getStore().subscribe((resp: any) => {
      this.storeId = resp.data.id;
      if (type === 'category') {
        this.getProductCategory();
      } else {
        this.getProduct(ele);
      }
    });
  }

  getProductCategory() {
    this.setupService.getProductCategories(this.storeId).subscribe((resp: any) => {
      this.cardFields.filter(ele => {
        if (ele.url === 'stores/?/categories/') {
          const selectedItem = resp.data.filter((item: any) => item.id === ele.data);
          if (selectedItem.length !== 0) ele.data_name = selectedItem[0].name;
        }
      });
    });
  }

  getProduct(ele: any) {
    this.setupService.getProductById(this.storeId, ele.data).subscribe((resp: any) => {
      ele.data_name = resp.data.name;
      // this.cardFields.filter(ele => {
      //   if (ele.url === 'product/stores/?/products/') {
      //     const selectedItem = resp.data.filter((item: any) => item.id === ele.data);
      //     if (selectedItem.length !== 0) ele.data_name = selectedItem[0].name;
      //   }
      // });
    });
  }

  getContacts(ele: any) {
    this.service.getContactById(ele.data).subscribe((resp: any) => {
      ele.data_name = resp.data.name;
      // this.cardFields.filter(ele => {
      //   if (ele.url === 'crm_service/Contacts') {
      //     const selectedItem = resp.data.filter((item: any) => item.id === ele.data);
      //     if (selectedItem.length !== 0) ele.data_name = selectedItem[0].first_name;
      //   }
      // });
    });
  }

  getCompanyById(ele: any){
    this.service.getCompanyById(ele.data).subscribe((resp: any)=>{
      ele.data_name = resp.data[0].name;
    });
  }
  /**********************************Fields API Ends**************************************/

  /***************************Pipeline END****************************** */

  goBack() {
    this._location.back();
  }

  getCompaniesList() {
    this.service.getCompaniesDropdownList().subscribe({
      next: (resp: any) => {
        this.companiesListArray = resp.data;
      }
    });
  }

  getProducts() {
    this.setupService.getStore().subscribe({
      next: (resp: any) => {
        this.setupService.getActiveProducts(resp.data.id).subscribe({
          next: (products_resp: any) => {
            console.log(products_resp);
            this.productsListArray = products_resp.data;
          }
        });
      }
    });
  }

  updateFieldChanges(body: any, type: string, field?: string) {
    // console.log("update fields - ", body);
    if (type === 'contact') {
      body.custom_contact_fields = [];
      this.service.updateContact(body).subscribe(() => {
        // this.conditionalInputs(field, false);
        this.getRelatedIdInfo(this.selected_id);
      });
    } else if (type === 'company') {
      this.service.updateCompany(body).subscribe(() => {
        // this.conditionalInputs(field, false);
        this.getRelatedIdInfo(this.selected_id);
      });
    } else if (type === 'lead') {
      body.custom_lead_fields = [];
      this.service.updateLead(body).subscribe((resp: any) => {
        this.getRelatedIdInfo(this.selected_id);
      });
    }
  }

  onUpdatePopOver(event: any, data: any, type: string) {
    const input: any = { id: data.id };

    if (event.type === 'dropdown') {
      if (event.labelName === 'Owner') {
        input.info = {
          "owner_info": {
            "id": event.value.id,
            "fname": event.value.fname,
            "lname": event.value.lname
          }
        };
        input.owner = event.value.id;
        input.owner_department = event.value.department_info?.id ? event.value.department_info?.id : '';
      } else if (event.labelName === 'Company Name') {
        input.company = event.value.id;
        input.company_id = event.value.id;
        // input.company_name = event.value.name;
      } else if (event.labelName === "Country") {
        input.country = event.value.Entity;
      } else if (event.labelName === 'Tag') {
        input[event.formName] = event.value.id;
      }
    } else
      input[event.formName] = event.value;

    this.updateFieldChanges(input, type);
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  getTags() {
    this.settingsService.getTags().subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }
}
