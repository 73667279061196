<div class="modal-container h-100">
    <div class="modal-header">
        <h5 class="modal-title text-primary">{{settingsformJson.heading}}</h5>
        <!-- <button type="button" class="btn-close" aria-label="Close"></button> -->
    </div>

    <div class="modal-body h-75 overflow-auto">
        <!-- <div class="row">
            <mat-label class="mb-2 font-size-14 fw-600">Feild Properties</mat-label>
            <mat-form-field appearance="outline" class="w-65">
                <mat-select placeholder="Select type" [(ngModel)]="selectedFieldType">
                    <mat-option *ngFor="let opt of options" value="{{opt.value}}">{{opt.type}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
        <form [formGroup]="fieldoption">
            <div class="row mt-3">
                <div class="col-4 m-auto text-end">
                    <label> {{settingsformJson.label_name}} </label>
                </div>
                <div class="col-8 m-auto">
                    <div *ngIf="fieldoption.value.label !== 'Service'">
                        <mat-form-field appearance="outline">
                            <input class="field mb-3" style="outline: none" matInput
                                placeholder="{{settingsformJson.name}}" type="text" formControlName="label">
                        </mat-form-field>
                    </div>
                    <div *ngIf="fieldoption.value.type === 'CHECK_BOX'" formArrayName="options">
                        <div class="d-inline-flex align-items-center" *ngFor="let item of FormOpt.controls;let i =index"
                            style="margin: 5px 0px;">
                            <!-- <input class="field propertyfeilds" type="checkbox">&nbsp; -->
                            <mat-form-field appearance="outline" class="mat-hint-none mb-2">
                                <input class="checkbox" [formControlName]="i" type="text" style="outline: none" matInput
                                    placeholder="{{settingsformJson.option_name}}" (input)="onInputChange()">
                            </mat-form-field>&nbsp;
                            <mat-icon (click)="deleteFormOption(item,i)" matSuffix
                                class="text-light">{{settingsformJson.icon_close}}</mat-icon>
                        </div>
                        <br>
                        <div class="d-inline-flex" *ngIf="checkboxesDataList.length<=500">
                            <input class="field m-auto" type="checkbox" style="width: 13px;height: 15px;">&nbsp;
                            <button class="font-size-14 btn btn-light"
                                (click)="addNew(true)">{{settingsformJson.add_option}}</button>
                        </div>
                    </div>
                    <div *ngIf="fieldoption.value.type === 'RADIO_BUTTON'" formArrayName="options">
                        <div class="d-inline-flex align-items-center" *ngFor="let item of FormOpt.controls;let i =index"
                            style="margin: 5px 0px;">
                            <!-- <input class="field" type="radio" style="width: 17px;height: 17px;">&nbsp; -->
                            <mat-form-field appearance="outline" class="mat-hint-none mb-2">
                                <input class="checkbox" [formControlName]="i" type="text" style="outline: none" matInput
                                    placeholder="{{settingsformJson.option_name}}" (input)="onInputChange()">
                            </mat-form-field>&nbsp;
                            <mat-icon (click)="deleteFormOption(item,i)" matSuffix
                                class="text-light">{{settingsformJson.icon_close}}</mat-icon>
                        </div>
                        <br>
                        <div class="d-inline-flex" *ngIf="checkboxesDataList.length<=500">
                            <input class="field m-auto" type="radio" style="width: 17px;height: 17px;">&nbsp;
                            <button class="font-size-14 btn btn-light"
                                (click)="addNew(true)">{{settingsformJson.add_option}}</button>
                        </div>
                    </div>
                    <div *ngIf="fieldoption.value.type === 'SELECT_DROPDOWN' && fieldoption.value.label !== 'Service'"
                        formArrayName="options">
                        <ol class="mb-0 p-0">
                            <li *ngFor="let item of FormOpt.controls;let i =index" class="d-flex mb-2">
                                <mat-form-field appearance="outline" class="mat-hint-none mb-2">
                                    <input class="checkbox" [formControlName]="i" type="text" style="outline: none"
                                        matInput placeholder="{{settingsformJson.option_name}}"
                                        (input)="onInputChange()">
                                </mat-form-field>&nbsp;
                                <mat-icon (click)="deleteFormOption(item,i)" matSuffix
                                    class="text-light">{{settingsformJson.icon_close}}</mat-icon>

                            </li>
                        </ol>
                        <div style="display: inline-flex" *ngIf="checkboxesDataList.length<=500">
                            <button class="font-size-14 btn btn-light"
                                (click)="addNew(true)">{{settingsformJson.add_option}}</button>
                        </div>
                    </div>
                    <div *ngIf="fieldoption.value.type === 'MULTI_SELECT_DROPDOWN' && fieldoption.value.label !== 'Service'"
                        formArrayName="options">
                        <ol class="mb-0 p-0">
                            <li *ngFor="let item of FormOpt.controls;let i =index" class="d-flex mb-2">
                                <mat-form-field appearance="outline" class="mat-hint-none mb-2">
                                    <input class="checkbox" [formControlName]="i" type="text" style="outline: none"
                                        matInput placeholder="{{settingsformJson.option_name}}"
                                        (input)="onInputChange()">
                                </mat-form-field>&nbsp;
                            </li>
                        </ol>
                        <div style="display: inline-flex" *ngIf="checkboxesDataList.length<=500">
                            <button class="font-size-14 btn btn-light"
                                (click)="addNew(true)">{{settingsformJson.add_option}}</button>
                        </div>
                    </div>
                    <div *ngIf="fieldoption.value.type === 'SELECT_DROPDOWN' && fieldoption.value.label === 'Service'">
                        <mat-form-field appearance="outline" class="w-65">
                            <mat-select placeholder="{{settingsformJson.placeholder_select}}" multiple>
                                <mat-option *ngFor="let item of serviceData"
                                    value="{{item.id}}">{{item.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div style="display: inline-flex">
                            <button class="font-size-14 btn btn-light">{{settingsformJson.create_service}}</button>
                        </div>
                    </div>

                    <div
                        *ngIf="formItem.key === 'APPOINTMENT_CATEGORIES' || formItem.key === 'APPOINTMENT' || formItem.key === 'PRODUCT_CATEGORIES' || formItem.key === 'PRODUCT'">
                        <ng-select bindLabel="mail" bindValue="mail" formControlName="select_options" [multiple]="true"
                            placeholder="Select Options">
                            <ng-option *ngFor="let item of API_LIST"
                                value='{"id":"{{item.id}}","name":"{{item.name}}"}'>{{item.name}}</ng-option>
                        </ng-select>

                    </div>

                </div>
            </div>
        </form>

        <p *ngIf="showError" class="text-center mt-3 text-danger bold">
            {{settingsformJson.please_fill_all_fields}}
        </p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-light" (click)="modelService.dismissAll()">
            Cancel
        </button>
        <button class="btn btn-primary" (click)="passBack()">
            Done
        </button>
    </div>
</div>