<div class="d-flex justify-content-between align-items-center">
    <h5 class="card-title fw-700 font-size-16">{{timelineJSON.heading}}</h5>
    <!-- <div class="d-flex align-items-center justify-content-end">
        <h5 class="card-title text-light me-3">{{timelineJSON.filter_label}}:</h5>
        <div class="timeline-select">
            <mat-form-field appearance="outline">
                <mat-select [(ngModel)]="timeline_filter" class="pt-1">
                    <mat-option value="All">All</mat-option>
                    <mat-option value="Calls">Calls</mat-option>
                    <mat-option value="Notes">Notes</mat-option>
                    <mat-option value="Task">Task</mat-option>
                    <mat-option value="Events">Events</mat-option>
                    <mat-option value="Files">Files</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div> -->
</div>
<div class="time-line-flow" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="1000"
    (scrolled)="onScroll()">
    <div class="list-block" *ngFor="let item of currentPageTimelineList">
        <div class="row">
            <p class="font-size-10 text-white col-md-2 text-center"></p>
            <div class="col-md-1 pe-0">
                <div class="border-1 border-radius-50 bg-black m-0 border-circle"></div>
                <div class="vertical-line"></div>
            </div>
            <div class="col-md-9 ps-0">
                <h6 class="font-size-14 fw-500 mb-0">{{item.on_date__date}}</h6>
            </div>
        </div>
        <div class="row" *ngFor="let subitem of item.timeline">
            <p class="font-size-10 color-l-gray col-md-2 text-center">{{subitem.time_stamp | date: 'h:mm a'}}</p>
            <div class="col-md-1 pe-0">
                <div class="border-light-1 border-radius-50 m-0 border-circle"></div>
                <div class="vertical-line"></div>
            </div>
            <div class="col-md-9 ps-0">
                <div class="d-flex">
                    <mat-icon style="overflow: visible">sticky_note_2</mat-icon>
                    <div class="ms-2">
                        <h6 class="font-size-12 fw-500 mb-0">{{subitem.activity}}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="d-flex">
            <p class="font-size-10 text-white w-8"></p>
            <div class="px-2 d-flex align-items-center flex-column">
                <div class="border-1 border-radius-50 bg-black m-0 border-circle"></div>
                <div class="vertical-line"></div>
            </div>
            <div class="d-flex">
                <h6 class="font-size-15 fw-500">{{item.on_date__date}}</h6>
            </div>
        </div>
        <div class="d-flex" *ngFor="let subitem of item.timeline">
            <p class="font-size-10 w-8 color-l-gray">{{subitem.time_stamp | date: 'h:mm a'}}</p>
            <div class="px-2 d-flex align-items-center flex-column">
                <div class="border-light-1 border-radius-50 m-0 border-circle"></div>
                <div class="vertical-line"></div>
            </div>
            <div class="d-flex">
                <mat-icon>sticky_note_2</mat-icon>
                <div class="ms-2">
                    <h6 class="font-size-14 fw-500 mb-0">{{subitem.activity}}
                    </h6>
                </div>
            </div>
        </div> -->
    </div>
</div>