<div class="container-fluid">
    <div class="row p-2">
        <div class="col-md-9">
            <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom mt-0"
                (navChange)="tabChange($event)">
                <li [ngbNavItem]="tab.id" class="me-2" *ngFor="let tab of tabs">
                    <a ngbNavLink class="d-flex" [routerLink]="[tab.route]">
                        <span class="d-sm-block font-size-14">{{tab.title}}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <router-outlet></router-outlet>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="customNav"></div>
        </div>
        <div class="col-md-3 h-35rem p-2">
            <div class="my-board ms-3">
                <div class="row mb-4">
                    <div class="col-12 ps-1">
                        <div class="d-flex">
                            <span class="border-left"></span>
                            <h5 class="fw-700 m-auto text-primary ms-2">{{homeTextjson.title2}}</h5>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body bg-primary rounded">
                        <div class="row">
                            <div class="col-md-3 text-center m-auto " matTooltip="Task" matTooltipPosition="above">
                                <label class="p-2 rounded bg-white mb-2">
                                    <!-- <mat-icon style="margin-bottom: -6px;"
                                        class="text-primary font-size-20 mt-1">event_note</mat-icon> -->
                                    <img src="../../../assets/images/myboard_tasks.svg" width="18">
                                </label>
                                <div class="d-flex flex-column text-white align-items-center">
                                    <!-- <label class="font-size-14">{{myboardJson.label_today}}</label> -->
                                    <h6 class="mb-1 font-size-12">{{taskCount}}</h6>
                                    <h6 class="mb-0 font-size-10">Task</h6>
                                </div>
                            </div>
                            <div class="col-md-3 text-center m-auto" matTooltip="Activity" matTooltipPosition="above">
                                <label class="p-2 rounded bg-white mb-2">
                                    <!-- <mat-icon style="margin-bottom: -6px;"
                                        class="text-primary font-size-20 mt-1">today</mat-icon> -->
                                    <img src="../../../assets/images/myboard_activity_log.svg" 
                                        width="18">
                                </label>
                                <div class="d-flex flex-column text-white align-items-center">
                                    <!-- <label class="font-size-14">{{myboardJson.label_tomorrow}}</label> -->
                                    <h6 class="mb-1 font-size-12">{{activityCount}}</h6>
                                    <h6 class="mb-0 font-size-10">Activity</h6>
                                </div>
                            </div>
                            <div class="col-md-3 text-center m-auto" matTooltip="Appointments"
                                matTooltipPosition="above">
                                <label class="p-2 rounded bg-white mb-2">
                                    <!-- <mat-icon style="margin-bottom: -5px;"
                                        class="text-primary font-size-20 mt-1">event_busy</mat-icon> -->
                                    <img src="../../../assets/images/myboard_appointments.svg"
                                        width="18">
                                </label>
                                <div class="d-flex flex-column text-white align-items-center">
                                    <!-- <label class="font-size-14">{{myboardJson.label_overdue}}</label> -->
                                    <h6 class="mb-1 font-size-12">{{aptCount}}</h6>
                                    <h6 class="mb-0 font-size-10">Appointments</h6>
                                </div>
                            </div>
                            <div class="col-md-3 text-center m-auto" matTooltip="Enquiries" matTooltipPosition="above">
                                <label class="p-2 rounded bg-white mb-2">
                                    <!-- <mat-icon style="margin-bottom: -5px;"
                                        class="text-primary  font-size-20 mt-1">event_available</mat-icon> -->
                                    <img src="../../../assets/images/myboard_orders.svg" width="18">
                                </label>
                                <div class="d-flex flex-column text-white align-items-center">
                                    <!-- <label class="font-size-14">{{myboardJson.label_donetoday}}</label> -->
                                    <h6 class="mb-1 font-size-12">{{enquireCount}}</h6>
                                    <h6 class="mb-0 font-size-10">Enquiries</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="activity_logs ms-3">
                <div class="row  mb-4">
                    <div class="col-12 d-flex justify-content-between ps-1">
                        <div class="d-flex">
                            <span class="border-left"></span>
                            <h5 class="fw-700 mb-0 text-primary ms-2">{{homeTextjson.title}}</h5>
                        </div>
                        <label class="m-0 cursor text-light font-size-14 cursor" (click)="markAllasRead()">Mark all as
                            read</label>
                    </div>
                </div>
                <div class="">
                    <div class="row overflow-auto border-radius-10" style="height: 57vh;border: 1px solid #00000012">
                        <div class="border-bottom least-border-radius p-3" *ngFor="let item of notification_list">
                            <div class="row">
                                <div class="col-2">
                                    <div class="avatar-xss d-flex justify-content-between">
                                        <span class="avatar-title rounded-circle text-uppercase font-size-12"
                                            [ngStyle]="{'background-color': item.colorCode}">{{item.info
                                            ? item.info?.first_name?.charAt(0) : ''}}{{item.info ?
                                            item.info?.last_name?.charAt(0) : ''}}</span>
                                    </div>
                                </div>
                                <div class="col-10 ps-0">
                                    <h6 class="font-size-14" [ngClass]="{'text-decoration-line-through': item.is_read}">{{item?.message | titlecase}}</h6>
                                    <label
                                        class="notification-message text-light font-size-12">{{item?.description}}</label>
                                    <div class="d-flex justify-content-between font-size-12">
                                        <label class="text-light font-size-12">{{item.updated_at | dateConversion}}
                                        </label><!--hours ago-->
                                        <div class="">
                                            <input type="checkbox"  class="cursor" (change)="markasRead(item)"
                                                [(ngModel)]="item.is_read">&nbsp;<label
                                                class="m-auto text-light font-size-12">Mark as
                                                read</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-12 mb-3" *ngFor="let log of activity_logs">
                            <div class="d-flex">
                                <div class="avatar-xs me-2">
                                    <span class="avatar-title rounded-circle">
                                        {{log.name[0]}}
                                    </span>
                                </div>
                                <div class="d-flex flex-column mb-2">
                                    <p class="mb-0"><span class="fw-700">{{log.name}}</span> assigned a <span
                                            class="fw-700">{{log.type}}</span> related to <span
                                            class="fw-700">{{log.assigned_by}}</span></p>
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex align-items-center text-green">
                                            <mat-icon class="font-size-20">assignment_turned_in</mat-icon>
                                            <label class="font-size-14">{{log.type}}</label>
                                        </div>
                                        <label class="text-light font-size-13">{{log.date}}</label>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>