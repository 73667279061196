<div class="modal-header">
    <h5 class="modal-title text-primary fw-600"><span>{{dayoff ? "Update" : "Add New"}}</span> {{dayoffjson.heading}}
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="ngbActiveModal.dismiss()"></button>
</div>
<div class="modal-body">
    <div class="row mb-2">
        <form [formGroup]="dayoffForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-12">
                    <label class="fw-600 mb-2 text-primary">{{dayoffjson.heading}} {{dayoffjson.title}} <span
                            class="text-danger">*</span></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder={{dayoffjson.title}} formControlName="title"
                            #myFirstInput />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['title'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['title'].errors['required']">{{dayoffjson.title}}
                                {{errorjson.required}}</span>
                            <span *ngIf="f['title'].errors['pattern']">Only alphanumeric are accepted.</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <label class="fw-600 mb-2 text-primary">{{dayoffjson.date_range}} <span
                            class="text-danger">*</span></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate placeholder="Start date">
                            <input matEndDate placeholder="End date">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <!-- <div class="col-12">
                    <label class="fw-600 mb-2 text-primary">{{dayoffjson.date_range}} <span
                            class="text-danger">*</span></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput [ngxMatDatetimePicker]="picker"
                            placeholder="Choose a date & time" >
                        <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
                    </mat-form-field>
                </div> -->

            </div>

            <div class="d-flex align-items-center justify-content-end modal-footer mt-2">
                <div class="d-flex align-items-center justify-content-end">
                    <button class="btn btn-light me-2" type="button" (click)="ngbActiveModal.dismiss()">
                        {{dayoffjson.close_btn}}
                    </button>
                    <button class="btn btn-primary" type="submit">{{dayoffjson.save_btn}}</button>
                </div>
            </div>
        </form>
    </div>
</div>