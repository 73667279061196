<div class="container w-100">
    <ng-container *ngIf="loggedInUser.data.role === 'ADMIN'">
        <mat-card class="border-radius-8">
            <mat-card-header>
                <div class="d-flex align-items-center">
                    <img src="../../../../assets/images/zoom_logo.svg" alt="zoom_icon" width="24px" height="24px"
                        class="me-3">
                    <h5 class="mb-0 text-primary d-flex align-items-center">Zoom <span class="ms-1 mt-1 p-1 border-radius-4 font-size-10" style="background-color: rgb(153, 255, 153, 0.4);" *ngIf="zoom_form.value.id">Connected</span></h5>
                </div>
            </mat-card-header>
            <mat-card-content class="p-4">
                <p class="text-primary">At the moment of booking, automatically create Zoom meetings for the linked
                    account.
                    A
                    booking
                    confirmation email with the Zoom meeting details will be sent to both the customer and employee.
                </p>
            </mat-card-content>
            <mat-card-actions class="d-flex align-items-center justify-content-end px-4">
                <button class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 text-primary px-4 me-2"
                    (click)="openZoomModal(zoom)" *ngIf="!zoom_form.value.id">Connect</button>
                <button class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 text-primary px-4 me-2"
                    (click)="openZoomModal(zoom)" *ngIf="zoom_form.value.id">Update</button>
                <button class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 text-primary px-4 me-2"
                    (click)="zoomDelete()" *ngIf="zoom_form.value.id">Disconnect</button>
            </mat-card-actions>
        </mat-card>
    </ng-container>

    <mat-card class="mt-3 border-radius-8">
        <mat-card-header>
            <div class="d-flex align-items-center">
                <img src="../../../../assets/images/google-gmail.svg" alt="gmail_icon" width="26px" height="24px"
                    class="me-3">
                <h5 class="mb-0 text-primary">Google Gmail & Calendar &nbsp;
                    <span class="font-size-12 text-center" *ngIf="outlookData?.is_connected">(You've Connected to
                        Outlook, disconnect outlook to connect Google)</span>
                </h5>
            </div>
        </mat-card-header>
        <mat-card-content class="p-4">
            <p class="text-primary">Store and sync emails from Google Gmail directly in Worke contact records to
                view a
                history of your correspondence and Connect your Google Calendar to sync events, prevent double
                booking,
                and manage your workday from one central hub.
            </p>
        </mat-card-content>
        <mat-card-actions class="d-flex align-items-center justify-content-end px-4">
            <button (click)="connectTogmail()" [disabled]="googleConnected || outlookData?.is_connected"
                [matTooltip]="googleConnected || outlookData?.is_connected ? 'You have Connected to Outlook' : ''"
                class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 text-primary px-4 me-2">{{googleConnected
                ? 'Update' : 'Connect'}}</button>
            <button (click)="disconnectgmail()" *ngIf="googleConnected"
                class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 text-primary px-4 me-2">Disconnect</button>
        </mat-card-actions>
    </mat-card>

    <mat-card class="mt-3 border-radius-8">
        <mat-card-header>
            <div class="d-flex align-items-center">
                <img src="../../../../assets/images/outlook-calendar.svg" alt="gmail_icon" width="26px" height="24px"
                    class="me-3">
                <h5 class="mb-0 text-primary d-flex align-items-center">Outlook Mail & Calendar &nbsp;
                    <span class="font-size-12 text-center" *ngIf="googleConnected">(You've Connected to Google,
                        disconnect google to connect Outlook)</span>
                </h5>
            </div>
        </mat-card-header>
        <mat-card-content class="p-4">
            <p class="text-primary">Create appointments in Worke and add booked appointments directly to your
                Outlook
                Calendar.
            </p>
        </mat-card-content>
        <mat-card-actions class="d-flex align-items-center justify-content-end px-4">
            <button (click)="connecttoOutlook('connect')" [disabled]="googleConnected || outlookData?.is_connected"
                class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 text-primary px-4 me-2">{{!outlookData
                || !outlookData?.is_connected ?
                'Connect' : 'Update'}}</button>
            <button class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 text-primary px-4 me-2"
                (click)="disconnectfromOutlook()" *ngIf="outlookData?.is_connected">Disconnect</button>
        </mat-card-actions>
    </mat-card>

    <mat-card class="my-3 border-radius-8 d-none">
        <mat-card-header>
            <div class="d-flex align-items-center">
                <img src="../../../../assets/images/microsoft-outlook.svg" alt="gmail_icon" width="26px" height="24px"
                    class="me-3">
                <h5 class="mb-0 text-primary">Microsoft Email</h5>
            </div>
        </mat-card-header>
        <mat-card-content class="p-4">
            <p class="text-primary">Store and sync emails from Microsoft email directly in Worke contact records to
                view
                a history of your correspondence.
            </p>
        </mat-card-content>
        <mat-card-actions class="d-flex align-items-center justify-content-end px-4">
            <button class="btn border-radius-8 border-1 bg-white fw-600 font-size-14 text-primary px-4 me-2">Coming
                soon</button>
        </mat-card-actions>
    </mat-card>
</div>

<ng-template #zoom let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-primary">
            Zoom Integrations
        </h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="zoom_form" (ngSubmit)="onSubmit()">
            <div class="row mt-3">
                <div class="col-12">
                    <label class="fw-600 font-size-14 mb-2">Zoom API Key <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="" formControlName="api_key" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['api_key'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['api_key'].errors['required']">Api key is required</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <label class="fw-600 font-size-14 mb-2">Zoom Secret Key <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="" formControlName="secret_key" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['secret_key'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['secret_key'].errors['required']">Secret key is required</span>

                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-end">
                <button type="submit" class="btn btn-primary mt-4" *ngIf="zoom_form.value.id">Update</button>
                <button type="submit" class="btn btn-primary mt-4" *ngIf="!zoom_form.value.id">Add</button>
            </div>
        </form>
    </div>
</ng-template>