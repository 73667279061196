import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { ClientsService } from 'src/app/core/service/clients.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { TaskBoardService } from 'src/app/core/service/task-board.service';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss']
})
export class AddTaskComponent implements OnInit {
  @Input() task: any = {};
  @Input() stage!: string;
  @Input() is_global!: boolean;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  taskForm!: FormGroup;
  submitted = false;
  errorJson = ERRORJSON[0];
  staffMembers: any = [];
  related_to: any = [];
  stagesList: any = [];
  info: any = {};
  selected_id!: string;
  selected_type!: string;
  pre_selected = false;
  previous_stage!: string;
  minDate = new Date();
  source_name: any;
  loggedInUser: any;

  @ViewChild('myFirstInput') myFirstInput!: ElementRef;
  private routeSubscription!: Subscription;
  constructor(private route: ActivatedRoute, private service: TaskBoardService, private settingsService: SettingsService, private crm_Service: CrmService, private client_Service: ClientsService, private ngbActiveModal: NgbActiveModal) { }

  ngOnInit() {
    this.routeSubscription = this.route.queryParams.subscribe((params: any) => {
      this.selected_id = params.id;
      this.selected_type = params.showtype;
      this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
      // console.log(this.selected_id, this.selected_type, this.is_global);
      if (this.selected_id && !this.is_global) {
        if (this.selected_type !== 'deal') this.getStages(this.selected_id);
        if (this.selected_type === 'lead') {
          this.getLeadsById(this.selected_id);
        } else if (this.selected_type === 'contact') {
          this.getContactsById(this.selected_id);
        } else if (this.selected_type === 'company') {
          this.getCompaniesById(this.selected_id);
        } else if (this.selected_type === 'project') {
          this.getProjectById(this.selected_id);
        } else if (this.selected_type === 'deal') {
          this.getCardById();
          this.getStages(params.pipeline_id);
        }
      }
    });
    this.intilizeForm();
    this.previous_stage = this.taskForm.value.current_stage;
    // console.log(this.stage, this.task);

    if (this.stage) {
      this.taskForm.patchValue({ current_stage: this.stage });
    }
    if (this.is_global) {
      this.taskForm.controls['related_to_type'].setValidators(Validators.required);
    }
    // this.organisationMembersList();
    this.taskForm.get('search')!.valueChanges.subscribe((change: any) => {
      if (change.length >= 3) {
        if (this.selected_type === 'lead' && this.is_global === true) {
          this.getLeadsList(change);
        } else if (this.selected_type === 'contact' && this.is_global === true) {
          this.getContactsList(change);
        } else if (this.selected_type === 'company' && this.is_global === true) {
          this.getCompaniesList(change);
        } else if (this.selected_type === 'project' && this.is_global === true) {
          this.getAllProjects(change);
        }
      }
    });

    if (!this.task?.id) {
      this.info = this.loggedInUser.data;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.myFirstInput.nativeElement.focus();
    }, 0);
  }
  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  intilizeForm() {
    this.taskForm = new FormGroup({
      id: new FormControl(this.task ? this.task.id : ''),
      name: new FormControl(this.task ? this.task.name : '', [Validators.required]),
      priority: new FormControl(this.task ? this.task.priority : 'Medium', [Validators.required]),
      source_id: new FormControl('', [Validators.required]),
      assignee: new FormControl(this.task ? (this.task.assignee ? this.task.assignee[0] : this.loggedInUser.data.id) : this.loggedInUser.data.id, [Validators.required]),
      due_date: new FormControl(this.task ? this.task.due_date : '', [Validators.required]),
      current_stage: new FormControl(this.task ? this.task.current_stage : '', [Validators.required]),
      description: new FormControl(this.task ? this.task.description : ''),
      client_visibility: new FormControl(this.task ? (this.task.client_visibility ? this.task.client_visibility : false) : false),
      related_to_type: new FormControl(''),
      search: new FormControl(''),
      source_name: new FormControl({ value: '', disabled: true })
    });

  }

  addStaffToList(member: any) {
    this.info = member;
  }

  get f() {
    return this.taskForm.controls;
  }

  onTaskSubmit() {
    this.submitted = true;
    // console.log(this.taskForm);
    if (this.taskForm.valid) {
      const assignee_array: any = [];
      assignee_array.push(this.taskForm.value.assignee);
      let info_body: any;
      if (Object.keys(this.info).length === 0) {
        info_body = this.task.info;
      } else {
        info_body = this.info;
      }
      // console.log(this.taskForm.value);

      const owner_department = this.staffMembers.filter((member: any) => member.id === this.taskForm.value.assignee);
      // console.log(owner_department);

      if (this.taskForm.value.id) {
        this.service.updateTask(this.selected_id, this.selected_type, { ...this.taskForm.value, assignee: assignee_array, info: info_body, assignee_department: [owner_department[0].department_info.id] }).subscribe({
          next: (resp: any) => {
            this.passBack(resp.data.current_stage);
            // console.log("previous - ", this.previous_stage, "current - ", resp.data.current_stage);

            this.ngbActiveModal.dismiss();
          }
        });
      } else {
        this.service.createTask(this.taskForm.value.source_id, this.selected_type, { ...this.taskForm.value, source_name: this.source_name, assignee: assignee_array, info: info_body, assignee_department: [owner_department[0].department_info.id] }).subscribe({
          next: (resp: any) => {
            this.passBack(resp.data.current_stage);
            // console.log("previous - ", this.previous_stage, "current - ", resp.data.current_stage);

            this.ngbActiveModal.dismiss();
          }
        });
      }
    }

  }

  selectedSource(id: string, name: string, source_data: any) {
    this.getStages(id);
    this.source_name = name;
    let staffIds: any = [];

    // console.log(source_data, "selected source");

    if (this.selected_type === 'project') {
      staffIds = source_data.staff_ids;
    } else {
      staffIds = source_data.assignee;
    }
    if (!staffIds.includes(source_data.owner)) {
      staffIds.push(source_data.owner);
    }
    if (staffIds.length !== 0) this.getSourceStaffList(staffIds);
  }

  getStages(id: string) {
    this.service.getStages(id, this.selected_type).subscribe({
      next: (resp: any) => {
        this.stagesList = resp.data;
      }
    });
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  getLeadsList(name: string) {
    this.crm_Service.getLeadsBySearch(name).subscribe({
      next: (resp: any) => {
        this.related_to = resp.data;
        if (!this.is_global) {
          this.taskForm.patchValue({ source_id: this.selected_id });
        }
      }
    });
  }

  getLeadsById(source_id: string) {
    this.crm_Service.getLeadById(source_id).subscribe({
      next: (resp: any) => {
        this.taskForm.patchValue({ source_id: resp.data[0].id, source_name: resp.data[0].requirement_title });
        this.source_name = resp.data[0].requirement_title;
        let staffIds: any = [];
        staffIds = resp.data[0].assignee;
        if (!staffIds.includes(resp.data[0].owner)) {
          staffIds.push(resp.data[0].owner);
        }
        if (staffIds.length !== 0) this.getSourceStaffList(staffIds);
      }
    });
  }

  getSourceStaffList(body: any) {
    this.service.getStaffForSource({ id: body }).subscribe({
      next: (resp: any) => {
        // console.log(resp, "ONLY SOURCE STAFF");
        this.staffMembers = resp.data;
      }
    });
  }


  getContactsList(name: string) {
    this.crm_Service.getContactsBySearch(name).subscribe({
      next: (resp: any) => {
        this.related_to = resp.data;
        if (!this.is_global) {
          this.taskForm.patchValue({ source_id: this.selected_id });
        }
      }
    });
  }

  getContactsById(source_id: string) {
    this.crm_Service.getContactById(source_id).subscribe({
      next: (resp: any) => {
        this.taskForm.patchValue({ source_id: resp.data[0].id, source_name: resp.data[0].first_name + " " + resp.data.last_name });
        this.source_name = resp.data[0].first_name + " " + resp.data.last_name;
        let staffIds: any = [];
        staffIds = resp.data[0].assignee;

        if (!staffIds.includes(resp.data[0].owner)) {
          staffIds.push(resp.data[0].owner);
        }

        if (staffIds.length !== 0) this.getSourceStaffList(staffIds);
      }
    });
  }

  getCompaniesList(name: string) {
    this.crm_Service.getCompaniesBySearch(name).subscribe({
      next: (resp: any) => {
        this.related_to = resp.data;
        if (!this.is_global) {
          this.taskForm.patchValue({ source_id: this.selected_id });
        }
      }
    });
  }

  getCompaniesById(source_id: string) {
    this.crm_Service.getCompanyById(source_id).subscribe({
      next: (resp: any) => {
        this.taskForm.patchValue({ source_id: resp.data[0].id, source_name: resp.data[0].name });
        this.source_name = resp.data[0].name;
        let staffIds: any = [];
        staffIds = resp.data[0].assignee;
        if (!staffIds.includes(resp.data[0].owner)) {
          staffIds.push(resp.data[0].owner);
        }
        if (staffIds.length !== 0) this.getSourceStaffList(staffIds);
      }
    });
  }

  getAllProjects(name: string) {
    this.client_Service.getAllProjectsBySearch(name).subscribe({
      next: (resp: any) => {
        this.related_to = resp.data;
        if (!this.is_global) {
          this.taskForm.patchValue({ source_id: this.selected_id });
        }
      }
    });
  }

  getProjectById(source_id: string) {
    this.client_Service.getProjectDetailsById(source_id).subscribe({
      next: (resp: any) => {
        this.taskForm.patchValue({ source_id: resp.data.id, source_name: resp.data.name });
        this.source_name = resp.data.name;
        let staffIds: any = [];
        staffIds = resp.data.staff_ids;
        if (!staffIds.includes(resp.data.owner)) {
          staffIds.push(resp.data.owner);
        }
        if (staffIds.length !== 0) this.getSourceStaffList(staffIds);
      }
    });
  }

  getCardById() {
    this.crm_Service.getCardsById(this.selected_id).subscribe((cards: any) => {
      this.taskForm.patchValue({ source_id: cards?.data[0].id, source_name: cards?.data[0].contact_first_name });
      let staffIds: any = [];
      staffIds = cards.data[0].assignee;
      if (!staffIds.includes(cards.data[0].owner)) {
        staffIds.push(cards.data[0].owner);
      }
      if (staffIds.length !== 0) this.getSourceStaffList(staffIds);
    });
  }

  cancelTaskModal() {
    this.ngbActiveModal.dismiss();
  }

  passBack(stage_id: string) {
    this.passEntry.emit({ update: true, current_stage: stage_id, previous_stage: this.previous_stage });
  }

  onRelatedTochange(ev: any) {
    this.selected_type = ev.value;
  }
}
