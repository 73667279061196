import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutsComponent } from './core/layouts/layouts.component';
import { Page404Component } from './pages/page404/page404.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule) },
  { path: '', component: LayoutsComponent, loadChildren: () => import('./core/layouts/layouts.module').then(m => m.LayoutsModule), canActivate: [AuthGuard] },
  { path: '**', pathMatch: 'full', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
