import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomizeFieldsComponent } from '../models/customize-fields/customize-fields.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss']
})
export class CustomFieldsComponent {
  form: any;

  constructor(private modalService: NgbModal) { }
  openCustomFieldsDialog() {
    this.modalService.open(CustomizeFieldsComponent, { size: 'lg', modalDialogClass: 'custom-pipeline-modal' });
  }

  initForm(data: any) {
    this.form = new FormGroup({
      "email": new FormControl(data ? data.email : ""),
      "contact_name": new FormControl(data ? data.contact_name : ""),
      "company_name": new FormControl(data ? data.company_name : ""),
      "pipeline_stage ": new FormControl(data ? data.pipeline_stage : ""),
      "amount": new FormControl(data ? data.amount : ""),
      "followDate": new FormControl(data ? data.followDate : ""),
      "description": new FormControl(data ? data.description : "")
    });
  }
}
