<mat-stepper linear #stepper>
    <mat-step label="Settings">
        <app-general-settings (onfinish)="onStepperCheck($event)"></app-general-settings>

    </mat-step>
    <mat-step label="Working Hours">
        <app-work-hours (onfinish)="onStepperCheck($event)"></app-work-hours>

    </mat-step>
    <mat-step label="Set Up">
        <div class="container-fluid">
            <div class="h-100 overflow-auto p-2">
                <h5 class="fw-500 font-size-22 text-primary my-2">{{setupJson.primary_heading_1}}</h5>
                <p class="fw-400 text-light my-2">{{setupJson.secondary_heading_1}}</p>
                <div class="recommended-cards row w-100 mt-4 mb-4">
                    <div class="card setup-cards col-md-4" *ngFor="let item of setupData">
                        <div class="card-body">
                            <div class="d-inline-flex align-items-center bg-circle border-radius-50 p-2">
                                <img [src]="item?.image" alt="appointment" width="25">
                            </div>
                            <h6 class="fw-500 font-size-16 text-primary mt-2 d-flex align-items-center">{{item.name}} &nbsp;<span class="font-size-12"
                                    *ngIf="item?.route_link==='None'">(Coming Soon)</span></h6>
                            <!-- <p class="fw-400 font-size-14 text-light mb-2">{{setupJson.appointy_heading_secondary}}</p> -->
                            <p class="fw-400 font-size-14 text-light my-2 content">{{item.description}}</p>
                            <div class="d-flex align-items-center justify-content-end mt-4" *ngIf="item?.route_link!=='None'">
                                <!-- <button class="btn border-card-btn fw-400 font-size-13 text-light" *ngIf="item.status"
                                    routerLink="/setup/{{item?.route_link}}">{{setupJson.open}}</button> -->
                                <label class="switch ms-3" *ngIf="loggedInUser.data.role === 'ADMIN'">
                                    <input type="checkbox" [id]="item.id" [checked]="item.status"
                                        (change)="onToggleChange($event,item)">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex align-items-center justify-content-end">
                    <button class="btn btn-primary mt-3" routerLink='/home'>
                        Finish
                    </button>
                </div>
            </div>
        </div>
    </mat-step>
</mat-stepper>