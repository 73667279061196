import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { APPOINTMENTJSON } from '../../../core/data/appointment_modal_json';
import { ERRORJSON } from '../../../core/data/error_json';
import { CrmService } from 'src/app/core/service/crm.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { HomeService } from 'src/app/core/service/home.service';

@Component({
  selector: 'app-appointment-modal',
  templateUrl: './appointment-modal.component.html',
  styleUrls: ['./appointment-modal.component.scss']
})
export class AppointmentModalComponent implements OnInit {
  appointmentJson = APPOINTMENTJSON[0];
  errorJson = ERRORJSON[0];
  aptForm!: FormGroup;
  submitted = false;
  @ViewChild('myFirstInput') myFirstInput!: ElementRef;
  contactListArray: any;
  staff_info: any;
  categoriesArray: any;
  appointments_list: any;
  selectedconsultation_data: any[] = [];
  locationsDetails: any;
  minDate: Date = new Date();
  selectedAppointment: any;
  slotsData: any[] = [];
  selectedCustomer: any;
  selectedLocation = '';
  @Input() appointmentId!: string;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  userDetails = JSON.parse(sessionStorage.getItem('currentUser')!);
  noslots = false;
  currentTime = '';
  currentUserTimeZone = 'Asia/Calcutta';
  total_price = 0;
  reschedule_staff_id: any;

  constructor(private ngbActiveModal: NgbActiveModal, private crm_service: CrmService, private homeService: HomeService,
    private settingService: SettingsService, private setupService: SetUpService, private authService: AuthService) { }

  ngOnInit() {
    this.getContactsList();
    this.intilizeForm();
    this.aptForm.get('search')!.valueChanges.subscribe((change: any) => {
      if (change?.length >= 3) {
        this.crm_service.getContactsBySearch(change).subscribe((result: any) => {
          this.contactListArray = result.data;
        });
      } else if (change?.length === 0) {
        // this.getContactsList();
      }
    });
    this.getCategoriesList();
    if (this.appointmentId) {
      this.homeService.getAppointmentById(this.appointmentId).subscribe((resp: any) => {
        this.intilizeForm(resp.data);
      });
    }
    // this.currentUserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.currentUserTimeZone = this.authService.getCompanyData()?.timezone;
  }

  // ngAfterViewInit() {
  //   setTimeout(() => {
  //     this.myFirstInput.nativeElement.focus();
  //   }, 0);
  // }

  intilizeForm(data?: any): void {
    this.aptForm = new FormGroup({
      id: new FormControl(data ? data.id : ''),
      contact_id: new FormControl(data ? data.contact_id : '', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      consultation_id: new FormControl(data ? data.consultation_id.id : '', [Validators.required]),
      phone_number: new FormControl('', [Validators.required]),
      category: new FormControl(data ? data.consultation_id.category_id : '', [Validators.required]),
      staff_id: new FormControl(data ? data.staff_id : ''),
      apt_details: new FormControl(data ? data.apt_details : '', [Validators.required]),
      date: new FormControl('', [Validators.required]),
      slot: new FormControl('', [Validators.required]),
      meeting_type: new FormControl(data ? data.meeting_type : '', [Validators.required]),
      org_address_id: new FormControl(data ? data.org_address_id : ''),
      payment_mode: new FormControl('COD'),
      is_paid: new FormControl(true),
      terms_conditions: new FormControl(true),
      search: new FormControl(''),
      source_type: new FormControl('ADMIN_PANEL'),
      ref_id: new FormControl('')
    });
    if (data) {
      this.onCategoryChange(data.consultation_id.category_id);
      this.onAptTypeChange(data.meeting_type);
      this.aptForm.patchValue({ apt_details: data.meeting_type === "OFF_LINE" ? data.org_address_id : 'Zoom' });
      this.aptForm.controls['phone_number'].disable();
      this.aptForm.controls['email'].disable();
    }
  }

  get f() {
    return this.aptForm.controls;
  }

  getContactById(contact_id: string) {
    this.crm_service.getContactById(contact_id).subscribe({
      next: (resp: any) => {
        const exisit = this.contactListArray.some((contact: any) => contact.id === resp.data[0].id);
        if (!exisit) {
          this.contactListArray.push(resp.data[0]);
          this.aptForm.patchValue({ email: resp.data[0].email, phone_number: resp.data[0].phone_number });
          this.aptForm.controls['phone_number'].disable();
          this.aptForm.controls['email'].disable();
        }
      }
    });
  }

  getContactsList() {
    this.crm_service.getContactsDropdownList().subscribe({
      next: (resp: any) => {
        this.contactListArray = resp.data;
        if (this.aptForm.value.id) {
          this.getContactById(this.aptForm.value.contact_id);
        }
      }
    });
  }

  onContactChange(event: any, data: any) {
    console.log(event, "event", data, this.aptForm.value.contact_id);
    if (event.isUserInput) {
      this.selectedCustomer = data;
      this.aptForm.patchValue({ email: data.email, phone_number: data.phone_number });
      this.aptForm.controls['phone_number'].disable();
      this.aptForm.controls['email'].disable();
    } else if (this.aptForm.value.id) {
      this.selectedCustomer = data;
      this.aptForm.patchValue({ email: data.email, phone_number: data.phone_number });
      this.aptForm.controls['phone_number'].disable();
      this.aptForm.controls['email'].disable();
      this.aptForm.controls['contact_id'].disable();
    }
  }

  getStaff() {
    this.settingService.getStaff_info().subscribe((resp: any) => {
      this.staff_info = [];
      resp?.data.forEach((element: any) => {
        this.selectedAppointment?.staff_data.forEach((staff: any) => {
          if (element.id === staff.staff_id && this.aptForm.value.meeting_type === staff.mode) {
            this.staff_info.push(element);
          }
        });
      });
      if (this.aptForm.value.id && this.staff_info.length !== 0) {
        this.reschedule_staff_id = this.aptForm.value.staff_id;
        this.aptForm.controls['meeting_type'].disable();
        this.aptForm.controls['staff_id'].disable();
      }
    });
  }

  getCategoriesList() {
    this.setupService.getAllActiveCategories().subscribe({
      next: (resp: any) => {
        this.categoriesArray = resp.data;
      }
    });
  }

  onCategoryChange(ev: any) {
    this.setupService.getConsulationsBycategoryIdActive(ev).subscribe((resp: any) => {
      this.appointments_list = resp.data;
      this.aptForm.patchValue({ ref_id: resp.ref_id });
    });
  }

  onAptSelected(item: any, event: any) {
    if (event.isUserInput) {
      this.selectedAppointment = item;
      this.selectedconsultation_data = item.consultation_data.filter((c: any) => c.is_active && c.mode !== "DOOR_STEP");
    } else if (this.aptForm.value.id) {
      this.selectedAppointment = item;
      this.selectedconsultation_data = item.consultation_data.filter((c: any) => c.is_active && c.mode !== "DOOR_STEP");
      this.aptForm.controls['category'].disable();
      this.aptForm.controls['consultation_id'].disable();
      this.aptForm.controls['apt_details'].disable();
      this.getStaff();
    }
  }

  onAptTypeChange(ev: any) {
    if (ev === "OFF_LINE") {
      this.settingService.getLocationSettings().subscribe((resp: any) => {
        this.locationsDetails = resp.data;
      });
    } else if (ev === "ON_LINE") {
      this.locationsDetails = [{ 'name': 'Zoom', 'id': 'Zoom' }];
    }
    this.getStaff();
  }

  locationChange(ev: any) {
    if (!this.aptForm.value.meeting_type.ON_LINE) {
      this.selectedLocation = this.locationsDetails.filter((l: any) => l.id === ev.value);
      this.aptForm.controls['org_address_id'].setValue(ev.value);
    } else {
      this.selectedLocation = 'Zoom';
    }
  }

  getSlots() {
    let slot = 30;
    this.aptForm.controls['slot'].setValue('');
    if (this.authService.getCompanyData().slot_duration) slot = this.authService.getCompanyData().slot_duration;
    const todaysDate = new Date();
    this.currentTime = '';
    if (this.convert(this.aptForm.value.date) === this.convert(todaysDate)) {
      const currentHour = todaysDate.getHours();
      const currentMinute = todaysDate.getMinutes();
      this.currentTime = (currentHour.toString().length === 1 ? ('0' + currentHour) : currentHour) + ':' + (currentMinute.toString().length === 1 ? ('0' + currentMinute) : currentMinute);
    }
    if (this.aptForm.value.date) {
      this.noslots = false;
      const body = {
        "staff": this.aptForm.value.staff_id ? this.aptForm.value.staff_id : (this.reschedule_staff_id || this.userDetails.data.organisation),
        "date": this.convert(this.aptForm.value.date),
        "appointment_duration": Number(this.selectedAppointment.duration),
        "slot_duration": Number(this.selectedAppointment.duration), //Number(slot)
        "time_zone": this.currentUserTimeZone
      };
      this.setupService.getSlotsBasedOnStaffId(body).subscribe((resp: any) => {
        this.slotsData = [];
        resp.data.forEach((element: any) => {
          if (element.slot_status) this.slotsData.push({ ...element, startTime: element.start_time.split('T')[1].slice(0, -3) });
        });
        if (this.slotsData.length === 0) this.noslots = true;
      }, () => {
        this.slotsData = [];
        this.noslots = true;
      });
    }
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  convertAptdate(str: any) {//Date formate: Day/month/year
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("-");
  }

  onAptSubmit() {
    const ref_id = this.aptForm.value.ref_id;
    this.submitted = true;
    if (this.aptForm.valid) {
      let selectedStaffDetails;
      const selectedStaff = this.staff_info.filter((c: any) => c.id === this.aptForm.value.staff_id);
      if (selectedStaff.length > 0) {
        selectedStaffDetails = {
          staffName: selectedStaff[0].fname,
          staffId: selectedStaff[0].id
        };
      }
      this.selectedconsultation_data.filter((item: any) => {
        if (item.mode === this.aptForm.value.meeting_type) {
          this.selectedAppointment.staff_data.filter((staff: any) => {
            if (staff.mode === this.aptForm.value.meeting_type && staff.staff_id === this.aptForm.value.staff_id) this.total_price = item.final_price + staff.staff_special_price;
          });
        }
      });
      if (this.aptForm.value.id) {
        this.setupService.putAppointmentReschedule(this.aptForm.value.id, { date: this.convertAptdate(this.aptForm.value.date), slot: this.aptForm.value.slot }).subscribe(() => {
          this.passEntry.emit("success");
          this.ngbActiveModal.dismiss();
        });
      } else {
        if (this.aptForm.valid) {
          const body = {
            ...this.aptForm.value,
            date: this.convertAptdate(this.aptForm.value.date),
            info: {
              user: {
                first_name: this.selectedCustomer.first_name,
                last_name: this.selectedCustomer.last_name,
                phone_number: this.selectedCustomer.phone_number,
                email: this.selectedCustomer.email,
                id: this.selectedCustomer.id,
                ccode: this.selectedCustomer.ccode
              },
              staff: selectedStaffDetails,
              address: this.selectedLocation[0]
            }
          };
          const payload = {
            gateway_name: "",
            type: "OFFLINE",
            reference_id: ref_id,
            source_type: "ADMIN_PANEL",
            source_id: ref_id,
            transaction: {
              amount: this.total_price,
              currency: this.selectedAppointment.currency
            },
            info: {
              user: this.selectedCustomer,
              staff: selectedStaffDetails
            }
          };
          this.setupService.postTransaction(payload).subscribe((resp: any) => {
            setTimeout(() => {
              this.setupService.postAppointment(body).subscribe(() => {
                this.ngbActiveModal.dismiss();
              });
            }, 5000);
          });
        }
      }
    }
  }

  cancelAptModal() {
    this.ngbActiveModal.dismiss();
  }
}
