import { Component, Input } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { FILESJSON } from 'src/app/core/data/crm_modal_json';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { CommonService } from 'src/app/core/service/common.service';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { HomeService } from 'src/app/core/service/home.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent {
  status_dropdown = 'pending';
  contentExpand = false;
  notesContent!: string;
  files: File[] = [];
  show_loader = false;
  filesTotal!: number;
  notesPageEvent!: PageEvent;
  notesCurrentPage = 0;
  notesList: any[] = [
    {
      id: '9d57e3b7-a47a-4df5-97e0-06a75817517c',
      organisation: '634d70a6-153f-4998-aff3-9c9f672c9a7e',
      department: '634d70a6-153f-4998-aff3-9c9f672c9a7e',
      is_active: true,
      status: true,
      deleted_at: null,
      deleted_by: null,
      created_at: '2023-04-24T17:24:03.105756Z',
      created_by: 'dc067508-f78f-4b1e-aea5-29188d55340b',
      updated_at: '2023-04-24T17:24:03.105774Z',
      updated_by: null,
      info: {
        fname: 'prasanthi',
        lname: 'dd'
      },
      source_id: 'b195762b-cb98-4420-b6c1-85684fcc8319',
      source_type: 'lead',
      notes: 'dssdfs',
      file_name: '',
      media_url: '',
      is_visible: false
    }
  ];
  filesJSON = FILESJSON[0];
  filesListArray: any = [];
  currentPage = 0;
  pageEvent!: PageEvent;
  presignedurl = environment.preSendUrl;
  @Input() taskId!: string;
  @Input() showType!: string;
  taskDetails: any;
  stageList: any;
  notesData!: any;
  edit = false;
  fileName!: string;
  selectedNote: any;
  activity_status!: string;
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  pipeline: any={};
  constructor(
    private confirmationMsz: ConfirmationMszService,
    private homeService: HomeService,
    private commonService: CommonService,
    private awsUtilService: AwsUtilsService,
    private crmService: CrmService
  ) { }
  ngOnInit() {
    console.log(this.taskId, this.showType);
    if (this.showType === 'task') {
      this.getTaskById();
    } else {
      this.getActivitiesTaskById();
    }
  }
  getTaskById() {
    this.homeService.getTaskById(this.taskId).subscribe((response: any) => {
      this.taskDetails = response.data;
      console.log(this.taskDetails, 'sss');
      this.status_dropdown = this.taskDetails.stage_name;
      if (this.taskDetails.source_type === 'deal') {
        this.getCardById();
      } else {
        this.getTaskStages();
      }
      this.getNotes(1);
      this.getFiles(1);
    });
  }
  getActivitiesTaskById() {
    this.homeService
      .getActivitiesTaskById(this.taskId)
      .subscribe((response: any) => {
        this.taskDetails = response.data;
        if (this.taskDetails.mark_as_done) {
          this.status_dropdown = 'Pending';
        }
        this.status_dropdown = this.taskDetails.stage_name;
        console.log(this.taskDetails);
        this.stageList = [{ name: 'pending' }, { name: 'completed' }];
        this.taskDetails.mark_as_done ? this.status_dropdown = 'completed' : this.status_dropdown = 'pending';
        this.taskDetails.mark_as_done ? this.activity_status = 'completed' : this.activity_status = 'pending';
        this.getNotes(1);
        this.getFiles(1);
      });
  }
  getTaskStages() {
    this.homeService
      .getTaskStages(this.pipeline?.source_id ? this.pipeline : this.taskDetails)
      .subscribe((response: any) => {
        this.stageList = response.data;
        console.log(this.stageList);
      });
  }
  getNotes(page: number) {
    this.homeService.getNotes(this.taskId, page).subscribe((response: any) => {
      console.log(response);
      this.notesData = response;
      this.contentExpand = false;
    });
  }
  saveNotes() {
    const body = {
      notes: this.notesContent,
      is_visible: false,
      info: {
        fname: this.loggedInUser.data.fname,
        lname: this.loggedInUser.data.lname
      },
      source_id: this.taskId,
      source_type: this.taskDetails.source_type
    };
    this.homeService.saveNotes(body).subscribe((response: any) => {
      this.notesContent = '';
      this.getNotes(1);
    });
  }
  updateNotes() {
    this.selectedNote.notes = this.notesContent;
    this.homeService.editNotes(this.selectedNote).subscribe((response: any) => {
      this.edit = false;
      this.notesContent = '';
      this.getNotes(1);
    });
  }

  clearNotes() {
    this.notesContent = '';
    this.contentExpand = false;
  }

  getFiles(page: number) {
    this.homeService
      .getFiles(this.taskDetails.id, page)
      .subscribe((response: any) => {
        this.filesTotal = response.count;
        this.filesListArray = response.data;
      });
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getFiles(this.currentPage);
  }

  fileSubmit() {
    const payload = {
      source_id: this.taskDetails.id,
      source_type: this.taskDetails.source_type,
      name: this.files[0].name,
      is_visible: true,
      media_url: this.fileName
    };
    this.show_loader = false;
    this.commonService.fileUploadWorke(payload).subscribe(() => {
      this.getFiles(1);
      this.files = [];
      this.show_loader = false;
    }, () => {
      this.files = [];
      this.show_loader = false;
    });
  }
  expandContent() {
    this.contentExpand = true;
  }
  onSelectStatus(event: any) {
    if (this.showType === 'task') {
      if (event.value === this.taskDetails.stage_name) {
        //@typescript-eslint/no-empty-function
      } else {
        this.stageList.forEach((element: any) => {
          if (element.name === event.value) {
            event.id = element.id;
          }
        });
        const body = {
          id: this.taskDetails.id,
          source_id: this.taskDetails.source_id,
          source_type: this.taskDetails.source_type,
          current_stage: event.id
        };
        this.homeService.editTask(body).subscribe((response: any) => {
          this.getTaskById();
        });
      }
    } else {
      if (event.value !== this.activity_status) {
        const body = {
          id: this.taskDetails.id,
          mark_as_done: event.value === 'pending' ? false : true,
          outcome: event.value === 'pending' ? 'pending' : 'completed'
        };
        this.homeService.editActivity(body).subscribe((response: any) => {
          this.getActivitiesTaskById();
        });
      }
    }

  }
  onSelect(event: any) {
    this.files.push(...event.addedFiles);

  }

  onUpload() {
    if (!this.files[0]) return;
    this.show_loader = true;
    this.awsUtilService
      .getUploadFileLink(this.files[0].type)
      .subscribe((resp) => {
        // console.log("first api - ", resp);
        this.fileName = resp.data.file_name;
        this.awsUtilService
          .uploadFile(this.files[0], resp.data.url)
          .subscribe(() => {
            //@typescript-eslint/no-empty-function
            this.fileSubmit();
          }, () => {
            this.files = [];
          });
      }, () => {
        this.files = [];
      });
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  editNotes(note: any) {
    this.contentExpand = true;
    this.edit = true;
    this.selectedNote = note;
    this.notesContent = note.notes;
  }

  deleteNote(id: any) {
    this.homeService.deleteNotes(id).subscribe((response: any) => {
      this.getNotes(1);
    });
  }
  deleteFile(id: any) {
    this.homeService.deleteFiles(id).subscribe((response: any) => {
      this.getFiles(1);
    });
  }
  confirmDeleteFile(fileId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteFile(fileId);
      }
    });
  }
  confirmDelete(noteId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteNote(noteId);
      }
    });
  }

  notesPage(e: PageEvent) {
    this.notesPageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.notesPageEvent.pageIndex > this.notesPageEvent.previousPageIndex!) {
      this.notesCurrentPage = this.notesPageEvent.pageIndex + 1;
    } else if (this.notesPageEvent.pageIndex < this.notesPageEvent.previousPageIndex!) {
      this.notesCurrentPage -= 1;
    }
    this.getNotes(this.notesCurrentPage);
  }

  getCardById() {
    this.crmService.getCardsById(this.taskDetails.source_id).subscribe((cards: any) => {
      this.pipeline.source_id = cards.data[0].pipeline;
      this.pipeline.source_type = this.taskDetails.source_type;
      this.getTaskStages();
    });
  }
}