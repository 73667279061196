import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { THANKUPAGE } from 'src/app/core/data/form_builder_json';
import { FormBuilderService } from 'src/app/core/service/form-builder.service';

@Component({
  selector: 'app-formthank-uscreen',
  templateUrl: './formthank-uscreen.component.html',
  styleUrls: ['./formthank-uscreen.component.scss']
})
export class FormthankUscreenComponent implements OnInit {

  @Input() formId = '';

  thankUpage = true;

  thankYouForm!: FormGroup;

  title = 'Saved';
  description = 'You submitted the form successfully.';
  thankupageJson = THANKUPAGE[0];

  constructor(
    private formBuilder: FormBuilder,
    private _formServices: FormBuilderService
  ) {
    this.initializeThankyouForm();
  }

  ngOnInit(): void {

    if (this.formId) {
      this.getFormThankYou();
    }
  }

  getFormThankYou() {
    this._formServices.getFormThankYou(this.formId).subscribe((resp: any) => {
      this.initializeThankyouForm(resp.data);
    });
  }

  initializeThankyouForm(data?: any) {
    this.thankYouForm = this.formBuilder.group({
      id: new FormControl(data?.id ? data.id : ''),
      title: new FormControl(data?.title ? data.title : ''),
      description: new FormControl(data?.description ? data.description : ''),
      show: new FormControl(data?.show ? data.show : false),
      form_id: new FormControl(this.formId)
    });
  }

  onThankYouFormSubmit() {
    if (this.thankYouForm.value.id) {
      this._formServices.putFormThankYou(this.thankYouForm.value, this.formId).subscribe((resp) => {
        this.getFormThankYou();
      });
    } else {
      this._formServices.postFormThankYou(this.thankYouForm.value).subscribe((resp) => {
        this.getFormThankYou();
      });
    }
  }


}
