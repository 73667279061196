<div style="height: 28rem; overflow-y: auto;" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="1000"
(scrolled)="onScroll()">
    <!-- <div class="d-flex align-items-center notes-select">
        <mat-select [(ngModel)]="notes_filter">
            <mat-option value="All Notes" class="notesfilteropt">All Notes</mat-option>
            <mat-option value="Dileep Notes" class="notesfilteropt">Dileep Notes</mat-option>
        </mat-select>
    </div> -->
    <div class="mt-3">
        <div class="bg-gray-light border-radius-8 p-3">
            <div class="notes bg-white border-radius-8">
                <!-- <input type="text" class="form-control title-input border-radius-0 m-0 pb-0 fw-600"
                    placeholder={{notesJson.title_placeholder}} aria-label="title" aria-describedby="basic-addon1"
                    *ngIf="notesTitleDisplay" [(ngModel)]="notesTitle"> -->
                <textarea class="form-control content-input border-radius-0 m-0 font-size-15" aria-label="With textarea"
                    placeholder={{notesJson.content_placeholder}} (click)="expandContent()"
                    [ngClass]="{'height-class' : contentExpand === true}" [(ngModel)]="notesContent"></textarea>
            </div>
            <div class="row col-md-8 mt-2" *ngIf="contentExpand">
                <div class="d-flex align-items-center">
                    <button class="btn border-radius-50 border-1 bg-primary fw-600 font-size-13 text-white px-4 me-3"
                        [disabled]="!notesContent" (click)="saveNotes()">{{notesJson.save_btn_label}}</button>
                    <button class="btn border-radius-50 border-1 bg-white fw-600 font-size-13 text-primary px-4 me-3"
                        (click)="cancel()">{{notesJson.cancel_btn_label}}</button>
                    <div class="d-flex align-items-center me-2 cursor" id="upload-btn" *ngIf="this.selected_type !== 'task'"
                        (change)="notesFileUpload($event)">
                        
                        <label class="fw-400 cursor d-flex align-items-center" for="attach-file">
                            <mat-icon style="transform: rotate(152deg);">attachment</mat-icon>
                            {{notesJson.attach_file_label}}
                            <input type="file" id="attach-file"
                                accept="image/*,video/*,audio/*,.pdf,.doc,.docx,.xml,.csv" hidden />
                        </label>
                    </div>
                    <!-- <button class="btn border-0 fw-400 font-size-16" *ngIf="!notesTitleDisplay"
                        (click)="displayNotesTitle()">{{notesJson.title_btn_label}}</button> -->
                </div>
            </div>
            <div class="mt-3 d-flex align-items-center" *ngIf="fileChosen">
                <img src="{{presignedurl}}{{thumbnail}}" height="50px" width="50px">
                <p class="mb-0 me-2 ms-2 font-size-14">{{fileChosen}}</p>
                <div class="progress-bar bg-primary border-radius-8 me-1" role="progressbar"
                    style="width: 50px; height:8px" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                <mat-icon (click)="removeFile()">close</mat-icon>
            </div>
        </div>
    </div>
    <ul class="list-group mt-2">
        <li class="list-group-item cursor" *ngFor="let note of currentPageNotesList">
            <div class="row d-flex align-items-center">
                <div class="col-md-10">
                    <div class="d-flex align-items-center">
                        <h6 class="bg-primary text-white border-radius-50 p-2 me-2 fw-400 font-size-11" *ngIf="note.info?.fname">{{note.info?.fname[0]}}{{note.info?.lname[0]}}</h6>
                        <h6 class="text-primary fw-600 font-size-16 me-2">{{note.info?.fname}} {{note.info?.lname}}</h6>
                        <h6 class="fw-400 font-size-13">{{note.created_at | dateConversion}}</h6>
                    </div>
                    <div class="ps-5">
                        <!-- <h6 class="text-primary fw-600 font-size-13" *ngIf="note.title">{{note.title}}</h6> -->
                        <p class="m-0 font-size-13 text-break">{{note.notes}}</p>
                        <div class="mt-3 d-flex align-items-center justify-content-around border-1 border-radius-50 px-3 py-1"
                            style="width:12rem;" *ngIf="note.file_name">
                            <img src="{{presignedurl}}{{note.media_url}}" height="25px" width="25px" *ngIf="note.media_url">
                            <p class="mb-0 me-2 ms-2 font-size-14 label-overflow" style="width:8rem;">
                                {{note.file_name}}</p>
                            <a class="d-flex align-items-center" href="{{presignedurl}}{{note.media_url}}" target="_blank" download>
                                <mat-icon style="color: rgb(0, 0, 0);">file_download_outlined</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 visiblity-on-hover">
                    <div class="d-flex justify-content-end align-items-center">
                        <div class="bg-white p-2 border-radius-50 d-flex align-items-center"
                            (click)="editNotes(note)">
                            <mat-icon style="font-size: 22px;">edit</mat-icon>
                        </div>
                        <mat-icon (click)="confirmDelete(note.id)">delete</mat-icon>
                    </div>
                </div>
            </div>
        </li>
    </ul>
    <div class="mt-2">
        <mat-paginator [length]="notes_total_count" [pageSize]="10" (page)="page($event)" aria-label="Select page"
            showFirstLastButtons>
        </mat-paginator>
    </div>
</div>