import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-table-popover',
  templateUrl: './table-popover.component.html',
  styleUrls: ['./table-popover.component.scss']
})
export class TablePopoverComponent {

  @Input() labelName: any;
  @Input() inputType: any;
  @Input() inputValue: any;
  @Input() formName: any;
  @Input() dropDownItems: any;
  @Input() selectedItem: any;
  @Output() update: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatMenuTrigger) popoverMenuTrigger!: MatMenuTrigger;
  @ViewChild(MatMenu) popoverMenu!: MatMenu;

  inputData: any = {};
  minDate = new Date();
  value: any;
  selectedItemData: any;
  modal: any;
  initialItem: any;

  ngOnInit() {
    this.inputData = {
      "labelName": this.labelName,
      "type": this.inputType,
      "value": this.inputValue,
      "formName": this.formName,
      "dropDownItems": this.dropDownItems,
      "selectedItem": this.selectedItem
    };

    this.modal = JSON.parse(JSON.stringify(this.inputValue || ""));
    this.inputValue = this.inputValue && this.inputValue !== "null null" ? this.inputValue : '-';
    this.initialItem = JSON.parse(JSON.stringify(this.selectedItem || ''));
    // this.selectedItem = this.selectedItem || ""

    console.log(this.inputData);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      // JSON object has changed, perform desired actions
      console.log('JSON object has changed:', changes);

      this.modal = JSON.parse(JSON.stringify(changes['inputValue'].currentValue || ""));
      // this.initialItem = JSON.parse(JSON.stringify(changes['selectedItem'].currentValue || ''));
    }
  }

  onChange(data: any) {
    console.log(data);
    this.selectedItem = data.id;
    this.selectedItemData = data;
    if (this.labelName === 'Owner')
      this.modal = `${data.fname} ${data.lname}`;
    else if (this.labelName === 'Company Name')
      this.modal = data.name;
    else if (this.labelName === 'Country') {
      this.selectedItem = data.Entity;
      this.modal = data.Entity;
    } else if (this.labelName === 'Tag') {
      this.modal = data.name;
    }
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");

  }

  onSave() {
    // Perform save logic
    const data: any = {
      labelName: this.labelName,
      type: this.inputType,
      value: this.modal,
      formName: this.formName
    };

    if (this.inputType === "dropdown") {
      data.value = this.selectedItemData;
    }
    if (/^(date|dateTime)$/.test(this.inputType)) {
      data.value = this.convert(this.modal);
    }

    if (this.inputType === "multiselect") {
      data.value = this.selectedItem;
    }

    // let inValidText = false;
    // if (this.inputType === "text") {
    //   inValidText = /^[A-Za-z\s]+$/.test(data.value)
    // }

    // if (!inValidText) {

    // } else {
    //   alert(inValidText)
    // }

    this.update.emit(data);
    this.closeMenu();
  }

  onCancel() {
    // Perform cancel logic
    this.modal = this.inputValue;
    this.selectedItem = this.initialItem;
    this.closeMenu();
  }

  closeMenu() {
    // Close the menu
    this.popoverMenuTrigger.closeMenu();
  }
}
