import { Component, Input, OnInit } from '@angular/core';
import { NOTESJSON } from '../../../../core/data/crm_modal_json';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/core/service/common.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'app-notes-crm',
  templateUrl: './notes-crm.component.html',
  styleUrls: ['./notes-crm.component.scss']
})
export class NotesCrmComponent implements OnInit {
  @Input() is_task!: string;
  @Input() details: any;
  notesJson = NOTESJSON[0];
  notes_filter = 'All Notes';
  contentExpand = false;
  notesTitleDisplay = false;
  notesTitle!: string;
  notesContent!: string;
  visabilityOnHover = false;
  editNoteId!: string;
  attachedFile: any;
  fileChosen: any;
  attachedFileDisplay = false;
  thumbnail: any;
  presignedurl = environment.preSendUrl;
  selected_id!: string;
  selected_type!: string;
  currentPageNotesList: any = [];
  totalNotesList: any = [];
  CurrentPage = 1;
  files: any;
  loggedInUser: any;
  source_name: any;
  notes_total_count!: number;
  pageEvent!: PageEvent;
  currentPage = 0;

  constructor(private route: ActivatedRoute, private service: CommonService, private awsUtilService: AwsUtilsService, private confirmationMsz: ConfirmationMszService, private crm_service: CrmService) { }

  ngOnInit(): void {
    // console.log(this.is_task);
    if (this.is_task === 'task') {
      this.selected_id = this.details;
      this.selected_type = this.is_task;
      this.getNotes(this.selected_id, 1);
    } else {
      this.route.queryParams.subscribe((params: any) => {
        // console.log("route params timeline - ", params);
        this.selected_id = params.id;
        this.selected_type = params.showtype;
        if (this.selected_id) {
          this.getNotes(this.selected_id, 1);
          if (this.selected_type === 'contact') this.getContactName(this.selected_id);
          else if (this.selected_type === 'lead') this.getLeadsName(this.selected_id);
          else if (this.selected_type === 'company') this.getCompanyName(this.selected_id);
        }
      });
    }
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  }

  getNotes(id: string, page: number) {
    this.service.getAllNotes(id, page).subscribe({
      next: (resp: any) => {
        this.notes_total_count = resp.count;
        this.currentPageNotesList = resp.data;
        console.log(this.currentPageNotesList);

        // this.totalNotesList.push(...this.currentPageNotesList);
      }
    });
  }

  expandContent() {
    this.contentExpand = true;
  }

  displayNotesTitle() {
    this.notesTitleDisplay = true;
  }

  saveNotes() {
    this.contentExpand = false;
    this.notesTitleDisplay = false;
    const body: any = {
      source_name: this.source_name,
      source_id: this.selected_id,
      source_type: this.selected_type,
      notes: this.notesContent,
      is_visible: false,
      info: {
        fname: this.loggedInUser.data.fname,
        lname: this.loggedInUser.data.lname
      }
    };
    if (this.editNoteId) {
      body["id"] = this.editNoteId;
      body["file_name"] = this.fileChosen ? this.fileChosen : '',
      body["media_url"] = this.attachedFile ? this.attachedFile : this.thumbnail,
      this.service.updateNotes(body).subscribe(() => {
        this.getNotes(this.selected_id, 1);
        this.notesContent = '';
        this.editNoteId = '';
        this.fileChosen = '';
        this.attachedFile = '';
        this.files = null;
      });
    } else if (this.selected_id) {
      body["file_name"] = this.files ? this.files.name : '',
      body["media_url"] = this.attachedFile ? this.attachedFile : '',
      this.service.createNotes(body).subscribe({
        next: (resp: any) => {
          // console.log("notes create - ", resp);
          this.getNotes(this.selected_id, 1);
          this.notesContent = '';
          this.editNoteId = '';
          this.fileChosen = '';
          this.attachedFile = '';
          this.files = null;
        }
      });
    }
  }

  cancel() {
    this.contentExpand = false;
    this.notesTitleDisplay = false;
    this.notesContent = '';
    this.editNoteId = '';
    this.fileChosen = '';
    this.thumbnail = '';
  }

  notesFileUpload(e: any) {
    this.files = e.target.files[0];
    // console.log(e.target.files[0]);
    this.fileChosen = this.files.name;
    this.awsUtilService.getUploadFileLink(e.target.files[0].type).subscribe((resp) => {
      // console.log("first api - ", resp);
      this.attachedFile = resp.data.file_name;
      this.awsUtilService.uploadFile(e.target.files[0], resp.data.url).subscribe(() => {//@typescript-eslint/no-empty-function
      });
    });
  }

  removeFile() {
    this.fileChosen = '';
    this.thumbnail = '';
  }

  editNotes(note: any) {
    this.editNoteId = note.id;
    if (note.media_url) {
      this.thumbnail = note.media_url;
      this.fileChosen = note.file_name;
    }
    this.notesContent = note.notes;
    this.contentExpand = true;
  }

  deleteNote(id: any) {
    this.service.deleteNotes(id).subscribe(() => {
      this.getNotes(this.selected_id, 1);
    });
  }

  confirmDelete(noteId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteNote(noteId);
      }
    });
  }

  onScroll(): void {
    this.getNotes(this.selected_id, ++this.CurrentPage);
  }

  getContactName(id: string) {
    this.crm_service.getContactById(this.selected_id).subscribe({
      next: (resp: any) => {
        this.source_name = resp.data[0].first_name + ' ' + resp.data[0].last_name;
      }
    });
  }

  getLeadsName(id: string) {
    this.crm_service.getLeadById(id).subscribe({
      next: (resp: any) => {
        this.source_name = resp.data[0].requirement_title;
      }
    });
  }

  getCompanyName(id: string) {
    this.crm_service.getCompanyById(id).subscribe({
      next: (resp: any) => {
        this, this.source_name = resp.data[0].name;
      }
    });
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getNotes(this.selected_id, this.currentPage);
  }

}
