import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-activity-modal',
  templateUrl: './activity-modal.component.html',
  styleUrls: ['./activity-modal.component.scss']
})
export class ActivityModalComponent {
  forModal = true;
  closeModal:any;
  constructor(private ngbActiveModal: NgbActiveModal) {}

  close(event: any) {
    //this.closeModal = event;
    if(event){
      this.cancel();
    }
  }

  cancel() {
    this.ngbActiveModal.dismiss();
  }

}
