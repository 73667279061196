<div class="container-fluid">
    <div class="row p-2" *ngIf="loggedInUser.data.role === 'ADMIN' || loggedInUser.data.role === 'MANAGER'">
        <div class="col-md-12">
            <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom mt-0"
                (navChange)="tabChange($event)">
                <li [ngbNavItem]="1" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">{{teamjson.heading}}</span>
                    </a>
                    <ng-template ngbNavContent *ngIf="templateTeamsList">
                        <div class="my-3">
                            <div class="row">
                                <div class="col-md-3"></div>
                                <div class="col-md-9 d-flex justify-content-end align-items-center">
                                    <button *ngIf="loggedInUser.data.role === 'ADMIN'"
                                        class="btn btn-primary d-flex align-items-center me-3" (click)="addTeam(false)">
                                        <mat-icon>add</mat-icon>
                                        {{teamjson.add_btn}}
                                    </button>
                                </div>

                            </div>
                            <div class="table-responsive table-height mt-4 roles-table">
                                <table class="table align-middle table-nowrap table-hover mb-0 users-table w-100">
                                    <thead>
                                        <tr>
                                            <th scope="col" *ngFor="let col of table_teams_cols"
                                                class="text-primary pb-3">
                                                {{col}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="cursor" *ngFor="let item of teamsList; let i = index">
                                            <td class="text-capitalize">{{ item.name }}</td>
                                            <td>{{ item.users_count }}</td>
                                            <td>
                                                <div class="dropdown" ngbDropdown>
                                                    <mat-icon [matMenuTriggerFor]="menuadd">more_vert</mat-icon>
                                                    <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                                                        <button mat-menu-item (click)="addTeam(false,item)"
                                                            class="py-2">
                                                            {{teamjson.edit_btn}}
                                                        </button>
                                                        <button mat-menu-item (click)="confirmTeams(item.id)"
                                                            class="py-2">
                                                            {{teamjson.delete_btn}}
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbNavContent *ngIf="!templateTeamsList">
                        <div class="my-3 w-50">
                            <div class="d-flex text-light">
                                <label class="cursor"
                                    (click)="addTeam(true)">{{teamjson.heading}}</label>&nbsp;>&nbsp;<label><span
                                        *ngIf="!addTeamForm.value.id">{{teamjson.add_btn}}</span>
                                    <span *ngIf="addTeamForm.value.id">{{teamjson.update_btn}}</span>
                                    {{teamjson.department}}</label>
                            </div>
                            <form [formGroup]="addTeamForm" (ngSubmit)="addnewTeamSubmit()" class="mt-3">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <label class="fw-600 mb-2">{{teamjson.field_name}} <small
                                                class="text-danger">*</small></label>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <input style="outline: none" matInput
                                                placeholder='{{staffjson.enter_placeholder}} {{teamjson.field_name}}'
                                                formControlName="name" #myFirstInput />
                                            <mat-error class="error text-danger d-flex"
                                                *ngIf="isteamSubmitted && teamForm['name'].errors">
                                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                                <span
                                                    *ngIf="teamForm['name'].errors['required']">{{teamjson.field_name}}
                                                    {{errorjson.required}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-sm-12 col-md-12 d-none">
                                        <label class="fw-600 mb-2">{{teamjson.field_people}} <small
                                                class="text-danger">*</small></label>
                                        <div class="d-flex align-items-center">
                                            <div class="avatar-group d-flex align-items-center">
                                                <div class="avatar-xs avatar-group-item bg-gray-dark fw-600 border-radius-50 d-flex align-items-center justify-content-center text-capitalize"
                                                    *ngFor="let item of teamsSelectedStaff">
                                                    {{item.fname[0]}}</div>
                                            </div>
                                            <mat-icon (click)="openStaffModel(selectStaffModal)">add</mat-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end">
                                    <button class="btn btn-light me-3" type="button"
                                        (click)="addTeam(true)">{{teamjson.close_btn}}</button>
                                    <button type="submit" class="btn btn-primary">{{teamjson.save_btn}}</button>
                                </div>
                            </form>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">{{staffjson.heading}}</span>
                    </a>
                    <ng-template ngbNavContent *ngIf="templateStaffListType">
                        <div class="my-3">
                            <div class="row">
                                <div class="col-md-3"></div>
                                <div class="col-md-9 d-flex justify-content-end align-items-center">
                                    <!-- <mat-form-field appearance="outline" class="me-3 mat-hint-none">
                                        <input style="outline: none" matInput placeholder="Search" />
                                        <mat-icon matPrefix>search</mat-icon>
                                    </mat-form-field> -->
                                    <button class="btn btn-primary d-flex align-items-center me-3"
                                        (click)="addStaff(false)">
                                        <mat-icon>add</mat-icon>
                                        {{staffjson.add_btn}}
                                    </button>
                                </div>

                            </div>
                            <div class="table-responsive table-height mt-4">
                                <table class="table align-middle table-nowrap table-hover mb-0 users-table w-100">
                                    <thead>
                                        <tr>
                                            <th scope="col" *ngFor="let col of table_cols" class="text-primary pb-3">
                                                {{col}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="cursor" *ngFor="let item of staffListArray; let i = index">
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="avatar-xs d-flex justify-content-between me-2 d-none">
                                                        <img class="rounded-circle avatar-xs"
                                                            src="../../../../assets/images/gigi-hadid-4.jpg" alt="">
                                                    </div>
                                                    {{ item?.fname + ' ' + item?.lname }}
                                                </div>
                                            </td>
                                            <td>{{ item?.email }}</td>
                                            <td>{{ item?.department_info ? item.department_info?.name : '' }}</td>
                                            <td>{{ item?.role_info?.name }}</td>
                                            <!-- <td>{{ item?.last_login }}</td> -->
                                            <td>
                                                <div class="d-flex" *ngIf="item?.utype !=='ADMIN'">
                                                    <label class="switch me-3">
                                                        <input type="checkbox" [(ngModel)]="item.status"
                                                            (change)="onStaffStatusChange(item)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                    <button mat-raised-button matTooltip="Edit"
                                                        aria-label="Button that displays a tooltip when focused or hovered over"
                                                        class="btn header-item border-0 p-0" mat-button><mat-icon
                                                            class="ms-2 cursor font-size-16"
                                                            (click)="addStaff(false,item)">edit</mat-icon></button>
                                                    <button mat-raised-button matTooltip="Info"
                                                        aria-label="Button that displays a tooltip when focused or hovered over"
                                                        class="btn header-item border-0 p-0" mat-button><mat-icon
                                                            class="font-size-20 me-1">info</mat-icon></button>
                                                    <button mat-raised-button matTooltip="services"
                                                        aria-label="Button that displays a tooltip when focused or hovered over"
                                                        class="btn header-item border-0 p-0" mat-button><mat-icon
                                                            class="font-size-20 me-1">card_travel</mat-icon></button>
                                                    <button mat-raised-button matTooltip="Day offs"
                                                        aria-label="Button that displays a tooltip when focused or hovered over"
                                                        class="btn header-item border-0 p-0" mat-button><mat-icon
                                                            class="font-size-20 me-1">access_time</mat-icon></button>
                                                    <button mat-raised-button matTooltip="Working hours"
                                                        aria-label="Button that displays a tooltip when focused or hovered over"
                                                        class="btn header-item border-0 p-0" mat-button><mat-icon
                                                            (click)="openWorkingHoursModel()"
                                                            class="font-size-20">calendar_today</mat-icon></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div>
                                    <mat-paginator [length]="staffListArray.length" [pageSize]="10"
                                        (page)="page($event)" aria-label="Select page" showFirstLastButtons
                                        hidePageSize="true">
                                    </mat-paginator>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbNavContent *ngIf="!templateStaffListType">
                        <div class="my-3 w-50">
                            <div class="d-flex text-light">
                                <label class="cursor" (click)="addStaff(true)">Users</label>&nbsp;>&nbsp;<label><span
                                        *ngIf="!addStaffForm.value.id">{{staffjson.new}}</span> <span
                                        *ngIf="addStaffForm.value.id">{{staffjson.update}}</span>
                                    {{staffjson.add_btn}}</label>
                            </div>
                            <div class="no-department text-danger font-size-16 fw-700 p-2"
                                *ngIf="teamsList.length === 0">
                                <label>Note: Please add atleast one department to create staff</label>
                            </div>
                            <form [formGroup]="addStaffForm" (ngSubmit)="addnewStaffSubmit()" class="mt-3">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label class="fw-600 mb-2">{{staffjson.field_name}} <small
                                                class="text-danger">*</small></label>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <input style="outline: none" matInput
                                                placeholder='{{staffjson.enter_placeholder}} {{staffjson.field_name}}'
                                                formControlName="fname" #myFirstStaffInput />
                                            <mat-error class="error text-danger d-flex"
                                                *ngIf="submited && f['fname'].errors">
                                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                                <span *ngIf="f['fname'].errors['required']">{{staffjson.field_name}}
                                                    {{errorjson.required}}</span>
                                                <span *ngIf="f['fname'].errors['pattern']">{{errorjson.invalid}}
                                                    {{staffjson.field_name}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label class="fw-600 mb-2">{{staffjson.field_lastname}} </label>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <input style="outline: none" matInput
                                                placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_lastname}}"
                                                formControlName="lname" />
                                            <mat-error class="error text-danger d-flex"
                                                *ngIf="submited && f['lname'].errors">
                                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                                <span *ngIf="f['lname'].errors['pattern']">{{errorjson.invalid}}
                                                    {{staffjson.field_lastname}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <label class="fw-600 mb-2">{{staffjson.field_email}} <small
                                                class="text-danger">*</small></label>

                                        <mat-form-field appearance="outline" class="w-100">
                                            <input style="outline: none" matInput
                                                placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_email}}"
                                                formControlName="email" type="email" />
                                            <mat-error class="error text-danger d-flex"
                                                *ngIf="submited && f['email'].errors">
                                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                                <span *ngIf="f['email'].errors['required']">{{staffjson.field_email}}
                                                    {{errorjson.required}}</span>
                                                <span *ngIf="f['email'].errors['pattern']">{{errorjson.invalid}}
                                                    {{staffjson.field_email}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                        <p class="text-light font-size-13">This email is used for user’s account
                                            settings and changing it will affect the user’s log in.</p>
                                    </div>

                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <label class="fw-600 mb-2">{{staffjson.field_number}} <small
                                                class="text-danger">*</small></label>
                                        <div class="d-flex">
                                            <mat-form-field appearance="outline" class="me-2">
                                                <mat-select formControlName="ccode"
                                                    placeholder="{{staffjson.enter_placeholder}} Country Code">
                                                    <mat-option>
                                                        <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                                            placeholderLabel="Search"
                                                            noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                                    </mat-option>
                                                    <mat-option *ngFor="let country of filteredBanks | async"
                                                        value={{country.country_code}}>{{country.country_code}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="w-100">
                                                <input style="outline: none" matInput
                                                    placeholder="{{staffjson.field_number}}"
                                                    formControlName="phone_number" type="text" />
                                                <mat-error class="error text-danger d-flex"
                                                    *ngIf="submited && f['phone_number'].errors">
                                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                                    <span
                                                        *ngIf="f['phone_number'].errors['required']">{{staffjson.field_number}}
                                                        {{errorjson.required}}</span>
                                                    <span
                                                        *ngIf="f['phone_number'].errors['pattern'] || f['phone_number'].errors['minlength'] || f['phone_number'].errors['maxlength']">{{errorjson.invalid}}
                                                        {{staffjson.field_number}}</span>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>


                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <label class="fw-600 mb-2">{{staffjson.field_badge}} </label>
                                            <label class="cursor mb-2" style="color: grey;" (click)="manageBadges()">
                                                <mat-icon
                                                    class="d-inline-flex align-items-center justify-content-end"
                                                    style="font-size: 14px;">settings</mat-icon>
                                                <span style="position: relative; bottom: 1px;" class="d-inline-flex align-items-center font-size-14">Manage Badges</span>
                                            </label>
                                        </div>

                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-select formControlName="badge"
                                                placeholder="Select {{staffjson.field_badge}}">
                                                <mat-option value="Best value"><span
                                                        class="bg-success py-1 px-2 border-radius-8">Best
                                                        value</span></mat-option>
                                                <mat-option value="Most popular"><span
                                                        class="bg-danger py-1 px-2 border-radius-8">Most
                                                        popular</span></mat-option>
                                                <mat-option value="Premium"><span
                                                        class="bg-warning py-1 px-2 border-radius-8">Premium</span></mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <label class="fw-600 mb-2">{{staffjson.field_role}} <small
                                                class="text-danger">*</small></label>

                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-select formControlName="role_id"
                                                placeholder="Select {{staffjson.field_role}}">
                                                <mat-option *ngFor="let role of rolesList"
                                                    [disabled]="role.name === 'ADMIN' ? true : false"
                                                    value={{role.id}}>{{role.name}}</mat-option>
                                            </mat-select>
                                            <mat-error class="error text-danger d-flex"
                                                *ngIf="submited && f['role_id'].errors">
                                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                                <span *ngIf="f['role_id'].errors['required']">{{staffjson.field_role}}
                                                    {{errorjson.required}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <label class="fw-600 mb-2">{{staffjson.field_team}} <small
                                                class="text-danger">*</small></label>
                                        <ng-select class="form-control" bindLabel="group_name" [multiple]="false"
                                            placeholder='Select Team' formControlName="department" [clearable]="false">
                                            <ng-option *ngFor="let team of teamsList" value="{{team.id}}">
                                                {{team.name}}
                                            </ng-option>
                                        </ng-select>
                                        <div style="height: 22px;">
                                            <mat-error class="error d-flex mt-2"
                                                *ngIf="submited && f['department'].errors">
                                                <mat-icon
                                                    class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                                                <span *ngIf="f['department'].errors['required']">
                                                    {{staffjson.field_team}}
                                                    {{errorjson.required}}</span>
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <label class="fw-600 mb-2">{{staffjson.field_location}} <small
                                                class="text-danger">*</small></label>
                                        <ng-select class="form-control" bindLabel="group_name" [multiple]="true"
                                            placeholder='Select Location' formControlName="locations"
                                            [clearable]="true">
                                            <ng-option *ngFor="let item of branches" value="{{item.id}}">
                                                {{item.name}}
                                            </ng-option>
                                        </ng-select>
                                        <mat-error class="error d-flex mt-2" *ngIf="submited && f['locations'].errors">
                                            <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                                            <span *ngIf="f['locations'].errors['required']">
                                                {{staffjson.field_location}}
                                                {{errorjson.required}}</span>
                                        </mat-error>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <mat-checkbox [checked]="addStaffForm.value.enable_for_booking"
                                            formControlName="enable_for_booking">{{staffjson.field_booking}}</mat-checkbox>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 password-field mt-3"
                                        *ngIf="!addStaffForm.value.id">
                                        <label class="fw-600 mb-2">{{staffjson.field_password}} <small
                                                class="text-danger">*</small></label><br>
                                        <!-- <mat-radio-group aria-label="Select an option" class="d-flex flex-column mb-2"
                                            formControlName="password_type" (change)="isPassword()">
                                            <mat-radio-button value="auto">Auto-generate password</mat-radio-button>
                                            <mat-radio-button value="manual">Create password manually</mat-radio-button>
                                        </mat-radio-group> -->
                                        <mat-form-field appearance="outline" class="w-100"><!-- *ngIf="passwordBox"-->
                                            <input style="outline: none" matInput
                                                placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_password}}"
                                                formControlName="password" [type]="hide ? 'password' : 'text'" />
                                            <mat-icon (click)="hide = !hide" class="cursor">{{
                                                hide ? "visibility_off" : "visibility"
                                                }}</mat-icon>
                                            <mat-error class="error text-danger d-flex"
                                                *ngIf="submited && f['password'].errors">
                                                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                                <span
                                                    *ngIf="f['password'].errors['required']">{{staffjson.field_password}}
                                                    {{errorjson.required}}</span>
                                                <span *ngIf="f['password'].errors['pattern']">{{errorjson.invalid}}
                                                    {{staffjson.field_password}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                        <label
                                            class="ms-1 mb- text-secondary mb-2 font-size-12">{{staffjson.text}}</label>
                                    </div>
                                </div>
                                <div class="row" *ngIf="addStaffForm.value.id">
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <label class="fw-600 mb-2">{{staffjson.field_designation}} </label>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <input style="outline: none" matInput
                                                placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_designation}}"
                                                formControlName="designation" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <label class="fw-600 mb-2">{{staffjson.field_specialisation}} </label>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <input style="outline: none" matInput
                                                placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_specialisation}}"
                                                formControlName="specialisation" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <label class="fw-600 mb-2">{{staffjson.field_qualification}} </label>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <input style="outline: none" matInput
                                                placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_qualification}}"
                                                formControlName="qualification" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <label class="fw-600 mb-2">{{staffjson.field_gender}} </label>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <input style="outline: none" matInput
                                                placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_gender}}"
                                                formControlName="gender" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <label class="fw-600 mb-2">{{staffjson.field_age}} </label>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <input style="outline: none" matInput
                                                placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_age}}"
                                                formControlName="age" />
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end">
                                    <button class="btn btn-light me-3" type="button"
                                        (click)="addStaff(true)">{{staffjson.close_btn}}</button>
                                    <button type="submit" class="btn btn-primary">
                                        {{staffjson.save_btn}}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">Roles</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-roles-permissions></app-roles-permissions>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">Day Off</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="my-3">
                            <div class="row">
                                <div class="col-md-3"></div>
                                <div class="col-md-9 d-flex justify-content-end align-items-center">
                                    <mat-form-field appearance="outline" class="me-3 mat-hint-none">
                                        <input matInput type="date" placeholder="select" matInput
                                            [matDatepicker]="picker">
                                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                    <button class="btn btn-primary d-flex align-items-center me-3"
                                        (click)="addDayoff()">
                                        <mat-icon>add</mat-icon>
                                        Add Company Dayoff
                                    </button>
                                </div>

                            </div>
                            <div class="table-responsive table-height mt-4">
                                <table class="table align-middle table-nowrap table-hover mb-0 users-table w-100">
                                    <thead>
                                        <tr>
                                            <th scope="col" *ngFor="let col of day_table_cols"
                                                class="text-primary pb-3">
                                                {{col}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="cursor" *ngFor="let item of daysListArray; let i = index">
                                            <td>{{ item?.title }}</td>
                                            <td>{{ item?.staff_name }}</td>
                                            <td>{{ item?.date }}</td>
                                            <td>
                                                <div class="d-flex">
                                                    <label class="switch me-3">
                                                        <input type="checkbox" [(ngModel)]="item.status">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <mat-icon class="ms-2 cursor font-size-16">edit</mat-icon>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div>
                                    <mat-paginator [length]="daysListArray.length" [pageSize]="10"
                                        (page)="page($event)" aria-label="Select page" showFirstLastButtons
                                        hidePageSize="true">
                                    </mat-paginator>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="customNav"></div>
        </div>
    </div>
    <ng-container *ngIf="!(loggedInUser.data.role === 'ADMIN' || loggedInUser.data.role === 'MANAGER')">
        <div class="d-flex align-items-center justify-content-center">
            <h6 class="text-secondary">You are not authorized to view this.</h6>
        </div>
    </ng-container>
</div>

<ng-template #selectStaffModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-primary">{{teamjson.field_member_list}}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="row px-2 mb-3">
            <label class="mb-2 fw-600 text-primary"> {{teamjson.field_member}} <small
                    class="text-danger">*</small></label>
            <ng-select class="form-control" bindLabel="group_name" [multiple]="true"
                placeholder='{{teamjson.select_placeholder}}' [(ngModel)]="selected_group">
                <ng-option *ngFor="let staff of staffListArray" value="{{staff.id}}">
                    {{staff.fname}}
                </ng-option>
            </ng-select>
        </div>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end mb-5">
        <button type="button" class="d-flex align-items-center btn btn-primary small"
            (click)="addToList()">{{teamjson.save_btn}}
        </button>
    </div>
</ng-template>