import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { PaymentsService } from 'src/app/core/service/payments.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent {
  errorjson = ERRORJSON[0];
  stripeForm!: FormGroup;
  stripeKeys: any = {};
  submitted = false;
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  constructor(
    private modal: NgbModal,
    private _paymentService: PaymentsService,
    private confirmationMsz: ConfirmationMszService
  ) {
    this.getKeys();
    this.initializeStripeForm();
  }


  initializeStripeForm(data?: any) {
    this.stripeForm = new FormGroup({
      sk_key: new FormControl(data?.sk_key ? data.sk_key : "", Validators.required),
      pk_key: new FormControl(data?.pk_key ? data.pk_key : "", Validators.required),
      mode: new FormControl("STAGE", Validators.required),
      currency: new FormControl(data?.currency ? data.currency : "INR", Validators.required)  // [TODO]: change this to get from general settings
    });
  }

  get f() {
    return this.stripeForm.controls;
  }

  openModal(ref: any, type: string) {
    this.modal.open(ref, { size: 'md' });
  }

  getKeys() {
    this._paymentService.getStripeKeys().subscribe((resp: any) => {
      this.stripeKeys = resp;
    });
  }

  onStripeKeySave() {
    this.submitted = true;
    if (this.stripeForm.invalid) {
      this.stripeForm.markAllAsTouched();
      return;
    }
    if (this.stripeKeys.data.gateway_id) {
      this._paymentService.updateStripeKeys(this.stripeKeys.data.gateway_id, this.stripeForm.value).subscribe(() => {
        this.getKeys();
        this.modal.dismissAll();
      });
    } else {
      this._paymentService.postStripeKeys(this.stripeForm.value).subscribe(() => {
        this.getKeys();
        this.modal.dismissAll();
      });
    }
  }

  disconnectStrip() {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this._paymentService.deleteStripeKeys(this.stripeKeys?.data?.gateway_id).subscribe(() => {
          this.getKeys();
        });
      }
    });
  }
}
