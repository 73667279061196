import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HomeService } from 'src/app/core/service/home.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { ClientsService } from 'src/app/core/service/clients.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-home-management',
  templateUrl: './home-management.component.html',
  styleUrls: ['./home-management.component.scss']
})
export class HomeManagementComponent implements OnInit {
  show_type = 'task';
  id: any;
  taskDetails: any;
  stageList: any;
  relatedSourceDetails: any;
  cardFields: any;
  storeId = '';

  constructor(
    private route: ActivatedRoute,
    private _location: Location,
    private homeService: HomeService,
    private crm_service: CrmService,
    private client_service: ClientsService,
    private setupService: SetUpService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      if (params.show_type) this.show_type = params.show_type;
      if (params.id) this.id = params.id;
    });
    if (this.show_type === 'task') {
      this.getTaskById();
    } else if (this.show_type === 'appointment') {
      console.log(this.id);
    } else {
      this.getActivitiesTaskById();
    }
  }
  getActivitiesTaskById() {
    this.homeService
      .getActivitiesTaskById(this.id)
      .subscribe((response: any) => {
        this.taskDetails = response.data;
      });
  }

  getTaskById() {
    this.homeService.getTaskById(this.id).subscribe((response: any) => {
      this.taskDetails = response.data;
      if (response.data.length !== 0) {
        if (response.data.source_type === 'lead') {
          this.getLeadById(response.data.source_id);
        } else if (response.data.source_type === 'contact') {
          this.getContactById(response.data.source_id);
        } else if (response.data.source_type === 'company') {
          this.getCompanyById(response.data.source_id);
        } else if (response.data.source_type === 'project') {
          this.getProjectById(response.data.source_id);
        } else if (response.data.source_type === 'deal') {
          this.getFieldsBasedonCardId(response.data.source_id);
        }
      }
    });
  }

  getLeadById(id: string) {
    this.crm_service.getLeadById(id).subscribe({
      next: (resp: any) => {
        console.log(resp, "lead");
        this.relatedSourceDetails = resp.data[0];
        if (this.relatedSourceDetails.tags) this.getTagData(this.relatedSourceDetails.tags, 'lead');
      }
    });
  }

  getContactById(id: string) {
    this.crm_service.getContactById(id).subscribe({
      next: (resp: any) => {
        // console.log(resp,"contact");
        this.relatedSourceDetails = resp.data[0];
        if (this.relatedSourceDetails.tags) this.getTagData(this.relatedSourceDetails.tags, 'contact');
      }
    });
  }

  getCompanyById(id: string) {
    this.crm_service.getCompanyById(id).subscribe({
      next: (resp: any) => {
        // console.log(resp, "company");
        this.relatedSourceDetails = resp.data[0];
        if (this.relatedSourceDetails.tags) this.getTagData(this.relatedSourceDetails.tags, 'company');
      }
    });
  }

  getProjectById(id: string) {
    this.client_service.getProjectDetailsById(id).subscribe({
      next: (resp: any) => {
        // console.log(resp, "project");
        this.relatedSourceDetails = resp.data;
        if (this.relatedSourceDetails.tag) this.getTagData(this.relatedSourceDetails.tag, 'project');
      }
    });
  }

  getTagData(id: string, type: string) {
    this.settingsService.getTagById(id).subscribe({
      next: (resp: any) => {
        if (type === 'project') {
          this.relatedSourceDetails.tag = resp.data.name;
          this.relatedSourceDetails.colour = resp.data.colour;
        } else if (type === 'contact' || type === 'lead' || type === 'company') {
          this.relatedSourceDetails.tags = resp.data.name;
          this.relatedSourceDetails.colour = resp.data.colour;
        }
        console.log(this.relatedSourceDetails);
        
      }
    });
  }

  onbackChange() {
    this._location.back();
  }

  //pipelineDetails
  getFieldsBasedonCardId(cardId: string) {
    this.crm_service.getFieldsbyCardId(cardId).subscribe((resp: any) => {
      this.cardFields = resp.data;

      this.cardFields.forEach((ele: any) => {
        if (ele.url === '/consultations/category/') {
          this.getAPTCategoriesList();
        } else if (ele.url === 'consultations/consultations/') {
          this.getAppointments(ele);
        } else if (ele.url === 'stores/?/categories/' && ele.data) {
          this.getStoreId('category');
        } else if (ele.url === 'product/stores/?/products/' && ele.data) {
          this.getStoreId('products', ele);
        } else if (ele.url === 'crm_service/Contacts') {
          this.getContacts(ele);
        } else if(ele.url === 'crm_service/Companys'){
          this.getCompanybyId(ele);
        }
      });
    });
  }

  /**********************************Fields API Starts*************************************/
  getAPTCategoriesList() {
    this.setupService.getAllCategories().subscribe({
      next: (resp: any) => {
        this.cardFields.filter((ele: any) => {
          if (ele.url === '/consultations/category/') {
            const selectedItem = resp.data.filter((item: any) => item.id === ele.data);
            if (selectedItem.length !== 0) ele.data_name = selectedItem[0].name;
          }
        });
      }
    });
  }

  getAppointments(ele: any) {
    this.setupService.getConsultationByID(ele.data).subscribe({
      next: (resp: any) => {
        ele.data_name = resp.data.name;
      }
    });
  }

  getStoreId(type: string, ele?: any) {
    this.setupService.getStore().subscribe((resp: any) => {
      this.storeId = resp.data.id;
      if (type === 'category') {
        this.getProductCategory();
      } else {
        this.getProduct(ele);
      }
    });
  }

  getProductCategory() {
    this.setupService.getProductCategories(this.storeId).subscribe((resp: any) => {
      this.cardFields.filter((ele: any) => {
        if (ele.url === 'stores/?/categories/') {
          const selectedItem = resp.data.filter((item: any) => item.id === ele.data);
          if (selectedItem.length !== 0) ele.data_name = selectedItem[0].name;
        }
      });
    });
  }

  getProduct(ele: any) {
    this.setupService.getProductById(this.storeId, ele.data).subscribe((resp: any) => {
      ele.data_name = resp.data.name;
    });
  }

  getContacts(ele: any) {
    this.crm_service.getContactById(ele.data).subscribe((resp: any) => {
      ele.data_name = resp.data.name;
    });
  }

  getCompanybyId(ele: any){
    this.crm_service.getCompanyById(ele.data).subscribe((resp: any)=>{
      ele.data_name = resp.data[0].name;
    });
  }
  /**********************************Fields API Ends**************************************/

}
