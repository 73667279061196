import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskBoardService } from 'src/app/core/service/task-board.service';

@Component({
  selector: 'app-task-detail-modal',
  templateUrl: './task-detail-modal.component.html',
  styleUrls: ['./task-detail-modal.component.scss']
})
export class TaskDetailModalComponent implements OnInit {
  @Input() details: any;
  @Input() is_task!: string;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  status_dropdown = false;
  contentExpand = false;
  notesContent!: string;
  activeId: any = 1;
  files: File[] = [];
  about_task!: string;
  task_id!: string;
  selected_id!: string;
  selected_type!: string;

  constructor(private taskboard_service: TaskBoardService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.about_task = this.is_task;
    this.task_id = this.details.id;
    // console.log(this.details);
    this.route.queryParams.subscribe((params: any) => {
      this.selected_id = params.id;
      this.selected_type = params.showtype;
    });
  }

  tabChange(event: any) {
    this.activeId = event.nextId;
  }

  taskStatusUpdate(status: any) {
    this.taskboard_service.updateTask(this.selected_id, this.selected_type, this.details).subscribe(() => { //@typescript-eslint/no-empty-function

    });
  }
}
