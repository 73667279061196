import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/core/service/settings.service';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  public selection = new SelectionModel<any>(true, []);
  @ViewChild('paginator') paginator!: MatPaginator;
  modalRef!: NgbModalRef;
  addTagForm!: FormGroup;
  submitted = false;
  column_names = ['Tag Name', 'Color', 'Actions'];
  tagColorsArray = ['#ff0000', '#0000ff', '#008000', '#ffff00', '#ffc0cb', '#ffa500', '#808080', '#ff00ff', '#00ff00', '#008080', '#f0ffff', '#5f9ea0', '#d2691e', '#daa520', '#e6e6fa', '#66cdaa', '#808000'];
  tagsListArray: any[] = [];
  totalTags: any;
  currentPage = 1;
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);

  constructor(private modalService: NgbModal, private settingsService: SettingsService,
    private confirmationMsz: ConfirmationMszService) { }

  ngOnInit() {
    this.initilizeTagForm();
    this.getTags();
  }

  initilizeTagForm(data?: any) {
    this.addTagForm = new FormGroup({
      name: new FormControl(data ? data.name : '', Validators.required),
      colour: new FormControl(data ? data.colour : '#FFFFFF'),
      id: new FormControl(data ? data.id : '')
    });
  }

  get f() {
    return this.addTagForm.controls;
  }

  getTags() {
    this.settingsService.getTags(this.currentPage).subscribe((resp: any) => {
      this.tagsListArray = resp.data;
      this.totalTags = resp?.count;
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.addTagForm.valid) {
      if (this.addTagForm.value.id) {
        this.settingsService.putTags(this.addTagForm.value).subscribe(() => {
          this.getTags();
          this.modalRef.dismiss();
        });
      } else {
        this.settingsService.postTags(this.addTagForm.value).subscribe(() => {
          this.getTags();
          this.modalRef.dismiss();
        });
      }
    }
  }

  selectedTagColor(color: any) {
    this.addTagForm.patchValue({ colour: color });
  }

  page(e: PageEvent) {
    if (e.pageIndex > e.previousPageIndex!) {
      this.currentPage = e.pageIndex + 1;
    } else if (e.pageIndex < e.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getTags();
  }

  deleteMeeting(id: any) {
    this.confirmationMsz.delete()
      .then((data) => {
        if (data.value)
          this.settingsService.deletetTags(id).subscribe((resp: any) => {
            this.getTags();
          });
      });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tagsListArray.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.tagsListArray.forEach((row) => {
      this.selection.select(row);
    });
  }

  openAddTagModal(content: any, data?: any) {
    this.submitted = false;
    this.modalRef = this.modalService.open(content, { size: 'md', centered: true });
    this.initilizeTagForm(data);
  }

}
