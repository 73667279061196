import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../service/auth.service';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService, private authservice: AuthService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap(evt => {
          if (evt instanceof HttpResponse) {
            if (request.url.includes('Contacts') && request.method === 'POST') {
              '';
            } else if (evt.body && request.method !== 'GET') {
              this.toastr.success(evt.body.message);
            }
          }
        }),
        // retry(1),
        catchError((error: HttpErrorResponse) => {
          if (error.error instanceof ErrorEvent) {
            //client side error
            // errormsz = `error: ${error.error.message}`;
            this.toastr.error(error.error.message);
          } else {
            //server side error
            if (error.status === 400 || error.status === 404) {
              this.toastr.error(error.error.message.includes('non_field_errors:') ? error.error.message.replace('non_field_errors:','') :error.error.message);
            } else if (error.status === 403) {
              this.toastr.error("Your session has expired, please log in again");
              this.authservice.logout();
            }
          }
          return throwError(error.error);
        })
      );
  }
}