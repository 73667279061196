<div class="mb-0">
    <div class="p-3">
        <div class="header p-4 d-flex justify-content-between bg-grey-light border-radius-8">
            <div class="">
                <h6 class="fw-600">Design line</h6>
                <label class="text-light font-size-13">Dinesh B</label>
            </div>
            <div>
                <mat-select class="status_dropdown bg-primary border-radius-8 p-2 px-3"
                    [(ngModel)]="appointment_status">
                    <mat-option value="pending">Pending</mat-option>
                    <mat-option value="completed">Completed</mat-option>
                </mat-select>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-8 border-right-1">
                <div class="row mt-3 d-flex justify-content-between align-items-center">
                    <div class="col-6">
                        <div class="mb-2">
                            <label class="text-light mb-0 font-size-13">Customer Name</label>
                            <p class="fw-600 mb-0">Saikrishna</p>
                        </div>
                        <div class="mb-2">
                            <label class="text-light mb-0 font-size-13">Email id</label>
                            <p class="fw-600 mb-0">sai@gmail.com</p>
                        </div>
                        <div class="mb-2">
                            <label class="text-light mb-0 font-size-13">Mobile no</label>
                            <p class="fw-600 mb-0">+91969665885</p>
                        </div>
                        <div class="mb-2">
                            <label class="text-light mb-0 font-size-13">Quantity</label>
                            <p class="fw-600 mb-0">* 4</p>
                        </div>
                        <div class="mb-2">
                            <label class="text-light mb-0 font-size-13">Total Pice</label>
                            <div class="d-flex">
                                <p class="fw-600 mb-0 mx-2">₹ 9,599</p>
                                <p
                                    class="text-name bg-success text-success border-radius-7 d-inline-flex align-items-center p-1 px-2">
                                    Paid</p>
                            </div>
                        </div>
                    </div>
                    <div class="text-end col-6">
                        <div class="mb-2">
                            <label class="text-light mb-0 font-size-13">Booked at</label>
                            <p class="fw-600 mb-0">22-Jan-23, 11:35 PM</p>
                        </div>
                        <div class="mb-2">
                            <label class="text-light mb-0 font-size-13">Order type</label>
                            <p class="fw-600 mb-0">Physical Product</p>
                        </div>
                        <div class="me-0 m-auto">
                            <label class="text-light mb-0 font-size-13">Address</label>
                            <p class="fw-500 mb-0 text-left">Street: 24,1st Floor, Sushila Bhavan, Adi Marzban Path,
                                Ballard
                                Estate
                                City: Mumbai
                                State/province/area: Maharashtra
                                Phone number 02222614694
                                Zip code 400038</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="p-3">
                    <h6 class="fw-700">Order details</h6>
                    <div class="m-auto text-center mb-2">
                        <img src="../../../../assets/images/product-sample.webp" height="80px" width="80px">
                    </div>
                    <h6 class="fw-700">keratin free shampoo</h6>
                    <div class="d-flex">
                        <label class="me-3 text-light">Variants</label>
                        <p
                            class="text-name bg-success text-success border-radius-7 d-inline-flex align-items-center p-1 px-2">
                            Paid</p>
                    </div>
                    <div class="d-flex">
                        <label class="me-3 text-light">Pricing :</label>
                        <p class="fw-700">₹ 3,599</p>
                    </div>
                    <div class="">
                        <label class="text-light">Description</label>
                        <p>Hello beautifully smooth, glossy glass like
                            hair. Like a Brazilian Blow publishing and graphic design, Lorem ipsum is a placeholder text
                            commonly used to demonstrate the visual form....</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>