import { Component, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DEALSJSON } from '../../../../core/data/crm_modal_json';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


const fileJSON = [
  {
    position: 1,
    deal_name: 'Murali',
    pipeline_name: 'Cross border team',
    amount: '5000000',
    stage: 'Lead'
  },
  {
    position: 2,
    deal_name: 'Kumar',
    pipeline_name: 'Cross border team',
    amount: '5000000',
    stage: 'Lead'
  },
  {
    position: 3,
    deal_name: 'Kiran',
    pipeline_name: 'Cross border team',
    amount: '5000000',
    stage: 'Lead'
  },

  {
    position: 5,
    deal_name: 'Veman',
    pipeline_name: 'Cross border team',
    amount: '5000000',
    stage: 'Lead'
  }

];

@Component({
  selector: 'app-deals-modal',
  templateUrl: './deals-modal.component.html',
  styleUrls: ['./deals-modal.component.scss']
})
export class DealsModalComponent implements OnInit {
  dealsJson = DEALSJSON[0];
  submitted = false;
  public show = false;
  public buttonName = 'Show';

  public isShow = false;
  public fieldName = 'Show';

  public addProduct = false;
  public addProductName = 'Show';

  dealForm!: FormGroup;


  files: File[] = [];
  filesListArray = fileJSON;

  filtersLables = [
    {
      position: 1,
      lableName: 'Deal Name'
    },
    {
      position: 2,
      lableName: 'Amount'
    },
    {
      position: 3,
      lableName: 'Stage'
    },
    {
      position: 4,
      lableName: 'Deal Owner'
    },
    {
      position: 5,
      lableName: 'Contact Number'
    },
    {
      position: 6,
      lableName: 'Email'
    },
    {
      position: 7,
      lableName: 'Contact Name'
    },
    {
      position: 8,
      lableName: 'Company Name'
    }
  ];

  importModalType: any;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.Initilize_dealsForm();
  }

  Initilize_dealsForm() {
    this.dealForm = this.formBuilder.group({
      deal_name: ['', [Validators.required]],
      stage: ['', [Validators.required]],
      contact_number: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      email: ['', [Validators.required]],
      follow_up_date: ['', [Validators.required]],
      contact_name: ['', Validators.required],
      description: ['', Validators.required],
      company_name: ['', Validators.required],
      lead_source: ['', Validators.required],
      webiste: ['', Validators.required],
      dob: ['', Validators.required],

      // add products
      product_name: ['', Validators.required],
      product_code: ['', Validators.required],
      product_category: ['', Validators.required],
      unit_price: ['', Validators.required]
    });
  }

  get f() {
    return this.dealForm.controls;
  }

  modalRef!: NgbModalRef;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = [
    'Deal Name',
    'Pipeline Name',
    'Amount',
    'Stage',
    'Plus icon'
  ];

  //filter dropdown fun
  selectFilterLable($event: any) {
    $event.stopPropagation();
    $event.preventDefault();

    if ($event.target) {
      $event.target.classList.toggle('selected');
    }
  }

  importEexportModalDialog(importExportModel: any, type: any) {
    this.modalService.open(importExportModel, { size: 'md' });
    this.importModalType = type;
  }

  expandDeals() {
    this.show = !this.show;
    if (this.show) this.buttonName = 'Hide';
    else this.buttonName = 'Show';
  }

  fieldSetting() {
    this.isShow = !this.isShow;
    if (this.isShow) this.fieldName = 'Hide';
    else this.fieldName = 'Show';
  }

  addProducts() {
    if (this.addProduct) this.fieldName = 'Hide';
    else this.addProductName = 'Show';
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = fileJSON.length;
    return numSelected === numRows;
  }

  onSubmit() {
    this.submitted = true;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : fileJSON.forEach((row) => this.selection.select(row));
  }
}
