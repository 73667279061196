import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-followup-detail',
  templateUrl: './followup-detail.component.html',
  styleUrls: ['./followup-detail.component.scss']
})
export class FollowupDetailComponent {
  status_dropdown = 'pending';
  contentExpand = false;
  notesContent!: string;
  files: File[] = [];
  @Input() taskId!: string;
  expandContent() {
    this.contentExpand = true;
  }

  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }
}
