import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalendarComponent } from './calendar/calendar.component';
// import { FinanceComponent } from './finance/finance.component';
// import { CreateFormComponent } from './form-builder/create-form/create-form.component';
import { FormBuilderComponent } from './form-builder/form-builder.component';
import { FormSettingsComponent } from './form-builder/form-settings/form-settings.component';
import { HeaderCalendarComponent } from './header-calendar/header-calendar.component';
import { HomeComponent } from './home/home.component';
import { SettingsComponent } from './settings/settings.component';
import { FormSubmissionsComponent } from './form-builder/form-submissions/form-submissions.component';
import { FinanceComponent } from './finance/finance.component';
import { HomeManagementComponent } from './home/home-management/home-management.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { OnboardingScreensComponent } from './onboarding-screens/onboarding-screens.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'onboarding', component: OnboardingScreensComponent },
  { path: 'home/task/home-management', component: HomeManagementComponent },
  { path: 'home/follow-up/home-management', component: HomeManagementComponent },
  { path: 'home/calls/home-management', component: HomeManagementComponent },
  { path: 'home/email/home-management', component: HomeManagementComponent },
  { path: 'home/meetings/home-management', component: HomeManagementComponent },
  { path: 'home/appointments/home-management', component: HomeManagementComponent },
  { path: 'home/calendar-bookings/home-management', component: HomeManagementComponent },
  { path: 'crm', loadChildren: () => import('./crm/crm.module').then(m => m.CrmModule) },
  { path: 'setup', loadChildren: () => import('./setup/setup.module').then(m => m.SetupModule) },
  { path: 'form-builder', component: FormBuilderComponent },
  // { path: 'create-formbuilder', component: CreateFormComponent },
  { path: 'form-settings', component: FormSettingsComponent },
  { path: 'form-submissions', component: FormSubmissionsComponent },
  { path: 'inbox', loadChildren: () => import('./inbox/inbox.module').then(m => m.InboxModule) },
  { path: 'clients', loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule) },
  // { path: 'finance', component: FinanceComponent, loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule) },
  { path: 'finance', component: FinanceComponent, loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule) },
  { path: 'settings', component: SettingsComponent, loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
  { path: 'calendar', component: CalendarComponent, loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule) },
  { path: 'campaign', loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule) },
  { path: 'chats', loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule) },
  { path: 'pipelines', loadChildren: () => import('./pipeline/pipeline.module').then(m => m.PipelineModule) },
  { path: 'header-calendar', component: HeaderCalendarComponent },
  { path: 'user-profile', component: UserProfileComponent},
  { path: 'automation', loadChildren: () => import('./automation/automation.module').then(m => m.AutomationModule) }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
