<div class="mb-0">
    <div class="p-3">
        <div class="header p-4 d-flex justify-content-between bg-grey-light border-radius-8">
            <div class="">
                <h6 class="fw-600">{{appointmentdata?.consultation_id.name}}</h6>
                <label class="text-light font-size-13">{{appointmentdata?.info?.staff?.staffName}}</label>
            </div>
            <div>
                <!-- <mat-select class="status_dropdown bg-primary border-radius-8 p-2 px-3"
                    [(ngModel)]="appointment_status">
                    <mat-option value="pending">Pending</mat-option>
                    <mat-option value="completed">Completed</mat-option>
                </mat-select> -->
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-8 border-right-1">
                <div class="bg-orange orange-border p-2 text-center border-radius-8 font-size-16"
                    *ngIf="appointmentdata?.source_type"> You got this source
                    from
                    <b>{{appointmentdata?.source_type}}</b>
                </div>
                <div class="mt-3 d-flex justify-content-between align-items-center">
                    <div>
                        <div class="d-flex align-items-center mb-2">
                            <mat-icon style="color: #00000059;">account_circle</mat-icon>
                            <p class="mb-0 ms-2" *ngIf="appointmentdata?.info?.user?.fname">
                                {{appointmentdata?.info?.user?.fname + ' ' +
                                appointmentdata?.info?.user?.lname}}</p>
                            <p class="mb-0 ms-2" *ngIf="appointmentdata?.info?.user?.first_name">
                                {{appointmentdata?.info?.user?.first_name + ' ' +
                                appointmentdata?.info?.user?.last_name}}</p>
                        </div>
                        <div class="d-flex align-items-center mb-2">
                            <mat-icon style="color: #00000059;">mail_outline</mat-icon>
                            <p class="mb-0 ms-2"> {{appointmentdata?.info?.user?.email}}</p>
                        </div>
                        <div class="d-flex align-items-center mb-2">
                            <mat-icon style="color: #00000059;">call</mat-icon>
                            <p class="mb-0 ms-2">{{appointmentdata?.info?.user?.phone_number}}</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        <div>
                            <label class="text-light mb-0 font-size-13">Assigned Staff</label>
                            <p class="fw-600 mb-0">{{appointmentdata?.info?.staff?.staffName}}</p>
                        </div>
                        <mat-icon>account_circle</mat-icon>
                    </div>
                </div>

                <mat-divider class="mx-2"></mat-divider>
                <div class="my-3 border-0 d-flex align-items-center justify-content-between">
                    <div>
                        <label class="mb-1 font-size-14">Booked at</label>
                        <p class="fw-600 mb-0">{{appointmentdata?.created_at | dateConversion}}</p>
                    </div>
                    <div>
                        <p class="mb-1">Status</p>
                        <mat-form-field appearance="outline" class="status-select mat-hint-none"
                            *ngIf="appointmentdata?.appointment_status==='HOLD'">
                            <mat-icon class="d-inline-flex align-items-center p-0 ps-3"
                                *ngIf="appointmentdata?.appointment_status!=='HOLD'"
                                [ngClass]="{'dot-approved': appointmentdata?.appointment_status === 'ACCEPTED','dot-pending': appointmentdata?.appointment_status === 'NO_SHOW','dot-rejected': appointmentdata?.appointment_status === 'REJECTED'}"
                                style="font-size: 9px;" matPrefix>circle</mat-icon>
                            <mat-select [(ngModel)]="appointmentdata.appointment_status" placeholder="Select" class="pt-1"
                                (selectionChange)="onStatusChange()">
                                <mat-option value="ACCEPTED">Accept</mat-option>
                                <mat-option value="REJECTED">Reject</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="status-select mat-hint-none"
                            *ngIf="appointmentdata?.appointment_status==='ACCEPTED'">
                            <mat-icon class="d-inline-flex align-items-center p-0 ps-3"
                                [ngClass]="{'dot-approved': appointmentdata?.appointment_status === 'ACCEPTED','dot-pending': appointmentdata?.appointment_status === 'NO_SHOW','dot-rejected': appointmentdata?.appointment_status === 'REJECTED'}"
                                style="font-size: 9px;" matPrefix>circle</mat-icon>
                            <mat-select [(ngModel)]="appointmentdata.appointment_status" class="pt-1"
                                (selectionChange)="onStatusChange()">
                                <mat-option value="ACCEPTED">Accepted</mat-option>
                                <mat-option value="COMPLETED">Completed</mat-option>
                                <mat-option value="NO_SHOW">No Show</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div *ngIf="appointmentdata?.appointment_status!=='ACCEPTED' && appointmentdata?.appointment_status!=='HOLD'"
                            class="badge-soft-info border-radius-4 fw-600 text-center font-size-12 p-2 text-capitalize"
                            style="width: 100px;"
                            [ngClass]="{'bg-low':appointmentdata?.appointment_status === 'COMPLETED','bg-high':appointmentdata?.appointment_status === 'REJECTED','bg-medium':appointmentdata?.appointment_status === 'NO_SHOW'}">
                            {{appointmentdata?.appointment_status | titlecase}}
                        </div>
                    </div>
                </div>
                <mat-divider class="mx-2"></mat-divider>
                <div class="my-3 border-0 d-flex align-items-center justify-content-between">
                    <div>
                        <p class="mb-0">Appointment Type</p>
                        <div class="d-flex align-items-center font-size-16 my-2 fw-700">
                            <div class="meet-type me-2"
                                [ngClass]="{'dot-online': appointmentdata?.meeting_type === 'ON_LINE','dot-offline': appointmentdata?.meeting_type === 'OFF_LINE','dot-doorstep': appointmentdata?.meeting_type === 'DOOR_STEP'}">
                            </div>
                            {{appointmentdata?.meeting_type}}
                        </div>
                        <div class="d-flex justify-content-between align-items-center"
                            *ngIf="appointmentdata?.meeting_type ==='ON_LINE'">
                            <p class="border-light-1 border-radius-6 px-3 py-2 mb-0 me-2 text-light fw-600"
                                style="width: 450px;">
                                {{appointmentdata?.meeting_info ? appointmentdata?.meeting_info?.join_url :
                                "Meeting Link not available, Please regenerate a meeting link "}}</p>
                            <mat-icon style="color:#00000080; cursor:pointer"
                                *ngIf="appointmentdata?.meeting_info?.join_url"
                                [cdkCopyToClipboard]="appointmentdata?.meeting_info.join_url">content_copy</mat-icon>
                        </div>
                        <div class="font-size-14" *ngIf="appointmentdata?.meeting_type ==='DOOR_STEP'">
                            <span class="fw-600">{{appointmentdata?.info?.address.name}}</span>&nbsp;
                            {{appointmentdata?.info?.address.address_one}},&nbsp;{{appointmentdata?.info?.address.address_two}}&nbsp;
                            {{appointmentdata?.info?.address.city}},&nbsp;{{appointmentdata?.info?.address.state}},&nbsp;<br>
                            {{appointmentdata?.info?.address.country}},&nbsp;{{appointmentdata?.info?.address.zipcode}}
                        </div>
                        <div class="font-size-14" *ngIf="appointmentdata?.meeting_type ==='OFF_LINE'">
                            <span class="fw-600">{{locationsDetails?.name}}</span>&nbsp; {{locationsDetails?.location}}
                        </div>
                    </div>
                    <div>
                        <div class="d-flex align-items-center justify-content-end">
                            <mat-icon class="d-inline-flex align-items-center ms-2"
                                style="color:#14353C;font-size: 16px;">calendar_today</mat-icon>
                            <p class="mb-0">Date & time</p>
                        </div>
                        <p class="my-2 fw-700 d-flex align-items-center justify-content-end">{{appointmentdata?.date}}
                        </p>
                        <p class="fw-700 mb-0 d-flex align-items-center justify-content-end">{{appointmentdata?.slot}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="p-3">
                    <h6 class="fw-700">Appointment details</h6>
                    <div class="m-auto text-center mb-2">
                        <img src="{{presignedurl}}{{appointmentdata?.consultation_id?.image}}"
                            onerror="this.src='../../../../assets/images/product-sample.webp'" height="80px"
                            width="80px">
                    </div>
                    <h6 class="fw-700">{{appointmentdata?.consultation_id.name}}</h6>
                    <div class="d-flex">
                        <label class="me-3 text-light">Duration :</label>
                        <p style="background-color: #81d2e4;"
                            class="text-name text-success border-radius-7 d-inline-flex align-items-center p-1 px-2">
                            {{appointmentdata?.consultation_id.duration}} min</p>
                    </div>
                    <div class="d-flex">
                        <label class="me-3 text-light">Pricing :</label>
                        <p class="fw-700">{{appointmentdata?.amount | currencyConversion}}</p>
                    </div>
                    <div class="">
                        <label class="text-light">Description :</label>
                        <p [innerHTML]="appointmentdata?.consultation_id.description"></p>
                    </div>
                    <button class="btn btn-primary" (click)="reschedule()">Reschedule</button>
                </div>
            </div>
        </div>
        <div class="tabs">
            <ul ngbNav #customNav="ngbNav" class="nav-pills mt-0"
                style="border: 1px solid #D8D8D8; width: fit-content;border-radius: 7px;">
                <li [ngbNavItem]="1" class="">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">Questionnaire</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="row py-3">
                            <div class="col-12" *ngFor="let item of questionnaire_list;let i = index">
                                <p class="font-size-15 fw-600">{{i+1}}. &nbsp;{{item?.question}}</p>
                                <h6 *ngIf="item.question_type !== 'ATTACHMENT'"><span class="fw-600">Answer:</span>
                                    {{item.answer}}</h6>
                                <h6 *ngIf="item.question_type === 'ATTACHMENT'" class="d-flex"><span class="fw-600">Download
                                        File: &nbsp;</span>
                                    <a href="{{item.answer}}" target="_blank"
                                        class="text-dark fw-medium" download>
                                        <mat-icon style="color: rgb(0, 0, 0);">file_download_outlined</mat-icon>
                                    </a>
                                </h6>
                            </div>
                        </div>
                        <app-nodata-found *ngIf="display_no_data"></app-nodata-found>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">Snooze</span>
                    </a>
                    <ng-template ngbNavContent>
                        <p class="p-5 text-center">Coming Soon...</p>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" class="">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">Files</span>
                    </a>
                    <ng-template ngbNavContent>
                        <!-- <div class="files mt-5">
                            <h6>Files</h6>
                            <ngx-dropzone (change)="onSelect($event)" [multiple]="false" class="bg-gray-light"
                                style="height: 61px;">
                                <ngx-dropzone-label>Drag a file here or <span class="text-primary fw-500">Browse</span>
                                    for a file to upload
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview [removable]="true" (removed)="onRemove(f)"
                                    ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
                                </ngx-dropzone-image-preview>
                            </ngx-dropzone>
                        </div> -->
                        <p class="p-5 text-center">Coming Soon...</p>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="customNav"></div>
        </div>
    </div>
</div>