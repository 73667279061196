import { Component, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventInput, DatesSetArg } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
// import listPlugin from '@fullcalendar/list';
import { createEventId } from './event-utils';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { AuthService } from 'src/app/core/service/auth.service';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-header-calendar',
  templateUrl: './header-calendar.component.html',
  styleUrls: ['./header-calendar.component.scss']
})
export class HeaderCalendarComponent {
  calendarVisible = true;
  showModal: boolean | undefined;
  eventModal = false;
  title = 'ngularfullcalendarbootstrap';
  selected: any;
  TODAY_STR = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
  data: EventInput[] = [];
  slots_data: any[] = [];
  dummytext = [];
  calendarOptions: CalendarOptions = {
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin
      // listPlugin
    ],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    initialView: 'dayGridMonth',
    timeZone: 'America/Los_Angeles',
    initialEvents: this.data, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    // select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    events: this.dummytext,
    datesSet: this.handleDatesSet.bind(this)
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  };
  currentEvents: EventApi[] = [];
  @ViewChild('first', { read: ElementRef })
    firstName!: ElementRef<HTMLElement>;
  @ViewChild('last', { read: ElementRef })
    lastName!: ElementRef<HTMLElement>;
  firstNameAutofilled!: boolean;
  lastNameAutofilled!: boolean;
  calendarView = 'month';
  eventsPromise!: Promise<EventInput>;
  selectedEvent: any;
  upcomingEvents: any[] = [];
  selectedType = 'dayGridMonth';
  selectedDate!: string;

  constructor(private changeDetector: ChangeDetectorRef, private _autofill: AutofillMonitor,
    private authService: AuthService, private settingsService: SettingsService) {

  }

  // ngAfterViewInit() {
  //   this._autofill
  //     .monitor(this.firstName)
  //     .subscribe(e => (this.firstNameAutofilled = e.isAutofilled));
  //   this._autofill
  //     .monitor(this.lastName)
  //   .subscribe(e => (this.lastNameAutofilled = e.isAutofilled));
  // }

  ngOnDestroy() {
    this._autofill.stopMonitoring(this.firstName);
    this._autofill.stopMonitoring(this.lastName);
  }
  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const title = ('Please enter a new title for your event');
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });
      this.showModal = true;
    }
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.eventModal = true;
    this.selectedEvent = clickInfo.event;
    // this.selectedEvent.endtime = (clickInfo.event.endStr.split('T')[1]);
    this.authService.getSlotDetailsByid(clickInfo.event.id).subscribe((resp: any) => {
      this.settingsService.getStaff_infoById(resp.data.staff).subscribe((staff: any) => {
        this.selectedEvent.staff = staff.data.fname;
      });
      this.selectedEvent.date = resp.data.startStr;
      // this.selectedEvent.start_time = resp.data.startStr.split('T')[1];
      // this.selectedEvent.end_time = resp.data.endStr.split('T')[1];
      this.selectedEvent.end_time = resp.data.endStr;
      this.selectedEvent.email = resp.data.email;
      this.selectedEvent.name = resp.data.name;
      this.selectedEvent.guest_email = resp.data.guest_email;
      this.selectedEvent.type = resp.data.appointment_id ? 'Appointment' : 'Meeting';
      console.log(this.selectedEvent, "event");
    });

    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove();
    // }
  }

  handleEvents(events: any) {
    this.upcomingEvents = [];
    this.currentEvents = events;
    this.currentEvents.forEach((element: any) => {
      if (new Date() <= element.start) this.upcomingEvents.push(element);
    });
    this.upcomingEvents.reverse();
    console.log(this.currentEvents,"events");
    
    this.changeDetector.detectChanges();
  }

  handleDatesSet(arg: DatesSetArg) {
    const selectedDate = arg.view.currentStart;
    this.selectedType = arg.view.type;
    this.selectedDate = this.convert(selectedDate);
    // Perform additional actions based on the selected month
    this.getCalendar();
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  hide() {
    this.showModal = false;
    this.eventModal = false;
  }

  getCalendar() {
    let type: any;
    if (this.selectedType === 'dayGridMonth') {
      type = 'month';
    } else if (this.selectedType === 'timeGridWeek') {
      type = 'week';
    } else if (this.selectedType === 'timeGridDay') {
      type = 'day';
    }
    this.authService.getAllSlotsData(type, this.selectedDate).subscribe((data: any) => {
      data.data.forEach((element: any) => {
        element.start = element.startStr;
        element.end = element.endStr;
      });
      this.slots_data = data.data;
      this.calendarOptions.events = this.slots_data;
      // this.handleEvents(data.data)
      return this.slots_data;
    });
  }
}
