<div class="h-28rem">
    <div class="d-flex align-items-center justify-content-end activityfilter">
        <!-- <mat-select [(ngModel)]="activity_filter" *ngIf="isModal !== 'modal'">
            <mat-option value="all" class="filteropt font-size-12">All Activities</mat-option>
            <mat-option value="open" class="filteropt font-size-12">Open Activities</mat-option>
            <mat-option value="closed" class="filteropt font-size-12">Closed Activities</mat-option>
            <mat-option value="" class="filteropt font-size-12">My Activites</mat-option>
            <mat-option value="" class="filteropt font-size-12">Next 7 Days + Overdue Acivites</mat-option>
            <mat-option value="" class="filteropt font-size-12">Overdue Acivites</mat-option>
            <mat-option value="" class="filteropt font-size-12">Today + Overdue Acivites</mat-option>
            <mat-option value="" class="filteropt font-size-12">Todays Acivites</mat-option>
            <mat-option value="" class="filteropt font-size-12">Tommorows Activites</mat-option>
        </mat-select> -->
        <div class="d-flex align-items-center">
            <button (click)="currentActivity('followup',formDirective)"
                [ngClass]="(activity_type === 'followup' && activity_show) ? 'bg-gradient-active': 'bg-gradient'"
                class="btn border-radius-50 border border-light-1 d-flex align-items-center fw-400 font-size-14 text-primary px-3 me-3">
                <mat-icon>add</mat-icon>Follow-up
            </button>
            <button (click)="currentActivity('call',formDirective)"
                [ngClass]="(activity_type === 'call' && activity_show) ? 'bg-gradient-active': 'bg-gradient'"
                class="btn bg-gradient border-radius-50 border border-light-1 d-flex align-items-center fw-400 font-size-14 text-primary px-3 me-3">
                <mat-icon>add</mat-icon>Call
            </button>
            <button (click)="currentActivity('email',formDirective)"
                [ngClass]="(activity_type === 'email' && activity_show) ? 'bg-gradient-active': 'bg-gradient'"
                class="btn bg-gradient border-radius-50 border border-light-1 d-flex align-items-center fw-400 font-size-14 text-primary px-3 me-3">
                <mat-icon>add</mat-icon>Email
            </button>
            <button (click)="currentActivity('meeting',formDirective)"
                [ngClass]="(activity_type === 'meeting' && activity_show) ? 'bg-gradient-active': 'bg-gradient'"
                class="btn bg-gradient border-radius-50 border border-light-1 d-flex align-items-center fw-400 font-size-14 text-primary px-3 me-3">
                <mat-icon>add</mat-icon>Meeting
            </button>
        </div>
    </div>
    <div class="card overflow-auto" [ngClass]="activity_show === true ? 'shrink-to-max' : 'shrink-to-0'">
        <div class="card-body">
            <!-- <ng-container *ngIf="activity_type ==='follow-up'">
                <form [formGroup]="follow_up" (ngSubmit)="submitFollowUp()">
                    <div class="row mt-3">
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label class="mb-2 fw-600 text-primary font-size-14">Follow-up Name<small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="Name"
                                    formControlName="followup_name" />
                                <mat-error class="error d-flex" *ngIf="submitedFollowup && f['followup_name'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="f['followup_name'].errors['required']"> Follow-up Name
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <label class="mb-2 fw-600 text-primary font-size-14">Due Date<small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput [matDatepicker]="picker" placeholder="Date"
                                    formControlName="due_date" />
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error class="error d-flex" *ngIf="submitedFollowup && f['due_date'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="f['due_date'].errors['required']"> Due Date
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <label class="mb-2 fw-600 text-primary font-size-14">Due Time<small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-select style="outline: none" placeholder="Time" formControlName="due_time">
                                    <mat-option *ngFor="let time of time_slotes" [value]="time">{{time}}</mat-option>
                                </mat-select>
                                <mat-error class="error d-flex" *ngIf="submitedFollowup && f['due_time'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="f['due_time'].errors['required']"> Due Time
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label class="mb-2 fw-600 text-primary font-size-14">Related To<small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-select style="outline: none" placeholder="Select"
                                    formControlName="associated_with">
                                    <mat-optgroup class="fw-500" [label]="option.group" *ngFor="let option of associated_with_options_array">
                                        <mat-option [value]="subOption.value" *ngFor="let subOption of option.options">{{subOption.value}}</mat-option>
                                    </mat-optgroup>
                                </mat-select>
                                <mat-error class="error d-flex" *ngIf="submitedFollowup && f['associated_with'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="f['associated_with'].errors['required']"> Related To
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label class="mb-2 fw-600 text-primary font-size-14">Owner <small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-select style="outline: none" placeholder="Select"
                                    formControlName="owner">
                                    <mat-optgroup class="fw-500" [label]="option.group" *ngFor="let option of associated_with_options_array">
                                        <mat-option [value]="subOption.value" *ngFor="let subOption of option.options">{{subOption.value}}</mat-option>
                                    </mat-optgroup>
                                </mat-select>
                                <mat-error class="error d-flex" *ngIf="submitedFollowup && f['owner'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="f['owner'].errors['required']"> Owner
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label class="mb-2 fw-600 text-primary font-size-14">Description</label>
                            <mat-form-field appearance="outline" class="w-100">
                                <textarea style="outline: none;" matInput formControlName="description"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary mt-3 px-3">Create Activity</button>
                </form>
            </ng-container>
            <ng-container *ngIf="activity_type ==='call'">
                <form [formGroup]="call" (ngSubmit)="submitCallForm()">
                    <div class="row mt-3">
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label class="mb-2 fw-600 text-primary font-size-14">Call Name<small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="Name" formControlName="call_name" />
                                <mat-error class="error d-flex" *ngIf="submitedCall && call_f['call_name'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="call_f['call_name'].errors['required']"> Call Name
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <label class="mb-2 fw-600 text-primary font-size-14">Due Date<small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput [matDatepicker]="picker" placeholder="Date"
                                    formControlName="due_date" />
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error class="error d-flex" *ngIf="submitedCall && call_f['due_date'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="call_f['due_date'].errors['required']"> Due Date
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <label class="mb-2 fw-600 text-primary font-size-14">Due Time<small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-select style="outline: none" placeholder="Time" formControlName="due_time">
                                    <mat-option *ngFor="let time of time_slotes" [value]="time">{{time}}</mat-option>
                                </mat-select>
                                <mat-error class="error d-flex" *ngIf="submitedCall && call_f['due_time'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="call_f['due_time'].errors['required']"> Due Time
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label class="mb-2 fw-600 text-primary font-size-14">Related To<small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-select style="outline: none" placeholder="Select"
                                    formControlName="associated_with">
                                    <mat-optgroup class="fw-500" [label]="option.group" *ngFor="let option of associated_with_options_array">
                                        <mat-option [value]="subOption.value" *ngFor="let subOption of option.options">{{subOption.value}}</mat-option>
                                    </mat-optgroup>
                                </mat-select>
                                <mat-error class="error d-flex"
                                    *ngIf="submitedCall && call_f['associated_with'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="call_f['associated_with'].errors['required']"> Related To
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label class="mb-2 fw-600 text-primary font-size-14">Owner <small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-select style="outline: none" placeholder="Select"
                                    formControlName="owner">
                                    <mat-optgroup class="fw-500" [label]="option.group" *ngFor="let option of associated_with_options_array">
                                        <mat-option [value]="subOption.value" *ngFor="let subOption of option.options">{{subOption.value}}</mat-option>
                                    </mat-optgroup>
                                </mat-select>
                                <mat-error class="error d-flex" *ngIf="submitedFollowup && f['owner'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="f['owner'].errors['required']"> Owner
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label class="mb-2 fw-600 text-primary font-size-14">Description</label>
                            <mat-form-field appearance="outline" class="w-100">
                                <textarea style="outline: none;" matInput formControlName="description"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary mt-3 px-3">Create Activity</button>
                </form>
            </ng-container>
            <ng-container *ngIf="activity_type ==='email'">
                <form [formGroup]="email" (ngSubmit)="submitEmailForm()">
                    <div class="row mt-3">
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label class="mb-2 fw-600 text-primary font-size-14">Email Name<small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="Name" formControlName="email_name" />
                                <mat-error class="error d-flex" *ngIf="submitedEmail && email_f['email_name'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="email_f['email_name'].errors['required']"> Email Name
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <label class="mb-2 fw-600 text-primary font-size-14">Due Date<small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput [matDatepicker]="picker" placeholder="Date"
                                    formControlName="due_date" />
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error class="error d-flex" *ngIf="submitedEmail && email_f['due_date'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="email_f['due_date'].errors['required']"> Due Date
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                            <label class="mb-2 fw-600 text-primary font-size-14">Due Time<small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-select style="outline: none" placeholder="Time" formControlName="due_time">
                                    <mat-option *ngFor="let time of time_slotes" [value]="time">{{time}}</mat-option>
                                </mat-select>
                                <mat-error class="error d-flex" *ngIf="submitedEmail && email_f['due_time'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="email_f['due_time'].errors['required']"> Due Time
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label class="mb-2 fw-600 text-primary font-size-14">Related To<small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-select style="outline: none" placeholder="Select"
                                    formControlName="associated_with">
                                    <mat-optgroup class="fw-500" [label]="option.group" *ngFor="let option of associated_with_options_array">
                                        <mat-option [value]="subOption.value" *ngFor="let subOption of option.options">{{subOption.value}}</mat-option>
                                    </mat-optgroup>
                                </mat-select>
                                <mat-error class="error d-flex"
                                    *ngIf="submitedEmail && email_f['associated_with'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="email_f['associated_with'].errors['required']"> Related To
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label class="mb-2 fw-600 text-primary font-size-14">Owner <small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-select style="outline: none" placeholder="Select"
                                    formControlName="owner">
                                    <mat-optgroup class="fw-500" [label]="option.group" *ngFor="let option of associated_with_options_array">
                                        <mat-option [value]="subOption.value" *ngFor="let subOption of option.options">{{subOption.value}}</mat-option>
                                    </mat-optgroup>
                                </mat-select>
                                <mat-error class="error d-flex" *ngIf="submitedFollowup && f['owner'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="f['owner'].errors['required']"> Owner
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label class="mb-2 fw-600 text-primary font-size-14">Description</label>
                            <mat-form-field appearance="outline" class="w-100">
                                <textarea style="outline: none;" matInput formControlName="description"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary mt-3 px-3">Create Activity</button>
                </form>
            </ng-container> -->
            <ng-container>
                <form [formGroup]="activity_form" #formDirective="ngForm"
                    (ngSubmit)="submitActivityForm(formDirective)">
                    <div class="row mt-3">
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label class="mb-2 fw-600 text-primary font-size-14" *ngIf="activity_type ==='email'">Email
                                Name<small class="text-danger">*</small></label>
                            <label class="mb-2 fw-600 text-primary font-size-14" *ngIf="activity_type ==='call'">Call
                                Name<small class="text-danger">*</small></label>
                            <label class="mb-2 fw-600 text-primary font-size-14"
                                *ngIf="activity_type ==='followup'">Follow-up Name<small
                                    class="text-danger">*</small></label>
                            <label class="mb-2 fw-600 text-primary font-size-14"
                                *ngIf="activity_type ==='meeting'">Meeting Name<small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="Name" formControlName="name" />
                                <mat-error class="error d-flex" *ngIf="submitedActivityForm && f['name'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="f['name'].errors['required']">
                                        <span *ngIf="activity_type === 'email'">Email</span>
                                        <span *ngIf="activity_type === 'call'">Call</span>
                                        <span *ngIf="activity_type === 'followup'">Follow up</span>
                                        <span *ngIf="activity_type ==='meeting'">Meeting</span>
                                        Name
                                        {{errorjson.required}}</span>
                                    <span *ngIf="f['name'].errors['pattern']">
                                        Only combination of alphanumeric @ / , are accepted.
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label class="mb-2 fw-600 text-primary font-size-14">Due Date & Time<small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput [ngxMatDatetimePicker]="picker" [min]="minDate"
                                    placeholder="Choose a date & time" formControlName="due_date">
                                <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
                                <mat-error class="error d-flex" *ngIf="submitedActivityForm && f['due_date'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="f['due_date'].errors['required']"> Due date
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label class="mb-2 fw-600 text-primary font-size-14">Owner<small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-select style="outline: none" placeholder="Select" formControlName="owner">
                                    <mat-option *ngFor="let member of staffMembers" [value]="member.id">{{member.fname}}
                                        {{member.lname}}</mat-option>
                                </mat-select>
                                <mat-error class="error d-flex" *ngIf="submitedActivityForm && f['owner'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="f['owner'].errors['required']"> Owner
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3" *ngIf="isModal">
                            <label class="fw-600 mb-2 text-primary w-100">Related to <small
                                    class="text-danger">*</small></label>
                            <mat-radio-group aria-label="Select an option" formControlName="related_to_type"
                                (change)="onRelatedTochange($event)">
                                <mat-radio-button value="contact">Contact</mat-radio-button>
                                <mat-radio-button value="lead">Enquiry</mat-radio-button>
                                <mat-radio-button value="company">Company</mat-radio-button>
                                <mat-radio-button value="project">Client Project</mat-radio-button>
                            </mat-radio-group>
                            <mat-error class="error d-flex" *ngIf="submitedActivityForm && f['related_to_type'].errors">
                                <mat-icon class="font-size-12">info</mat-icon>
                                <span *ngIf="f['related_to_type'].errors['required']">Related to
                                    {{errorjson.required}}</span>
                            </mat-error>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="activity_type ==='meeting'">
                            <label class="mb-2 fw-600 text-primary font-size-14">Guest Mail</label>
                            <ng-select class="form-control no-arrow" [items]="guestsMeetingList" bindLabel="mail"
                                bindValue="mail" [addTag]="addnew" [multiple]="true" (change)="onSelectEmail($event)"
                                placeholder="Guest mail">
                            </ng-select>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="activity_type ==='meeting'">
                            <label class="mb-2 fw-600 text-primary font-size-14">Location</label>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-select style="outline: none" placeholder="Select" formControlName="location">
                                    <mat-option value="zoom">Zoom</mat-option>
                                    <mat-option value="google">Google Meet</mat-option>
                                    <mat-option value="custom">Custom Link</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="activity_type ==='meeting'">
                            <label class="mt-2 fw-600 text-primary font-size-14">Meeting Link</label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="Meeting link"
                                    formControlName="meeting_link" />
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label class="mt-2 fw-600 text-primary font-size-14">Description</label>
                            <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                <textarea style="outline: none;" matInput formControlName="description"></textarea>
                            </mat-form-field>
                            <small class="font-size-10">Maximum 500 characters are accepted.</small>
                        </div>


                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3"
                            *ngIf="activity_form.value.related_to_type">
                            <label class="fw-600 mb-2 text-primary"
                                *ngIf="activity_form.value.related_to_type === 'contact'">Select
                                Contact <small class="text-danger">*</small></label>
                            <label class="fw-600 mb-2 text-primary"
                                *ngIf="activity_form.value.related_to_type === 'lead'">Select Enquiry
                                <small class="text-danger">*</small></label>
                            <label class="fw-600 mb-2 text-primary"
                                *ngIf="activity_form.value.related_to_type === 'company'">Select
                                Company <small class="text-danger">*</small></label>
                            <label class="fw-600 mb-2 text-primary"
                                *ngIf="activity_form.value.related_to_type === 'project'">Select
                                Client Project <small class="text-danger">*</small></label>

                            <div>
                                <mat-form-field appearance="outline" class="w-100"
                                    *ngIf="selected_type === 'project' || activity_form.value.related_to_type === 'project'">
                                    <mat-select placeholder='Related to' formControlName="source_id">
                                        <mat-option>
                                            <ngx-mat-select-search formControlName="search" placeholderLabel="Search"
                                                noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let item of related_to" value={{item.id}}
                                            (click)="selectedSource(item.name)">{{item.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w-100"
                                    *ngIf="selected_type === 'company' || activity_form.value.related_to_type === 'company'">
                                    <mat-select placeholder='Related to' formControlName="source_id">
                                        <mat-option>
                                            <ngx-mat-select-search formControlName="search" placeholderLabel="Search"
                                                noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let item of related_to" value={{item.id}}
                                            (click)="selectedSource(item.name)">{{item.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w-100"
                                    *ngIf="selected_type === 'contact' || activity_form.value.related_to_type === 'contact'">
                                    <mat-select placeholder='Related to' formControlName="source_id">
                                        <mat-option>
                                            <ngx-mat-select-search formControlName="search" placeholderLabel="Search"
                                                noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let item of related_to" value={{item.id}}
                                            (click)="selectedSource(item.first_name + ' ' + item.last_name)">{{item.first_name}}
                                            {{item.last_name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w-100"
                                    *ngIf="selected_type === 'lead' || activity_form.value.related_to_type === 'lead'">
                                    <mat-select placeholder='Related to' formControlName="source_id">
                                        <mat-option>
                                            <ngx-mat-select-search formControlName="search" placeholderLabel="Search"
                                                noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let item of related_to" value={{item.id}}
                                            (click)="selectedSource(item.requirement_title)">{{item.requirement_title}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error class="error d-flex" *ngIf="submitedActivityForm && f['source_id'].errors">
                                    <mat-icon class="font-size-12">info</mat-icon>
                                    <span *ngIf="f['source_id'].errors['required']">Related to
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="isModal !== true">
                            <label class="mb-2 fw-600 text-primary font-size-14">Related To<small
                                    class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100 text-primary">
                                <input style="outline: none" matInput placeholder="Related"
                                    formControlName="source_name" />
                                <mat-error class="error d-flex" *ngIf="submitedActivityForm && f['source_name'].errors">
                                    <mat-icon class="me-1 my-auto">info</mat-icon>
                                    <span *ngIf="f['source_name'].errors['required']"> Related To
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary mt-3 px-3">Create Activity</button>
                </form>
            </ng-container>
        </div>
    </div>
    <ng-container *ngIf="isModal !== true">
        <div class="overflow-auto"
            [ngClass]="(activity_type === 'followup' || activity_type === 'call' || activity_type === 'email' || activity_type === 'meeting') ? 'shrink_div' : 'expand_div'">
            <div class="d-flex justify-content-between align-items-center text-center">
                <mat-divider class="w-100 m-0"></mat-divider>
                <p class="m-0 w-50 text-light">Activities</p>
                <mat-divider class="w-100 m-0"></mat-divider>
            </div>
            <div class="mt-3">
                <div class="card" *ngFor="let activity of activitiesArray">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <h6 class="text-primary fw-600">{{activity.name | titlecase}}</h6>
                                <div class="d-flex">
                                    <!-- <mat-icon class="text-secondary">calendar_today</mat-icon> -->
                                    <label class="text-black font-size-14">{{activity.description | titlecase}}</label>
                                </div>
                                <div class="mt-2 font-size-14" *ngIf="activity.guest.length !== 0">
                                    <b>Guest Mails </b>: <span *ngFor="let item of activity.guest; let i = index">
                                        {{item}} <span *ngIf="activity.guest.length !== i + 1">,</span></span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <mat-icon class="cursor d-none me-2"
                                        (click)="confirmDelete(activity.id)">delete</mat-icon>
                                </div>
                                <button class="btn bg-white border-1 text-primary font-size-12"
                                    (click)="updateActivity(activity.id,true)" *ngIf="!activity.mark_as_done">Mark As
                                    Completed</button>
                                <button class="btn bg-white border-1 text-primary font-size-12"
                                    (click)="updateActivity(activity.id,false)" *ngIf="activity.mark_as_done">
                                    Completed</button>
                            </div>
                        </div>
                        <hr>
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <p class="bg-gray-light px-3 py-2 border-radius-8">{{activity.type | titlecase }}</p>
                                <p class="ms-2">Due on {{activity.due_date | dateConversion}}</p>
                            </div>
                            <p>{{activity.created_at | dateConversion}} | {{activity.created_at | date: 'h:mm a'}}</p>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <mat-paginator [length]="activities_total_count" [pageSize]="10" (page)="page($event)"
                        aria-label="Select page" showFirstLastButtons>
                    </mat-paginator>
                </div>
            </div>
        </div>
    </ng-container>
</div>