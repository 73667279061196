export const FINANCE = [
  {
    tab_col_one: 'Invoices',
    tab_col_two: 'Estimates',
    tab_col_three: 'Settings'
  }
];

export const INVOICE = [
  {
    back_label: 'Back',
    search_placeholder: 'Search Invoice',
    label_add_invoice: 'Add Invoice'

  }
];