<div class="container-fluid">
    <div class="row h-100 no-gutters">
        <div class="col-md-4 pe-0 h-100">
            <app-client-info></app-client-info>
        </div>
        <div class="col-md-8 h-100 ps-0 p-3">
            <div class="crm-client-content h-100">
                <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom mt-0 mb-3"
                    (navChange)="tabChange($event)">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink class="d-flex">
                            <span class="d-sm-block font-size-12">Timeline</span>
                        </a>
                        <ng-template ngbNavContent>
                            <app-time-line></app-time-line>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink class="d-flex">
                            <span class="d-sm-block font-size-12">Task Board</span>
                        </a>
                        <ng-template ngbNavContent>
                            <app-task-board></app-task-board>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                        <a ngbNavLink class="d-flex">
                            <span class="d-sm-block font-size-12">Notes</span>
                        </a>
                        <ng-template ngbNavContent>
                            <app-notes></app-notes>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4">
                        <a ngbNavLink class="d-flex">
                            <span class="d-sm-block font-size-12">Activity</span>
                        </a>
                        <ng-template ngbNavContent>
                            <app-activity></app-activity>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="5">
                        <a ngbNavLink class="d-flex">
                            <span class="d-sm-block font-size-12">Files</span>
                        </a>
                        <ng-template ngbNavContent>
                            <app-files></app-files>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="6">
                        <a ngbNavLink class="d-flex">
                            <span class="d-sm-block font-size-12">Conversations</span>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="h-35rem">
                                <app-client-conversations></app-client-conversations>
                            </div>
                        </ng-template>
                    </li>
                    <!-- <li [ngbNavItem]="7">
                        <a ngbNavLink class="d-flex">
                            <span class="d-sm-block font-size-12">Products</span>
                        </a>
                        <ng-template ngbNavContent>
                        </ng-template>
                    </li> -->
                </ul>
                <div [ngbNavOutlet]="customNav"></div>
            </div>
        </div>
    </div>
</div>