<div class="h-28rem overflow-auto">
  <!-- <div class="d-flex justify-content-between align-items-center mb-3">
    <h6 class="text-primary">{{dealsJson.deals_label}}</h6>
    <div class="d-flex rounded border p-1">
      (click)="expandDeals()"
      <mat-icon>add</mat-icon> <span>{{dealsJson.deals_label}}</span>
    </div>
  </div> -->

  <div class="example-container table-responsive">
    <table class="table align-middle table-nowrap table-hover mb-0 file-table table-sm">
      <thead>
        <tr>
          <th scope="col">{{dealsJson.col_one_label}}</th>
          <th scope="col">{{dealsJson.col_two_label}}</th>
          <th scope="col">{{dealsJson.col_three_label}}</th>
          <th scope="col">{{dealsJson.col_four_label}}</th>
          <th scope="col">
            <mat-icon mat-raised-button [matMenuTriggerFor]="filter_search_menu"
              class="menuTrigger text-primary material-icons cursor">add</mat-icon>
          </th>

          <mat-menu #filter_search_menu="matMenu">
            <span class="text-primary m-3 mb-0">{{dealsJson.mat_menu_search_label}}</span>
            <mat-form-field appearance="outline" class="m-3 mb-1">
              <input style="outline: none" matInput placeholder={{dealsJson.search_placeholder}} />
            </mat-form-field>

            <div class="" *ngFor="let item of filtersLables; let i = index">
              <button mat-menu-item (click)="selectFilterLable($event)" id="{{ i }}">
                <mat-icon class="empty">check_box_outline_blank</mat-icon>
                <span class="small">{{ item.lableName }}</span>
              </button>
            </div>
          </mat-menu>
        </tr>
      </thead>
      <!-- -->

      <tbody>
        <tr *ngFor="let item of filesListArray; let i = index">
          <td>
            {{ item.deal_name }}
          </td>
          <td>
            {{ item.pipeline_name }}
          </td>

          <td>{{ item.amount }}</td>
          <td>
            {{ item.stage }}
          </td>
          <td>
            <div class="dropdown" ngbDropdown>
              <span class="material-icons">mode_edit</span>
              <mat-icon>more_vert</mat-icon>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <ng-container *ngIf="show">
      <form class="m-3" [formGroup]="dealForm" (ngSubmit)="onSubmit()">
        <div class="d-flex justify-content-between mb-3">
          <h6>{{dealsJson.deal_info_label}}</h6>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6">
            <label class="fw-600 mb-2">{{dealsJson.deal_name_label}}</label>
            <mat-form-field appearance="outline" class="w-100">
              <input style="outline: none" matInput placeholder="Deal Name" formControlName="deal_name" />
              <mat-error class="error text-danger d-flex" *ngIf="submitted && f['deal_name'].errors">
                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                <span *ngIf="submitted && f['deal_name'].errors['required']">First name is required</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6">
            <label class="fw-600 mb-2">Stage</label>
            <mat-form-field appearance="outline" class="w-100">
              <mat-select placeholder="Stage" class="pt-1" formControlName="stage">
                <mat-option [value]="'choose-stage'">Choose a stage</mat-option>
                <mat-option [value]="'one'">One</mat-option>
                <mat-option [value]="'two'">Two</mat-option>
                <mat-option [value]="'three'">Three</mat-option>
              </mat-select>
              <mat-error class="error text-danger d-flex" *ngIf="submitted && f['deal_name'].errors">
                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                <span *ngIf="submitted && f['deal_name'].errors['required']">Stage is required</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6">
            <label class="fw-600 mb-2">Contact number</label>
            <mat-form-field appearance="outline" class="w-100">
              <input style="outline: none" matInput placeholder="Contact Number" formControlName="contact_number" />
              <mat-error class="error text-danger d-flex" *ngIf="submitted && f['contact_number'].errors">
                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                <span *ngIf="submitted && f['contact_number'].errors['required']">Contact Number is required</span>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6">
            <label class="fw-600 mb-2">Company Name</label>
            <mat-form-field appearance="outline" class="w-100">
              <input style="outline: none" matInput placeholder="Company Name" formControlName="company_name" />
              <mat-error class="error text-danger d-flex" *ngIf="submitted && f['company_name'].errors">
                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                <span *ngIf="submitted && f['company_name'].errors['required']">Company Name is required</span>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6">
            <label class="fw-600 mb-2">Amount</label>
            <mat-form-field appearance="outline" class="w-100">
              <input style="outline: none" matInput placeholder="Amount" formControlName="amount" />
              <mat-error class="error text-danger d-flex" *ngIf="submitted && f['amount'].errors">
                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                <span *ngIf="submitted && f['amount'].errors['required']">Contact Number is required</span>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6">
            <label class="fw-600 mb-2">Email</label>
            <mat-form-field appearance="outline" class="w-100">
              <input style="outline: none" matInput placeholder="Email" formControlName="email" />
              <mat-error class="error text-danger d-flex" *ngIf="submitted && f['email'].errors">
                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                <span *ngIf="submitted && f['email'].errors['required']">Email is required</span>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6">
            <label class="fw-600 mb-2">Follow-up Date</label>

            <mat-form-field appearance="outline" class="w-100">
              <input style="outline: none" matInput placeholder="Date" formControlName="follow_up_date" />
              <mat-error class="error text-danger d-flex" *ngIf="submitted && f['follow_up_date'].errors">
                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                <span *ngIf="submitted && f['follow_up_date'].errors['required']">Follow up date is required</span>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6">
            <label class="fw-600 mb-2">Contact Name</label>
            <mat-form-field appearance="outline" class="w-100">
              <input style="outline: none" matInput placeholder="name" formControlName="contact_name" />
              <mat-error class="error text-danger d-flex" *ngIf="submitted && f['contact_name'].errors">
                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                <span *ngIf="submitted && f['contact_name'].errors['required']">Contact name is required</span>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6">
            <label class="fw-600 mb-2">Description</label>
            <mat-form-field appearance="outline" class="w-100">
              <input style="outline: none" matInput placeholder="Description" formControlName="description" />
              <mat-error class="error text-danger d-flex" *ngIf="submitted && f['description'].errors">
                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                <span *ngIf="submitted && f['description'].errors['required']">Description is required</span>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6">
            <label class="fw-600 mb-2">Lead Source</label>
            <mat-form-field appearance="outline" class="w-100">
              <mat-select placeholder="Lead Source" class="pt-1" formControlName="lead_source">
                <mat-option [value]="'lead_source'">Choose a source</mat-option>
                <mat-option [value]="'one'">One</mat-option>
                <mat-option [value]="'two'">Two</mat-option>
                <mat-option [value]="'three'">Three</mat-option>
              </mat-select>
              <mat-error class="error text-danger d-flex" *ngIf="submitted && f['deal_name'].errors">
                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                <span *ngIf="submitted && f['deal_name'].errors['required']">Stage is required</span>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6">
            <label class="fw-600 mb-2">Website</label>
            <mat-form-field appearance="outline" class="w-100">
              <input style="outline: none" matInput placeholder="Webiste" formControlName="webiste" />
              <mat-error class="error text-danger d-flex" *ngIf="submitted && f['webiste'].errors">
                <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                <span *ngIf="submitted && f['webiste'].errors['required']">Description is required</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="mb-3 mt-3">
          <h6 class="fw-600 mb-2">Additional Information</h6>
          <label class="fw-600 mb-2">DOB</label><br />
          <mat-form-field appearance="outline" class="w-50">
            <input style="outline: none" matInput placeholder="DOB" formControlName="dob" />
            <mat-error class="error text-danger d-flex" *ngIf="submitted && f['dob'].errors">
              <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
              <span *ngIf="submitted && f['dob'].errors['required']">Description is required</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="mb-3 align-items-center">
          <button class="btn btn-primary align-items-center d-flex" type="submit" (click)="addProducts()">
            <mat-icon>add</mat-icon> Add Products
          </button>
        </div>
        <div class="d-flex justify-content-between pb-3">
          <span class="text-secondary" (click)="fieldSetting()">Field Setting</span>
          <button class="btn btn-primary" type="submit">Save</button>
        </div>
      </form>
    </ng-container>

    <!-- <ng-container *ngIf="isShow"> Hello exapnded </ng-container> -->

    <!-- <ng-container *ngIf="addProductName">
        <form class="m-3" [formGroup]="dealForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <label class="fw-600 mb-2">Product Name</label>
              <mat-form-field appearance="outline" class="w-100">
                <input
                  style="outline: none"
                  matInput
                  placeholder="Deal Name"
                  formControlName="product_name"
                />
                <mat-error
                  class="error text-danger d-flex"
                  *ngIf="submitted && f['product_name'].errors"
                >
                  <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                  <span
                    *ngIf="submitted && f['product_name'].errors['required']"
                    >Product name is required</span
                  >
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">
              <label class="fw-600 mb-2">Product Code</label>
              <mat-form-field appearance="outline" class="w-100">
                <input
                  style="outline: none"
                  matInput
                  placeholder="Contact Number"
                  formControlName="product_category"
                />
                <mat-error
                  class="error text-danger d-flex"
                  *ngIf="submitted && f['product_code'].errors"
                >
                  <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                  <span
                    *ngIf="submitted && f['product_code'].errors['required']"
                    >Product code is required</span
                  >
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">
              <label class="fw-600 mb-2">Product Category</label>
              <mat-form-field appearance="outline" class="w-100">
                <mat-select
                  placeholder="Product Category"
                  class="pt-1"
                  formControlName="product_category"
                >
                  <mat-option [value]="'choose-stage'"
                    >Choose a stage</mat-option
                  >
                  <mat-option [value]="'one'">One</mat-option>
                  <mat-option [value]="'two'">Two</mat-option>
                  <mat-option [value]="'three'">Three</mat-option>
                </mat-select>
                <mat-error
                  class="error text-danger d-flex"
                  *ngIf="submitted && f['deal_name'].errors"
                >
                  <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                  <span *ngIf="submitted && f['deal_name'].errors['required']"
                    >Product Category is required</span
                  >
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">
              <label class="fw-600 mb-2">Unit Price</label>
              <mat-form-field appearance="outline" class="w-100">
                <input
                  style="outline: none"
                  matInput
                  placeholder="Company Name"
                  formControlName="company_name"
                />
                <mat-error
                  class="error text-danger d-flex"
                  *ngIf="submitted && f['company_name'].errors"
                >
                  <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                  <span
                    *ngIf="submitted && f['company_name'].errors['required']"
                    >Company Name is required</span
                  >
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">
              <label class="fw-600 mb-2">Description</label>
              <mat-form-field appearance="outline" class="w-100">
                <input
                  style="outline: none"
                  matInput
                  placeholder="Amount"
                  formControlName="amount"
                />
                <mat-error
                  class="error text-danger d-flex"
                  *ngIf="submitted && f['amount'].errors"
                >
                  <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                  <span *ngIf="submitted && f['amount'].errors['required']"
                    >Contact Number is required</span
                  >
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </ng-container> -->
  </div>
</div>