import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ERRORJSON } from 'src/app/core/data/error_json';

@Component({
  selector: 'app-template-builder',
  templateUrl: './template-builder.component.html',
  styleUrls: ['./template-builder.component.scss']
})
export class TemplateBuilderComponent implements OnInit {
  templateForm!: FormGroup;
  errorjson = ERRORJSON[0];
  submited = false;
  builder_image: File[] = [];
  addListofFields = [
    {
      type: 'Radio',
      value: 'RADIO_BUTTON'
    },
    {
      type: 'Text Area',
      value: 'TEXT_AREA'
    },
    {
      type: 'Check Box',
      value: 'CHECK_BOX'
    }
  ];
  @Input() templateData: any;
  selected_field_type: any;
  optIndex=0;

  constructor(private fb: FormBuilder, private ngbActiveModal: NgbActiveModal) { }

  ngOnInit() {
    this.intilizeForm();
  }

  intilizeForm() {
    this.templateForm = this.fb.group({
      title: new FormControl(this.templateData?.title ? this.templateData.title : '', Validators.required),
      description: new FormControl(this.templateData?.dsp ? this.templateData.dsp : '', Validators.required),
      logo: new FormControl(this.templateData?.img ? this.templateData.img : ''),
      img: new FormControl(''),
      action: new FormControl(''),
      redirection_url: new FormControl(''),
      fields: new FormArray([])
    });
    console.log(this, this.templateForm.value);
  }

  get f() {
    return this.templateForm.controls;
  }

  get FormItems() {
    return this.templateForm.get('fields') as FormArray;
  } 

  FormItemsArray() {
    return new FormGroup({
      field_label: new FormControl(this.selected_field_type.type, Validators.required),
      type: new FormControl(this.selected_field_type.value),
      options: new FormArray([new FormControl('', Validators.required)])
    });
  }
  FormOpt(index: number) {
    // return this.templateForm.get('fields')['controls'][this.FormItems.controls.length-1]['controls'].options as FormArray;
    // return this.FormItems.get('options') as FormArray; //sucess
    return this.FormItems.controls[index]?.get('options') as FormArray;
  }
  addOptions(index: number){
    // this.optIndex++;
    
    this.FormOpt(index).push(new FormControl('', Validators.required));
    console.log(this.templateForm.value,this.FormOpt(index).controls,"values");
  }
  
  deleteFormOption(index: number,index_option: number) {
    // this.optIndex--;
    this.FormOpt(index).removeAt(index_option);
  }
  onFieldSelect(type: any) {
    // this.optIndex = 0;
    this.selected_field_type = type;
    this.FormItems.push(this.FormItemsArray());
    console.log(this.templateForm.value);
  }

  close() {
    this.ngbActiveModal.dismiss();
  }

  onSelect(event: any) {
    this.templateForm.value.img = this.builder_image.push(...event.addedFiles);
    console.log(this.templateForm);

  }

  onRemove(event: any) {
    this.builder_image.splice(this.builder_image.indexOf(event), 1);
  }
}
