import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { dayoffJson } from 'src/app/core/data/settings_json';

@Component({
  selector: 'app-day-off-model',
  templateUrl: './day-off-model.component.html',
  styleUrls: ['./day-off-model.component.scss']
})
export class DayOffModelComponent implements OnInit {
  dayoffForm!: FormGroup;
  dayoffjson = dayoffJson[0];
  errorjson = ERRORJSON[0];
  dayoff: any;
  submitted = false;

  constructor(public ngbActiveModal: NgbActiveModal) { }

  ngOnInit() {
    this.intilizeForm();
  }

  intilizeForm(data?: any) {
    this.dayoffForm = new FormGroup({
      title: new FormControl(data ? data.title : ''),
      date_range: new FormControl(data ? data.date_range : '')
    });
  }

  get f() {
    return this.dayoffForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.dayoffForm, "dayoff");
  }
}
