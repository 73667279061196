<div class="bg-crm-info h-100 p-3">
  <div class="container-fluid info-screen-overflow bg-white border-radius-10">
    <div class="d-flex align-items-center pt-2 pb-2">
      <div class="background-on-hover me-2 d-flex align-items-center">
        <mat-icon mat-raised-button matTooltip="Back" class="btn border-0 p-0"
          aria-label="Button that displays a tooltip when focused or hovered over" class="text-primary cursor"
          *ngIf="selected_type === 'lead'" [routerLink]="['/crm/leads']"
          routerLinkActive="router-link-active">arrow_back_ios </mat-icon>
        <mat-icon mat-raised-button matTooltip="Back" class="btn border-0 p-0"
          aria-label="Button that displays a tooltip when focused or hovered over" class="text-primary cursor"
          *ngIf="selected_type === 'contact'" [routerLink]="['/crm/contacts']"
          routerLinkActive="router-link-active">arrow_back_ios </mat-icon>
        <mat-icon mat-raised-button matTooltip="Back" class="btn border-0 p-0"
          aria-label="Button that displays a tooltip when focused or hovered over" class="text-primary cursor"
          *ngIf="selected_type === 'company'" [routerLink]="['/crm/companies']"
          routerLinkActive="router-link-active">arrow_back_ios </mat-icon>
        <mat-icon mat-raised-button matTooltip="Back" class="btn border-0 p-0"
          aria-label="Button that displays a tooltip when focused or hovered over" class="text-primary cursor"
          *ngIf="selected_type === 'deal'" (click)="goBack()" routerLinkActive="router-link-active">arrow_back_ios
        </mat-icon>
      </div>
      <div class="person-name">
        <h5 class="text-primary fw-500 font-size-16 mb-0" *ngIf="selected_type === 'contact'">
          {{intro_obj.first_name | titlecase}} {{intro_obj.last_name}}</h5>
        <h5 class="text-primary fw-500 font-size-16 mb-0" *ngIf="selected_type === 'lead'">
          {{intro_obj.requirement_title | titlecase}}</h5>
        <h5 class="text-primary fw-500 font-size-16 mb-0" *ngIf="selected_type === 'company'">{{intro_obj.name
          | titlecase}}
        </h5>
        <h5 class="text-primary fw-500 font-size-16 mb-0" *ngIf="selected_type === 'deal'">{{cardDetails?.title
          | titlecase}}
        </h5>
        <!-- <div class="d-flex align-items-center">
          <mat-icon style="color: #9197B3;font-size: 22px">check_circle_outline</mat-icon>
          <p class="text-name-secondary ps-1 m-0">Not contacted yet</p>
        </div> -->
      </div>
      <!-- <mat-icon>more_horiz</mat-icon> -->
    </div>
    <div class="overflow-auto" style="height: 88%">
      <!-- <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)"> -->
      <!-- <div class="example-box mt-3"> -->
      <div class="card" *ngIf="selected_type === 'deal'">
        <div class="card-body">
          <div class="d-flex align-items-center overflow-x-auto mb-2">
            <span class="h-2rem w-100 stageclip cursor"
              [ngClass]="{'fillstage': cardStageIndex >= i, 'withoutfillstage': cardStageIndex < i,'stageclip_start': i===0,'stageclip_end': i === stagesList.length-1}"
              *ngFor="let item of stagesList;let i=index" matTooltip="{{item.name}}" matTooltipPosition="above"
              (click)="stageUpdate(item.id,i)"></span>
          </div>
          <div class="font-size-12 ms-3">Created at : {{ cardDetails?.created_at | dateConversion }}</div>
        </div>
      </div>
      <div class="mb-3" *ngIf="selected_type === 'lead'">
        <mat-accordion class="enquire-accord">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <!-- <img src="../../../../../assets/images/Vector (4).svg"> -->
                <p class="fw-400 mb-0 text-inner-info">Enquiry</p>
                <mat-icon>expand_more</mat-icon>
              </mat-panel-title>
              <!-- <mat-icon class="cursor-move" cdkDragHandle>reorder</mat-icon> -->
            </mat-expansion-panel-header>
            <!-- <ng-container *ngIf="item.title === 'Contact'"> -->

            <ng-container *ngIf="intro_obj">
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-inner-info">Title : </p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name text-ellipsis w-100">
                    <!-- : {{intro_obj.requirement_title ? intro_obj.requirement_title : "-"}} -->
                    <app-table-popover [inputType]="'text'" [inputValue]="intro_obj?.requirement_title"
                      [formName]="'requirement_title'" [labelName]="'Title'"
                      (update)="onUpdatePopOver($event, intro_obj, 'lead')"></app-table-popover>
                  </p>
                </div>
              </div>
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-inner-info">Product : </p>
                </div>
                <div class="col-md-8 info-accord">
                  <app-table-popover [inputType]="'multiselect'" [inputValue]="product_details" [formName]="'product'"
                    [labelName]="'Products'" [dropDownItems]="productsListArray" [selectedItem]="intro_obj?.product"
                    (update)="onUpdatePopOver($event, intro_obj, 'lead')"></app-table-popover>

                  <!-- <ng-container *ngIf="intro_obj?.product?.length !== 0">
                    <p class="text-name text-wrap w-100" *ngFor="let item of intro_obj.product">{{ item }}</p>
                  </ng-container>
                  <p class="text-name w-100" *ngIf="intro_obj?.product?.length === 0">-</p> -->
                </div>
              </div>
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-inner-info">Type : </p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name">{{intro_obj?.type ? (intro_obj?.type | titlecase) : "-" }}</p>
                </div>
              </div>
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-inner-info">Owner : </p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name text-ellipsis w-100">
                    <app-table-popover [inputType]="'dropdown'"
                      [inputValue]="enquiry_owner?.fname + ' ' + enquiry_owner?.lname" [formName]=""
                      [labelName]="'Owner'" [dropDownItems]="staffMembers" [selectedItem]="intro_obj?.owner"
                      (update)="onUpdatePopOver($event, intro_obj, 'lead')"></app-table-popover>
                  </p>
                </div>
              </div>
              <ng-container *ngIf="!enquiry_seemore">
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Tag : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <!-- <p class="text-name bg-tag d-inline-flex align-items-center px-2 border-radius-50">
                      {{intro_obj?.tags
                      ? intro_obj?.tags : "-"}}</p> -->
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="border-radius-50 m-0 p-2 tag-color me-2" style="height: 2px;"
                        [style]="{'background-color': intro_obj?.colour}"></span>
                      <p class="text-name text-ellipsis w-100" style="margin-bottom: 0px !important;">
                        <app-table-popover [inputType]="'dropdown'" [inputValue]="intro_obj?.tag_name"
                          [formName]="'tags'" [labelName]="'Tag'" [dropDownItems]="tagsListArray"
                          [selectedItem]="intro_obj?.tags"
                          (update)="onUpdatePopOver($event, intro_obj, 'lead')"></app-table-popover>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Source : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">{{intro_obj?.source_type ? intro_obj?.source_type : "-"}}</p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Updated At : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">{{intro_obj?.updated_at ? (intro_obj?.updated_at | dateConversion) : "-"}}
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Created At : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">{{intro_obj?.created_at ? (intro_obj?.created_at | dateConversion) : "-"}}
                    </p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Description : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name w-100">
                      <app-table-popover [inputType]="'text'" [inputValue]="intro_obj?.description"
                        [formName]="'description'" [labelName]="'Description'"
                        (update)="onUpdatePopOver($event, intro_obj, 'lead')"></app-table-popover>
                    </p>
                  </div>
                </div>
              </ng-container>
              <p class="text-primary cursor fw-500" (click)="seeMore('enquiry')"><span *ngIf="enquiry_seemore">See 5
                  more</span><span *ngIf="!enquiry_seemore">See less</span></p>
            </ng-container>
            <!-- </ng-container> -->
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="mb-3" *ngIf="selected_type === 'deal'">
        <mat-accordion class="pipeline-accord">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p class="fw-400 mb-0 text-inner-info">Pipeline</p>
                <mat-icon>expand_more</mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row no-gutters mt-2 cursor" *ngIf="intro_obj">
              <div class="row align-items-center" *ngFor="let field of cardFields | slice:0:sliceItem">
                <div class="col-md-4 info-accord">
                  <p class="text-inner-info">{{field.label | titlecase}}</p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name text-ellipsis w-100" *ngIf="!field.url && field.data_type !== 'DATE_TIME'">: {{field.data ?
                    (field.data | titlecase) : "-"}}</p>
                  <p class="text-name text-ellipsis w-100" *ngIf="!field.url && field.data_type === 'DATE_TIME'">: {{field.data ?
                    (field.data | dateConversion) : "-"}}</p>
                  <p class="text-name text-ellipsis w-100" *ngIf="field.url === 'status' || field.url === 'source'">
                    : {{field.data ?
                    (field.data | titlecase) : "-" }}</p>
                  <p class="text-name text-ellipsis w-100"
                    *ngIf="field.url && field.url !== 'status' && field.url !=='source'">: {{field.data_name ?
                    field.data_name : "-"}}</p>
                </div>
              </div>
              <p class="text-primary fw-500" (click)="seeMoreFields()" *ngIf="cardFields.length>=5">See more</p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="mb-3" *ngIf="selected_type !== 'company'">
        <mat-accordion>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div>
                  <div class="d-flex align-items-center">
                    <p class="fw-600 text-primary mb-1">Related Contact</p>
                    <mat-icon class="mb-1">expand_more</mat-icon>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="avatar-group d-flex align-items-center">
                      <div
                        class="avatar-xss font-size-12 avatar-group-item bg-primary text-white fw-400 border-radius-50 d-flex align-items-center justify-content-center">
                        {{contact_info?.first_name ? (contact_info?.first_name[0] | titlecase) : "-"}}
                      </div>
                    </div>
                    <p class="fw-400 mb-0 ms-2 text-inner-info">{{contact_info?.first_name ?
                      contact_info?.first_name : "-"}} {{contact_info?.last_name ?
                      contact_info?.last_name : ""}}</p>
                  </div>
                </div>

              </mat-panel-title>
              <!-- <mat-icon class="cursor-move" cdkDragHandle>reorder</mat-icon> -->
            </mat-expansion-panel-header>
            <!-- <ng-container *ngIf="item.title === 'Contact'"> -->
            <ng-container *ngIf="contact_info else no_contact_data">
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-inner-info">First name : </p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name text-ellipsis w-100">
                    <!-- : {{contact_info.first_name ? contact_info.first_name : "-"}} -->
                    <app-table-popover [inputType]="'text'" [inputValue]="contact_info?.first_name"
                      [formName]="'first_name'" [labelName]="'Firstname'"
                      (update)="onUpdatePopOver($event, contact_info, 'contact')"></app-table-popover>
                  </p>
                </div>
              </div>
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-inner-info">Last name : </p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name text-ellipsis w-100">
                    <!-- {{contact_info.last_name ? contact_info.last_name : "-"}} -->
                    <app-table-popover [inputType]="'text'" [inputValue]="contact_info?.last_name"
                      [formName]="'last_name'" [labelName]="'Lastname'"
                      (update)="onUpdatePopOver($event, contact_info, 'contact')"></app-table-popover>
                  </p>
                </div>
              </div>
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-inner-info">Email : </p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name text-ellipsis w-100"> {{contact_info?.email ?
                    contact_info?.email : "-"}}</p>
                </div>
              </div>
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-inner-info">Mobile : </p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name text-ellipsis w-100">
                    <!-- {{contact_info.phone_number ? contact_info.phone_number : "-"}} -->
                    <app-table-popover [inputType]="'text'" [inputValue]="contact_info?.phone_number"
                      [labelName]="'Mobile Number'" [formName]="'phone_number'"
                      (update)="onUpdatePopOver($event, contact_info, 'contact')"></app-table-popover>
                  </p>
                </div>
              </div>
              <ng-container *ngIf="!contact_seemore">
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Owner : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name text-ellipsis w-100">
                      <app-table-popover [inputType]="'dropdown'"
                        [inputValue]="contact_owner?.fname + ' ' + contact_owner?.lname" [formName]=""
                        [labelName]="'Owner'" [dropDownItems]="staffMembers" [selectedItem]="contact_info?.owner"
                        (update)="onUpdatePopOver($event, contact_info, 'contact')"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Tag : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <!-- <p class="text-name bg-tag d-inline-flex align-items-center px-2 border-radius-50">
                      {{contact_info?.tags
                      ?
                      contact_info?.tags : "-"}}</p> -->
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="border-radius-50 m-0 p-2 tag-color me-2" style="height: 2px;"
                        [style]="{'background-color': contact_info?.colour}"></span>
                      <p class="text-name text-ellipsis w-100" style="margin-bottom: 0px !important;">
                        <app-table-popover [inputType]="'dropdown'" [inputValue]="contact_info?.tag_name"
                          [formName]="'tags'" [labelName]="'Tag'" [dropDownItems]="tagsListArray"
                          [selectedItem]="contact_info?.tags"
                          (update)="onUpdatePopOver($event, contact_info, 'contact')"></app-table-popover>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Source : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">{{contact_info?.source ? contact_info?.source : "-"}}</p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Updated At : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">{{contact_info?.updated_at ? (contact_info?.updated_at | dateConversion) :
                      "-"}}
                    </p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Created At : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">{{contact_info?.created_at ? (contact_info?.created_at | dateConversion) :
                      "-"}}
                    </p>
                  </div>
                </div>
              </ng-container>
              <p class="text-primary cursor fw-500" (click)="seeMore('contact')"><span *ngIf="contact_seemore">See 5
                  more</span><span *ngIf="!contact_seemore">See less</span></p>
            </ng-container>

            <ng-template #no_contact_data>
              <div class="text-center text-name-secondary py-4">No Data</div>
            </ng-template>
            <!-- </ng-container> -->
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="mb-3" *ngIf="selected_type !== 'deal'">
        <mat-accordion>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div>
                  <div class="d-flex align-items-center">
                    <p class="fw-600 text-primary mb-1">Related Company</p>
                    <mat-icon class="mb-1">expand_more</mat-icon>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="avatar-group d-flex align-items-center">
                      <div
                        class="avatar-xss font-size-12 avatar-group-item bg-primary text-white fw-400 border-radius-50 d-flex align-items-center justify-content-center">
                        {{company_info?.name ? (company_info?.name[0] | titlecase) : "-" }}
                      </div>
                    </div>
                    <p class="fw-400 mb-0 ms-2 text-inner-info">
                      <span *ngIf="selected_type==='company'">{{company_info?.name ? company_info?.name : "-"}}</span>

                      <app-table-popover [inputType]="'dropdown'" [inputValue]="company_info?.name" [formName]=""
                        [labelName]="'Company Name'" [dropDownItems]="companiesListArray"
                        [selectedItem]="intro_obj?.company" (update)="onUpdatePopOver($event, intro_obj, selected_type)"
                        *ngIf="selected_type!=='company'"></app-table-popover>
                    </p>
                  </div>
                </div>
              </mat-panel-title>
              <!-- <mat-icon class="cursor-move" cdkDragHandle>reorder</mat-icon> -->
            </mat-expansion-panel-header>
            <!-- <ng-container *ngIf="item.title === 'Company'"> -->
            <ng-container *ngIf="company_info else no_company_data">
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-inner-info">Name : </p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name">
                    <span *ngIf="selected_type!=='company'">{{company_info?.name ? company_info?.name : "-"}}</span>
                    <app-table-popover [inputType]="'text'" [inputValue]="company_info?.name" [labelName]="'Name'"
                      [formName]="'name'" (update)="onUpdatePopOver($event, company_info, 'company')"
                      *ngIf="selected_type==='company'"></app-table-popover>
                  </p>
                </div>
              </div>
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-inner-info">Email : </p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name">{{company_info?.email ? company_info?.email : "-"}}</p>
                </div>
              </div>
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-inner-info">Mobile : </p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name">
                    <span *ngIf="selected_type!=='company'">{{company_info?.phonenumber ? company_info?.phonenumber :
                      "-"}}</span>
                    <app-table-popover [inputType]="'text'" [inputValue]="company_info?.phonenumber"
                      [labelName]="'Mobile Number'" [formName]="'phonenumber'"
                      (update)="onUpdatePopOver($event, company_info, 'company')"
                      *ngIf="selected_type==='company'"></app-table-popover>
                  </p>
                </div>
              </div>
              <div class="row no-gutters mt-2 cursor">
                <div class="col-md-4 info-accord">
                  <p class="text-inner-info">Website : </p>
                </div>
                <div class="col-md-8 info-accord">
                  <p class="text-name">
                    <span *ngIf="selected_type!=='company'">{{company_info?.website ? company_info?.website :
                      "-"}}</span>

                    <app-table-popover [inputType]="'text'" [inputValue]="company_info?.website" [labelName]="'Website'"
                      [formName]="'website'" (update)="onUpdatePopOver($event, company_info, 'company')"
                      *ngIf="selected_type==='company'"></app-table-popover>
                  </p>
                </div>
              </div>
              <ng-container *ngIf="!company_seemore">
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Owner : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">
                      <span *ngIf="selected_type!=='company'">{{(company_owner.fname +' '+company_owner.lname) ||
                        '-'}}</span>
                      <app-table-popover [inputType]="'dropdown'"
                        [inputValue]="(company_owner?.fname || '-') + ' ' + (company_owner?.lname || '-')" [formName]=""
                        [labelName]="'Owner'" [dropDownItems]="staffMembers" [selectedItem]="company_info?.owner"
                        (update)="onUpdatePopOver($event, company_owner, 'company')"
                        *ngIf="selected_type==='company'"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Tag :</p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <!-- <p class="text-name bg-tag d-inline-flex align-items-center px-2 border-radius-50">
                      {{company_info?.tags? company_info?.tags : "-"}}</p> -->
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="border-radius-50 m-0 p-2 tag-color me-2" style="height: 2px;"
                        [style]="{'background-color': intro_obj?.colour}"></span>
                      <p class="text-name text-ellipsis w-100" style="margin-bottom: 0px !important;">
                        <app-table-popover [inputType]="'dropdown'" [inputValue]="intro_obj?.tag_name"
                          [formName]="'tags'" [labelName]="'Tag'" [dropDownItems]="tagsListArray"
                          [selectedItem]="intro_obj?.tags"
                          (update)="onUpdatePopOver($event, company_info, 'company')"></app-table-popover>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Source : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">{{company_info?.source? company_info?.source : "-"}}</p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Address : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name w-100 text-ellipsis">
                      <span *ngIf="selected_type!=='company'">{{company_info?.address ? company_info?.address :
                        "-"}}</span>

                      <app-table-popover [inputType]="'text'" [inputValue]="company_info?.address"
                        [labelName]="'Address'" [formName]="'address'"
                        (update)="onUpdatePopOver($event, company_info, 'company')"
                        *ngIf="selected_type==='company'"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">City : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">
                      <span *ngIf="selected_type!=='company'">{{company_info?.city ? company_info?.city :
                        "-"}}</span>

                      <app-table-popover [inputType]="'text'" [inputValue]="company_info?.city" [labelName]="'City'"
                        [formName]="'city'" (update)="onUpdatePopOver($event, company_info, 'company')"
                        *ngIf="selected_type==='company'"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Zipcode : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">
                      <span *ngIf="selected_type!=='company'">{{company_info?.zipcode ? company_info?.zipcode :
                        "-"}}</span>

                      <app-table-popover [inputType]="'text'" [inputValue]="company_info?.zipcode"
                        [labelName]="'Zipcode'" [formName]="'zipcode'"
                        (update)="onUpdatePopOver($event, company_info, 'company')"
                        *ngIf="selected_type==='company'"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Country : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">

                      <span *ngIf="selected_type!=='company'">{{company_info.country}}</span>

                      <app-table-popover [inputType]="'dropdown'" [inputValue]="company_info?.country"
                        [labelName]="'Country'" [formName]="'country'" [dropDownItems]="countryjson"
                        [selectedItem]="company_info?.country"
                        (update)="onUpdatePopOver($event, company_info, 'company')"
                        *ngIf="selected_type==='company'"></app-table-popover>

                    </p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Updated At : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">{{company_info?.updated_at ? (company_info?.updated_at | dateConversion) : ""}}
                    </p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Created At : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name">{{company_info?.created_at ? (company_info?.created_at | dateConversion) : ""}}
                    </p>
                  </div>
                </div>
                <div class="row no-gutters mt-2 cursor">
                  <div class="col-md-4 info-accord">
                    <p class="text-inner-info">Description : </p>
                  </div>
                  <div class="col-md-8 info-accord">
                    <p class="text-name text-wrap w-100">
                      <span *ngIf="selected_type!=='company'">{{company_info?.description ? company_info?.description :
                        "-"}}</span>

                      <app-table-popover [inputType]="'textarea'" [inputValue]="company_info?.description"
                        [labelName]="'Description'" [formName]="'description'"
                        (update)="onUpdatePopOver($event, company_info, 'company')"
                        *ngIf="selected_type==='company'"></app-table-popover>
                    </p>
                  </div>
                </div>
              </ng-container>
              <p class="text-primary cursor fw-500" (click)="seeMore('company')"><span *ngIf="company_seemore">See 10
                  more</span><span *ngIf="!company_seemore">See less</span></p>
            </ng-container>

            <ng-template #no_company_data>
              <div class="text-center text-name-secondary py-4">No Data</div>
            </ng-template>
            <!-- </ng-container> -->
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>


<!-- <div class="d-flex align-items-center mb-3">
                  <input class="border-0 w-100" type="text" (click)="conditionalInputs('input_field_cmpny_name',true)"
                    [(ngModel)]="company_info.name">
                  <div class="d-flex align-items-center" *ngIf="input_field_cmpny_name">
                    <mat-icon class="d-flex align-items-center font-size-16 mx-2" style="color: rgb(0, 128, 55);"
                      (click)="updateFieldChanges(company_info,'company','input_field_cmpny_name')">done</mat-icon>
                    <mat-icon class="d-flex align-items-center font-size-16" style="color: rgb(255, 0, 0);"
                      (click)="conditionalInputs('input_field_cmpny_name',false)">clear</mat-icon>
                  </div>
                </div> -->

<!-- <div class="d-flex align-items-center mb-3">
                  <input class="border-0 w-100" type="text" (click)="conditionalInputs('input_field_cmpny_email',true)"
                    [(ngModel)]="company_info.email">
                  <div class="d-flex align-items-center" *ngIf="input_field_cmpny_email">
                    <mat-icon class="d-flex align-items-center font-size-16 mx-2" style="color: rgb(0, 128, 55);"
                      (click)="updateFieldChanges(company_info,'company','input_field_cmpny_email')">done</mat-icon>
                    <mat-icon class="d-flex align-items-center font-size-16" style="color: rgb(255, 0, 0);"
                      (click)="conditionalInputs('input_field_cmpny_email',false)">clear</mat-icon>
                  </div>
                </div> -->

<!-- <div class="d-flex align-items-center mb-3">
                  <input class="border-0 w-100" type="text" (click)="conditionalInputs('input_field_cmpny_phno',true)"
                    [(ngModel)]="company_info.phonenumber">
                  <div class="d-flex align-items-center" *ngIf="input_field_cmpny_phno">
                    <mat-icon class="d-flex align-items-center font-size-16 mx-2" style="color: rgb(0, 128, 55);"
                      (click)="updateFieldChanges(company_info,'company','input_field_cmpny_phno')">done</mat-icon>
                    <mat-icon class="d-flex align-items-center font-size-16" style="color: rgb(255, 0, 0);"
                      (click)="conditionalInputs('input_field_cmpny_phno',false)">clear</mat-icon>
                  </div>
                </div> -->

<!-- <div class="d-flex align-items-center mb-3">
                  <input class="border-0 w-100" type="text"
                    (click)="conditionalInputs('input_field_cmpny_website',true)" [(ngModel)]="company_info.website">
                  <div class="d-flex align-items-center" *ngIf="input_field_cmpny_website">
                    <mat-icon class="d-flex align-items-center font-size-16 mx-2" style="color: rgb(0, 128, 55);"
                      (click)="updateFieldChanges(company_info,'company', 'input_field_cmpny_website')">done</mat-icon>
                    <mat-icon class="d-flex align-items-center font-size-16" style="color: rgb(255, 0, 0);"
                      (click)="conditionalInputs('input_field_cmpny_website',false)">clear</mat-icon>
                  </div>
                </div> -->