<div class="mb-0">
    <div class="p-3">
        <div class="header p-4 d-flex justify-content-between bg-grey-light border-radius-8">
            <div class="">
                <h6 class="fw-600">Hair therapy treatment</h6>
                <label class="text-light font-size-13">Dinesh B</label>
            </div>
            <div>
                <mat-select class="status_dropdown bg-primary border-radius-8 p-2 px-3"
                    [(ngModel)]="status_dropdown">
                    <mat-option value="pending">Pending</mat-option>
                    <mat-option value="completed">Completed</mat-option>
                </mat-select>
            </div>
        </div>
        <div class="mt-4">
            <h6 class="fw-700">Task Details</h6>
            <div class="row no-gutters mt-2">
                <div class="col-md-2">
                    <p class="text-name-secondary">Due Date</p>
                    <p class="text-name-secondary">Priority</p>
                    <p class="text-name-secondary">Reminder</p>
                    <p class="text-name-secondary">Description</p>
                    <p class="text-name-secondary">Last Modified</p>
                </div>
                <div class="col-md-6">
                    <p class="text-name">Mar 31, 2022</p>
                    <p class="text-name bg-tag d-inline-flex align-items-center px-2 border-radius-50">
                        Normal</p>
                    <p class="text-name">On due date, 08:00 AM</p>
                    <p class="text-name">Need to send a follow Up email</p>
                    <p class="text-name">Dileep K on Mar 30, 2022, 06:22 PM</p>
                </div>
            </div>
        </div>
        <div class="notes mt-4">
            <h6>Notes</h6>
            <div class="bg-gray-light border-radius-8 p-3">
                <div class="notes bg-white border-radius-8">
                    <textarea class="form-control content-input border-radius-0 m-0 font-size-15" aria-label="With textarea"
                        placeholder="What's this note about?" (click)="expandContent()"
                        [ngClass]="{'height-class' : contentExpand === true}" [(ngModel)]="notesContent"></textarea>
                </div>
                <div class="row col-md-8 mt-2" *ngIf="contentExpand">
                    <div class="d-flex align-items-center">
                        <button class="btn border-radius-50 border-1 bg-primary fw-600 font-size-13 text-white px-4 me-3"
                            [disabled]="!notesContent" >Save</button>
                        <button class="btn border-radius-50 border-1 bg-white fw-600 font-size-13 text-primary px-4 me-3"
                            >Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="files mt-5">
            <h6>Files</h6>
            <ngx-dropzone (change)="onSelect($event)" [multiple]="false" class="bg-gray-light" style="height: 61px;">
                <ngx-dropzone-label>Drag a file here or <span
                        class="text-primary fw-500">Browse</span>
                        for a file to upload
                </ngx-dropzone-label>
                <ngx-dropzone-image-preview [removable]="true" (removed)="onRemove(f)" ngProjectAs="ngx-dropzone-preview"
                    *ngFor="let f of files" [file]="f">
                </ngx-dropzone-image-preview>
            </ngx-dropzone>
        </div>
    </div>
</div>