import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { COMPANIESJSON } from 'src/app/core/data/companies_json';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { CrmService } from 'src/app/core/service/crm.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { countryData } from 'src/app/core/data/countryData';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-company-modal',
  templateUrl: './company-modal.component.html',
  styleUrls: ['./company-modal.component.scss']
})
export class CompanyModalComponent implements OnInit {
  user_details = JSON.parse(sessionStorage.getItem('currentUser')!);
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  countryjson = countryData;
  @Input() company: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  companiesJson = COMPANIESJSON[0];
  errorjson = ERRORJSON[0];
  staffMembers: any = [];
  contactListArray: any = [];
  companyForm!: FormGroup;
  submitted = false;
  info: any = {};
  loading = false;
  @ViewChild('myFirstInput') myFirstInput!: ElementRef;
  selectContact: any;
  newEmail = false;
  loggedInUser: any;
  assignee_departments: any = [];
  tagsListArray: any[] = [];

  constructor(public ngbActiveModal: NgbActiveModal, private service: CrmService, private settingsService: SettingsService, private authService: AuthService) { }

  ngOnInit() {
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.getContactsList();
    this.organisationMembersList();
    this.intilizeForm();
    this.getTags();
    this.companyForm.get('search')!.valueChanges.subscribe((change: any) => {
      if (change?.length >= 3) {
        this.service.getContactsBySearch(change).subscribe((result: any) => {
          this.contactListArray = result.data;
        });
      } else if (change?.length === 0) {
        this.getContactsList();
      }
    });

    if (!this.company) {
      this.info['owner_info'] = {
        fname: this.loggedInUser.data.fname,
        lname: this.loggedInUser.data.lname
      };
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.myFirstInput.nativeElement.focus();
    }, 0);
  }

  preventDefault() {
    return false;
  }

  intilizeForm() {
    this.companyForm = new FormGroup({
      id: new FormControl(this.company ? this.company.id : ''),
      name: new FormControl(this.company?.name ? this.company.name : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9_\s]{1,}$/)]),
      email: new FormControl(this.company?.email ? this.company.email : '', Validators.compose([Validators.required, Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)])),//eslint-disable-line 
      phonenumber: new FormControl(this.company?.phonenumber ? this.company.phonenumber : '', Validators.compose([Validators.required, Validators.pattern("[0-9]+"), Validators.minLength(6), Validators.maxLength(12)])),
      // contact : new FormControl(this.company ? this.company.contact : ''),
      address: new FormControl(this.company?.address ? this.company.address : '', Validators.required),
      owner: new FormControl(this.company?.owner ? this.company.owner : this.loggedInUser.data.id, Validators.required),
      assignee: new FormControl(this.company?.assignee ? this.company.assignee : []),
      city: new FormControl(this.company?.city ? this.company.city : '', Validators.required),
      country: new FormControl(this.company?.country ? this.company.country : '', Validators.required),
      zipcode: new FormControl(this.company?.zipcode ? this.company.zipcode : '', Validators.compose([Validators.required, Validators.pattern("[0-9]+")])),
      website: new FormControl(this.company?.website ? this.company.website : '', Validators.compose([Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')])),
      description: new FormControl(this.company?.description ? this.company.description : ''),
      source: new FormControl(this.company?.source ? this.company.source : 'manual'),
      contact_id: new FormControl(this.company?.contact_id ? this.company.contact_id : '', Validators.required),
      contact_name: new FormControl(this.company?.contact_name ? this.company.contact_name : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9_\s]{1,}$/)]),
      contact_email: new FormControl(this.company?.contact_email ? this.company.contact_email : '', Validators.compose([Validators.required, Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)])),//eslint-disable-line
      contact_number: new FormControl(this.company?.contact_number ? this.company.contact_number : '', Validators.compose([Validators.required, Validators.pattern("[0-9]+"), Validators.minLength(6), Validators.maxLength(12)])),
      tags: new FormControl(this.company ? this.company.tags : ''),
      related_contact: new FormControl({ value: '', disabled: true }),
      organisation: new FormControl(''),
      search: new FormControl(''),
      ccode: new FormControl(this.company?.ccode ? this.company.ccode : this.authService.getCompanyData().ccode, [Validators.required])
    });
    if (this.company) {
      this.companyForm.controls['email'].disable();
      this.companyForm.controls['source'].disable();
      this.companyForm.controls['contact_name'].disable();
      this.companyForm.controls['contact_number'].disable();
      this.companyForm.controls['contact_email'].disable();
      this.companyForm.controls['contact_id'].disable();
    }
  }

  // createNewEmail = (option: any) => ({ email: option, phonenumber: '' });

  get f() {
    return this.companyForm.controls;
  }

  onSubmit() {
    // console.log("create company - ", this.companyForm);
    this.submitted = true;
    if (this.companyForm.valid) {
      this.loading = true;
      this.assignee_departments = [];

      if (this.companyForm.value.assignee.length !== 0) {
        this.staffMembers.forEach((item: any) => {
          this.companyForm.value.assignee.filter((assigne: any) => {
            if (assigne === item.id) {
              this.assignee_departments.push(item.department_info.id);
            }
          });
        });
      }
      const owner_department = this.staffMembers.filter((member: any) => member.id === this.companyForm.value.owner);

      if (this.companyForm.value.id) {
        let info_body, valid_mailId;
        if (this.companyForm.controls["email"].status === 'DISABLED') {
          valid_mailId = this.companyForm.controls["email"].value;
        }
        if (Object.keys(this.info).length === 0) {
          info_body = this.company.info;
        } else {
          info_body = this.info;
        }
        this.service.updateCompany({ ...this.companyForm.value, info: info_body, email: valid_mailId, assignee_department: this.assignee_departments ? this.assignee_departments : [], owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : '' }).pipe(finalize(() => this.loading = false)).subscribe({
          next: () => {
            this.passBack();
            this.ngbActiveModal.dismiss();
          }
        });
      } else {
        if (this.newEmail) {
          this.createContact();
        } else {
          this.createCompany();
        }
      }
    }
  }

  createCompany() {
    const owner_department = this.staffMembers.filter((member: any) => member.id === this.companyForm.value.owner);
    this.service.createCompany({
      ...this.companyForm.value, info: this.info, contact_email: this.selectContact[0].email, contact_number: this.selectContact[0].phone_number,
      contact_name: this.selectContact[0].first_name, assignee_department: this.assignee_departments ? this.assignee_departments : [], owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : ''
    }).pipe(finalize(() => this.loading = false)).subscribe({
      next: () => {
        this.passBack();
        this.ngbActiveModal.dismiss();
      }
    });
  }

  createContact() {
    console.log(this.companyForm.value.contact_name, "contact name in company");

    const owner_department = this.staffMembers.filter((member: any) => member.id === this.companyForm.value.owner);
    this.settingsService.getStaff_info().subscribe((data: any) => {
      const loggedMember = data.data.filter((staff: any) => staff.id === this.user_details.data.id);
      const body = {
        custom_contact_fields: [],
        phone_number: this.companyForm.value.contact_number,
        ccode: this.companyForm.value.ccode,
        email: this.companyForm.value.contact_email,
        first_name: this.companyForm.value.contact_name,
        info: {
          owner_info: {
            fname: loggedMember.fname,
            lname: loggedMember.lname
          }
        },
        owner: this.user_details.data.id,
        owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : ''
      };
      this.service.createContact(body).subscribe({
        next: (resp: any) => {
          this.newEmail = false;
          this.selectContact = [resp.data];
          this.companyForm.patchValue({ contact_id: resp.data?.id, contact_name: resp.data.first_name });
          this.createCompany();
        }
      });
    });
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  getContactsList() {
    this.service.getContactsDropdownList().subscribe({
      next: (resp: any) => {
        this.contactListArray = resp.data;
      }
    });
  }

  addNewName = (name: any) => ({ first_name: name, id: '' });

  searchContact(term: any) {
    if (term.term.length >= 3) {
      this.service.getContactsBySearch(term.term).subscribe((result: any) => {
        this.contactListArray = result.data;
      });
    } else if (term.term.length === 0) {
      this.getContactsList();
    }
  }

  onContactChange(data: any) {
    console.log(data, "data");
    if (data?.id) {
      this.newEmail = false;
      this.selectContact = this.contactListArray.filter((contact: any) => contact.id === data.id);
      this.companyForm.patchValue({
        contact_email: this.selectContact[0].email, contact_number: this.selectContact[0].phone_number,
        contact_name: this.selectContact[0].first_name, contact_id: this.selectContact[0].id
      });
      this.companyForm.controls['contact_number'].disable();
      this.companyForm.controls['contact_email'].disable();
      this.companyForm.controls['contact_id'].setValidators(Validators.compose([Validators.required]));
      this.companyForm.controls['contact_id'].updateValueAndValidity();
    } else {
      this.newEmail = true;
      this.companyForm.patchValue({contact_id: data.id, contact_name : data.first_name});
      this.companyForm.controls['contact_id'].clearValidators();
      this.companyForm.controls['contact_id'].updateValueAndValidity();
    }
  }

  clearContactFields(e: any) {
    console.log(e, "clear event");
    this.companyForm.controls['contact_number'].enable();
    this.companyForm.controls['contact_email'].enable();
    this.companyForm.patchValue({ contact_email: '', contact_id: '', contact_number: '', contact_name: '' });
  }

  onOwnerSelect(owner: any) {
    this.info['owner_info'] = {
      fname: owner.fname,
      lname: owner.lname
    };
  }

  //*******************************Search filters START******************************************** *//
  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

  passBack() {
    this.passEntry.emit(true);
  }

  getTags() {
    this.settingsService.getTags().subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }
}
