import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray, FormGroupDirective } from '@angular/forms';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { ACTIVITYJSON } from '../../../../core/data/crm_modal_json';
import { CommonService } from 'src/app/core/service/common.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { ClientsService } from 'src/app/core/service/clients.service';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {
  @Input() isModal!: string;
  @Output() closeModalEvent = new EventEmitter<string>();
  activityJSON = ACTIVITYJSON[0];
  errorjson = ERRORJSON[0];
  selected_id!: string;
  // selected_type!: string;
  activity_show = false;
  activity_type = '';
  associated_dropdown = 'All';
  associated_with_options_array: any = [
    {
      group: "CONTACTS",
      options: [
        {
          value: 'website design'
        },
        {
          value: 'sahithy@syoft.com'
        },
        {
          value: 'deepak'
        }
      ]
    },
    {
      group: "DEALS",
      options: [
        {
          value: 'website design'
        }
      ]
    },
    {
      group: "CLIENTS",
      options: [
        {
          value: 'website design'
        }
      ]
    }
  ];
  submitedActivityForm = false;
  activity_form!: FormGroup;
  activity_filter = 'all';
  activityList_filter = 'all';
  activitiesArray: any = [];
  staffMembers: any = [];
  time_slotes = [
    '12:00 AM',
    '12:30 AM',
    '1:00 AM',
    '1:30 AM',
    '2:00 AM',
    '2:30 AM',
    '3:00 AM',
    '3:30 AM',
    '4:00 AM',
    '4:30 AM',
    '5:00 AM',
    '5:30 AM',
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM'
  ];
  source_id_details: any;
  info: any = {};
  loggedInUser: any;
  guestsMeetingList: any = [];
  minDate = new Date();
  activities_total_count!: number;
  pageEvent!: PageEvent;
  currentPage = 0;

  constructor(private route: ActivatedRoute, private service: CommonService, private client_service: ClientsService, private settingsService: SettingsService, private confirmationMsz: ConfirmationMszService) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      // console.log("route params timeline - ", params);
      this.selected_id = params.id;
      // this.selected_type = params.showtype;
      if (this.selected_id) {
        this.initlizeActivityForm();
        this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        this.activity_form.patchValue({ owner: this.loggedInUser.data.id });
        this.organisationMembersList();
        this.getAllActivites(this.selected_id, 1);

        this.client_service.getProjectDetailsById(this.selected_id).subscribe({
          next: (resp: any) => {
            this.source_id_details = resp.data;
            this.activity_form.patchValue({ source_id: this.source_id_details.id, source_name: this.source_id_details.name });
          }
        });
      }
    });
    if (this.isModal) {
      this.activity_show = true;
      this.activity_type = 'followup';
    }
  }

  getAllActivites(id: string, page: number) {
    this.service.getActivitysById(id, page).subscribe({
      next: (resp: any) => {
        // console.log("get activities - ", resp);
        this.activities_total_count = resp.count;
        this.activitiesArray = resp.data;
      }
    });
  }

  createActivity(body: any, source_name: string, formDirective: any) {
    let department: any;
    for (const item of this.staffMembers) {
      if (item.id === body.owner) {
        this.info['owner_info'] = {
          fname: item.fname,
          lname: item.lname
        };
        department = item.department_info.id;
      }
    }
    this.service.createActivity({ ...body, source_name: source_name, info: this.info, department: department }).subscribe({
      next: () => {
        this.activity_show = false;
        this.closeModalEvent.emit('close');
        this.guestsMeetingList = [];
        // console.log(this.guestsMeetingList,"meetingslist");

        this.getAllActivites(this.selected_id, 1);
        formDirective.resetForm();
        this.activity_form.reset();
        // console.log(this.activity_form.value);

        this.submitedActivityForm = false;
        this.initlizeActivityForm();
        this.activity_form.patchValue({ type: this.activity_type });
        this.activity_form.patchValue({ owner: this.loggedInUser.data.id });
        this.activity_form.patchValue({ source_id: this.source_id_details.id, source_name: this.source_id_details.name });

      }
    });
  }

  currentActivity(content: string, formDirective: FormGroupDirective) {
    this.submitedActivityForm = false;
    formDirective.resetForm();
    this.activity_form.reset();
    this.initlizeActivityForm();
    this.activity_show = true;
    this.activity_type = content;
    this.activity_form.patchValue({ type: this.activity_type });
    this.activity_form.patchValue({ owner: this.loggedInUser.data.id });
    this.activity_form.patchValue({ source_id: this.source_id_details.id, source_name: this.source_id_details.name });
    // console.log(this.activity_form.value,"value",this.guestsMeetingList);

  }

  initlizeActivityForm() {
    this.activity_form = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9,-/@_\s]{1,}$/)]),
      due_date: new FormControl('', Validators.required),
      source_id: new FormControl(this.selected_id, Validators.required),
      source_name: new FormControl({ value: '', disabled: true }),
      owner: new FormControl('', Validators.required),
      source_type: new FormControl('client', Validators.required),
      type: new FormControl('', Validators.required),
      location: new FormControl(''),
      meeting_link: new FormControl(''),
      guest: new FormArray([]),
      description: new FormControl('')
    });
  }

  get guestList() {
    return this.activity_form.get('guest') as FormArray;
  }

  addMailsToTheList(mail: any) {
    this.guestList.push(new FormControl(mail));
  }

  addnew = (option: any) => ({ mail: option });

  onSelectEmail(e: any) {
    // console.log("event", e);
    this.guestsMeetingList = e;
  }

  get f() {
    return this.activity_form.controls;
  }

  submitActivityForm(formDirective: FormGroupDirective) {
    // console.log(this.activity_form);

    this.submitedActivityForm = true;
    if (this.activity_form.valid) {
      if (this.guestsMeetingList) {
        this.guestsMeetingList.map((data: any) => {
          this.addMailsToTheList(data.mail);
        });
      }
      this.createActivity(this.activity_form.value, this.activity_form.value.source_name ? this.activity_form.value.source_name : this.activity_form.controls["source_name"].value, formDirective);
    }
  }

  updateActivity(id: any, status: boolean) {
    this.service.updateActivity(id, { mark_as_done: status }).subscribe(() => {
      this.getAllActivites(this.selected_id, 1);
    });
  }

  deleteActivity(id: string) {
    this.service.deleteActivty(id).subscribe(() => {
      this.getAllActivites(this.selected_id, 1);
    });
  }

  confirmDelete(fileId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteActivity(fileId);
      }
    });
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getAllActivites(this.selected_id, this.currentPage);
  }

}
