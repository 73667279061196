import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-details-modal',
  templateUrl: './details-modal.component.html',
  styleUrls: ['./details-modal.component.scss']
})
export class DetailsModalComponent implements OnInit{
  activeId: any = 1;
  selected_id!: string;
  selected_type!: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.selected_id = params.id;
      this.selected_type = params.showtype;
    });
  }

  tabChange(event: any) {
    this.activeId = event.nextId;
    // this.router.navigate([],{ relativeTo: this.route,queryParams: { showType: this.activeId } })
  }
}
