import { Component, OnInit } from '@angular/core';
import { TIMELINEJSON } from '../../../../core/data/crm_modal_json';
import { CommonService } from 'src/app/core/service/common.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-time-line',
  templateUrl: './time-line.component.html',
  styleUrls: ['./time-line.component.scss']
})
export class TimeLineComponent implements OnInit {
  timelineJSON = TIMELINEJSON[0];
  selected_id!: string;
  selected_type!: string;
  timeline_filter = 'All';
  CurrentPage = 1;
  currentPageTimelineList: any = [];
  orderedTimelineList: any = [];

  constructor(private route: ActivatedRoute, private service: CommonService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      // console.log("route params timeline - ", params);
      this.selected_id = params.id;
      this.selected_type = params.showtype;
      if (this.selected_id) this.getTimelineList(this.selected_id, 1);
    });
  }

  getTimelineList(id: any, page: any) {
    this.service.getUserTimeline(id, page).subscribe((resp: any) => {
      // console.log("timeline - ", resp);
      this.currentPageTimelineList = resp.data;
      if (resp.data.length !== 0) {
        this.currentPageTimelineList.forEach((item: any, index: number) => {
          const activity_array: any = [];
          this.currentPageTimelineList[index].on_date.forEach((timestamp: any, sub_index: number) => {
            const mapping_object: any = {};
            mapping_object['time_stamp'] = timestamp;
            mapping_object['activity'] = item.text[sub_index];
            activity_array.push(mapping_object);
          });
          this.currentPageTimelineList[index]['timeline'] = activity_array;
          // console.log(this.currentPageTimelineList);

          // this.orderedTimelineList.push(this.currentPageTimelineList[index]);
        });
      }
    });
  }

  onScroll(): void {
    this.getTimelineList(this.selected_id, ++this.CurrentPage);
  }
}
