export interface MenuItem {
  id?: number;
  label?: string;
  icon?: string;
  image?: string;
  link?: string;
  subItems?: any;
  isTitle?: boolean;
  badge?: any;
  slug?: any;
  parentId?: number;
  isLayout?: boolean;
  color_img?: string;
}

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'Home',
    icon: 'home',
    link: '/home',
    slug: 'dashboard',
    image: './assets/images/Home_Icon.svg',
    color_img: './assets/images/Color_Home_Icon.svg'
  },
  {
    id: 2,
    label: 'Crm',
    icon: 'folder_shared',
    link: '/crm/leads',
    slug: 'crm',
    image: './assets/images/CRM_Icon.svg',
    color_img: './assets/images/crm_blue.svg',
    subItems: [
      {
        id: 3,
        label: 'Enquiries',
        link: '/crm/leads',
        slug: 'leads',
        parentId: 2
      },
      {
        id: 5,
        label: 'Contacts',
        link: '/crm/contacts',
        slug: 'leads',
        parentId: 2
      },
      {
        id: 6,
        label: 'Companies',
        link: 'crm/companies',
        slug: 'leads',
        parentId: 2
      }
    ]
  },
  {
    id: 4,
    label: 'Pipelines',
    icon: 'folder_shared',
    link: 'pipelines/deals',
    slug: 'deals',
    image: './assets/images/pipelines.svg',
    color_img: './assets/images/color_pipeline.svg'
  },
  {
    id: 7,
    label: 'Clients',
    icon: 'people',
    link: '/clients/clients',
    slug: 'clients',
    image: './assets/images/Client_Icon.svg',
    color_img: './assets/images/clients_icon_blue.svg',
    subItems: [
      {
        id: 8,
        label: 'Clients',
        link: '/clients/clients',
        slug: 'clients',
        parentId: 7
      },
      {
        id: 9,
        label: 'Client Projects',
        link: '/clients/client-projects',
        slug: 'client projects',
        parentId: 7
      }
    ]
  },
  {
    id: 10,
    label: 'SetUp',
    icon: '',
    link: '/setup',
    slug: 'setup',
    image: './assets/images/set_up.svg',
    color_img: './assets/images/color_set_up.svg'
  },
  // {
  //   id: 11,
  //   label: 'Inbox',
  //   icon: '',
  //   link: '/inbox/appointments',
  //   slug: 'inbox',
  //   image: './assets/images/Inbox_Icon.svg',
  //   subItems: [
  //     {
  //       id: 12,
  //       label: 'Appointments',
  //       link: '/inbox/appointments',
  //       slug: 'appointments',
  //       parentId: 9
  //     },
  //     {
  //       id: 13,
  //       label: 'Orders',
  //       link: '/inbox/orders',
  //       slug: 'orders',
  //       parentId: 9
  //     }
  //   ]
  // },
  {
    id: 17,
    label: 'Finance',
    icon: 'monetization_on',
    link: '/finance',
    slug: 'finance',
    image: './assets/images/Finance_Icon.svg',
    color_img: './assets/images/color_finance_Icon.svg'
  },
  {
    id: 14,
    label: 'Chats',
    icon: 'chat',
    link: '/chats',
    slug: 'live_chat',
    image: './assets/images/Chat_Icon.svg',
    color_img: './assets/images/color_chat_Icon.svg'
  },
  {
    id: 15,
    label: 'Campaign',
    icon: 'campaign',
    link: '/campaign',
    slug: 'campaign',
    image: './assets/images/Campaign_Icon.svg',
    color_img: './assets/images/campaign_blue.svg'
  },
  // {
  //   id: 16,
  //   label: 'Reports',
  //   icon: 'pie_chart',
  //   link: '',
  //   slug: 'reports',
  //   image: './assets/images/Report_Icon.svg'
  // },
  {
    id: 20,
    label: 'Automation',
    icon: 'monetization_on',
    link: '/automation',
    slug: 'automation',
    image: './assets/images/automation.svg',
    color_img: './assets/images/color_automation.svg'
  }
];

export const header = [
  {
    text: "You are in Free plan.",
    text2: "Upgrade or Extend Trial",
    input_search: "Search here",
    profile_btn: 'Profile',
    logout_btn: 'Logout'
  }
];
