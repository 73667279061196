import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ADDCLIENTJSON } from 'src/app/core/data/client_json';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { mobile_json } from 'src/app/core/data/mobile_json';
import { ClientsService } from 'src/app/core/service/clients.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { countryData } from 'src/app/core/data/countryData';
import { ReplaySubject, Subject, finalize, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-client-modal',
  templateUrl: './client-modal.component.html',
  styleUrls: ['./client-modal.component.scss']
})
export class ClientModalComponent implements OnInit {
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  countryjson = countryData;
  submitted = false;
  clientForm!: FormGroup;
  addclient = ADDCLIENTJSON[0];
  errorJson = ERRORJSON[0];
  countryNamesJson = mobile_json;
  contactListArray: any = [];
  companiesListArray: any = [];
  staffMembers: any = [];
  newEmail = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() clientData: any;
  info: any = {};
  info_body: any;
  loading = false;
  loggedInUser: any;
  @ViewChild('myFirstInput') myFirstInput!: ElementRef;
  tagsListArray: any;

  constructor(private fb: FormBuilder, public ngbActiveModal: NgbActiveModal, private service: ClientsService, private crm_service: CrmService, private settingsService: SettingsService, private authService: AuthService) { }

  ngOnInit() {
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.initilizeForm();
    this.getContactsList();
    this.getCompaniesList();
    this.organisationMembersList();
    this.getTags();

    if (this.clientData?.id) {
      this.clientForm.controls["phone_number"].disable();
      this.clientForm.controls["email"].disable();
      this.clientForm.controls["name"].disable();
      this.clientForm.controls["source"].disable();
    } else {
      this.info['owner_info'] = {
        fname: this.loggedInUser.data.fname,
        lname: this.loggedInUser.data.lname
      };
    }
  }

  // ngAfterViewInit() {
  //   setTimeout(() => {
  //     this.myFirstInput.nativeElement.focus();
  //   }, 0);
  // }

  initilizeForm() {
    this.clientForm = this.fb.group({
      id: [this.clientData ? this.clientData.id : ''],
      name: [this.clientData ? this.clientData.name : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9_\s]{1,}$/)]],
      contact_id: [this.clientData ? this.clientData.contact_id : ''],
      email: [this.clientData ? this.clientData.email : '', Validators.compose([Validators.required, Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)])],//eslint-disable-line 
      phone_number: [this.clientData ? this.clientData.phone_number : '', Validators.compose([Validators.required, Validators.pattern("[0-9]+"), Validators.minLength(6), Validators.maxLength(12)])],
      source_id: [this.clientData ? this.clientData.source_id : ''],
      // source_type: ['company'],
      tag: [this.clientData ? this.clientData.tag : ''],
      owner: [this.clientData ? this.clientData.owner : this.loggedInUser.data.id, [Validators.required]],
      source_type: [this.clientData ? this.clientData.source_type : 'client'],
      source: [this.clientData ? this.clientData.source : 'manual'],
      country: [this.clientData ? this.clientData.country : ''],
      ccode: [this.clientData?.ccode ? this.clientData.ccode : this.authService.getCompanyData().ccode, [Validators.required]]
    });
    // console.log(this.clientForm.value, "kjdsfk", this.clientData);
    if (this.clientForm.value.id) this.clientForm.controls["ccode"].disable();

  }

  addNewName = (name: any) => ({ first_name: name, id: '' });

  onSelectContact(contact: any) {
    // console.log(contact, "contact selected");
    this.clientForm.controls["phone_number"].enable();
    this.clientForm.controls["email"].enable();
    this.clientForm.controls["ccode"].enable();
    if (contact?.id) {
      this.newEmail = false;
      this.clientForm.patchValue({ email: contact.email, contact_id: contact.id, phone_number: contact.phone_number, name: contact.first_name });
      this.clientForm.controls["phone_number"].disable();
      this.clientForm.controls["email"].disable();
      this.clientForm.controls["ccode"].disable();
    } else {
      this.clientForm.patchValue({ contact: contact.id, name: contact.first_name });
      this.newEmail = true;
    }
  }

  get f() {
    return this.clientForm.controls;
  }

  // createNewEmail = (option: any) => ({ email: option, phone_number: '', id: '' });

  // onSelectEmail(value: any) {
  //   if (value.email && value.id) {
  //     this.newEmail = false;
  //     this.clientForm.patchValue({ contact_id: value.id });
  //   } else {
  //     this.newEmail = true;
  //   }
  //   if (value) this.clientForm.patchValue({ email: value.email, contact: value.id, phone_number: value.phone_number });
  // }

  onSubmit() {
    this.submitted = true;
    if (this.clientForm.valid) {
      this.loading = true;
      let valid_name: any;
      let valid_mailId: any;
      let valid_phone_number: any;
      let valid_ccode: any;

      if (this.clientForm.controls["name"].status === 'DISABLED') {
        valid_name = this.clientForm.controls["name"].value;
      } else {
        valid_name = this.clientForm.value.name;
      }

      if (this.clientForm.controls["email"].status === 'DISABLED') {
        valid_mailId = this.clientForm.controls["email"].value;
      } else {
        valid_mailId = this.clientForm.value.email;
      }

      if (this.clientForm.controls["phone_number"].status === 'DISABLED') {
        valid_phone_number = this.clientForm.controls["phone_number"].value;
      } else {
        valid_phone_number = this.clientForm.value.phone_number;
      }

      if (this.clientForm.controls["ccode"].status === 'DISABLED') {
        valid_ccode = this.clientForm.controls["ccode"].value;
      } else {
        valid_ccode = this.clientForm.value.ccode;
      }

      if (Object.keys(this.info).length === 0) {
        this.info_body = this.clientData?.info;
      } else {
        this.info_body = this.info;
      }
      if (this.newEmail === true) {
        this.clientForm.controls['contact_id'].setValidators(Validators.required);
        this.clientForm.controls['contact_id'].updateValueAndValidity();
        this.createContact(valid_phone_number, valid_mailId, valid_name, valid_ccode);
      } else {
        this.createClient(valid_phone_number, valid_mailId, valid_name, valid_ccode);
      }
    }
  }

  createClient(valid_phone_number: string, valid_mailId: string, valid_name: string, valid_ccode: any) {
    if (this.clientForm.valid) {
      const owner_department = this.staffMembers.filter((member: any) => member.id === this.clientForm.value.owner);
      if (this.clientForm.value.id) {
        this.service.updateClient(this.clientForm.value.id, { ...this.clientForm.value, ccode: valid_ccode, phone_number: valid_phone_number, email: valid_mailId, name: valid_name, info: this.info_body, source: this.clientForm.controls["source"].value, owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : '' }).pipe(finalize(() => this.loading = false)).subscribe((resp => {
          this.passEntry.emit("success");
          this.ngbActiveModal.dismiss();
        }));
      } else {
        this.service.createClient({ ...this.clientForm.value,ccode: valid_ccode, phone_number: valid_phone_number, email: valid_mailId, name: valid_name, info: this.info_body, owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : '' }).pipe(finalize(() => this.loading = false)).subscribe((resp => {
          this.passEntry.emit("success");
          this.ngbActiveModal.dismiss();
        }));
      }
    }
  }

  onOwnerSelect(owner: any) {
    this.info['owner_info'] = {
      fname: owner.fname,
      lname: owner.lname
    };
  }

  createContact(valid_phone_number: string, valid_mailId: string, valid_name: string, valid_ccode: any) {
    const owner_department = this.staffMembers.filter((member: any) => member.id === this.clientForm.value.owner);
    const body = {
      ...this.clientForm.value,
      custom_contact_fields: [],
      phone_number: valid_phone_number,
      email: valid_mailId,
      company: this.clientForm.controls["source_id"].value,
      first_name: valid_name,
      info: this.info_body,
      ccode: valid_ccode,
      owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : ''
    };
    this.crm_service.createContact(body).subscribe({
      next: (resp: any) => {
        this.clientForm.controls['contact_id'].setValue(resp.data?.id);
        this.createClient(valid_phone_number, valid_mailId, valid_name, valid_ccode);
      }
    });
  }

  searchContact(term: any) {
    if (term.term.length >= 3) {
      this.crm_service.getContactsBySearch(term.term).subscribe((result: any) => {
        this.contactListArray = result.data;
      });
    } else if (term.term.length === 0) {
      this.getContactsList();
    }
  }

  getContactsList() {
    this.crm_service.getContactsDropdownList().subscribe({
      next: (resp: any) => {
        this.contactListArray = resp.data;
        if (this.clientData?.id) {
          this.contactListArray.forEach((item: any) => {
            if (item.id !== this.clientData.contact_id) {
              this.getContactById(this.clientData.contact_id);
            }
          });
        }
      }
    });
  }

  getContactById(id: string) {
    this.crm_service.getContactById(id).subscribe({
      next: (resp: any) => {
        if (resp.data[0]) {
          this.contactListArray.push(resp.data[0]);
        }
      }
    });
  }

  getCompaniesList() {
    this.crm_service.getCompaniesDropdownList().subscribe({
      next: (resp: any) => {
        this.companiesListArray = resp.data;
      }
    });
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  clearContactFields(e: any) {
    console.log(e, "clear event");
    this.clientForm.patchValue({ email: '', contact_id: '', phone_number: '', name: '' });
  }

  //*******************************Search filters START******************************************** *//
  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

  getTags() {
    this.settingsService.getTags().subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }
}
