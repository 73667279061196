import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { countryData } from 'src/app/core/data/countryData';
import { currencyJson } from 'src/app/core/data/currencyData';
import { time_zone_json } from 'src/app/core/data/mobile_json';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { COMPANYSETTINGS, LOCATIONS } from 'src/app/core/data/settings_json';
import { SettingsService } from 'src/app/core/service/settings.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { environment } from 'src/environments/environment';
import { MatTooltip } from '@angular/material/tooltip';
import { AuthService } from 'src/app/core/service/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  countryjson = countryData;
  currencyjson = currencyJson;
  timezonejson = time_zone_json;
  errorjson = ERRORJSON[0];
  companysettings = COMPANYSETTINGS[0];
  locationsettings = LOCATIONS[0];
  loggedInUser: any;
  activeId = 1;
  public bankFilterCurrencyCtrl: FormControl = new FormControl();
  public filteredCurrencyBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onCurrencyDestroy = new Subject<void>();
  public bankFilterTimezoneCtrl: FormControl = new FormControl();
  public filteredTimezoneBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onTimezoneDestroy = new Subject<void>();
  companySettingsForm!: FormGroup;
  submited = false;
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  company_logo: File[] = [];
  locationData!: FormGroup;
  generalSettingsForm!: FormGroup;
  isCompanySubmitted = false;
  branches: any = [];
  locationSubmit = false;
  editBusinessUrl = false;
  fileName!: string;
  presignedurl = environment.preSendUrl;
  @ViewChild('myFirstInput') myFirstInput!: ElementRef;
  @ViewChild('locationFirstElement') locationFirstElement!: ElementRef;
  @ViewChild('tp') _matTooltip!: MatTooltip;
  chatBotId: any;
  Iframe_url = '';
  activatedRouter='';
  @Output() onfinish :EventEmitter<any> = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private settingsService: SettingsService, private modalService: NgbModal,
    private confirmationMsz: ConfirmationMszService, private awsUtilService: AwsUtilsService,
    private renderer: Renderer2,private authService: AuthService,private router: Router) { }

  ngOnInit() {
    this.activatedRouter = this.router.url;
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.filteredCurrencyBanks.next(this.currencyjson.slice());
    this.bankFilterCurrencyCtrl.valueChanges
      .pipe(takeUntil(this._onCurrencyDestroy))
      .subscribe(() => {
        this.filterCurrencyBanks();
      });
    this.filteredTimezoneBanks.next(this.timezonejson.slice());
    this.bankFilterTimezoneCtrl.valueChanges
      .pipe(takeUntil(this._onCurrencyDestroy))
      .subscribe(() => {
        this.filterTimezoneBanks();
      });
    this.Initilize_General_Settings_Form();
    this.getGeneralSettingsDetails();
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'underline', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
  }

  tabChange(event: any) {
    this.activeId = event.nextId;
    if (this.activeId === 1) {
      // this.getGeneralSettingsDetails();
    } else if (this.activeId === 2) {
      this.Intilizie_Company_settings_form();
      this.getCompanySettingsDetails();
      this.getIframeDefaultBot();
      this.editBusinessUrl = false;

      setTimeout(() => {
        this.myFirstInput.nativeElement.focus();
      }, 0);
    } else if (this.activeId === 3) {
      this.intilizeLocationForm();
      this.getLocationDetails();
    }
  }

  Initilize_General_Settings_Form(data?: any) {
    this.generalSettingsForm = this.formBuilder.group({
      country: [data ? data.country : '', Validators.required],
      currency: [data ? data.currency : '', Validators.required],
      timezone: [data ? data.timezone : '', Validators.required],
      ccode: [data ? data.ccode : '+91', [Validators.required]],
      dateformat: [data ? data.dateformat : 'MM/dd/YYYY'],
      timeformat: [data ? data.timeformat : 'H:MM A'],
      slot_duration: [data ? data.slot_duration : '30 MIN']
    });
  }

  Intilizie_Company_settings_form(data?: any) {
    this.companySettingsForm = this.formBuilder.group({
      id: [data ? data.id : ''],
      name: [data ? data.name : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z_\s]{1,}$/)]],
      email: [data ? data.email : '', Validators.compose([Validators.required, Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)])],//eslint-disable-line 
      website: [data ? data.website : '', Validators.compose([Validators.required, Validators.pattern("[a-zA-Z0-9].+")])],
      description: [data ? data.description : ''],
      language: [data ? 'english' : 'english'],
      image: [data ? data.image : ''],
      website_url: [data ? data.website_url : ''],
      instagram_url: [data ? data.instagram_url : ''],
      twitter_url: [data ? data.twitter_url : ''],
      linkedin_url: [data ? data.linkedin_url : ''],
      youtube_url: [data ? data.youtube_url : ''],
      slug: [data ? data.slug : '', [Validators.required, Validators.pattern(/^[a-z][a-z0-9]{2,}$/)]]
    });
    this.fileName = data ? data.image : '';
  }

  get f() {
    return this.generalSettingsForm.controls;
  }

  get companyForm() {
    return this.companySettingsForm.controls;
  }

  onGeneralSettingsDetailsSubmit() {
    this.submited = true;
    if (this.generalSettingsForm.valid) {
      this.settingsService.postGeneralSettings(this.generalSettingsForm.value).subscribe(() => {
        this.getGeneralSettingsDetails();
        this.authService.getData();
        if(this.activatedRouter === '/onboarding') this.onActiveChange(2);
      });
    }
  }

  getGeneralSettingsDetails() {
    this.settingsService.getGeneralSettings().subscribe((resp: any) => {
      this.Initilize_General_Settings_Form(resp.data);
    });
  }

  onCompanyDetailsSubmit() {
    this.isCompanySubmitted = true;
    if (this.companySettingsForm.valid) {
      this.companySettingsForm.value.image = this.fileName;
      this.settingsService.postCompanySettings(this.companySettingsForm.value).subscribe(() => {
        this.getCompanySettingsDetails();
        this.editBusinessUrl = false;
        if(this.activatedRouter === '/onboarding') this.onActiveChange(3);
      });
    }
  }

  getIframeDefaultBot() {
    this.settingsService.getIframeDefaultBot().subscribe((resp: any) => {
      this.chatBotId = resp.data._id;
      if (this.chatBotId) {
        this.Iframe_url = 'https://www.widget.worke.io/?organizationId=' + this.companySettingsForm.value.id + '&chatBotId=' + this.chatBotId;
      } else {
        this.Iframe_url = 'https://www.widget.worke.io/?organizationId=' + this.companySettingsForm.value.id;
      }
    });
  }

  getCompanySettingsDetails() {
    this.settingsService.getCompanySettings().subscribe((resp: any) => {
      this.Intilizie_Company_settings_form(resp.data);
    });
  }
  businessURLUpdate(value: boolean) {
    this.editBusinessUrl = value;
  }
  onSelect(event: any) {
    this.company_logo.push(...event.addedFiles);
    if (this.company_logo && this.company_logo.length >= 1) {
      this.onRemove(this.company_logo[0]);
    }
    this.company_logo.push(...event.addedFiles);
    this.awsUtilService.getUploadFileLink(this.company_logo[0].type).subscribe((resp) => {
      this.fileName = resp.data.file_name;
      this.awsUtilService.uploadFile(this.company_logo[0], resp.data.url).subscribe(() => {//@typescript-eslint/no-empty-function
      });
    });
  }

  onRemove(event: any) {
    this.fileName = '';
    this.company_logo.splice(this.company_logo.indexOf(event), 1);
  }


  // For Google Search
  initAutocomplete(eventData: any) {
    const input = document.getElementById('pac-input-1') as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (places!.length === 0) {
        return;
      } else if (places?.length === 1) {
        this.locationData.controls['location'].setValue(places[0].name + ',' + places[0].formatted_address);
      }
    });
  }

  intilizeLocationForm(data?: any) {
    this.locationData = this.formBuilder.group({
      id: [data ? data.id : ''],
      name: [data ? data.name : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9_\s]{1,}$/)]],
      location: [data ? data.location : '', Validators.required],
      status: [data ? data.status : 'true', Validators.required],
      default: [data ? data.default : 'false', Validators.required]
    });
  }

  get locationForm() {
    return this.locationData.controls;
  }

  addLocation(model: any, data?: any) {
    this.intilizeLocationForm(data);
    this.modalService.open(model, { size: 'md' });

    setTimeout(() => {
      this.locationFirstElement.nativeElement.focus();
    }, 0);
  }

  confirm(deleteId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteLocation(deleteId);
      }
    });
  }

  deleteLocation(id: string) {
    this.settingsService.deleteLocationSettings(id).subscribe(() => {
      this.getLocationDetails();
    });
  }

  onLocationSubmit() {
    this.locationSubmit = true;
    if (this.locationData.valid) {
      if (!this.locationData.value.id) {
        this.settingsService.postLocationSettings(this.locationData.value).subscribe(() => {
          this.getLocationDetails();
        });
      } else {
        this.settingsService.putLocationSettings(this.locationData.value).subscribe(() => {
          this.getLocationDetails();
        });
      }
      this.modalService.dismissAll();
    }
  }

  toggleVisibility(item: any) {
    this.settingsService.putLocationSettings(item).subscribe(() => {
      this.getLocationDetails();
    });
  }

  getLocationDetails() {
    this.settingsService.getLocationSettings().subscribe((resp: any) => {
      this.branches = resp.data;
    });
  }

  //*******************************Search filters START******************************************** *//
  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterCurrencyBanks() {
    if (!this.currencyjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCurrencyCtrl.value;
    if (!search) {
      this.filteredCurrencyBanks.next(this.currencyjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCurrencyBanks.next(
      this.currencyjson.filter(bank => (bank.currency.toLowerCase().indexOf(search) > -1) ||
        bank.abbreviation.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterTimezoneBanks() {
    if (!this.timezonejson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterTimezoneCtrl.value;
    if (!search) {
      this.filteredTimezoneBanks.next(this.timezonejson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredTimezoneBanks.next(
      this.timezonejson.filter(bank => (bank.text.toLowerCase().indexOf(search) > -1))
    );
  }
  //*******************************Search filters END******************************************** *//
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onCurrencyDestroy.next();
    this._onCurrencyDestroy.complete();
    this._onTimezoneDestroy.next();
    this._onTimezoneDestroy.complete();
  }

  onClipboardCopy(e: any) {
    setTimeout(() => {
      this._matTooltip.show();
      this._matTooltip.message = e ? 'Copied!' : 'Copy to clipboard';
    });
  }

  onActiveChange(activeId: number){
    if (activeId === 2) {
      this.activeId = activeId;
      this.Intilizie_Company_settings_form();
      this.getCompanySettingsDetails();
      this.getIframeDefaultBot();
      this.editBusinessUrl = false;
      setTimeout(() => {
        this.myFirstInput.nativeElement.focus();
      }, 0);
      this.onfinish.emit(false);
    } else if (activeId === 3) {
      this.activeId = activeId;
      this.intilizeLocationForm();
      this.getLocationDetails();
    } else if (activeId === 4) {
      this.onfinish.emit(true);
    }
  }
}
