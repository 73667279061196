<div class="modal-header">
    <h5 class="modal-title text-primary fw-600" *ngIf="!clientProjectData?.id">{{addclient.heading}}</h5>
    <h5 class="modal-title text-primary fw-600" *ngIf="clientProjectData?.id">Update Project</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancelModal()"></button>
</div>

<div class="modal-body">
    <form [formGroup]="clientForm" (ngSubmit)="onSubmit()">
        <div class="row">

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <label class="fw-600 mb-2 text-primary">{{addclient.label_project}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder="{{addclient.label_project}}"
                        formControlName="name" />
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['name'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['name'].errors['required']"> Project
                            {{errorJson.required}}</span>
                        <span *ngIf="f['name'].errors['pattern']">Only alphanumeric are accepted.</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <label class="fw-600 mb-2 text-primary">{{addclient.label_name}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="client_id" placeholder="Client" class="pt-1">
                        <mat-option (click)="onSelectClient(item)" *ngFor="let item of clients" [value]="item.id"
                            class="filteropt">{{item.name}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['client_id'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['client_id'].errors['required']"> Client
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <label class="fw-600 mb-2 text-primary">{{addclient.label_phone}}</label>
                <div class="d-flex align-items-center">
                    <mat-form-field appearance="outline" class="me-1">
                        <mat-select placeholder="Country code" formControlName="ccode">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of filteredBanks | async"
                                [ngClass]="{'d-none': country.IAC === ''}"
                                value={{country.IAC}}>{{country.IAC}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100">
                        <input readonly style="outline: none" matInput placeholder="{{addclient.label_phone}}"
                            formControlName="phone" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['phone'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['phone'].errors['required']"> {{addclient.label_phone}}
                                {{errorJson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- <label class="fw-600 mb-2 text-primary">{{addclient.label_phone}} <small
                        class="text-danger">*</small></label>
                <div formArrayName="phone">
                    <div *ngFor="let skill of phone.controls; let i = index">
                        <div class="d-flex" [formGroupName]="i" [ngClass]="i >= 1 ? 'mt-2' : ''">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="{{addclient.label_phone}}"
                                    formControlName="phone" />
                                <mat-error class="error text-danger d-flex" *ngIf="submitted && f['phone'].errors">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                    <span *ngIf="f['phone'].errors['required']">
                                        {{addclient.label_phone}} {{errorJson.required}}
                                    </span>
                                </mat-error>
                            </mat-form-field>
                            <button *ngIf="i > 0" type="button" class="btn btn-danger ms-2" style="height: 45px"
                                (click)="removePhoneNumField(i)">
                                <mat-icon>{{addclient.btn_cancel}}</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <span (click)="addPhoneNumFields()"
                    class="text-success font-size-12 cursor">+{{addclient.btn_add_phone}}</span> -->
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <!-- <form > -->
                <label class="fw-600 mb-2 text-primary">{{addclient.label_email}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <input readonly style="outline: none" matInput placeholder="{{addclient.label_email}}"
                        formControlName="email" />
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['email'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['email'].errors['required']"> {{addclient.label_email}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
                <!-- <label class="fw-600 mb-2 text-primary">{{addclient.label_email}} <small
                        class="text-danger">*</small></label>
                <ng-select class="form-control" [items]="clientsArray" bindLabel="email_id" [addTag]="addOptionToList"
                    [multiple]="false" placeholder="{{addclient.label_email}}" formControlName="email">
                </ng-select> -->
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 text-primary">{{addclient.label_product}}</label>
                <ng-select class="form-control" [items]="products" bindValue="id" bindLabel="name" [addTag]="false"
                    [multiple]="true" placeholder="{{addclient.label_product}}" formControlName="service_ids">
                </ng-select>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 text-primary">{{addclient.label_tag}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="tag" placeholder="Select Tag" class="pt-1">
                        <mat-option [value]="item.id" *ngFor="let item of tagsListArray">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <label class="fw-600 mb-2 text-primary">Status</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="project_status" placeholder="Status">
                        <mat-option value="PENDING" class="filteropt">Pending</mat-option>
                        <mat-option value="COMPLETED" class="filteropt">Completed</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">Channel</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="source" placeholder="Select Channel" class="pt-1">
                        <mat-option value="whatsapp">Whatsapp</mat-option>
                        <mat-option value="manual">Manual entry</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{addclient.label_price}}</label>
                <div class="d-flex">
                    <!-- <mat-form-field appearance="outline" class="w-50rem me-3">
                        <mat-select style="outline: none" placeholder="{{addclient.label_currency}}"
                            formControlName="currency">
                            <mat-option *ngFor="let item of currency" [value]="item">{{item}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="{{addclient.label_price}}"
                            formControlName="cost" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['cost'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['cost'].errors['pattern']"> {{addclient.label_price}}
                                {{errorJson.invalid}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{addclient.label_date}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput [matDatepicker]="picker" [min]="minDate"
                        placeholder="{{addclient.label_date}}" formControlName="due_date" />
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['due_date'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['due_date'].errors['required']">Due date
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{addclient.label_staff}} <small
                        class="text-danger">*</small></label>
                <ng-select class="form-control" [items]="staffMembers" bindValue="id" bindLabel="fname" [addTag]="false"
                    [multiple]="true" placeholder="{{addclient.label_staff}}" formControlName="staff_ids">
                </ng-select>
                <div class="error d-flex mt-2" *ngIf="submitted && f['staff_ids'].errors">
                    <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                    <span *ngIf="f['staff_ids'].errors['required']"> Staff
                        {{errorJson.required}}</span>
                </div>
                <!-- <label class="fw-600 mb-2 text-primary">{{addclient.owner}} <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="staff_ids" placeholder={{addclient.owner}} class="pt-1">
                        <mat-option *ngFor="let member of staffMembers" [value]="member.id">{{member.fname}}
                            {{member.lname}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['owner'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['owner'].errors['required']">
                            {{addclient.owner}} {{errorJson.required}}
                        </span>
                    </mat-error>
                </mat-form-field> -->
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{addclient.owner}} <small class="text-danger">*</small></label>
                <ng-select class="form-control" [items]="staffMembers" bindValue="id" (change)="selectOwner($event)"
                    bindLabel="fname" [addTag]="false" placeholder="{{addclient.owner}}" formControlName="owner">
                </ng-select>
                <div class="error d-flex mt-2" *ngIf="submitted && f['owner'].errors">
                    <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                    <span *ngIf="f['owner'].errors['required']"> Owner
                        {{errorJson.required}}</span>
                </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                <label class="fw-600 mb-2 text-primary">{{addclient.label_dsp}}</label>
                <mat-form-field appearance="outline" class="w-100 h-10rem">
                    <textarea style="outline: none;" matInput class="h-10rem" formControlName="description"></textarea>
                </mat-form-field>
            </div>
        </div>

        <div class="mt-0">
            <div class="mt-3 d-flex justify-content-end">
                <div class="d-none" routerLink="settings/custom-fields" (click)="ngbActiveModal.dismiss()">
                    <span class="material-icons text-primary">settings_applications</span>
                    <span class="text-primary"></span> {{addclient.customize_text}}
                </div>
                <div class="d-flex">
                    <button class="btn btn-light me-2" type="button" (click)="cancelModal()">
                        {{addclient.cancel_btn}}
                    </button>
                    <button class="btn btn-primary" [disabled]="loading" type="submit">{{addclient.save_btn}}</button>
                </div>
            </div>
        </div>
    </form>
</div>