import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SETTINGSFORM } from 'src/app/core/data/form_builder_json';

@Component({
  selector: 'app-settings-model',
  templateUrl: './settings-model.component.html',
  styleUrls: ['./settings-model.component.scss']
})
export class SettingsModelComponent implements OnInit {
  options = [
    {
      type: 'Text box',
      value: 'TEXT'
    },
    {
      type: 'Checkboxes',
      value: 'CHECK_BOX'
    },
    {
      type: 'Radio',
      value: 'RADIO_BUTTON'
    },
    {
      type: 'DropDown',
      value: 'SELECT_DROPDOWN'
    },
    {
      type: 'Upload',
      value: 'FILE'
    },
    {
      type: 'Text Area',
      value: 'TEXT_AREA'
    }
  ];
  showError = false;
  fieldoption!: FormGroup;
  checkboxesDataList: any = [];
  @Input() formItem: any;
  @Input() API_LIST: any;
  JSON: any = JSON;
  selectedFieldType!: string;
  serviceData = [
    {
      id: 1,
      name: 'Service'
    },
    {
      id: 2,
      name: 'Service2'
    },
    {
      id: 3,
      name: 'Service3'
    },
    {
      id: 4,
      name: 'Service4'
    }
  ];
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  settingsformJson = SETTINGSFORM[0];

  constructor(public modelService: NgbModal) { }

  ngOnInit() {
    this.fieldoption = new FormGroup({
      label: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
      placeholder: new FormControl(''),
      required: new FormControl(),
      options: new FormArray([]),
      select_options: new FormControl()
    });

    this.formItem?.options?.forEach((element: any) => {
      this.addNew();
    });

    this.fieldoption.patchValue({ ...this.formItem, select_options: this.formItem.options });
    this.selectedFieldType = this.fieldoption.value.type;
  }

  get FormOpt() {
    return this.fieldoption.get('options') as FormArray;
  }

  addNew(isNew = false) {
    const arr = this.FormOpt.value;
    const lastElement = arr[arr.length - 1];
    if (isNew && lastElement?.trim() === "") {
      return;
    }
    this.FormOpt.push(new FormControl('', Validators.required));
    this.checkboxesDataList = this.FormOpt.value;
  }

  deleteFormOption(item: any, index_option: number) {
    this.FormOpt.removeAt(index_option);
    this.checkboxesDataList = this.FormOpt.value;
  }

  passBack() {
    const arr = this.FormOpt.value;
    const lastElement = arr[arr.length - 1];
    if (lastElement?.trim() === "") {
      this.showError = true;
      return;
    }
    this.passEntry.emit(this.fieldoption.value);
    this.modelService.dismissAll();

  }
  onInputChange() {
    const arr = this.FormOpt.value;
    const lastElement = arr[arr.length - 1];
    if (lastElement !== "") {
      this.showError = false;
    }
  }
}
