<div class="w-100 overflow-auto">
    <div class="p-4">
        <div class="header p-4 d-flex justify-content-between bg-grey-light border-radius-8">
            <div class="">
                <h6 class="fw-600">{{details.name}}</h6>
                <label class="text-light font-size-13">Assignee : {{details.info.fname}} {{details.info.lname}}</label>
            </div>
            <!-- <div>
                <mat-select class="status_dropdown bg-crm-info border-1 border-radius-8 p-2 px-3" [(ngModel)]="details.status" (ngModelChange)="taskStatusUpdate($event)">
                    <mat-option [value]="false">Pending</mat-option>
                    <mat-option [value]="true">Completed</mat-option>
                </mat-select>
            </div> -->
        </div>
        <div class="mt-4">
            <h6 class="fw-700">Task Details</h6>
            <div class="row no-gutters mt-2">
                <div class="col-md-4">
                    <p class="text-name-secondary"> Created At</p>
                    <p class="text-name-secondary">Priority</p>
                    <p class="text-name-secondary">Reminder</p>
                    <p class="text-name-secondary">Last Modified</p>
                    <p class="text-name-secondary">Description</p>
                </div>
                <div class="col-md-8">
                    <p class="text-name">{{(details.created_at ? details.created_at : '-') | dateConversion}}</p>
                    <p class="text-name bg-tag d-inline-flex align-items-center px-2 border-radius-50">
                        {{details.priority ? details.priority : '-'}}</p>
                    <p class="text-name">Due date is {{(details.due_date ? details.due_date : '-') | dateConversion}}</p>
                    <p class="text-name">{{(details.updated_at ? details.updated_at : '-') | dateConversion}}</p>
                    <p class="text-name" style="word-wrap: break-word;">{{details.description ? details.description : '-'}}</p>
                </div>
            </div>
        </div>
        <div class="">
            <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom mt-0 mb-3"
                    (navChange)="tabChange($event)">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink class="d-flex">
                            <span class="d-sm-block font-size-12">Notes</span>
                        </a>
                        <ng-template ngbNavContent>
                            <app-notes-crm [is_task]="about_task" [details]="task_id"></app-notes-crm>
                            <!-- <div class="bg-gray-light border-radius-8 p-3">
                                <div class="notes bg-white border-radius-8">
                                    <textarea class="form-control content-input border-radius-0 m-0 font-size-15"
                                        aria-label="With textarea" placeholder="What's this note about?" (click)="expandContent()"
                                        [ngClass]="{'height-class' : contentExpand === true}" [(ngModel)]="notesContent"></textarea>
                                </div>
                                <div class="row col-md-8 mt-2" *ngIf="contentExpand">
                                    <div class="d-flex align-items-center">
                                        <button
                                            class="btn border-radius-50 border-1 bg-primary fw-600 font-size-13 text-white px-4 me-3"
                                            [disabled]="!notesContent">Save</button>
                                        <button
                                            class="btn border-radius-50 border-1 bg-white fw-600 font-size-13 text-primary px-4 me-3">Cancel</button>
                                    </div>
                                </div>
                            </div> -->
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink class="d-flex">
                            <span class="d-sm-block font-size-12">Files</span>
                        </a>
                        <ng-template ngbNavContent>
                            <app-files-modal [is_task]="about_task" [details]="task_id"></app-files-modal>
                            <!-- <ngx-dropzone (change)="onSelect($event)" [multiple]="false" class="bg-gray-light" style="height: 61px;">
                                <ngx-dropzone-label>Drag a file here or <span class="text-primary fw-500">Browse</span>
                                    for a file to upload
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview [removable]="true" (removed)="onRemove(f)"
                                    ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
                                </ngx-dropzone-image-preview>
                            </ngx-dropzone> -->
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="customNav"></div>
        </div>
        <!-- <div class="notes mt-4">
            <h6>Notes</h6>
          
        </div>
        <div class="files mt-5">
            <h6>Files</h6>
            
        </div> -->
    </div>
</div>