<div class="modal-header">
    <h5 class="modal-title text-primary fw-600 text-capitalize">{{ editCard ? pipelineJson.update_label :
        pipelineJson.add_label}}
        {{selectedPipelineData ? (selectedPipelineData[0].card_name ? selectedPipelineData[0].card_name :
        pipelineJson.heading_label): pipelineJson.heading_label }}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancelModal()"></button>
</div>

<div class="modal-body">
    <form [formGroup]="pipelineForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{pipelineJson.label_name}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select style="outline: none" [disabled]="editCard ? true : false"
                        placeholder="{{pipelineJson.label_name}}" formControlName="pipeline"
                        (selectionChange)="onChangePipeline($event)">
                        <mat-option *ngFor="let item of pipeline_list"
                            [disabled]="editCard ? true : false || pipelineData ? true : false"
                            [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['pipeline'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['pipeline'].errors['required']"> {{pipelineJson.label_name}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{pipelineJson.label_stage}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select style="outline: none" placeholder="{{pipelineJson.label_stage}}" formControlName="stage"
                        (selectionChange)="updatePipelineCard()">
                        <mat-option *ngFor="let item of selected_pipeline_stages"
                            [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['stage'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['stage'].errors['required']"> {{pipelineJson.label_stage}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{pipelineJson.pipeline_name}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput type="text" placeholder="{{pipelineJson.pipeline_name}}" formControlName="title"
                        (blur)="updatePipelineCard()">
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['title'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['title'].errors['required']"> {{pipelineJson.pipeline_name}}
                            {{errorJson.required}}</span>
                        <span *ngIf="f['title'].errors['pattern']"> {{pipelineJson.pipeline_name}}
                            {{errorJson.invalid}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{pipelineJson.contact_name}} <small
                        class="text-danger">*</small></label>
                <ng-select class="form-control" [items]="contactsList" bindLabel="first_name" [addTag]="addNewName"
                    [multiple]="false" (change)="onContactChange($event)" placeholder={{pipelineJson.contact_name}}
                    formControlName="contact_first_name" (search)="searchContact($event)" (clear)="clearContactFields($event)">
                </ng-select>
                <div class="error d-flex mt-2" *ngIf="submitted && f['contact_first_name'].errors">
                    <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                    <span *ngIf="f['contact_first_name'].errors['required']"> {{pipelineJson.contact_name}}
                        {{errorJson.required}}</span>
                </div>
                <!-- <mat-form-field appearance="outline" class="w-100">
                    <mat-select placeholder="{{pipelineJson.contact_name}}" formControlName="contact"
                        (selectionChange)="onContactChange($event.value)">
                        <mat-option *ngFor="let item of contactsList" [value]="item.id">
                            {{item.first_name}}
                        </mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['contact'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['contact'].errors['required']"> {{pipelineJson.contact_name}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field> -->
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{pipelineJson.email}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput type="text" placeholder="{{pipelineJson.email}}" formControlName="email"
                        [disabled]="editCard ? true : false">
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['email'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['email'].errors['required']"> {{pipelineJson.email}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{pipelineJson.phone_number}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput type="text" placeholder="{{pipelineJson.phone_number}}"
                        formControlName="phone_number" [disabled]="editCard ? true : false">
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['phone_number'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['phone_number'].errors['required']"> {{pipelineJson.phone_number}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{pipelineJson.due_date}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput type="text" placeholder="{{pipelineJson.due_date}}" matInput
                        [matDatepicker]="picker" formControlName="due_date" [min]="minDate"
                        (dateChange)="updatePipelineCard()">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['due_date'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['due_date'].errors['required']"> {{pipelineJson.due_date}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="pipelineForm.value.price_on">
                <label class="fw-600 mb-2 text-primary">{{pipelineJson.budget}} </label>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput type="text" placeholder="{{pipelineJson.budget}}" formControlName="price"
                        (blur)="updatePipelineCard()">
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['price'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['price'].errors['pattern']"> {{pipelineJson.budget}}
                            {{errorJson.invalid}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 text-primary">{{pipelineJson.label_owner}} <small
                        class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="owner" placeholder={{pipelineJson.label_owner}} class="pt-1" (selectionChange)="updatePipelineCard()">
                        <mat-option *ngFor="let member of staffMembers"
                            [value]="member.id">{{member.fname}}
                            {{member.lname}}</mat-option>
                    </mat-select>
                    <mat-error class="error d-flex" *ngIf="submitted && f['owner'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['owner'].errors['required']"> {{pipelineJson.label_owner}}
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{pipelineJson.label_assignee}}</label>
                <ng-select class="form-control" [items]="staffMembers" bindValue="id" bindLabel="fname" [addTag]="false"
                    [multiple]="true" placeholder="Select staff" formControlName="assignee" (change)="updatePipelineCard()">
                </ng-select>
                <!-- <div class="error d-flex mt-2" *ngIf="submitted && f['assignee'].errors">
                    <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                    <span *ngIf="f['assignee'].errors['required']"> Staff
                        {{errorJson.required}}</span>
                </div> -->
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 text-primary">{{pipelineJson.label_tag}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="tags" placeholder="Select {{pipelineJson.label_tag}}" class="pt-1" (selectionChange)="updatePipelineCard()">
                        <mat-option [value]="item.id" *ngFor="let item of tagsListArray">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" formArrayName="fields"
                *ngFor="let field of fieldsForm().controls;let i=index;" [ngSwitch]="field.value.data_type">
                <div class="" [formGroupName]="i">
                    <label class="fw-600 mb-2 text-primary">{{field.value.data_name}}</label>
                    <!-- Text Box -->
                    <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'TEXT'">
                        <input matInput type="text" formControlName="data" placeholder="{{field.value.data_name}}"
                            (blur)="selectChange($event,field)" (keydown.enter)="selectChange($event,field)">
                    </mat-form-field>
                    <!--Text Area-->
                    <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'TEXT_AREA'">
                        <textarea matInput type="text" class="textareamat" formControlName="data"
                            placeholder="{{field.value.data_name}}"
                            (blur)="selectChange($event,field)" (keydown.enter)="selectChange($event,field)"></textarea>
                    </mat-form-field>
                    <!--Date & Time-->
                    <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'DATE_TIME'">
                        <input style="outline: none" matInput [matDatepicker]="picker"
                            placeholder="{{field.value.data_name}}" formControlName="data"
                            (dateChange)="selectChange($event,field)" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <!-- Check Box -->
                    <!-- <ul *ngSwitchCase="'CHECK_BOX'" class="mb-2 ps-0" [formGroupName]="field.label">
                    <li *ngFor="let field_option of field.options">
                        <mat-checkbox class="mb-2" [formControlName]="field_option">
                            {{field_option}}
                        </mat-checkbox>
                    </li>{hair wash:false}
                </ul> -->
                    <ul *ngSwitchCase="'CHECK_BOX'" class="mb-2 ps-0">
                        <li *ngFor="let field_option of field.value.options;let l=index">
                            <mat-checkbox class="mb-2" [checked]="field_option.checked"
                                (change)="setAll($event,field_option.binder,i)">
                                {{field_option.binder}}
                            </mat-checkbox>
                        </li>
                    </ul>
                    <!-- Radio button -->
                    <div *ngSwitchCase="'RADIO_BUTTON'" class="mb-4">
                        <mat-radio-group aria-labelledby="example-radio-group-label" formControlName="data"
                            class="example-radio-group">
                            <mat-radio-button class="example-radio-button" (change)="radioChange($event,field)"
                                *ngFor="let field_option of field.value.options" [value]="field_option">
                                {{field_option}} &nbsp;
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <!-- Drop down-->
                    <mat-form-field appearance="outline" class="w-100" *ngSwitchCase="'SELECT_DROPDOWN'">
                        <mat-select formControlName="data" placeholder="{{field.value.data_name}}"
                            (selectionChange)="selectChange($event.value,field)">
                            <ng-container *ngIf="field.value.url===''">
                                <mat-option *ngFor="let field_option of field.value.options" [value]="field_option">
                                    {{field_option}}
                                </mat-option>
                            </ng-container>
                            <ng-container *ngIf="field.value.url==='/consultations/category/'">
                                <mat-option *ngFor="let item of aptCategoriesArray" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </ng-container>
                            <ng-container *ngIf="field.value.url==='consultations/consultations/'">
                                <mat-option *ngFor="let item of appointmentsArray" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </ng-container>
                            <ng-container *ngIf="field.value.url==='stores/?/categories/'">
                                <mat-option *ngFor="let item of productCategory" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </ng-container>
                            <ng-container *ngIf="field.value.url==='product/stores/?/products/'">
                                <mat-option *ngFor="let item of productsList" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </ng-container>
                            <ng-container *ngIf="field.value.url==='crm_service/Contacts'">
                                <mat-option *ngFor="let item of contactsList" [value]="item.id">
                                    {{item.first_name}}
                                </mat-option>
                            </ng-container>
                            <ng-container *ngIf="field.value.url==='crm_service/Companys'">
                                <mat-option *ngFor="let item of companiesList" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </ng-container>
                            <ng-container *ngIf="field.value.url==='status'">
                                <mat-option value="pending">Pending</mat-option>
                                <mat-option value="approved">Approved</mat-option>
                                <mat-option value="rejected">Rejected</mat-option>
                                <mat-option value="completed">Completed</mat-option>
                            </ng-container>
                            <ng-container *ngIf="field.value.url==='source'">
                                <mat-option value="whatsapp">Whatsapp</mat-option>
                                <mat-option value="manual">Manual entry</mat-option>
                            </ng-container>
                            <!-- <ng-container *ngIf="field.label!=='Service'">
                            <mat-option *ngFor="let field_options of field.field_options"
                                [value]="field_options">
                                {{field.label==='Service' ? returnServiceName(field_options) :
                                field_options}}
                            </mat-option>
                        </ng-container> -->
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer border-0 d-flex justify-content-between mb-5">
    <div class="d-flex cursor" (click)="openCustomPipelineDialog()" *ngIf="loggedInUser.data.role === 'ADMIN'">
        <span class="material-icons text-primary">settings_applications</span>{{pipelineJson.customize_text}}
    </div>
    <div class="d-flex">
        <button class="btn btn-light me-2" type="submit" *ngIf="!editCard" (click)="cancelModal()">
            {{pipelineJson.cancel_btn}}
        </button>
        <button class="btn btn-primary" type="submit" (click)="onSubmit()" *ngIf="!editCard"
            [disabled]="loading">{{pipelineJson.save_btn}}</button>
    </div>
</div>