<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 h-35rem">
            <ul ngbNav #customNav="ngbNav" class="nav-tabs nav-tabs-custom mt-0 me-3">
                <li [ngbNavItem]="1" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">{{financeJson.tab_col_one}}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-invoices></app-invoices>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="me-2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">Payments</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-payments></app-payments>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" class="me-2 d-none">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">{{financeJson.tab_col_two}}</span>
                    </a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4" class="me-2 d-none">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-14">{{financeJson.tab_col_three}}</span>
                    </a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="customNav"></div>
        </div>
    </div>
</div>