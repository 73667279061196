<div class="container-fluid mt-3">
    <div class="row">
        <div class="col-12 col-lg-3">
            <mat-card class="matcard" style="height: 80vh;">
                <h6 class="heading font-size-18 fw-700 ms-3 mt-1" style="width: fit-content;">{{createformJson.heading}}
                </h6>
                <div class="row">
                    <div class="col-12 text-center">
                        <mat-form-field appearance="outline">
                            <input style="outline: none" [(ngModel)]="searchInput" (input)="onSearchInputChange($event)"
                                matInput placeholder="{{createformJson.palcehold_search}}" />
                            <mat-icon matSuffix>{{createformJson.icon_search}}</mat-icon>
                        </mat-form-field>

                    </div>
                </div>
                <div cdkDropList #itemsList="cdkDropList" [cdkDropListData]="items"
                    [cdkDropListConnectedTo]="[doneList]" class="example-list scroll_droplist"
                    (cdkDropListDropped)="drop($event)">
                    <div class="example-box drag_box cursor-move" style="padding: 12px;" *ngFor="let item of items"
                        cdkDrag>
                        {{item.label}}
                        <label class="mb-0">
                            <mat-icon>{{createformJson.icon_indicator}}</mat-icon>
                        </label>
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="col-12 col-lg-9">
            <div class="">
                <form [formGroup]="custom_form">
                    <div class="d-flex align-items-center">
                        <mat-form-field appearance="outline">
                            <input style="outline: none;" matInput type="text" required placeholder="Enter Form Name"
                                formControlName="name">
                        </mat-form-field>
                        <div class="ms-3" *ngIf="originalFormName !== custom_form.value.name">

                            <button class="btn btn-primary btn-sm mb-4"
                                (click)="onFormNameSubmit()">{{createformJson.submit_btn}}</button>
                        </div>
                    </div>

                    <div style="width: 77%;min-height: 50px;">
                        <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="dropItemData"
                            style="border: dashed 1px #d6d8d88f;" [cdkDropListConnectedTo]="[itemsList]"
                            class="example-list" (cdkDropListDropped)="drop($event)">
                            <div class="example-box drop_box cursor-move"
                                style="position: relative;align-items: baseline;"
                                *ngFor="let doneitem of dropItemData;let i= index" cdkDrag>
                                <label class="item" style="min-width: 35%;">{{doneitem.label}}</label>

                                <!--Text Box-->
                                <input class="form-control" style="max-width: 50%;" type="text"
                                    *ngIf="doneitem.type==='TEXT'">

                                <!--Text area-->
                                <textarea class="form-control" style="max-width: 50%;" type="text"
                                    *ngIf="doneitem.type==='TEXT_AREA'" style="min-height: 50px;"></textarea>

                                <!-- Select -->
                                <select class="form-select" style="max-width: 50%;"
                                    *ngIf="doneitem.type==='SELECT_DROPDOWN' || doneitem.type==='MULTI_SELECT_DROPDOWN'">
                                    <option *ngFor="let options of doneitem.options">
                                        {{options}}
                                    </option>
                                </select>


                                <!-- Check Box --> <!-- Radio Button -->
                                <div style="max-width: 50%; min-width: fit-content;"
                                    *ngIf="doneitem.type==='CHECK_BOX' || doneitem.type==='RADIO_BUTTON'">
                                    <div class="d-flex justify-content-start"
                                        *ngFor="let options of doneitem.options;let i= index">
                                        <input class="form-check-input" type="checkbox"
                                            *ngIf="doneitem.type==='CHECK_BOX'">
                                        <input class="form-check-input" type="radio"
                                            *ngIf="doneitem.type==='RADIO_BUTTON'">
                                        <label class="item labelname mx-1" style="width: 100%;bottom: 0px;"
                                            *ngIf="doneitem.type==='CHECK_BOX' || doneitem.type==='RADIO_BUTTON'">
                                            {{options}}
                                        </label>
                                    </div>
                                </div>

                                <!-- Date Time -->
                                <input class="form-control" style="max-width: 50%;" *ngIf="doneitem.type==='DATETIME'"
                                    type="date" />

                                <!--File upload-->
                                <input class="form-control" style="max-width: 50%;" type="file"
                                    *ngIf="doneitem.type==='FILE'">
                                
                                <!-- Select -->
                                <ng-container *ngIf="doneitem.key === 'APPOINTMENT_CATEGORIES'">
                                    <div style="min-width: 50%;">
                                        <select class="form-select" style="min-width: 100%;">
                                            <option *ngFor="let options of doneitem.options">
                                                {{getItemName(options)}}

                                            </option>
                                        </select>
                                        <!-- <div class="mt-2">
                                            <input type="text" disabled class="form-control" placeholder="{{createformJson.appointment_text_field}}">
                                        </div> -->
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="doneitem.key === 'PRODUCT_CATEGORIES'">
                                    <div style="min-width: 50%;">
                                        <select class="form-select" style="min-width: 100%;">
                                            <option *ngFor="let options of doneitem.options">
                                                {{getItemName(options)}}


                                            </option>
                                        </select>
                                        <!-- <div class="mt-2">
                                            <input type="text" disabled class="form-control" placeholder="{{createformJson.product_text_field}}">
                                        </div> -->
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="doneitem.key === 'PRODUCT'">
                                    <div style="min-width: 50%;">
                                        <select class="form-select" style="min-width: 100%;">
                                            <option *ngFor="let options of doneitem.options">
                                                {{getItemName(options)}}
                                            </option>
                                        </select>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="doneitem.key === 'APPOINTMENT'">
                                    <div style="min-width: 50%;">
                                        <select class="form-select" style="min-width: 100%;">
                                            <option *ngFor="let options of doneitem.options">
                                                {{getItemName(options)}}
                                            </option>
                                        </select>
                                    </div>
                                </ng-container>



                                <!-- </div> -->
                                <label class="icons d-flex align-items-center" style="transform: scale(0.6);">
                                    <span class="text-danger del cursor" (click)="deleteFormItem(i)">
                                        <mat-icon>{{createformJson.icon_delete}}</mat-icon>
                                    </span>

                                    <span class="dialog_btn px-3" (click)="openSettingsModel(doneitem, i)">
                                        <mat-icon>{{createformJson.icon_settings}}</mat-icon>
                                    </span>
                                </label>
                            </div>
                            <div class="card p-5 text-center" *ngIf="dropItemData.length===0">
                                <label>{{createformJson.drop_text}}</label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="row nxt_step d-none">
                <div class="col-12">
                    <button class="btn-light btn" >{{createformJson.cancel_btn}}</button>&nbsp;&nbsp;&nbsp;
                    <button class="btn-primary btn" (click)="onFormSubmit()">{{createformJson.submit_btn}}</button>
                </div>
            </div>
        </div>
    </div>
</div>