<mat-sidenav-container class="sidenav-container h-100">
    <mat-sidenav #drawer class="bg-primary" fixedInViewport="true" style="width: 66px;"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false">

        <mat-nav-list class="sidenav-list">
            <app-sidebar></app-sidebar>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content style="overflow-x: hidden;">
        <mat-toolbar class="d-block" style="position: fixed !important;">
            <button type="button" aria-label="Toggle sidenav" mat-icon-button *ngIf="isHandset$ | async"
                (click)="drawer.toggle()" class="border-0 btn-outline-light"> <mat-icon
                    aria-label="Side nav toggle icon">menu</mat-icon></button>
                    <app-header
                [selectedMenu]="selectedMenu"></app-header>
        </mat-toolbar>
        <div class="mt-5 pt-4" style="z-index: -1; position: relative;">
            <router-outlet (activate)="changeOfRoutes()"></router-outlet>
        </div>
        <!-- <mat-toolbar>
            <app-footer></app-footer>
        </mat-toolbar> -->
    </mat-sidenav-content>
</mat-sidenav-container>