import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';
import { MaterialModule } from 'src/app/core/common/material/material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { UsersControlComponent } from './users-control/users-control.component';
import { TagsComponent } from './tags/tags.component';
import { BillingComponent } from './billing/billing.component';
import { DataAdministrationComponent } from './data-administration/data-administration.component';
import { RolesPermissionsComponent } from './roles-permissions/roles-permissions.component';
import { WorkHoursComponent } from './work-hours/work-hours.component';
import { CustomFieldsComponent } from './custom-fields/custom-fields.component';
import { CustomizeFieldsComponent } from './models/customize-fields/customize-fields.component';
import { SmsSettingsComponent } from './sms-settings/sms-settings.component';
import { EmailNotificationsComponent } from './email-notifications/email-notifications.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { WhatsappNotificationsComponent } from './whatsapp-notifications/whatsapp-notifications.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { IntegrationsComponent } from './integrations/integrations.component';
import { PaymentsComponent } from './payments/payments.component';
import { DayOffModelComponent } from './day-off-model/day-off-model.component';


@NgModule({
  declarations: [
    SettingsComponent,
    GeneralSettingsComponent,
    UsersControlComponent,
    SmsSettingsComponent,
    CustomFieldsComponent,
    CustomizeFieldsComponent,
    TagsComponent,
    BillingComponent,
    DataAdministrationComponent,
    RolesPermissionsComponent,
    WorkHoursComponent,
    EmailNotificationsComponent,
    WhatsappNotificationsComponent,
    IntegrationsComponent,
    PaymentsComponent,
    DayOffModelComponent
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    MaterialModule,
    NgbModule,
    NgxDropzoneModule,
    CKEditorModule,
    NgSelectModule
  ],
  exports: [
    WorkHoursComponent,
    GeneralSettingsComponent
  ]
})
export class SettingsModule { }
