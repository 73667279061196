import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(
    private http: HttpClient
  ) { }

  getStripeKeys(){
    return this.http.get<any>(`${environment.urlPrefix}${constant.payments}`);

  }
  postStripeKeys(payload: any) {
    return this.http.post<any>(`${environment.urlPrefix}${constant.payments}`, payload);
  }

  updateStripeKeys(id: string,payload: any) {
    return this.http.put<any>(`${environment.urlPrefix}${constant.payments}${id}/`,payload);
  }

  deleteStripeKeys(id: string) {
    return this.http.delete<any>(`${environment.urlPrefix}${constant.payments}${id}/`);
  }
}
