import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ContactsModalComponent } from '../../shared/contacts-modal/contacts-modal.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { CONTACTSJSON } from '../../../core/data/contacts_json';
import { ERRORJSON } from '../../../core/data/error_json';
import { CrmService } from 'src/app/core/service/crm.service';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { environment } from 'src/environments/environment';
import { TaskBoardComponent } from '../../clients/client-managment/task-board/task-board.component';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  contactsJson = CONTACTSJSON[0];
  errorJson = ERRORJSON[0];
  @Input() companyId: any;
  previewModal!: NgbModalRef;
  modalRef!: NgbModalRef;
  currentPage = 0;
  pageEvent!: PageEvent;
  presignedurl = environment.preSendUrl;
  search_global!: string;

  @ViewChild('paginator') paginator!: MatPaginator;
  submitted = false;
  contactForm!: FormGroup;
  public selection = new SelectionModel<any>(true, []);
  files: File[] = [];
  contactsObj: any = {};
  contactListArray: any = [];
  selected_group: any;
  page_size = 10;
  select_type = [
    {
      label: 'All',
      value: 'all'
    },
    {
      label: 'Webchat',
      value: 'webchat'
    },
    {
      label: 'Whatsapp',
      value: 'whatsapp'
    },
    {
      label: 'Facebook',
      value: 'facebook'
    },
    {
      label: 'Manual',
      value: 'manual'
    }
    // 'Mailing Labels',
    // 'My Contacts',
    // 'New Last Week',
    // 'New This Week',
    // 'Recently Created Contacts',
    // 'Recently Modified Contacts',
    // 'Not Yet Viewed',
    // 'Unsubscribed Contacts'
  ];
  filter: any = this.select_type[0].value;
  selected_filter!: string;
  selected_filter_value: any;
  filtersLables = [
    {
      position: 1,
      lableName: 'Name',
      checked: true
    },
    {
      position: 2,
      lableName: 'Email',
      checked: true
    },
    {
      position: 3,
      lableName: 'Mobile Number',
      checked: true
    },
    {
      position: 4,
      lableName: 'Next Activity',
      checked: false
    },
    {
      position: 5,
      lableName: 'Company Name',
      checked: true
    },
    {
      position: 6,
      lableName: 'Owner',
      checked: true
    },
    {
      position: 7,
      lableName: 'Channel',
      checked: true
    },
    {
      position: 8,
      lableName: 'Created On',
      checked: true
    },
    {
      position: 9,
      lableName: 'First Name',
      checked: false
    },
    {
      position: 10,
      lableName: 'Last Name',
      checked: false
    },
    {
      position: 11,
      lableName: 'Last Activity Date & Time',
      checked: false
    },
    {
      position: 12,
      lableName: 'Modified by',
      checked: false
    },
    {
      position: 13,
      lableName: 'Modified Date & Time',
      checked: false
    },
    {
      position: 14,
      lableName: 'Mailing Street',
      checked: false
    },
    {
      position: 15,
      lableName: 'Mailing Address',
      checked: false
    },
    {
      position: 16,
      lableName: 'Mailing City',
      checked: false
    },
    {
      position: 17,
      lableName: 'Mailing Country',
      checked: false
    },
    {
      position: 18,
      lableName: 'Mailing State',
      checked: false
    },
    {
      position: 19,
      lableName: 'Shipping Address',
      checked: false
    },
    {
      position: 20,
      lableName: 'Shipping Street',
      checked: false
    },
    {
      position: 21,
      lableName: 'Shipping City',
      checked: false
    },
    {
      position: 22,
      lableName: 'Shipping Country',
      checked: false
    },
    {
      position: 23,
      lableName: 'Zip Code',
      checked: false
    },
    {
      position: 24,
      lableName: 'Title',
      checked: false
    },
    {
      position: 25,
      lableName: 'Action',
      checked: true
    }
  ];

  listArray = [
    {
      id: 'uenc9393ur4hisudhfcsffs',
      group_name: 'Zepul'
    },
    {
      id: 'jnxshdsdcjikdjidjn',
      group_name: 'Worke'
    },
    {
      id: 'dushfiahnvlkwdy8scm',
      group_name: 'Easyrooms'
    }
  ];

  importModalType: any;
  enableImportBtn = false;
  selectedFile: any;
  display_no_data = false;
  loggedInUser: any;
  staffMembers: any = [];
  companiesListArray: any = [];

  constructor(private modalService: NgbModal,
    private service: CrmService,
    private confirmationMsz: ConfirmationMszService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);

    // this.dataSource.paginator = this.paginator;
    this.getContactsList(1);
    this.contactForm = new FormGroup({
      email: new FormArray([])
    });
    //this.addEmailFields();
  }

  onFilterChange(e: any) {
    // console.log(e);
    this.filter = e;
    if (this.filter === 'webchat') {
      this.selected_filter = 'source';
      this.selected_filter_value = 'webchat';
    } else if (this.filter === 'whatsapp') {
      this.selected_filter = 'source';
      this.selected_filter_value = 'Whatsapp';
    } else if (this.filter === 'manual') {
      this.selected_filter = 'source';
      this.selected_filter_value = 'manual';
    } else if (this.filter === 'facebook') {
      this.selected_filter = 'source';
      this.selected_filter_value = 'facebook';
    } else {
      this.selected_filter = '';
      this.selected_filter_value = '';
    }
    // console.log(this.selected_filter, this.selected_filter_value);

    this.getContactsList(1, this.selected_filter, this.selected_filter_value);
  }

  getContactsList(page: any, filter_param?: string, filter?: any) {
    this.service.getAllContacts(page, filter_param, filter, this.companyId).subscribe({
      next: (resp: any) => {
        this.contactsObj = resp;
        this.contactListArray = resp.data;
        if (this.contactListArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }

        this.organisationMembersList();
        this.getCompaniesList();
      }
    });
  }

  openTaskBoardPreview() {
    this.previewModal = this.modalService.open(TaskBoardComponent, { size: 'lg', scrollable: true });
    this.previewModal.componentInstance.crm_type = 'contact';
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getContactsList(this.currentPage, this.selected_filter, this.selected_filter_value);
  }

  removeContact(contactId: string) {
    this.service.deleteContact(contactId).subscribe({
      next: () => {
        this.getContactsList(1, this.selected_filter, this.selected_filter_value);
      }
    });
  }

  confirmDelete(contactId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.removeContact(contactId);
      }
    });
  }

  get f() {
    return this.contactForm.controls;
  }
  get email(): FormArray {
    return this.contactForm.get('email') as FormArray;
  }

  // addEmailFields() {
  //   this.email.push(this.newEmail());
  // }

  removeEmailField(i: number) {
    this.email.removeAt(i);
  }

  newEmail(email: any): FormGroup {
    return new FormGroup(
      {
        email: new FormControl(email, Validators.required)
      }
    );
  }

  addOptionToList = (option: any) => ({ id: '', group_name: option });

  addToList() {
    // console.log(this.selected_group);
    this.selected_group.map((email: any) => {
      this.email.push(
        this.newEmail(email)
      );
    });
    // console.log(this.contactForm.value, "contactform");

  }

  //filter dropdown fun
  selectFilterLable(event: any) {
    event.stopPropagation();
    // $event.preventDefault();

    // console.log('evey taget value', $event.target);
    // if ($event.target) {
    //   $event.target.classList.toggle('selected');
    // }
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  openDialog(addtolistModel: any) {
    this.modalService.open(addtolistModel, { size: 'lg' });
  }

  openContactsModalDialog(contact?: any) {
    this.modalRef = this.modalService.open(ContactsModalComponent, { size: 'lg' });
    this.modalRef.componentInstance.contact = contact;
    this.modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry) this.getContactsList(1);
    });
  }

  importEexportModalDialog(importExportModel: any, type: any) {
    this.modalService.open(importExportModel, { size: 'md' });
    this.importModalType = type;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.contactListArray.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.contactListArray.forEach((row: any) => {
      this.selection.select(row);
      // console.log(this.selection, row, "selected");

    });
  }

  // clientDetailsPopup() {
  //   this.modalRef = this.modalService.open(DetailsModalComponent, {
  //     modalDialogClass: 'crm-modal',
  //     centered: false,
  //     size: 'xl'
  //   });
  // }

  // checkedContacts() {
  //   console.log(this.selection.selected); // list of checked contacts
  // }

  onFileChange(event: any) {
    this.enableImportBtn = true;
    this.selectedFile = event.target.files[0];
  }

  importContacts() {
    const formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);

    this.service.importContacts(formData).subscribe((resp: any) => {
      this.modalService.dismissAll('cross click');
      this.getContactsList(1);
    });
  }

  search(name: any) {
    if (name.length >= 3) {
      this.service.getContactsBySearch(name).subscribe({
        next: (resp: any) => {
          this.contactsObj = resp;
          this.page_size = this.contactsObj.total;
          this.contactListArray = resp.data;
          if (this.contactListArray.length === 0) {
            this.display_no_data = true;
          } else {
            this.display_no_data = false;
          }
        }
      });
    } else if (name.length < 1) {
      this.page_size = 10;
      this.getContactsList(1);
    }
  }

  downloadContacts(event: any) {
    // console.log(this.checkedContacts());
    const outputContacts: any = [];
    if (this.selection.selected.length === 0) {
      this.service.getAllContactsForExport().subscribe((resp: any) => {
        resp.data.forEach((contact: any) => {
          outputContacts.push({
            "Name": contact.first_name + " " + contact.last_name,
            "Email": contact.email,
            "Phone": contact.phone_number
          });
        });
        this.service.jsonToCsv(outputContacts, "Contacts");
      });
    } else {
      this.selection.selected.forEach((contact: any) => {
        outputContacts.push({
          "Name": contact.first_name + " " + contact.last_name,
          "Email": contact.email,
          "Phone": contact.phone_number
        });
      });
      this.service.jsonToCsv(outputContacts, "Contacts");
    }
  }

  downloadSampleFile() {
    this.service.downloadSample('Contacts');
  }

  getCompaniesList() {
    this.service.getCompaniesDropdownList().subscribe({
      next: (resp: any) => {
        this.companiesListArray = resp.data;
      }
    });
  }

  onUpdatePopOver(event: any, data: any) {
    const input: any = { id: data.id, custom_contact_fields: [] };

    if (event.type === 'dropdown') {
      if (event.labelName === 'Owner') {
        input.info = {
          "owner_info": {
            "id": event.value.id,
            "fname": event.value.fname,
            "lname": event.value.lname
          }
        };
        input.owner = event.value.id;
        input.owner_department = event.value.department_info?.id ? event.value.department_info?.id : '';
        // this.staffMembers.forEach((staff: any) => {
        //   if (staff.id === event.value.id) input.owner_department = staff.department_info.id;
        // });
      } else if (event.labelName === 'Company Name') {
        input.company = event.value.id;
        input.company_name = event.value.name;
      }
    } else
      input[event.formName] = event.value;

    this.service.updateContact(input).subscribe((resp: any) => {
      this.getContactsList(1, this.selected_filter, this.selected_filter_value);
    });
  }
}
