import { Component, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { SETUPJSON } from 'src/app/core/data/setup_module_json';
import { SetUpService } from 'src/app/core/service/set-up.service';

@Component({
  selector: 'app-onboarding-screens',
  templateUrl: './onboarding-screens.component.html',
  styleUrls: ['./onboarding-screens.component.scss']
})
export class OnboardingScreensComponent {
  setupJson = SETUPJSON[0];
  appointment_setup_status!: boolean;
  setupData: any;
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  stepperCheck: any;
  @ViewChild('stepper') stepper!: MatStepper;
  
  constructor(private setupService: SetUpService) { }

  ngOnInit() {
    this.getInfo('');
    console.log(this.stepperCheck);
    
  }
  openBot() {
    window.open('https://www.sandbox.worke.ai/', '_blank');
    // window.open('https://www.chat.worke.ai/','_blank')//Production
  }

  getInfo(type: string) {
    this.setupService.getSetupInformation(type).subscribe((data: any) => {
      this.setupData = data;
    });
  }

  onToggleChange(ev: any, item: any) {
    this.setupService.updateSetupStatus(item.id, { status: ev.target.checked }).subscribe(() => {
      this.getInfo('forceUpdate');
    });
  }

  onStepperCheck(ev: any){
    console.log(ev);
    if(ev){
      this.stepper.next();
    }
  }
}
