import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CREATEFORM } from 'src/app/core/data/form_builder_json';
import { SettingsModelComponent } from './settings-model/settings-model.component';
import { cloneDeep } from 'lodash';
import { FormBuilderService } from 'src/app/core/service/form-builder.service';
import { SetUpService } from 'src/app/core/service/set-up.service';

@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.scss']
})
export class CreateFormComponent implements OnInit {

  @Input() formId = '';

  custom_form!: FormGroup;
  items: any = [];
  OriginalItems = [
    { label: "Contact Name", type: "TEXT", options: [], key: "TEXT", status: true },
    { label: "Email", type: "TEXT", options: [], key: "EMAIL", status: true },
    { label: "Phone Number", type: "TEXT", options: [], key: "NUMBER", status: true },
    { label: "Company Name", type: "TEXT", options: [], key: "TEXT", status: true },
    { label: "Description", type: "TEXT_AREA", options: [], key: "TEXT_AREA", status: true },
    { label: "Follow Up Date", type: "DATETIME", options: [], key: "DATETIME", status: true },
    { label: "Budget", type: "TEXT", options: [], key: "NUMBER", status: true },
    { label: "Website", type: "TEXT", options: [], key: "TEXT", status: true },
    
    { label: "Appointments", type: "API", options: [], key: "APPOINTMENT", status: true },
    { label: "Products", type: "API", options: [], key: "PRODUCT", status: true },
    { label: "Appointment Categories", type: "API", options: [], key: "APPOINTMENT_CATEGORIES", status: true },
    { label: "Product Categories", type: "API", options: [], key: "PRODUCT_CATEGORIES", status: true },
    
    { label: "Amount", type: "TEXT", options: [], key: "NUMBER", status: true },
    { label: "Applying Position", type: "TEXT", options: [], key: "TEXT", status: true },
    { label: "Bug", type: "TEXT", options: [], key: "TEXT", status: true },
    { label: "Card Name", type: "TEXT", options: [], key: "TEXT", status: true },
    { label: "Closing Date", type: "DATETIME", options: [], key: "DATETIME", status: true },
    { label: "Cost", type: "TEXT", options: [], key: "NUMBER", status: true },
    { label: "Current Salary", type: "TEXT", options: [], key: "NUMBER", status: true },
    { label: "Current Job Status", type: "TEXT", options: [], key: "TEXT", status: true },
    { label: "Current Employment Status", type: "TEXT", options: [], key: "TEXT", status: true },
    { label: "Date of Birth", type: "DATETIME", options: [], key: "DATETIME", status: true },
    { label: "Deal Type", type: "TEXT", options: [], key: "TEXT", status: true },
    { label: "End Date", type: "DATETIME", options: [], key: "DATETIME", status: true },
    { label: "Expected Salary", type: "TEXT", options: [], key: "NUMBER", status: true },
    { label: "Expected Revenue", type: "TEXT", options: [], key: "NUMBER", status: true },
    { label: "Lead Source", type: "TEXT", options: [], key: "TEXT", status: true },
    { label: "Next Step", type: "TEXT", options: [], key: "TEXT", status: true },
    { label: "Primary Contact", type: "TEXT", options: [], key: "TEXT", status: true },
    { label: "Priority", type: "TEXT", options: [], key: "TEXT", status: true },
    { label: "Probability", type: "TEXT", options: [], key: "TEXT", status: true },
    { label: "Reason", type: "TEXT", options: [], key: "TEXT", status: true },
    { label: "Secondary Contact", type: "TEXT", options: [], key: "TEXT", status: true },
    { label: "Start Date", type: "DATETIME", options: [], key: "DATETIME", status: true },
    { label: "Source", type: "SELECT_DROPDOWN", options: [], key: "SELECT_DROPDOWN", status: true },
    { label: "Status", type: "SELECT_DROPDOWN", options: ["To-Do", "In Progress", "Done"], key: "SELECT_DROPDOWN", status: true },
    { label: "Stages", type: "SELECT_DROPDOWN", options: [], key: "SELECT_DROPDOWN", status: true },
    { label: "Sub-Pipeline & Stage", type: "TEXT", options: [], key: "TEXT", status: true },


    { label: "Text Box", type: "TEXT", options: [], key: "TEXT", status: true },
    { label: "Text Area", type: "TEXT_AREA", options: [], key: "TEXT_AREA", status: true },
    { label: "Drop Down", type: "SELECT_DROPDOWN", options: ["Option One", "Option Two", "Option Three"], key: "SELECT_DROPDOWN", status: true },
    { label: "Check Box", type: "CHECK_BOX", options: ["Option One", "Option Two", "Option Three"], key: "CHECK_BOX", status: true },
    { label: "Radio Button", type: "RADIO_BUTTON", options: ["Option One", "Option Two", "Option Three"], key: "RADIO_BUTTON", status: true },
    { label: "Date", type: "DATETIME", options: [], key: "DATETIME", status: true }
  ];

  APPOINTMENT_CATEGORY: any = [];
  APPOINTMENT: any = [];
  PRODUCT_CATEGORY: any = [];
  PRODUCT: any = [];
  STORE: any = {};

  dropItemData: any = [];
  createformJson = CREATEFORM[0];
  searchInput = "";

  originalFormName = '';

  constructor(
    public modalService: NgbModal,
    private _formServices: FormBuilderService,
    private _setUpService: SetUpService
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.initialCall();
    this.items = this.OriginalItems;
    this.initialGetDataCall();
  }

  initialCall() {
    this.getFormInfo(this.formId);
    this.getFormFields(this.formId);

  }

  initialGetDataCall() {  // [TODO]: should be improved, as we are making unwanted api calls over here.
    this.getAppointmentCategories();
    this.getAppointment();
    this.getStore();
  }

  getFormInfo(id: string) {
    this._formServices.getFormData(id).subscribe((resp: any) => {
      this.initializeForm(resp.data);
      this.originalFormName = resp.data.name;
    });
  }

  getFormFields(id: string) {

    this._formServices.getFormFields(id).subscribe((resp: any) => {
      this.dropItemData = [];
      resp.data.forEach((item: any) => {
        this.addFormItem(item);
        this.dropItemData.push(item);
      });
    });
  }

  initializeForm(data?: any) {
    this.custom_form = new FormGroup({
      form_id: new FormControl(this.formId),
      name: new FormControl(data?.name ? data.name : '', [Validators.required]),
      form_fields_payload: new FormArray([])
    });
  }

  FormItemsArray(data?: any) {

    return new FormGroup({
      id: new FormControl(data?.id ? data.id : '', Validators.required),
      label: new FormControl(data?.label ? data.label : '', Validators.required),
      type: new FormControl(data?.type ? data.type : '', Validators.required),
      options: new FormControl(data?.options ? this.convertJSONToArray(data.options) : []),
      key: new FormControl(data?.key ? data.key : 'FIELD', Validators.required),
      status: new FormControl(data?.status ? data.status : true, Validators.required),
      order: new FormControl(data?.order ? data.order : 999, Validators.required)
    });


  }

  convertJSONToArray(obj: any) {
    if (typeof obj === "object" && obj !== null) {
      try {
        JSON.parse(JSON.stringify(obj));
        return Array.isArray(obj) && obj.length > 0 ? obj : [];
      } catch (e) {
        return obj;
      }
    } else {
      return obj;
    }
  }

  FormOptionsArray() {
    return new FormGroup({
      option: new FormControl('')
    });
  }

  get FormItems() {
    return this.custom_form.get('form_fields_payload') as FormArray;
  }

  FormOptions(i?: number) {// @ts-ignore: Object is possibly 'null'.
    return this.custom_form.get('form_fields_payload')['controls'][i].get('options') as FormArray;
  }

  addFormItem(data?: any) {
    this.FormItems.push(this.FormItemsArray(data));
  }

  addFormOption(i?: number) {
    this.FormOptions(i).push(new FormControl('', Validators.required));
  }

  deleteFormItem(index: number) {
    this.FormItems.removeAt(index);
    if (index > -1) {
      this._formServices.deleteFormFields(this.dropItemData[index].id).subscribe((resp) => {
        this.dropItemData.splice(index, 1);
      });
    }
  }


  deleteFormOption(index: number, index_option: number) {
    this.FormOptions(index).removeAt(index_option);
  }

  drop(event: CdkDragDrop<any>) {

    if (event.previousContainer.id !== event.container.id && Number(event.container.id.split("-")[3]) > Number(event.previousContainer.id.split("-")[3])) {
      // Clone the item that was dropped.
      const clone = cloneDeep(event.previousContainer.data[event.previousIndex]);

      // Add the clone to the new array.
      event.container.data.splice(event.currentIndex, 0, clone);
      const form_fields_payload = { ...event.previousContainer.data[event.previousIndex], order: event.currentIndex + 1 };
      this.addFormItem(form_fields_payload);
      if (event.previousContainer.data[event.previousIndex].type === "API") {
        const apiType = event.previousContainer.data[event.previousIndex].key;
        let tempOptions = [];
        const options: any = [];
        switch (apiType) {
          case "APPOINTMENT_CATEGORIES": tempOptions = this.APPOINTMENT_CATEGORY; break;
          case "APPOINTMENT": tempOptions = this.APPOINTMENT; break;
          case "PRODUCT_CATEGORIES": tempOptions = this.PRODUCT_CATEGORY; break;
          case "PRODUCT": tempOptions = this.PRODUCT; break;
        }

        tempOptions.forEach((opt: any) => {
          options.push(JSON.stringify({ id: opt.id, name: opt.name }));
        });
        form_fields_payload.options = options;
      }
      this._formServices.postFormFields({
        form_id: this.formId,
        form_fields_payload: [form_fields_payload]
      }).subscribe((resp) => {
        this.getFormFields(this.formId);
      });
    }
    else if (Number(event.container.id.split("-")[3]) < Number(event.previousContainer.id.split("-")[3])) {
      this.deleteFormItem(event.previousIndex);
    } else if (event.previousContainer.id === event.container.id && Number(event.container.id.split("-")[3]) % 2 !== 0) {
      const form_fields_payload = { ...event.previousContainer.data[event.previousIndex], order: event.currentIndex + 1 };
      this._formServices.putFormFields(form_fields_payload, this.formId).subscribe((resp: any) => {
        this.getFormFields(this.formId);
      });
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

  }

  openSettingsModel(item: any, index: number) {

    const modalRef = this.modalService.open(SettingsModelComponent, {
      modalDialogClass: 'settings-dialog',
      centered: false,
      size: 'xl'
    });
    modalRef.componentInstance.formItem = item;
    switch (item.key) {
      case "APPOINTMENT_CATEGORIES":
        modalRef.componentInstance.API_LIST = this.APPOINTMENT_CATEGORY;
        break;
      case "APPOINTMENT":
        modalRef.componentInstance.API_LIST = this.APPOINTMENT;

        break;
      case "PRODUCT_CATEGORIES":
        modalRef.componentInstance.API_LIST = this.PRODUCT_CATEGORY;

        break;
      case "PRODUCT":
        modalRef.componentInstance.API_LIST = this.PRODUCT;
        break;
      default:
        break;
    }
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      const options = this.dropItemData[index].type === "API" ? receivedEntry.select_options : receivedEntry.options;
      this.dropItemData[index].options = [];

      options.forEach((opitem: any) => {
        this.dropItemData[index].options.push(opitem);
      });
      this.dropItemData[index].label = receivedEntry.label;
      this.dropItemData[index].type = receivedEntry.type;

      this._formServices.putFormFields({ ...this.dropItemData[index], options: options }, this.formId).subscribe((resp) => {
        this.initialCall();
      });
    });
  }

  onFormSubmit() {
    this._formServices.postFormFields(this.custom_form.value).subscribe((resp: any) => {
      this.initialCall();
    });
  }

  onFormNameSubmit() {
    this._formServices.putForm(this.custom_form.value, this.formId).subscribe((resp: any) => {
      this.initialCall();
    });
  }

  onSearchInputChange(event: any) {
    if (this.searchInput !== "") {
      this.items = this.OriginalItems.filter(item => item.label.toLowerCase().includes(this.searchInput.toLowerCase()));
    } else {
      this.items = this.OriginalItems;
    }
  }


  getAppointmentCategories() {
    this._setUpService.getAllCategories().subscribe((resp: any) => {
      this.APPOINTMENT_CATEGORY = resp.data;
    });
  }

  getAppointment() {
    this._setUpService.getAllConsulations().subscribe((resp: any) => {
      this.APPOINTMENT = resp.data;
    });
  }

  getStore() {
    this._setUpService.getStore().subscribe((resp: any) => {
      this.STORE = resp.data;
      this.getProductCategories();
      this.getProduct();
    });
  }

  getProductCategories() {
    this._setUpService.getProductCategories(this.STORE.id).subscribe((resp: any) => {
      this.PRODUCT_CATEGORY = resp.data;

    });

  }

  getProduct() {
    this._setUpService.getProduct(this.STORE.id).subscribe((resp: any) => {
      this.PRODUCT = resp.data;

    });
  }

  getName(arr: any, id: string) {
    return arr.find((item: any) => item.id === id)?.name;
  }

  getItemName(option: string) {
    return JSON.parse(option)?.name;
  }
}
