<div class="my-3">
    <div *ngIf="templateRolesListType">
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-9 d-flex justify-content-end align-items-center">
                <button class="btn btn-primary d-none align-items-center me-3" (click)="addRole(false)">
                    <mat-icon>add</mat-icon>
                    Add New role
                </button>
            </div>

        </div>
        <div class="table-responsive table-height mt-4 roles-table">
            <table class="table align-middle table-nowrap table-hover mb-0 users-table w-100">
                <thead>
                    <tr>
                        <th scope="col" *ngFor="let col of table_cols" class="text-primary pb-3">
                            {{col}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="cursor" *ngFor="let item of rolesList; let i = index">
                        <td>{{ item.name }}</td>
                        <td>{{ item.staffs_active_count }}</td>
                        <td>{{ item.staffs_inactive_count }}</td>
                        <!-- <td><mat-icon>more_vert</mat-icon></td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div *ngIf="!templateRolesListType">
        <div class="addRoles">
            <div class="my-3 w-80 m-auto">
                <div class="d-flex text-light">
                    <label class="cursor" (click)="addRole(true)">Roles</label>&nbsp;>&nbsp;<label>Create New
                        Role</label>
                </div>
                <form [formGroup]="addRoleForm" (ngSubmit)="addnewRoleSubmit()" class="mt-3">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <label class="fw-600 mb-2">Role Name <small class="text-danger">*</small></label>
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder='Enter Role Name'
                                    formControlName="name" />
                                <mat-error class="error text-danger d-flex" *ngIf="submited && f['name'].errors">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                    <span *ngIf="f['name'].errors['required']">Role Name
                                        {{errorjson.required}}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="table-responsive table-height mt-4 roles-table mb-3">
                        <table class="table align-middle table-nowrap mb-0 users-table w-100">
                            <thead>
                                <tr>
                                    <th scope="col" *ngFor="let col of add_role_table_cols" class="text-primary pb-3">
                                        {{col}}</th>
                                </tr>
                            </thead>
                            <tbody formArrayName="permissions">
                                <tr class="cursor" *ngFor="let item of formPermissions().controls; let i = index" [formGroupName]="i">
                                    <td>{{ formPermissions().value[i].name }}</td>
                                    <td>
                                        <section class="example-section">
                                            <p class="mb-0"><mat-checkbox formControlName="own">View(Own)</mat-checkbox>
                                            </p>
                                            <p class="mb-0"><mat-checkbox
                                                    formControlName="global">View(Global)</mat-checkbox></p>
                                            <p class="mb-0"><mat-checkbox
                                                    formControlName="team">View(Team)</mat-checkbox></p>
                                            <p class="mb-0"><mat-checkbox formControlName="create">Create</mat-checkbox>
                                            </p>
                                            <p class="mb-0"><mat-checkbox formControlName="edit">Edit</mat-checkbox></p>
                                            <p class="mb-0"><mat-checkbox formControlName="delete">Delete</mat-checkbox>
                                            </p>
                                        </section>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-light me-3" (click)="addRole(true)">Cancel</button>
                        <button type="submit" class="btn btn-primary">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>