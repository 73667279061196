<div class="modal-header">
    <h5 class="modal-title text-primary fw-600">Manage Employee Badges
    </h5>

    <button type="button" class="btn-close" aria-label="Close" (click)="ngbActiveModal.dismiss()"></button>
</div>
<div class="px-3 pt-3">
    <h6 class="text-primary font-size-14">Define new or edit existing badges for employees.</h6>
</div>

<div class="modal-body mx-3 my-1">
    <form [formGroup]="badgeForm" (ngSubmit)="onSubmit()">

        <div class="row border-radius-8 py-2 d-flex align-items-center" style="background-color: #F2F6FE;">
            <p class="text-primary my-2">Create New Badge</p>
            <div class="col-sm-6 col-md-5 col-lg-5 col-xl-5">
                <label class="fw-600 mb-2 text-primary font-size-14">Badge Name<small class="text-danger">
                        *</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder="Badge name" formControlName="name" />
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['name'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['name'].errors['required']"> Badge name required</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-4 col-sm-6 col-lg-4 col-xl-4">
                <label class="fw-600 mb-2 text-primary font-size-14">Badge Color<small class="text-danger">
                        *</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder="Color" formControlName="color" type="color" />
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['color'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['color'].errors['required']"> Badge color required</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-3 col-sm-4 col-lg-3 col-xl-3 d-flex align-items-center">
                <button class="btn btn-primary px-4 mt-2" type="submit">Add</button>
            </div>
        </div>
    </form>
    <div class="my-3" style="max-height:300px; overflow: auto;">
        <ul class="list-group list-group-flush">
            <li *ngFor="let item of badgesArray"
                class="list-group-item d-flex align-items-center justify-content-between">
                <span class="border-radius-8 px-2 font-size-14" [ngStyle]="{'background-color': item.color}">{{item.name}}</span>
                <div class="d-flex align-items-center justify-content-end">
                    <mat-icon class="cursor" style="font-size: 20px;">edit</mat-icon>
                    <mat-icon class="ms-2 cursor" style="font-size: 20px;">delete</mat-icon>
                </div>
            </li>

        </ul>
    </div>
</div>