import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';

@Component({
  selector: 'app-customize-fields',
  templateUrl: './customize-fields.component.html',
  styleUrls: ['./customize-fields.component.scss']
})
export class CustomizeFieldsComponent {

  todo = ['1','2','3','4','5','6','7'];
  todo1 = ['1','2','3','4'];
  done = ['1','2','3','4','5','6','7'];
  addCustomField = false;
  field_types=[
    {
      type: 'Text box',
      value: 'TEXT'
    },
    {
      type: 'Checkboxes',
      value: 'CHECK_BOX'
    },
    {
      type: 'Radio',
      value: 'RADIO_BUTTON'
    },
    {
      type: 'DropDown',
      value: 'SELECT_DROPDOWN'
    },
    {
      type: 'Upload',
      value: 'FILE'
    },
    {
      type: 'Text Area',
      value: 'TEXT_AREA'
    }
  ];
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  AddField(){
    this.addCustomField = true;
  }
  backtocustomList(){
    this.addCustomField = false;
  }
}
