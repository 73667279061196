import { Component } from '@angular/core';

@Component({
  selector: 'app-client-managment',
  templateUrl: './client-managment.component.html',
  styleUrls: ['./client-managment.component.scss']
})
export class ClientManagmentComponent {
  activeId: any = 1;


  tabChange(event: any) {
    this.activeId = event.nextId;
  }

}
