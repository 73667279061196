import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
  selector: 'app-whatsapp-notifications',
  templateUrl: './whatsapp-notifications.component.html',
  styleUrls: ['./whatsapp-notifications.component.scss']
})
export class WhatsappNotificationsComponent implements OnInit{
  public Editor: any = ClassicEditor;
  ckconfigQue:any;
  Message = `Dear {{customer_full_name}}, You have successfully scheduled {{service_name}} appointment with {{employee_full_name}}. We are waiting you on {{appointment_start_date_time}}. If you want to cancel the appointment, please click on the following link. If you want to reschedule the appointment, please click on the following link. Thank you for choosing our company, You are receiving this email at the account because you booked appointment at {{booking_website}}.`;

  ngOnInit(): void {
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
  }

}
