import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { INVOICE } from 'src/app/core/data/finance_module_json';
import { InvoiceService } from 'src/app/core/service/invoice.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  public selection = new SelectionModel<any>(true, []);
  table_cols = [
    'Invoice Id',
    // 'Created At',
    'Name',
    'Email',
    'Mobile No',
    'Related To',
    // 'Payment Status',
    'Total Amount',
    'Created On',
    'Invoice Status',
    'Actions'
  ];
  invoiceJson = INVOICE[0];
  total_count!: number;
  invoiceArray: any = [];
  currentPage = 0;
  pageEvent!: PageEvent;
  display_no_data = false;

  constructor(private service: InvoiceService) { }

  ngOnInit(): void {
    this.getInvoices(1);
  }

  getInvoices(page: number) {
    this.service.getInvoiceList(page).subscribe({
      next: (resp: any) => {
        // console.log(resp);
        this.total_count = resp.count;
        this.invoiceArray = resp.data;
        if(this.invoiceArray.length === 0) this.display_no_data = true;
      }
    });
  }

  retryInvoice(invoice_id: string) {
    this.service.retryForInvoice(invoice_id).subscribe(() => {
      this.getInvoices(1);
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.invoiceArray.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.invoiceArray.forEach((row: any) => {
      this.selection.select(row);
    });
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getInvoices(this.currentPage);
  }
}
