import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ADDCLIENTPROJECTJSON } from 'src/app/core/data/client_project_json';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { ClientsService } from 'src/app/core/service/clients.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { countryData } from 'src/app/core/data/countryData';
import { ReplaySubject, Subject, finalize, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';

@Component({
  selector: 'app-client-project-modal',
  templateUrl: './client-project-modal.component.html',
  styleUrls: ['./client-project-modal.component.scss']
})
export class ClientProjectModalComponent implements OnInit {
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  countryjson = countryData;
  submitted = false;
  clientForm!: FormGroup;
  addclient = ADDCLIENTPROJECTJSON[0];
  errorJson = ERRORJSON[0];
  currency!: string;
  clients: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() clientProjectData: any;
  products: any;
  staffMembers: any;
  info: any = {};
  minDate = new Date();
  loading = false;
  loggedInUser: any;
  tagsListArray: any;
  // @ViewChild('myFirstInput') myFirstInput!: ElementRef;

  constructor(private fb: FormBuilder, private settingsService: SettingsService, public ngbActiveModal: NgbActiveModal, private clientService: ClientsService, private authService: AuthService) { }
  ngOnInit() {
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    // console.log(this.clientProjectData);

    this.clientForm = this.fb.group({
      client_id: [this.clientProjectData ? this.clientProjectData.client_id : '', [Validators.required]],
      service_ids: [this.clientProjectData ? this.clientProjectData.service_ids : ''],
      name: [this.clientProjectData ? this.clientProjectData.name : '', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z0-9_\s]{1,}$/)]],
      phone: [this.clientProjectData ? this.clientProjectData.phone : ''],
      email: [this.clientProjectData ? this.clientProjectData.email : ''],
      tag: [this.clientProjectData ? this.clientProjectData.tag : ''],
      project_status: [this.clientProjectData ? this.clientProjectData.project_status : 'PENDING'],
      staff_ids: [this.clientProjectData ? this.clientProjectData.staff_ids : '', [Validators.required]],
      owner: [this.clientProjectData ? this.clientProjectData.owner : this.loggedInUser.data.id, [Validators.required]],
      description: [this.clientProjectData ? this.clientProjectData.description : ''],
      due_date: [this.clientProjectData ? this.clientProjectData.due_date : '', [Validators.required]],
      cost: [this.clientProjectData ? this.clientProjectData.cost : '', [Validators.pattern("[0-9]+"), Validators.min(0)]],
      source: [this.clientProjectData ? this.clientProjectData.source : 'manual'],
      id: [this.clientProjectData ? this.clientProjectData.id : ''],
      ccode: [this.clientProjectData?.ccode ? this.clientProjectData.ccode : this.authService.getCompanyData().ccode, [Validators.required]]
    });
    if (this.clientProjectData?.id) {
      this.clientForm.controls["client_id"].disable();
      this.clientForm.controls["email"].disable();
      this.clientForm.controls["phone"].disable();
      this.clientForm.controls["ccode"].disable();
      this.clientForm.controls["source"].disable();
    } else {
      this.info['owner_info'] = {
        fname: this.loggedInUser.data.fname,
        lname: this.loggedInUser.data.lname
      };
    }
    this.getAllClients();
    this.getStoreId();
    this.organisationMembersList();
    this.getTags();
  }

  // ngAfterViewInit() {
  //   setTimeout(() => {
  //     this.myFirstInput.nativeElement.focus();
  //   }, 0)
  // }

  addOptionToList = (option: any) => ({ name: option });

  get f() {
    return this.clientForm.controls;
  }

  cancelModal() {
    this.ngbActiveModal.dismiss();
  }
  onSubmit() {
    this.submitted = true;
    if (this.clientForm.valid) {
      this.loading = true;
      const owner_department = this.staffMembers.filter((member: any) => member.id === this.clientForm.value.owner);
      this.clientForm.patchValue({ due_date: this.convert(this.clientForm.controls['due_date'].value), cost: this.clientForm.controls['cost'].value ? this.clientForm.controls['cost'].value : null });
      const assignee_departments: any = [];

      if (this.clientForm.value.staff_ids.length !== 0) {
        this.staffMembers.forEach((item: any) => {
          this.clientForm.value.staff_ids.filter((assigne: any) => {
            if (assigne === item.id) {
              assignee_departments.push(item.department_info.id);
            }
          });
        });
      }

      const body = this.clientForm.value;
      body.info = this.info;
      if (this.clientForm.value.id) {
        this.updateClientProject(assignee_departments);
      } else {
        this.clientService.createClientProject({ ...body, email: this.clientForm.controls["email"].value, phone: this.clientForm.controls["phone"].value, ccode: this.clientForm.controls["ccode"].value, assignee_department: assignee_departments ? assignee_departments : [], owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : '' }).pipe(finalize(() => this.loading = false)).subscribe((response: any) => {
          if (response) {
            this.passEntry.emit("success");
            this.ngbActiveModal.dismiss();
          }
        });
      }
    }
  }
  updateClientProject(assignee_departments: any) {
    const owner_department = this.staffMembers.filter((member: any) => member.id === this.clientForm.value.owner);
    const body = this.clientForm.value;
    if (Object.keys(this.info).length === 0) {
      body.info = this.clientProjectData.info;
    } else {
      body.info = this.info;
    }
    this.clientService.updateClientProject(this.clientProjectData.id, { ...body, email: this.clientForm.controls["email"].value, phone: this.clientForm.controls["phone"].value, ccode: this.clientForm.controls["ccode"].value, client_id: this.clientForm.controls["client_id"].value, source: this.clientForm.controls["source"].value, assignee_department: assignee_departments ? assignee_departments : [], owner_department: owner_department[0].department_info.id ? owner_department[0].department_info.id : '' }).pipe(finalize(() => this.loading = false)).subscribe((response: any) => {
      if (response) {
        this.passEntry.emit("success");
        this.ngbActiveModal.dismiss();
      }
    });
  }

  getAllClients() {
    this.clientService.getAllClients(1).subscribe((response: any) => {
      this.clients = response.data;
    });

  }

  getStoreId() {
    this.clientService.getStoreId().subscribe((response: any) => {
      this.getStoreProducts(response.data.id);
    });
  }

  getStoreProducts(id: string) {
    this.clientService.getStoreProducts(id).subscribe((response: any) => {
      this.products = response.data;
    });
  }

  onSelectClient(data: any) {
    // console.log(data);
    this.clientForm.patchValue({ email: data.email });
    this.clientForm.patchValue({ phone: data.phone_number, ccode: data.ccode });
    this.clientForm.controls["email"].disable();
    this.clientForm.controls["phone"].disable();
    this.clientForm.controls["ccode"].disable();
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  selectOwner(data: any) {
    this.info['owner_info'] = {
      fname: data.fname,
      lname: data.lname
    };
  }

  //*******************************Search filters START******************************************** *//
  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

  getTags() {
    this.settingsService.getTags().subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }
}
