import { Component } from '@angular/core';
import { FINANCE } from 'src/app/core/data/finance_module_json';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss']
})
export class FinanceComponent {
  financeJson = FINANCE[0];
}
