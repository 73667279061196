import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';

@Pipe({
  name: 'dateConversion'
})
export class DateConversionPipe implements PipeTransform {
  constructor(private authService: AuthService) { }

  transform(value: any, ...args: unknown[]): unknown {
    const date = this.authService.getCompanyData();
    const datePipe = new DatePipe("en-US");
    value = datePipe.transform(value, date?.dateformat || "yyyy-MM-dd");
    return value;
  }

}
