import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {
  modalRef!: NgbModalRef;
  zoom_form!: FormGroup;
  submitted = false;
  zoomKeys: any = {};
  outlookData: any;
  notRegistered = false;
  googleConnected = false;
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);

  constructor(private modelservice: NgbModal, private service: SettingsService, private confirmationMsz: ConfirmationMszService) { }

  ngOnInit(): void {
    this.getKeys();
    this.initilize_zoom_form();
    this.getOutlook();
    this.getGoogle();
  }

  initilize_zoom_form() {
    this.zoom_form = new FormGroup({
      id: new FormControl(''),
      api_key: new FormControl('', Validators.required),
      secret_key: new FormControl('', Validators.required)
    });
  }

  get f() {
    return this.zoom_form.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.zoom_form.valid) {
      this.modalRef.close();
      if (this.zoom_form.value.id) {
        this.service.updateZoomKeys(this.zoom_form.value).subscribe(() => {
          this.getKeys();
        });
      } else {
        this.service.postZoomKeys(this.zoom_form.value).subscribe(() => {
          this.getKeys();
        });
      }
    }
  }

  getKeys() {
    this.service.getZoomKeys().subscribe({
      next: (resp: any) => {
        this.zoomKeys = resp.data;
        if (Object.keys(this.zoomKeys).length !== 0) {
          this.zoom_form.patchValue(this.zoomKeys);
        }
      }
    });
  }

  openZoomModal(modal: any) {
    console.log(this.zoom_form.value);
    this.modalRef = this.modelservice.open(modal, { size: 'md' });
  }

  zoomDelete() {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.service.deleteZoomKeys(this.zoom_form.value.id).subscribe(() => {
          this.getKeys();
          this.initilize_zoom_form();
        });
      }
    });
  }

  getGoogle() {
    this.service.getGoogle().subscribe((resp: any) => {
      if (resp.message === 'Staff Connected to Google') {
        this.googleConnected = true;
      } else if (resp.message === 'Staff Not Registered') {
        this.googleConnected = false;
        this.notRegistered = true;
      } else {
        this.googleConnected = false;
      }
    });
  }

  googleRegister() {
    this.service.googleRegister().subscribe(() => {
      this.getGoogle();
      this.authorizeTogmail();
    });
  }

  connectTogmail() {
    if (this.notRegistered) {
      this.googleRegister();
    } else {
      this.authorizeTogmail();
    }
  }

  authorizeTogmail() {
    this.service.connectTogmail().subscribe((resp: any) => {
      window.open(resp.data,"_self");
    });
  }

  disconnectgmail() {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.service.disconnectTogmail().subscribe(() => {
          this.getGoogle();
        });
      }
    });
  }

  getOutlook() {
    this.service.getOutlook().subscribe((resp: any) => {
      this.outlookData = resp.data;
    });
  }

  connecttoOutlook(type: string) {
    this.service.connectToOutlook(type).subscribe((resp: any) => {
      if (type === 'connect') {
        window.open(resp.data.url,"_self");
      } else {
        this.getOutlook();
      }
    });
  }

  disconnectfromOutlook() {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.connecttoOutlook('disconnect');
        this.getGoogle();
      }
    });
  }
}
