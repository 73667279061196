import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { PROJECTJSON } from 'src/app/core/data/crm_modal_json';
import { ClientsService } from 'src/app/core/service/clients.service';

@Component({
  selector: 'app-project-modal',
  templateUrl: './project-modal.component.html',
  styleUrls: ['./project-modal.component.scss']
})
export class ProjectModalComponent implements OnInit {
  projectJson = PROJECTJSON[0];
  selected_id!: string;
  selected_type!: string;
  projectsArray: any = [];
  pageEvent!: PageEvent;
  currentPage = 0;
  totalCount!: number;

  constructor(private client_service: ClientsService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.selected_id = params.id;
      this.selected_type = params.showtype;
      if (this.selected_id) {
        this.getProjects(this.selected_id, this.selected_type, 1);
      }
    });
  }

  getProjects(id: string, type: string, page: number) {
    if (type === 'contact') {
      this.client_service.getAllProjectsByContact(id, page).subscribe({
        next: (resp: any) => {
          // console.log("clients", resp);
          this.totalCount = resp.count;
          this.projectsArray = resp.data;
        }
      });
    } else if (type === 'company') {
      this.client_service.getAllProjectsByCompanyId(id, page).subscribe({
        next: (resp: any) => {
          // console.log("clients", resp);
          this.totalCount = resp.count;
          this.projectsArray = resp.data;
        }
      });
    }
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getProjects(this.selected_id, this.selected_type, this.currentPage);
  }
}
