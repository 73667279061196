<div class="modal-container">
    <div class="modal-header">
        <h5 class="modal-title text-primary fw-600">Add Template</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="modal-body overflow-auto" style="height: 77vh;">
        <div class="row mb-3">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <form [formGroup]="templateForm">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 text-end" *ngIf="!templateData">
                                <div class="dropdown d-inline-block" ngbDropdown>
                                    <button mat-button class="btn btn-primary d-flex align-items-center"
                                        [matMenuTriggerFor]="menuadd" id="page-add-fields-dropdown">
                                        <mat-icon class="font-size-26">add</mat-icon> Add Fields
                                    </button>
                                    <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                                        <button mat-menu-item *ngFor="let item of addListofFields"
                                            (click)="onFieldSelect(item)" class="py-2">
                                            <label> {{ item.type }} </label>
                                        </button>
                                    </mat-menu>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12">
                                <label class="fw-600 mb-2 text-primary">Title <span class="text-danger">*</span></label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Enter title'
                                        formControlName="title" />
                                    <mat-error class="error text-danger d-flex" *ngIf="submited && f['title'].errors">
                                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                        <span *ngIf="f['title'].errors['required']">Title
                                            {{errorjson.required}}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <label class="mb-2 fw-600 text-primary font-size-14">Description <span
                                        class="text-danger">*</span></label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <textarea style="outline: none;" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="10" cdkAutosizeMaxRows="12" matInput
                                        formControlName="description" placeholder="Enter Description"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <label class="mb-2 fw-600 text-primary">Image</label>
                                <ngx-dropzone (change)="onSelect($event)" [multiple]="false" class="bg-gray-light mb-3"
                                    style="height: 100px;">
                                    <ngx-dropzone-label>Drag a file here or <span
                                            class="text-primary fw-500">Browse</span>
                                        for a file to upload
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-image-preview [removable]="true" (removed)="onRemove(f)"
                                        ngProjectAs="ngx-dropzone-preview" *ngFor="let f of builder_image" [file]="f">
                                    </ngx-dropzone-image-preview>
                                </ngx-dropzone>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <label class="fw-600 mb-2 text-primary">Redirect URL</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" matInput placeholder='Enter Redirect URL'
                                        formControlName="redirection_url" />
                                    <mat-error class="error text-danger d-flex"
                                        *ngIf="submited && f['redirection_url'].errors">
                                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                        <span *ngIf="f['redirection_url'].errors['required']">Redirect URL
                                            {{errorjson.required}}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-sm-12 col-md-12" formArrayName="fields">
                                <div class="" *ngFor="let field of FormItems.controls;let field_index = index"
                                    [formGroupName]="field_index">
                                    {{field.value.field_label}}
                                    <div class="d-flex mx-2 flex-column" formArrayName="options"
                                        style="margin: 5px 0px;">
                                        <div class="d-flex align-items-center" *ngFor="let item of FormOpt(field_index).controls;let i =index">
                                            <div class="d-flex align-items-center mb-2" *ngIf="field.value.type === 'RADIO_BUTTON'">
                                                <input class="field" type="radio"
                                                    style="width: 17px;height: 17px;">&nbsp;
                                                <mat-form-field appearance="outline" class="mat-hint-none">
                                                    <input class="checkbox" [formControlName]="i" type="text"
                                                        style="outline: none" matInput placeholder="Form Name">
                                                </mat-form-field>&nbsp;
                                            </div>
                                            <div class="d-flex align-items-center mb-2" *ngIf="field.value.type === 'CHECK_BOX'">
                                                <input class="field" type="checkbox"
                                                    style="width: 17px;height: 17px;">&nbsp;
                                                <mat-form-field appearance="outline" class="mat-hint-none">
                                                    <input class="checkbox" [formControlName]="i" type="text"
                                                        style="outline: none" matInput placeholder="Form Name">
                                                </mat-form-field>&nbsp;
                                            </div>
                                            <div class="d-flex align-items-center mb-2" *ngIf="field.value.type === 'TEXT_AREA'">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <textarea style="outline: none;" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                                    cdkAutosizeMinRows="5" cdkAutosizeMaxRows="12" matInput [formControlName]="i"></textarea>
                                                </mat-form-field>
                                            </div>
                                            <mat-icon (click)="deleteFormOption(field_index,i)" matSuffix *ngIf="field.value.type !== 'TEXT_AREA'"
                                                class="text-light">close</mat-icon>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="d-inline-flex" *ngIf="field.value.type !== 'TEXT_AREA'">
                                        <button class="font-size-14 btn btn-light"
                                            (click)="addOptions(field_index)">Option</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="preview bg-gray-light p-3">
                        <div class="card mb-0">
                            <div class="card-body text-center">
                                <img src="./assets/images/upload-image.png" height="300" width="400" class="mb-2">
                                <div class="d-flex">
                                    <img src="{{f['logo'].value}}" height="50" class="mb-2">
                                    <div class="ms-3 text-start ">
                                        <div *ngFor="let skill of FormItems.value;let i = index">
                                            <input type="checkbox" *ngIf="skill.type==='CHECK_BOX'"
                                                style="width: 23px;height: 15px;margin: auto 0;">
                                            <input type="radio" *ngIf="skill.type==='RADIO_BUTTON'"
                                                style="width: 17px;height: 17px;margin: auto 0;">
                                                <!-- <span *ngFor="let opt of skill.options.value">{{FormItems[i]}}</span> -->
                                            {{skill.options.value}}
                                            <textarea type="text" *ngIf="skill.type==='TEXT_AREA'" rows="5"
                                                cols="50"></textarea>
                                        </div>
                                        <label class="fw-600">{{f['title'].value ? f['title'].value : 'Title'}}</label>
                                        <p>{{f['description'].value ? f['description'].value : 'Description'}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-end">
        <div class="d-flex">
            <button class="btn btn-light me-2" type="submit" (click)="close()">
                Cancel
            </button>
            <button class="btn btn-primary" type="submit">Save</button>
        </div>
    </div>
</div>