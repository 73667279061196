import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompaniesComponent } from './companies/companies.component';
import { ContactsComponent } from './contacts/contacts.component';
import { LeadsComponent } from './leads/leads.component';

const routes: Routes = [
  {
    path: 'contacts', component: ContactsComponent
  },
  {
    path: 'companies', component: CompaniesComponent
  },
  {
    path: 'leads', component: LeadsComponent
  },
  {
    path: 'crm-details', loadChildren: () => import('./management-modal/management-modal.module').then(m => m.ManagementModalModule) 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CrmRoutingModule { }
