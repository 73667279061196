import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BillingComponent } from './billing/billing.component';
import { CustomFieldsComponent } from './custom-fields/custom-fields.component';
import { DataAdministrationComponent } from './data-administration/data-administration.component';
import { EmailNotificationsComponent } from './email-notifications/email-notifications.component';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { SmsSettingsComponent } from './sms-settings/sms-settings.component';
import { TagsComponent } from './tags/tags.component';
import { UsersControlComponent } from './users-control/users-control.component';
import { WhatsappNotificationsComponent } from './whatsapp-notifications/whatsapp-notifications.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { PaymentsComponent } from './payments/payments.component';

const routes: Routes = [
  {
    path: '',
    component: GeneralSettingsComponent
  },
  {
    path: 'users&controls',
    component: UsersControlComponent
  },
  {
    path: 'sms',
    component: SmsSettingsComponent
  },
  {
    path: 'custom-fields',
    component: CustomFieldsComponent
  },
  {
    path: 'tags',
    component: TagsComponent
  },
  {
    path: 'billing',
    component: BillingComponent
  },
  {
    path: 'data-administration',
    component: DataAdministrationComponent
  },
  {
    path:'email-notifications',
    component: EmailNotificationsComponent
  },
  {
    path:'whatsapp-notificaions',
    component: WhatsappNotificationsComponent
  },
  {
    path:'integrations',
    component: IntegrationsComponent
  },
  {
    path:'payments',
    component: PaymentsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
